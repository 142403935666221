import React from 'react';
import { EArticleStatuses, TArticle, TArticleFilled } from './ArticlesTypes';
import { Badge } from 'antd';
import { TFunction } from 'i18next';

export const getArticleStatus = (article: TArticle): EArticleStatuses => {
  if (!article.publishDate && !article.unpublishDate) {
    return EArticleStatuses.DRAFT;
  }
  if (article.publishDate && !article.unpublishDate) {
    return EArticleStatuses.PUBLISHED;
  }
  if (article.unpublishDate) {
    return EArticleStatuses.ARCHIVED;
  }
  return EArticleStatuses.DRAFT;
};

export const getArticleStatusRendered = (
  article: TArticle | TArticleFilled,
  t: TFunction<'translation', undefined>,
): JSX.Element => {
  switch (getArticleStatus(article)) {
    case EArticleStatuses.DRAFT: {
      return <Badge color="yellow" text={t('generic.draft')} />;
    }
    case EArticleStatuses.ARCHIVED: {
      return <Badge status="default" text={t('generic.archived')} />;
    }
    case EArticleStatuses.PUBLISHED: {
      return <Badge color="green" text={t('generic.published')} />;
    }
    default: {
      return <Badge color="yellow" text={t('generic.draft')} />;
    }
  }
};
