import styles from './StaticInput.module.scss';

type StaticInputProps = {
  text: string;
};

function StaticInput({ text }: StaticInputProps) {
  return (
    <div className={styles.container}>
      <span className={styles.text}>{text}</span>
    </div>
  );
}

export default StaticInput;
