import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import BackgroundWave from '../../../components/BackgoundWave/BackgroundWave';
import SideBackgroundImage from '../../../components/SideBackgroudImage/SideBackgroundImage';
import CentralCard from '../../../components/CentralCard/CentralCard';
import styles from './ResetPassword.module.scss';
import { resetPasswordAsync, selectResetPasswordStatus } from '../AuthSlice';
import requestStatuses from '../../../constants/requestStatuses';
import { ROUTES } from '../../../constants/routes';

const { Item } = Form;

function ResetPassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useParams() as { token: string };
  const navigate = useNavigate();

  const status = useSelector(selectResetPasswordStatus);

  const [password, setPassword] = useState(null as string | null);
  const [confirmPassword, setConfirmPassword] = useState(null as string | null);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (status === requestStatuses.SUCCESS && sent) {
      navigate(ROUTES.login);
    }
  });

  const onSend = () => {
    if (token && password && password === confirmPassword) {
      dispatch<any>(resetPasswordAsync({ password, token }));
    }
  };

  return (
    <BackgroundWave>
      <SideBackgroundImage
        leftImage="/assets/auth/fox.svg"
        rightImage="/assets/auth/payment.svg"
      >
        <div className={styles.wrap}>
          <Link to="/" className={styles.logoContainer}>
            <ReactSVG className={styles.logo} src="/assets/general/logo.svg" />
            <div className={styles.logoText}>Vitaance app</div>
          </Link>
          <CentralCard className={styles.centralCard}>
            <>
              <div className={styles.title}>{t('resetPassword.title')}</div>
              <div className={styles.helpText}>
                {t('resetPassword.helpText')}
              </div>
              <Form className={styles.form} layout="vertical">
                <Item label={t('auth.newPassword')}>
                  <Input.Password
                    className={styles.input}
                    placeholder={t('auth.inputPassword') || ''}
                    onChange={(e) => setPassword(e.target.value)}
                    onPaste={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    required
                  />
                </Item>
                <Item label={t('auth.confirmPassword')}>
                  <Input.Password
                    className={styles.input}
                    placeholder={t('auth.inputPassword') || ''}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onPaste={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    required
                  />
                </Item>
                {status === requestStatuses.FAILED && (
                  <div className={styles.errorMessage}>
                    {t('auth.expiredLink')}
                  </div>
                )}
                {confirmPassword && confirmPassword !== password && (
                  <div className={styles.errorMessage}>
                    {t('auth.passwordMatchError')}
                  </div>
                )}
                <Item>
                  <Button
                    onClick={() => {
                      setSent(true);
                      onSend();
                    }}
                    htmlType="submit"
                    className={styles.signInButton}
                    type="primary"
                    disabled={password !== confirmPassword}
                  >
                    {t('generic.send')}
                  </Button>
                </Item>
              </Form>
            </>
          </CentralCard>
          <div className={styles.legalBlock}>
            <div>{t('generic.copyright')}</div>
            <div>
              <a href="https://www.vitaance.com/aviso-legal" target="_blank" rel="noreferrer">
                {t('generic.legalDocs')}
              </a>
              {' '}
              |
              {' '}
              <a
                href="https://www.vitaance.com/politica-de-privacidad-vitaance"
                target="_blank"
                rel="noreferrer"
              >
                {t('generic.privacyPolicy')}
              </a>
            </div>
          </div>
        </div>
      </SideBackgroundImage>
    </BackgroundWave>
  );
}

export default ResetPassword;
