import { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Button, Form, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import fileToBase64 from '../../helpers/fileToBase64';
import styles from './ImageUploadForm.module.scss';

type TImageUploadFormProps = {
  onFileChanged: (image: File | null, imageBase64: string | null) => void;
  currentImage?: string | null;
  tooltip: string;
  title?: string | null;
  disabled?: boolean;
};

function ImageUploadForm({
  onFileChanged,
  currentImage,
  tooltip,
  title,
  disabled,
}: TImageUploadFormProps) {
  const { t } = useTranslation();

  const [imageBase64, setImageBase64] = useState(null as string | null);

  useEffect(() => {
    if (currentImage) {
      setImageBase64(currentImage);
    } else {
      setImageBase64(null);
    }
  }, [currentImage]);

  const onCropImage = (file: any) => {
    fileToBase64(file, (base64) => {
      setImageBase64(base64);
      onFileChanged(file, base64);
    });
  };

  const onDelete = () => {
    setImageBase64(null);
    onFileChanged(null, null);
  };

  return (
    <div className={styles.image}>
      <Form layout="vertical">
        <Form.Item label={title || t('generic.uploadImage.title')}>
          <div className={styles.uploadContainer}>
            <ImgCrop aspect={2} onModalOk={onCropImage}>
              <Upload
                customRequest={() => {}}
                className={styles.upload}
                name="image"
                listType="picture-card"
                showUploadList={false}
                disabled={disabled}
              >
                {imageBase64 ? (
                  <img
                    className={styles.uploadImage}
                    src={imageBase64}
                    alt="challenge pic"
                  />
                ) : (
                  <div className={styles.uploadButton}>
                    <PlusOutlined />
                    <div className={styles.uploadText}>
                      {t('generic.upload')}
                    </div>
                  </div>
                )}
              </Upload>
            </ImgCrop>
            <div className={styles.uploadHint}>{tooltip}</div>
          </div>
          {imageBase64 && (
            <div className={styles.uploadButtons}>
              <ImgCrop aspect={2} onModalOk={onCropImage}>
                <Upload
                  customRequest={() => {}}
                  showUploadList={false}
                >
                  <Button
                    type="primary"
                    ghost
                    icon={<UploadOutlined />}
                    size="small"
                    disabled={disabled}
                  >
                    {t('generic.replace')}
                  </Button>
                </Upload>
              </ImgCrop>
              <Button
                className={styles.deleteButton}
                icon={<DeleteOutlined />}
                size="small"
                onClick={onDelete}
                disabled={disabled}
              >
                {t('generic.delete')}
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </div>
  );
}

ImageUploadForm.defaultProps = {
  currentImage: null,
  title: null,
  disabled: false,
};

export default ImageUploadForm;
