import { useEffect, useState } from 'react';
import { Button, Modal, Tabs } from 'antd';
import { omit } from 'lodash';
import { ProPageHeader } from '@ant-design/pro-layout';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './AdminUserForm.module.scss';
import ModalSide from '../../../components/ModalSide/ModalSide';
import Information from './screens/Information/Information';
import { TAdminUser, TAdminUserForm, TAdminUserResponse } from '../AdminUsersTypes';
import { actions, asyncActions, selectors } from '../AdminUsersSlice';
import DeleteAdminUserModal from '../../../components/Modals/AdminUsersModals/DeleteAdminUserModal/DeleteAdminUserModal';
import ContinueEditingModal from '../../../components/Modals/ContinueEditingModal/ContinueEditingModal';
import RequestStatuses from '../../../constants/requestStatuses';

type TAdminUserFormProps = {
  show: boolean;
  currentAdminUser?: TAdminUser['id'] | null;
  onClose: () => void;
  onDelete: (id: TAdminUser['id']) => () => void;
};


const InfoFormSchema = yup
  .object({
    firstName: yup
      .string()
      .trim()
      .required('adminUsers.validation.firstNameRequired'),
    lastName: yup
      .string()
      .trim()
      .required('adminUsers.validation.lastNameRequired'),
    email: yup
      .string()
      .trim()
      .email('adminUsers.validation.emailFormat')
      .required('adminUsers.validation.emailRequired'),
    phoneCountryCode: yup.string().trim().optional(),
    phoneNumber: yup.string().trim().optional(),
  })
  .required();

function AdminUserForm({
  show,
  currentAdminUser,
  onClose,
  onDelete,
}: TAdminUserFormProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const infoFormMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(InfoFormSchema),
  });

  const { isDirty, isValid } = infoFormMethods?.formState;

  const [emailError, setEmailError] = useState('');

  const currentAdminUserData = useSelector(selectors.currentItem);
  const statuses = useSelector(selectors.statuses);

  const userEmail = infoFormMethods.watch('email');

  useEffect(() => {
    setEmailError('');
  }, [userEmail, currentAdminUserData]);

  useEffect(() => {
    if (currentAdminUser) {
      dispatch<any>(asyncActions.get({ id: currentAdminUser }));
    } else {
      dispatch(actions.clearCurrentItem());
    }
  }, [dispatch, currentAdminUser]);

  useEffect(() => {
    if (statuses.create === RequestStatuses.SUCCESS) {
      onCancel();
    }

    const newValue =  statuses.create === RequestStatuses.FAILED ||
      statuses.update === RequestStatuses.FAILED
      ? t('generic.emailAlreadyExist')
      : ''
      
    setEmailError(newValue)
  }, [statuses]);

  const onSave = (data: TAdminUserForm) => {
    if (currentAdminUser) {
      const adminUserData: TAdminUserForm = omit(data, [
        'phoneCountryCode',
        'phoneNumber',
      ]);

      if (data.phoneCountryCode && data.phoneNumber) {
        adminUserData.phoneNumber = `${data.phoneCountryCode}-${data.phoneNumber}`;
      }

      dispatch<any>(
        asyncActions.update({
          id: currentAdminUser,
          dataJson: adminUserData,
        }),
      );

      onCancel();
    }
  };

  const onCancel = () => {
    dispatch(actions.clearStatuses());
    onClose();
  };

  const onBackdropClick = () => {
    Modal.confirm({
      title: t('generic.modals.continueEditing.title'),
      okText: t('generic.modals.continueEditing.buttons.continue'),
      cancelText: t('generic.modals.continueEditing.buttons.discard'),
      content: t('generic.modals.continueEditing.content'),
      icon: <ExclamationCircleOutlined className={styles.confirmIcon} />,
      onCancel,
    });
  };

  return (
    <ModalSide
      position="right"
      show={show}
      onClose={isDirty ? onBackdropClick : onCancel}
    >
      <>
        <ProPageHeader
          prefixedClassName={styles.header}
          title={t('adminUsers.userProfile')}
          extra={[
            <DeleteAdminUserModal
              userName={`${currentAdminUserData?.firstName} ${currentAdminUserData?.lastName}`}
              onClick={() => {
                if (currentAdminUserData?.id) {
                  onDelete(currentAdminUserData?.id)();
                  onCancel();
                }
              }}
            >
              <Button
                icon={<DeleteOutlined />}
                type="text"
                disabled={
                  !currentAdminUserData ||
                  currentAdminUserData.status.adminStatusTitle === 'deleted'
                }
              >
                {t('adminUsers.buttons.deleteUser')}
              </Button>
            </DeleteAdminUserModal>,
            isDirty ? (
              <ContinueEditingModal onClick={onCancel}>
                <Button className={styles.cancelButton}>
                  {t('generic.cancel')}
                </Button>
              </ContinueEditingModal>
            ) : (
              <Button className={styles.cancelButton} onClick={onCancel}>
                {t('generic.cancel')}
              </Button>
            ),
            <Button
              type="primary"
              disabled={!isValid || !!emailError}
              onClick={infoFormMethods.handleSubmit(onSave)}
            >
              {t('adminUsers.buttons.saveUser')}
            </Button>,
          ]}
        />
        <Tabs activeKey="1">
          <Tabs.TabPane tab={t('generic.information')} key="1">
            <FormProvider {...infoFormMethods}>
              <Information error={emailError} />
            </FormProvider>
          </Tabs.TabPane>
        </Tabs>
      </>
    </ModalSide>
  );
}

export default AdminUserForm;
