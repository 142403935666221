import styles from './VLogo.module.scss';

import logo from './images/Vector.svg';

function VLogo() {
  return (
    <>
      <div className={styles.container}>
        <img src={logo} />
        <span className={styles.text}>Vitaance</span>
      </div>
    </>
  );
}

export default VLogo;
