import buildSlice from '../../../../../general/helpers/genericSliceBuilder';
import {
  TAdminUser,
  TCompanyAdminsListResponse,
  TCompanyAdminResponse,
} from './CompanyAdminsType';
import {
  createCompanyAdmin,
  deleteCompanyAdmin,
  updateCompanyAdmin,
  fetchCompanyAdmin,
} from './CompanyAdminsApi';

const companyAdminsSlice = buildSlice<
  TAdminUser,
  TCompanyAdminResponse,
  TCompanyAdminsListResponse
>('companyAdmins', {
  createJson: createCompanyAdmin as (data: {
    [key: string]: any;
  }) => Promise<TCompanyAdminResponse>,
  delete: deleteCompanyAdmin,
  updateJson: updateCompanyAdmin as (
    id: number,
    data: { [key: string]: any },
  ) => Promise<any>,
  fetchList: fetchCompanyAdmin,
});

export const { selectors, asyncActions, actions } = companyAdminsSlice;

export default companyAdminsSlice.reducer;
