import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Badge,
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Row,
  Select,
  Table,
  TablePaginationConfig,
  Tag,
  Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { flatten } from 'lodash';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Key } from 'antd/es/table/interface';
import styles from './ProductsList.module.scss';
import {
  activatePolicies,
  deactivatePolicies,
  getCompanyProductsByEmployees,
  selectProducts,
  selectStatuses,
  clearStatuses,
  clearProcessedPolicies,
  selectProcessedPolicies,
  deleteEmployeeCertificate,
} from './ProductsSlice';
import UserForm from '../../../../../../users/UserForm/UserForm';
import { enableForRoles } from '../../../../../../../helpers/role';
import { UserRoles } from '../../../../../../../constants/userRoles';
import { selectAuthenticatedUser } from '../../../../../../auth/AuthSlice';
import RoleWrapper from '../../../../../../../components/RoleWrapper/RoleWrapper';
import { TUser } from '../../../../../../users/UsersTypes';
import {
  asyncActions as usersAsyncActions,
  selectors as usersSelectors,
} from '../../../../../../users/UsersSlice';
import { TProduct } from './ProductsType';
import { selectors, actions, asyncActions } from '../../Products/ProductsSlice';
import { selectors as companySelectors } from '../../../../CompaniesSlice';
import ActionAlert from '../../../../../../../components/ActionAlert/ActionAlert';
import { config } from '../../../../../../../config';
import { defaultHeaders } from '../../../../../../../helpers/apiRequest';
// eslint-disable-next-line max-len
import DeleteEmployeeCertificateModal from '../../../../../../../components/Modals/CollectiveModals/DeleteEmployeeCertificateModal/DeleteEmployeeCertificateModal';

type TProductsListProps = {
  companyId: number;
};

type TTableControls = {
  pagination: {
    page: number | undefined;
    perPage: number | undefined;
  };
  order?:
    | {
        field: string;
        orderDirection: string;
      }[]
    | null;
  filter?:
    | {
        field: string;
        value: string;
      }[]
    | null;
  search?: string | null;
};

type TTableData = {
  pathToFile: string | undefined;
  key: number;
  id: number;
  employeeId: number;
  employeeName: number;
  plan: string;
  name: string;
  employeeAddedAt: string;
  policyStatus: string;
  actions: null;
  activatedAt: string | null;
  deactivatedAt: string | null;
  filePath: string | null;
};

const badgeColors: any = {
  Basic: '#EB2F96',
  Flexible: '#13C2C2',
  Individual: '#597EF7',
};

function ProductsList({ companyId }: TProductsListProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const products = useSelector(selectProducts);
  const statuses = useSelector(selectStatuses);
  const processedPolicies = useSelector(selectProcessedPolicies);
  const selectedUser = useSelector(usersSelectors.currentItem);
  const generalProductsData = useSelector(selectors.listData);
  const currentCompany = useSelector(companySelectors.currentItem);

  const [tableControlObj, setTableControlObj] = useState({
    pagination: {
      page: 1,
      perPage: 10,
    },
    order: [
      {
        field: 'employeeAddedAt',
        orderDirection: 'desc',
      },
    ],
  } as TTableControls);
  const [userEditWindow, setUserEditWindow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null as number | null);
  const [selectedProducts, setSelectedProducts] = useState([] as TTableData[]);
  const [bulkOption, setBulkOption] = useState(
    currentCompany?.status.companyStatusTitle !== 'active'
      ? 'inactivate'
      : 'activate',
  );
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    dispatch(actions.clearFilters());
    dispatch(actions.setPagination({ page: 1, perPage: -1 }));
    dispatch<any>(asyncActions.fetchList({ id: companyId }));

    return () => {
      dispatch(clearStatuses());
      dispatch(clearProcessedPolicies());
    };
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch<any>(
      getCompanyProductsByEmployees({
        companyId,
        ...tableControlObj,
      }),
    );
  }, [dispatch, companyId, tableControlObj]);

  useEffect(() => {
    if (statuses.activate === 'success') {
      if (processedPolicies.updated.length) {
        ActionAlert.success(
          t(
            processedPolicies.updated.length > 1
              ? 'collective.company.products.activatedBulk'
              : 'collective.company.products.activated',
          ),
        );
      }

      if (processedPolicies.ignored.length) {
        ActionAlert.info(
          t(
            processedPolicies.ignored.length > 1
              ? 'collective.company.products.ignored'
              : 'collective.company.products.ignoredSingular',
            {
              names: processedPolicies.ignored
                .map((product: TProduct) => product.employeeName)
                .join(', '),
            },
          ),
        );
      }
    }

    if (statuses.deactivate === 'success') {
      ActionAlert.info(
        t(
          processedPolicies.updated.length > 1
            ? 'collective.company.products.deactivatedBulk'
            : 'collective.company.products.deactivated',
        ),
      );
    }
  }, [statuses]);

  useEffect(() => {
    if (products.outdated) {
      dispatch<any>(
        getCompanyProductsByEmployees({
          companyId,
          ...tableControlObj,
        }),
      );
    }
  }, [products]);

  const isFiltersActive = () =>
    tableControlObj.filter?.length || tableControlObj.search;

  const onUserDelete = (id: TUser['id']) => () => {
    if (selectedUser) {
      dispatch<any>(usersAsyncActions.delete({ item: selectedUser }));
    }
  };

  const onEdit = (userId: number) => {
    setUserEditWindow(true);
    setSelectedUserId(userId);
  };

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    const controls = {
      ...tableControlObj,
      pagination: {
        ...tableControlObj.pagination,
        page: tablePagination.current,
        perPage: tablePagination.pageSize,
      },
      order: sorter?.order
        ? [
          {
            field: sorter.field,
            orderDirection: sorter.order === 'descend' ? 'desc' : 'asc',
          },
        ]
        : [],
      filter: flatten(
        Object.keys(filters).map((field: any) =>
          (filters[field]?.length
            ? filters[field].map((filter: any) => ({
              field,
              value: filter,
            }))
            : [])),
      ),
    };

    setTableControlObj(controls);
  };

  const onBulkOperation = () => {
    const options = {
      companyId,
      policies: selectedProducts.map((selectedEmployee) => ({
        productId: selectedEmployee.id,
        userId: selectedEmployee.employeeId,
      })),
    };

    switch (bulkOption) {
      case 'activate': {
        dispatch<any>(activatePolicies(options));
        break;
      }
      case 'inactivate': {
        dispatch<any>(deactivatePolicies(options));
        break;
      }
    }

    setSelectedProducts([]);
    setTableControlObj({ ...tableControlObj });
  };

  const columns = [
    {
      title: t('collective.company.products.userId'),
      dataIndex: 'employeeId',
      width: 100,
      sorter: true,
    },
    {
      title: t('collective.company.products.employee'),
      dataIndex: 'employeeName',
      render: (_: null, record: TTableData) => (
        <a onClick={() => onEdit(record.employeeId)}>{record.employeeName}</a>
      ),
      sorter: true,
    },
    {
      title: t('collective.company.products.plan'),
      dataIndex: 'plan',
      filters: [
        { value: 'Basic', text: t('collective.company.products.basic') },
        { value: 'Flexible', text: t('collective.company.products.flexible') },
        {
          value: 'Individual',
          text: t('collective.company.products.individual'),
        },
      ],
      onFilter: (value: string | number | boolean, record: TTableData) =>
        record.plan === value,
      render: (_: null, record: TTableData) => (
        <Tag color={badgeColors[record.plan]} key={record.plan}>
          {record.plan}
        </Tag>
      ),
    },
    {
      title: t('collective.company.products.product'),
      dataIndex: 'productId',
      filters: generalProductsData?.list?.map((product) => ({
        value: product.id,
        text: product.name,
      })),
      render: (_: string, record: TTableData) => record.name,
    },
    {
      title: t('collective.company.products.additionDate'),
      dataIndex: 'employeeAddedAt',
      width: 150,
      sorter: true,
    },
    {
      title: t('collective.company.products.policyStatus'),
      dataIndex: 'policyStatus',
      filters: [
        { value: 'pending', text: t('generic.pending') },
        { value: 'active', text: t('generic.active') },
        { value: 'inactive', text: t('generic.inactive') },
      ],
      render: (_: null, record: TTableData) => {
        let badgeColor = 'default';

        switch (record.policyStatus) {
          case 'pending':
            badgeColor = 'yellow';
            break;
          case 'active':
            badgeColor = 'green';
            break;
        }

        return (
          <RoleWrapper
            roles={[UserRoles.ADMIN]}
            alternative={(
              <Badge
                color={badgeColor}
                text={t(`generic.${record.policyStatus}`)}
              />
            )}
          >
            <Select
              key={`${record.id}-${record.policyStatus}`}
              bordered={false}
              defaultValue={record.policyStatus}
              options={[
                {
                  value: 'pending',
                  label: <Badge color="yellow" text={t('generic.pending')} />,
                  disabled: true,
                },
                {
                  value: 'active',
                  label: <Badge color="green" text={t('generic.active')} />,
                  disabled:
                    currentCompany?.status.companyStatusTitle !== 'active' ||
                    !record.activatedAt,
                },
                {
                  value: 'inactive',
                  label: <Badge color="#BDC0C1" text={t('generic.inactive')} />,
                },
              ]}
              onChange={(label: string) => {
                const options = {
                  companyId,
                  policies: [
                    {
                      productId: record.id,
                      userId: record.employeeId,
                    },
                  ],
                };

                if (label === 'active') {
                  dispatch<any>(activatePolicies(options));
                } else if (label === 'inactive') {
                  dispatch<any>(deactivatePolicies(options));
                }
              }}
            />
          </RoleWrapper>
        );
      },
    },
    {
      title: t('generic.products.certificate'),
      dataIndex: 'pathToFile',
      width: 170,
      sorter: false,
      className: styles.actionColumn,
      render: (_: null, record: TTableData) => {
        if (!record.pathToFile) return null;
        return (
          <div className={styles.tableControl}>
            <div className={`${styles.actionButton}`}>
              <a
                href={record.pathToFile}
                target="_blank"
                className={styles.actionButtonInfo}
                rel="noreferrer"
              >
                Preview
              </a>
            </div>

            <DeleteEmployeeCertificateModal
              onClick={() => {
                dispatch<any>(
                  deleteEmployeeCertificate({
                    companyId: record.id,
                    userId: record.employeeId,
                  }),
                );
              }}
            >
              <div className={`${styles.actionButton} ${styles.actionButtonPrimary}`}>
                <span className={styles.actionDelete}>Delete</span>
              </div>
            </DeleteEmployeeCertificateModal>
          </div>
        );
      },
    },
  ];

  if (enableForRoles(authenticatedUser.role as UserRoles, [UserRoles.ADMIN])) {
    columns.push({
      title: t('collective.company.products.actions'),
      dataIndex: 'actions',
      width: 100,
      className: styles.actionColumn,
      render: (_: null, record: TTableData) => (
        <div className={styles.tableActions}>
          <div
            className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
            onClick={() => onEdit(record.employeeId)}
          >
            <EditOutlined />
          </div>
        </div>
      ),
    } as any);
  }

  if (!products.data.length && !isFiltersActive()) {
    return (
      <Row className={styles.emptyContainer}>
        <Col span={24}>
          <Empty
            className={styles.empty}
            image="/assets/user/no-users-icon.svg"
            description={t('collective.company.products.empty')}
          />
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <Row gutter={32} align="middle" className={styles.searchBarContainer}>
            <Col span={12}>
              <div className={styles.title}>
                {t('collective.company.products.all', {
                  productsCount: products?.pagination?.total || 0,
                })}
              </div>
            </Col>
            <Col span={12} className={styles.conrolBlock}>
              <Input.Search
                allowClear
                className={styles.searchField}
                placeholder={t('collective.company.products.search') || ''}
                onSearch={(value: string) => {
                  setTableControlObj({
                    ...tableControlObj,
                    pagination: {
                      ...tableControlObj.pagination,
                      page: 1,
                    },
                    search: value,
                  });
                }}
              />
              <Upload
                name="file"
                action={`${config.BACKEND_URL}/v1/users/products/files`}
                method="PATCH"
                headers={defaultHeaders()}
                showUploadList={false}
                onChange={(info: any ) => {
                  if (info.file.status === 'error') {
                    setOpen(true);
                    if (typeof info.file.response.message === 'string'
                      || info.file.response.message instanceof String) {
                      setModalText(info.file.response.message);
                    }
                    if (Array.isArray(info.file.response.message)) {
                      const errorTitle = info.file.response?.error ?? '';
                      const errorText = info.file.response.message.map((item: any) =>
                        `${Object.keys(item.constraints).map((param) => `${item.constraints[param]}`)}`);
                      const message = `${errorTitle} \n${errorText}`;
                      setModalText(message);
                    }
                  }

                  dispatch<any>(
                    getCompanyProductsByEmployees({
                      companyId,
                      ...tableControlObj,
                    }),
                  );
                }}
              >
                <Button
                  type="default"
                  icon={<PlusOutlined />}
                >
                  {t('generic.products.uploadSertificate')}
                </Button>
              </Upload>

              <Modal
                title={t('generic.modal.error')}
                visible={open}
                onOk={() => {
                  setOpen(false);
                  setConfirmLoading(false);
                  setModalText('');
                }}
                closable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                confirmLoading={confirmLoading}
              >
                {modalText}
              </Modal>
            </Col>
          </Row>
          {!!selectedProducts.length && (
            <RoleWrapper roles={[UserRoles.ADMIN]}>
              <div className={styles.bulkMenu}>
                <div className={styles.bulkMenuCounter}>
                  {t('generic.bulk.selected', {
                    length: selectedProducts.length,
                  })}
                </div>
                <Select
                  className={styles.bulkMenuDropdown}
                  defaultValue={bulkOption}
                  options={[
                    ...(currentCompany?.status.companyStatusTitle !== 'active'
                      ? []
                      : [{ value: 'activate', label: t('generic.activate') }]),
                    { value: 'inactivate', label: t('generic.inactivate') },
                  ]}
                  onChange={(value: string) => setBulkOption(value)}
                />
                <Button type="primary" onClick={onBulkOperation}>
                  {t('generic.confirm')}
                </Button>
              </div>
            </RoleWrapper>
          )}
          <Row>
            <Col span={24}>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedProducts.map(
                    (selectedEmployee) => selectedEmployee.key,
                  ),
                  onChange: (
                    selectedRowKeys: Key[],
                    selectedRows: Array<TTableData>,
                  ) => {
                    setSelectedProducts(selectedRows);
                  },
                }}
                dataSource={products?.data?.map((product: TProduct) => ({
                  ...product,
                  key: `${product.employeeId}-${product.id}`,
                  employeeAddedAt: dayjs(product.employeeAddedAt).format(
                    'DD/MM/YYYY',
                  ),
                }))}
                columns={columns}
                pagination={{
                  showQuickJumper: true,
                  total: products?.pagination?.total,
                  current: tableControlObj?.pagination?.page,
                  pageSize: tableControlObj?.pagination?.perPage || 0,
                  defaultPageSize: 10,
                  defaultCurrent: 1,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  showSizeChanger: true,
                }}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <UserForm
        currentUser={selectedUserId}
        show={userEditWindow}
        onClose={() => {
          setUserEditWindow(false);
          setSelectedUserId(null);

          dispatch<any>(
            getCompanyProductsByEmployees({
              companyId,
              ...tableControlObj,
            }),
          );
        }}
        onDelete={onUserDelete}
      />
    </div>
  );
}

export default ProductsList;
