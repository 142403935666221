import React from 'react';
import { useTranslation } from 'react-i18next';
import { EGoalTypes, IChallenge, TTopTeam } from '../../../ChallengesTypes';
import { goalTypeUnits } from '../../constants/goalTypeUnits';
import styles from './TopTeams.module.scss';

type TTopTeamsProps = {
  teams: TTopTeam[];
  currentChallenge: IChallenge;
};

function TopTeams({ teams, currentChallenge }: TTopTeamsProps) {
  const { t } = useTranslation();

  const leaderText = {
    1: 'challenges.form.leaderboard.firstPosition',
    2: 'challenges.form.leaderboard.secondPosition',
    3: 'challenges.form.leaderboard.thirdPosition',
  };

  const goalTypeUnit = goalTypeUnits[currentChallenge.goalType];

  const teamInfoBuilder = (
    number: keyof typeof leaderText,
    name: string,
    total: number,
  ) => {
    const numStyles = {
      1: styles.number1,
      2: styles.number2,
      3: styles.number3,
    };
    const resultNumber = ((value: number) => {
      const calcVal = goalTypeUnits[currentChallenge.goalType]?.refunc(value);

      switch (currentChallenge.goalType) {
        case EGoalTypes.DISTANCE:
          return Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }).format(calcVal);
        default:
          return Intl.NumberFormat('de-DE', {
            maximumFractionDigits: 0,
          }).format(calcVal);
      }
    })(total);

    return (
      <div className={styles.teamInfo}>
        <div className={`${styles.number} ${numStyles[number]}`}>{number}</div>
        <div className={styles.teamData}>
          <div className={styles.teamName}>{name}</div>
          <div className={styles.teamResult}>
            {t(leaderText[number], {
              result: `${resultNumber} ${t(goalTypeUnit.name)}`,
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>
        {t('challenges.form.leaderboard.top3', { count: teams.length })}
      </div>
      <div className={styles.subtitle}>
        {t('challenges.form.leaderboard.top3Subtitle')}
      </div>
      <div className={styles.topBlock}>
        {teams.map((team, idx) => {
          let { total } = team;

          if (currentChallenge.workout) {
            switch (currentChallenge.goalType) {
              case EGoalTypes.STEPS:
                total = team.total_workout_steps_progress || 0;
                break;
              case EGoalTypes.DISTANCE:
                total = team.total_workout_distance_progress || 0;
                break;
              case EGoalTypes.CALORIES:
                total = team.total_workout_calories_progress || 0;
                break;
            }
          }

          return teamInfoBuilder(
            (idx + 1) as keyof typeof leaderText,
            team.name,
            total,
          );
        })}
      </div>
    </div>
  );
}

export default TopTeams;
