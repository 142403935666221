import { UserRoles } from '../../constants/userRoles';
import { EQuizStatuses, IQuiz } from './QuizzesTypes';

export const getQuizStatus = (quiz: IQuiz): EQuizStatuses => {
  if (!quiz.publishDate && !quiz.unpublishDate) {
    return EQuizStatuses.DRAFT;
  }

  if (quiz.publishDate && !quiz.unpublishDate) {
    return EQuizStatuses.PUBLISHED;
  }

  if (quiz.unpublishDate) {
    return EQuizStatuses.ARCHIVED;
  }

  return EQuizStatuses.DRAFT;
};

const consistsAllIds = (companyIds: number[], quizzesIds: number[]) =>
  !quizzesIds.find((cId) => !companyIds.includes(cId));

export const isActionAvailable = (
  role: UserRoles | null,
  userCompaniesIds: number[] = [],
  quizCompaniesIds: number[] = [],
): boolean =>
  role === UserRoles.ADMIN ||
  !quizCompaniesIds.length ||
  consistsAllIds(userCompaniesIds, quizCompaniesIds);