import { TPlace } from './PlacesTypes';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPlaces } from './PlacesApi';
import { extraReducerBuilderV2 } from '../../helpers/extraReducerBuilder';
import RequestStatuses from '../../constants/requestStatuses';

const placeActions = {
  FETCH_PLACES: 'places/fetchPlaces',
};

type TState = {
  statuses: {
    fetch?: RequestStatuses | null;
  };
  places: TPlace[];
};

const initialState: TState = {
  statuses: {},
  places: [],
};

export const fetchPlacesAsync = createAsyncThunk(
  placeActions.FETCH_PLACES,
  async ({ query }: { query: string }) => {
    return fetchPlaces(query);
  },
);

export const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    extraReducerBuilderV2<TState, TPlace[]>(builder, fetchPlacesAsync, {
      statusChanger: (state, status) => {
        state.statuses.fetch = status;
      },
      events: {
        onSuccess: (state, payload) => {
          state.places = payload;
        },
      },
      skipSuccessInResponse: true,
    });
  },
});

export const selectors = {
  placeList: (state: { places: TState }) => state.places.places,
};

export const asyncActions = {
  fetch: fetchPlacesAsync,
};

export default placesSlice.reducer;
