import { apiRequest, apiMobRequest } from '../../helpers/apiRequest';
import { TLoginResponse, TAdminUserDetailsResponse } from './AuthTypes';

export const loginRequest = async (
  email: string,
  password: string,
): Promise<TLoginResponse> =>
  apiRequest<TLoginResponse>('/auth/admins/login', 'post', { email, password }, { version: 'v2' });

export const forgotPassword = async (email: string): Promise<{}> =>
  apiRequest<{}>('/auth/admins/forgot-password', 'post', { email }, { version: 'v2' });

export const resetPassword = async (
  password: string,
  token: string,
): Promise<{}> =>
  apiRequest<{}>(
    '/auth/admins/check-forgot-password',
    'post',
    { password, token },
    { version: 'v2' },
  );

export const setUserPassword = async (
  password: string,
  token: string,
): Promise<{}> =>
  apiRequest<{}>('/auth/users/set-password', 'post', { password, token }, { version: 'v2' });

export const resetAppPassword = async (
  password: string,
  token: string,
): Promise<{}> =>
  apiRequest<{}>('/auth/users/set-password', 'post', { password, token }, { version: 'v2' });

export const resetUserPassword = async (
  password: string,
  code: string,
): Promise<{}> =>
  apiMobRequest<{}>('/auth/reset-password', 'post', { password, code }, { version: 'v2' });

export const getAuthenticatedUserDetails = async (): Promise<TAdminUserDetailsResponse> =>
  apiRequest<TAdminUserDetailsResponse>('/admin-users/me', 'get', {}, { version: 'v2' });

export const refreshTokenRequest = async (
  refreshToken: string,
): Promise<TLoginResponse> =>
  apiRequest<TLoginResponse>(
    '/auth/admins/refresh-token',
    'post',
    { refreshToken },
    { version: 'v2' },
  );
