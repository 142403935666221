import { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './CarouselAntd.module.scss';

import firstEnglish from '../../assets/svg/first-en.svg';
import secondEnglish from '../../assets/svg/second-en.svg';
import thirdEnglish from '../../assets/svg/third-en.svg';
import fourthEnglish from '../../assets/svg/fourth-en.svg';

import firstSpanish from '../../assets/svg/first-es.svg';
import secondSpanish from '../../assets/svg/second-es.svg';
import thirdSpanish from '../../assets/svg/third-es.svg';
import fourthSpanish from '../../assets/svg/fourth-es.svg';

import { selectCurrentLang } from '../../features/i18n/i18nSlice';
import { useSelector } from 'react-redux';

function CarouselAntd() {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState<string>('');
  const lang = useSelector(selectCurrentLang);

  useEffect(() => {
    setLanguage(lang || 'es');
  }, [lang]);

  const Data = [
    {
      Image: language.includes('es') ? firstSpanish : firstEnglish,
      Text: t('auth.onboarding.carousel.slideOne'),
    },
    {
      Image: language.includes('es') ? secondSpanish : secondEnglish,
      Text: t('auth.onboarding.carousel.slideTwo'),
    },
    {
      Image: language.includes('es') ? thirdSpanish : thirdEnglish,
      Text: t('auth.onboarding.carousel.slideThree'),
    },
    {
      Image: language.includes('es') ? fourthSpanish : fourthEnglish,
      Text: t('auth.onboarding.carousel.slideFour'),
    },
  ];

  const imageStyle: React.CSSProperties = {
    height: '472px',
    width: '100%',
    background: '#FFEBE5',
    marginTop: '20%',
    marginBottom: 57,
  };

  return (
    <Carousel autoplay>
      {
        Data.map((item) => (
          <div className={styles.carousel}>
            <img src={item.Image} style={imageStyle} />
            <div className={styles.textContainer}>
              <h3 className={styles.carouselText}>{item.Text}</h3>
            </div>
          </div>
        ))
      }
    </Carousel>
  );
}

export default CarouselAntd;
