import { Key } from 'antd/es/table/interface';
import { IPagination } from '../../general/GeneralTypes';

export type TTableData = {
  key: Key;
  id: TClaimedReward['id'];
  name: string;
  email: TUser['email'];
  reward: string;
  createdAt: TClaimedReward['updatedAt'];
  status: string;
};

export type TClaimedReward = {
  id: number;
  canceledAt?: string;
  createdAt: string;
  grantedAt?: string;
  reward: TReward;
  rewardId: number;
  updatedAt: string;
  user: TUser;
  userId: 26;
};

export type TReward = {
  id: number;
  name: string;
};

export type TUser = {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
};

export type TClaimedRewardRewardsResponse = {
  data: TClaimedReward;
  // pagination: IPagination,
};

export type TSingleClaimedRewardRewardResponse = {
  /* statusCode: number,
  message?: string[],
  error?: string,
  success?: boolean, */
  data: TClaimedReward[];
  pagination: IPagination;
};

export enum EClaimedRewardStatuses {
  REQUESTED = 'requested',
  GRANTED = 'granted',
  CANCELED = 'canceled',
}
