import React, { useEffect, useState } from 'react';
import { Button, Input, Tooltip, Tag } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'antd/es/table/interface';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { actions, asyncActions, selectors } from '../CategoriesSlice';
import { TCategory } from '../CategoriesTypes';
import DeleteCategoryModal from '../../../components/Modals/CategoriesModal/DeleteCategoriesModal/DeleteChallengeModal';
import styles from './CategoryList.module.scss';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../constants/requestStatuses';
import ColorPicker from '../../../components/ColorPicker/ColorPicker';
import BulkActions from '../../../components/BulkActions/BulkActions';
import Table from '../../../components/Table/Table';
import useQuery from '../../../helpers/useQuery';

const { Search } = Input;

type TTableData = {
  key: Key;
  id: TCategory['id'];
  name: TCategory['name'];
  color: TCategory['color'];
  actions: null;
  activities: TCategory['activityTagToCategory'];
};

function CategoriesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { section }= useParams();

  const query = useQuery();

  // selectors
  const categoriesData = useSelector(selectors.listData);
  const statuses = useSelector(selectors.statuses);
  const bulkCategories = useSelector(selectors.bulkItems);
  const actionCategories = useSelector(selectors.actionItems);

  // effects
  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';

      dispatch(
        actions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (categoriesData.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, categoriesData]);

  // status popup
  const bulkPopup = (
    status: RequestStatuses | null | undefined,
    categories: TCategory[],
    operation: string,
    success?: boolean,
  ) => {
    const { length } = categories;
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        t(
          `categories.tooltip.${operation}.${
            length > 1 ? 'multiple' : 'single'
          }`,
          { length },
        ),
      );
      dispatch(actions.clearStatuses());
    }
  };
  const singlePopup = (
    status: RequestStatuses | null | undefined,
    category: TCategory | null,
    operation: string,
    success?: boolean,
  ) => {
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        t(`categories.tooltip.${operation}`, { name: category?.name }),
      );
      dispatch(actions.clearStatuses());
    }
  };
  useEffect(
    () => bulkPopup(statuses.deleteBulk, bulkCategories.delete, 'deleteBulk'),
    [statuses],
  );
  useEffect(
    () => singlePopup(statuses.delete, actionCategories.delete, 'delete'),
    [statuses],
  );
  useEffect(
    () => singlePopup(statuses.create, actionCategories.create, 'create', true),
    [statuses],
  );

  // state
  const [selectedIds, setSelectedIds] = useState([] as Key[]);

  useEffect(() => setSelectedIds([]), [statuses]);

  // actions
  const onCopy = (id: TCategory['id']) => () => {
    // TODO: implement copy
  };
  const onDelete = (id: TCategory['id']) => () => {
    const category = categoriesData.list?.find((c) => c.id === id);
    if (category) {
      const { page = 0, perPage = 10 } =
        categoriesData.listParams?.pagination || {};
      if (categoriesData.list?.length === 1 && page > 1) {
        dispatch(actions.setPagination({ page: page - 1, perPage }));
      }

      dispatch<any>(asyncActions.delete({ item: category }));
    }
  };

  const onBack = () =>
    navigate(`/${section}`,
      { state: location.state as string}
    );

  // table data
  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };
  const actionsBlock = (id: TCategory['id'], title: TCategory['name']) => (
    <div className={styles.tableActions}>
      <Link
        to={`/categories/${section}/${id}?${query.toString()}`}
        className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
      >
        <EditOutlined />
      </Link>
      {[{ icon: <CopyOutlined />, action: onCopy(id) }].map((b) => (
        <div className={styles.actionButton} onClick={b.action}>
          {b.icon}
        </div>
      ))}
      <DeleteCategoryModal categoryTitle={title} onClick={onDelete(id)}>
        <div className={styles.actionButton}>
          <DeleteOutlined />
        </div>
      </DeleteCategoryModal>
    </div>
  );
  const tableData: TTableData[] =
    categoriesData.list?.map((item) => ({
      key: item.id,
      id: item.id,
      name: item.name,
      color: item.color,
      actions: null,
      activities: item.activityTagToCategory,
    })) || [];
  const columns = [
    {
      title: t('guarantees.table.id'),
      dataIndex: 'id',
      sorter: true,
      ...(query.has('sort') &&
      ['id', '-id'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-id' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('categories.categoryName'),
      dataIndex: 'name',
      render: (_: string, record: TTableData) => (
        <Link
          to={`/categories/${section}/${record.id}?${query.toString()}`}
          className={styles.categoryTitle}
        >
          {record.name.length > 30 ? (
            <Tooltip placement="top" title={record.name}>{`${record.name.slice(
              0,
              30,
            )}...`}</Tooltip>
          ) : (
            record.name
          )}
        </Link>
      ),
      sorter: true,
      ...(query.has('sort') &&
      ['name', '-name'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-name' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('categories.color'),
      dataIndex: 'color',
      render: (_: string, record: TTableData) => (
        <ColorPicker currentColor={record.color} displayOnly />
      ),
    },
    {
      title: t('categories.activities'),
      dataIndex: 'activities',
      render: (activities: TTableData['activities']) => (
        <>
          {activities.map((a) => (
            <Tag className={styles.tag} color="#69AA9E">
              {a.activityTag.name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      render: (_: null, record: TTableData) =>
        actionsBlock(parseInt(record.key.toString(), 10), record.name),
    },
  ];

  // bulk menu dropdown
  const dropdownOptions = [
    {
      key: 'delete',
      text: t('generic.delete'),
      action: () => {
        const selectedCategories =
          categoriesData.list?.filter(
            ({ id }) => selectedIds.indexOf(id) !== -1,
          ) || [];
        dispatch<any>(asyncActions.deleteBulk({ items: selectedCategories }));
        setSelectedIds([]);
      },
    },
  ];

  return (
    <div>
      <ProPageHeader prefixedClassName={styles.pageHeader} title={t('categories.manageCategories')} onBack={onBack} />
      <ActionAlert />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>
          {t('categories.categoriesCreated')}
        </div>
        <div className={styles.buttonContainer}>
          <Search
            allowClear
            className={styles.searchField}
            placeholder={t('categories.placeholder.searchCategory') || ''}
            defaultValue={query.get('search') || ''}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                categoriesData.listParams?.pagination?.perPage
                  ? categoriesData.listParams?.pagination?.perPage.toString()
                  : '10',
              );
              navigate( `${location.pathname}?${query.toString()}`);
            }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() =>
              navigate(`/categories/${section}/new?${query.toString()}`)
            }
          >
            {t('categories.buttons.createCategory')}
          </Button>
        </div>
      </div>
      {!!selectedIds.length && (
        <BulkActions
          dropdownOptions={dropdownOptions}
          selectedKeys={selectedIds.map((key) => key.toString())}
        />
      )}
      <Table
        actions={actions}
        className={styles.table}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: categoriesData.listParams?.pagination?.total,
          current: categoriesData.listParams?.pagination?.page,
        }}
      />
    </div>
  );
}

export default withTranslation()(CategoriesList);
