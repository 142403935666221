import { getAvailableForms } from './TypeFormsApi';
import { IForm, IGetFormsResponse, TNewFormResponse } from './TypeFormsTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

export const typeFormsSlice = buildSlice<
  IForm,
  TNewFormResponse,
  IGetFormsResponse
>('typeForms', {
  fetchList: getAvailableForms,
});

export const { selectors, asyncActions, actions } = typeFormsSlice;

export default typeFormsSlice.reducer;
