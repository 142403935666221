import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { chunk } from 'lodash';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styles from './HomePage.module.scss';
import {
  getActiveUsersAsync,
  getStatistics,
  getStatisticsAsync,
} from './HomePageSlice';
import { selectAuthenticatedUser } from '../auth/AuthSlice';
import { i18nInsertElement } from '../../helpers/i18nInsertElement';
import { UserRoles } from '../../constants/userRoles';
import {
  getCompanyAnalytics,
  selectCompanyStatistics,
} from '../analytics/WellnessAnalyticsSlice';
import { generalConstants } from '../general/GeneralTypes';

function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const statistics = useSelector(getStatistics);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const companyStatistics = useSelector(selectCompanyStatistics);

  useEffect(() => {
    dispatch<any>(getStatisticsAsync());
    dispatch<any>(getActiveUsersAsync());
  }, [dispatch]);

  useEffect(() => {
    const startDate = dayjs().subtract(7, 'days').startOf('day');
    const endDate = dayjs().endOf('day');

    const params = {
      ...(authenticatedUser.role === UserRoles.ADMIN
        ? {
            filter: [
              {
                field: 'notCompanyUsers',
                value: 'allUsers',
              },
            ],
          }
        : {}),
      createdAt: {
        createdAtStart: startDate.toISOString(),
        createdAtEnd: endDate.toISOString(),
      },
    };

    dispatch<any>(getCompanyAnalytics(params));
  }, [dispatch, authenticatedUser]);

  let activeUsersPercent = 0;
  if (companyStatistics.registeredUsers) {
    activeUsersPercent =
      (companyStatistics.activeUsers.currentActivity * 100) /
      companyStatistics.registeredUsers;
  }

  const tiles = [
    ...(authenticatedUser.role === UserRoles.ADMIN
      ? [
          {
            icon: '/assets/home/hotel-icon.svg',
            title: t('homePage.tiles.companies.title'),
            totalTitle: t('homePage.tiles.companies.totalText'),
            data: statistics?.companiesCounter || 0,
            link: '/collective',
          },
        ]
      : []),
    {
      icon: '/assets/home/user-icon.svg',
      title: t('homePage.tiles.users.title'),
      totalTitle: t('homePage.tiles.users.totalText'),
      data: companyStatistics?.totalEmployees || 0,
      link: authenticatedUser.role === UserRoles.ADMIN ? '/users' : '',
    },
    {
      icon: '/assets/home/protection-icon.svg',
      title: t('homePage.tiles.insurance.title'),
      totalTitle: t('homePage.tiles.insurance.totalText'),
      data: statistics?.productsCounter || 0,
      link: '/members',
    },
    {
      icon: '/assets/home/wellness-icon.svg',
      title: t('homePage.tiles.wellness.title'),
      totalTitle: t('homePage.tiles.wellness.totalText'),
      data: `${activeUsersPercent.toFixed(0)}%`,
      link: '/wellness/analytics',
    },
  ];

  const tileFabric = (
    icon: string,
    title: string,
    totalTitle: string,
    data: string | number,
    link: string,
  ) => (
    <div className={styles.tile} onClick={() => navigate(link)}>
      <div className={styles.tileTitle}>
        <div className={styles.iconWrap}>
          <ReactSVG className={styles.icon} src={icon} />
        </div>
        <div>{title}</div>
      </div>
      <div className={styles.tileContent}>
        <div className={styles.data}>{data}</div>
        <div className={styles.total}>
          <div className={styles.totalTitle}>{totalTitle}</div>
          {!!link && (
            <ReactSVG className={styles.row} src="/assets/home/arrow.svg" />
          )}
        </div>
      </div>
    </div>
  );

  const shortcuts = [
    {
      icon: '/assets/home/hotel-icon.svg',
      title: t('homePage.shortcut.createCompany'),
      link: '/collective/new',
    },
    {
      icon: '/assets/home/reward-icon.svg',
      title: t('homePage.shortcut.createChallenge'),
      link: '/challenges/biometrics/new',
    },
    {
      icon: '/assets/home/star-icon.svg',
      title: t('homePage.shortcut.createReward'),
      link: '/rewards/new',
    },
    {
      icon: '/assets/home/book-icon.svg',
      title: t('homePage.shortcut.createInsight'),
      link: '/insights/new',
    },
  ];

  const shortcutFabric = (icon: string, title: string, link: string) => (
    <div className={styles.shortcut} onClick={() => navigate(link)}>
      <ReactSVG className={styles.icon} src={icon} />
      <div className={styles.shortcutTitle}>{title}</div>
    </div>
  );

  return (
    <div className={styles.wrap}>
      <div
        className={styles.bannerWrap}
        style={{ backgroundImage: 'url(/assets/home/banner.svg)' }}
      >
        {t('homePage.welcome.title', { name: authenticatedUser.firstName })
          .split(', ')
          .map((w, i, arr) => (
            <div
              className={i === 0 ? styles.welcome : styles.welcomeSecondLine}
            >
              {`${w}${arr.length - 1 !== i ? ',' : ''}`}
            </div>
          ))}
        <div className={styles.date}>
          {dayjs().format('dddd, DD MMMM YYYY')}
        </div>
      </div>
      <div className={styles.overall}>
        <div className={styles.title}>{t('homePage.overall.title')}</div>
        <div className={styles.tileWrap}>
          {chunk(tiles, 2).map((chT) => (
            <div className={styles.tilePairs} style={{ flex: chT.length }}>
              {chT.map((tile) => {
                const { icon, title, totalTitle, data, link } = tile;
                return tileFabric(icon, title, totalTitle, data, link);
              })}
            </div>
          ))}
        </div>
      </div>
      {authenticatedUser.role === UserRoles.ADMIN && (
        <div className={styles.overall}>
          <div className={styles.title}>{t('homePage.shortcut.title')}</div>
          <div className={styles.tileWrap}>
            {chunk(shortcuts, 2).map((chS) => (
              <div className={styles.tilePairs} style={{ flex: chS.length }}>
                {chS.map((s) => {
                  const { icon, title, link } = s;
                  return shortcutFabric(icon, title, link);
                })}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.helpCenter}>
        <ReactSVG className={styles.icon} src="/assets/home/round-icon.svg" />
        <div className={styles.needHelp}>{t('homePage.needHelp')}</div>
        <div className={styles.contactUs}>
          {i18nInsertElement(t('homePage.contactUs'), 'btn', (text) => (
            <a
              href={`mailto:${generalConstants.APP_ADMIN_EMAIL}?subject=${t(
                'generic.emailSubject.help',
              )}`}
              rel="noopener noreferrer"
            >
              <Button className={styles.hepCenterLink} type="link">
                {text}
              </Button>
            </a>
          ))}
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default HomePage;
