export const i18nInsertElement = (
  str: string,
  tag: string,
  elementBuilder: (text: string) => JSX.Element,
): JSX.Element | string => {
  const matchReg = new RegExp(`(.*)<${tag}>(.+)<\\/${tag}>(.*)`);
  const found = (str || '').match(matchReg);

  if (found && found.length === 4) {
    return (
      <>
        {i18nInsertElement(found[1], tag, elementBuilder)}
        {elementBuilder(found[2])}
        {found[3]}
      </>
    );
  }
  return str;
};
