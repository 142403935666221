import { useEffect } from 'react';
import { Config } from '../../../config/config';
import Finish from '../Finish/Finish';

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android, or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  const { userAgent } = navigator;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (navigator.userAgent.includes('Mac') && 'ontouchend' in document) {
    return 'iOS';
  }

  return 'unknown';
}

function RedirectAppStores() {
  useEffect(() => {
    const mobileOperatingSystem = getMobileOperatingSystem();

    if (mobileOperatingSystem === 'Android') {
      window.location.href = 'market://details?id=com.vitaance.mobile';
    } else if (mobileOperatingSystem === 'iOS') {
      window.location.href = Config.APP_STORE;
    }
  }, []);

  return <Finish />;
}

export default RedirectAppStores;
