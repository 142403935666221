import {
  createChallenge,
  deleteChallenge,
  fetchChallenges,
  getChallenge,
  publishChallenge,
  saveAsDraftChallenge,
  unpublishChallenge,
  updateChallenge,
} from './ChallengesApi';
import {
  IChallenge,
  IFetchChallengesResponse,
  TNewChallengeResponse,
} from './ChallengesTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

export const challengesSlice = buildSlice<
  IChallenge,
  TNewChallengeResponse,
  IFetchChallengesResponse
>('challenges', {
  create: createChallenge,
  delete: deleteChallenge,
  fetchList: fetchChallenges,
  getOne: getChallenge,
  publish: publishChallenge,
  unpublish: unpublishChallenge,
  update: updateChallenge,
  saveAsDraft: saveAsDraftChallenge,
});

export const { selectors, asyncActions, actions } = challengesSlice;

export default challengesSlice.reducer;
