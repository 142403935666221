import { createSlice } from '@reduxjs/toolkit';

export const authActions = {
  COLLAPSE: 'nav/collapse',
};

interface IState {
  collapsed: boolean;
}

const initialState: IState = {
  collapsed: false,
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    collapseSideMenu: (state, action) => {
      const { collapse } = action.payload || {};
      state.collapsed = collapse;
    },
  },
});

export const { collapseSideMenu } = navSlice.actions;

export const selectCollapsed = (state: { nav: IState }) => state.nav.collapsed;

export default navSlice.reducer;
