import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getCompaniesListRequest,
  getCompanyAnalyticsRequest,
  getDailyActivityRequest,
  getEngagementStatisticsRequest,
} from './WellnessAnalyticsApi';
import { TCompany } from '../collective/companies/CompanyType';
import { TChallenge, TReward } from './WellnessAnalyticsType';

interface WellnessAnalyticsState {
  companiesList: TCompany[];
  company: {
    totalEmployees: number;
    registeredUsers: number;
    activeUsers: {
      currentActivity: number;
      previousActivity: number;
    };
  };
  dailyActivity: {
    current: {
      calories: number;
      distance: number;
      steps: number;
    };
    history: {
      calories: number;
      distance: number;
      steps: number;
    };
  };
  engagement: {
    popularChallenges: TChallenge[];
    mostCompletedChallenge: TChallenge | null;
    leastCompletedChallenge: TChallenge | null;
    mostClaimedReward: {
      current: TReward | null;
      previous: TReward | null;
    };
  };
}

const initialState: WellnessAnalyticsState = {
  companiesList: [],
  company: {
    totalEmployees: 0,
    registeredUsers: 0,
    activeUsers: {
      currentActivity: 0,
      previousActivity: 0,
    },
  },
  dailyActivity: {
    current: {
      calories: 0,
      distance: 0,
      steps: 0,
    },
    history: {
      calories: 0,
      distance: 0,
      steps: 0,
    },
  },
  engagement: {
    popularChallenges: [],
    mostCompletedChallenge: null,
    leastCompletedChallenge: null,
    mostClaimedReward: {
      current: null,
      previous: null,
    },
  },
};

export const getCompaniesList = createAsyncThunk(
  'getCompaniesList',
  async () => {
    return getCompaniesListRequest();
  },
);

export const getCompanyAnalytics = createAsyncThunk(
  'getCompanyAnalytics',
  async (data: any) => {
    return getCompanyAnalyticsRequest(data);
  },
);

export const getDailyActivity = createAsyncThunk(
  'getDailyActivity',
  async (data: any) => {
    return getDailyActivityRequest(data);
  },
);

export const getEngagementStatistics = createAsyncThunk(
  'getEngagementStatisticsRequest',
  async (data: any) => {
    return getEngagementStatisticsRequest(data);
  },
);

const wellnessAnalyticsSlice = createSlice({
  name: 'wellnessAnalytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyAnalytics.fulfilled, (state, action) => {
      state.company = {
        ...action.payload,
      };
    });
    builder.addCase(getCompaniesList.fulfilled, (state, action) => {
      state.companiesList = action.payload.data;
    });
    builder.addCase(getDailyActivity.fulfilled, (state, action) => {
      state.dailyActivity = action.payload.data;
    });
    builder.addCase(getEngagementStatistics.fulfilled, (state, action) => {
      state.engagement = action.payload.data;
    });
  },
});

export const selectCompaniesList = (state: any) =>
  state.wellnessAnalytics.companiesList;
export const selectCompanyStatistics = (state: any) =>
  state.wellnessAnalytics.company;
export const selectDailyActivity = (state: any) =>
  state.wellnessAnalytics.dailyActivity;
export const selectEngagementStatistics = (state: any) =>
  state.wellnessAnalytics.engagement;

export default wellnessAnalyticsSlice.reducer;
