import { ProPageHeader } from '@ant-design/pro-layout';
import { Link } from 'react-router-dom';
import styles from './PageHeaderDefault.module.scss';

type TPageHeaderDefaultProps = {
  routes: {
    [key: string]: string;
  };
  title: string;
};

type TRoute = {
  path: string,
  breadcrumbName: string,
}

function PageHeaderDefault({
  title,
  routes,
}: TPageHeaderDefaultProps) {
  const routeList = Object.entries(routes).map(([name, route]) => ({
    breadcrumbName: name,
    path: route,
  }));

  return (
    <ProPageHeader
      prefixedClassName={styles.pageHeader}
      title={title}
      breadcrumb={{
        itemRender: (route: TRoute ) =>
          route.path ? (
            <Link to={route.path}>{route.breadcrumbName}</Link>
          ) : (
            route.breadcrumbName
          ),
        routes: routeList,
      }}
    />
  )
}

export default PageHeaderDefault;
