type LeftRowTextProps = {
  text: string;
  textPos: string;
  textStyle: string;
};

function LeftRowText({ text, textPos, textStyle }: LeftRowTextProps) {
  return (
    <>
      <div className={textPos}>
        <span className={textStyle}>{text}</span>
      </div>
    </>
  );
}

export default LeftRowText;
