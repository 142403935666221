import React from 'react';
import styles from './FormTitle.module.scss';

type TFormTitleProps = {
  children: JSX.Element | string;
  className?: string;
};
function FormTitle({ children, className }: TFormTitleProps) {
  return <div className={`${styles.title} ${className || ''}`}>{children}</div>;
}

export default FormTitle;
