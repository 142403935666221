import {
  apiRequest,
  apiRequestFormData,
  apiRequestGet,
  TListParams,
} from '../../helpers/apiRequest';
import {
  TArticle,
  TArticlesResponse,
  TSingleArticleResponse,
} from './ArticlesTypes';

export const createArticle = async (
  articleData: FormData,
): Promise<TSingleArticleResponse> =>
  apiRequestFormData<TSingleArticleResponse>(
    '/articles',
    'post',
    articleData,
    null,
  );

export const publishArticle = async (
  id: TArticle['id'],
): Promise<TSingleArticleResponse> =>
  apiRequest<TSingleArticleResponse>(
    `/articles/${id}/publish`,
    'post',
    null,
    null,
  );

export const unpublishArticle = async (
  id: TArticle['id'],
): Promise<TSingleArticleResponse> =>
  apiRequest<TSingleArticleResponse>(
    `/articles/${id}/unpublish`,
    'post',
    null,
    null,
  );

export const saveAsDraftArticle = async (
  id: TArticle['id'],
): Promise<TSingleArticleResponse> =>
  apiRequest<TSingleArticleResponse>(
    `/articles/${id}/save-as-draft`,
    'post',
    null,
    null,
  );

export const fetchArticles = async (
  listParams?: TListParams,
): Promise<TArticlesResponse> =>
  apiRequestGet<TArticlesResponse>('/articles', listParams);

export const deleteArticle = async (
  id: TArticle['id'],
): Promise<TSingleArticleResponse> =>
  apiRequest<TSingleArticleResponse>(`/articles/${id}`, 'delete', null);

export const getArticle = async (
  id: TArticle['id'],
): Promise<TSingleArticleResponse> =>
  apiRequestGet<TSingleArticleResponse>(`/articles/${id}`);

export const updateArticle = async (
  id: TArticle['id'],
  articleData: FormData,
): Promise<TSingleArticleResponse> =>
  apiRequestFormData<TSingleArticleResponse>(
    `/articles/${id}`,
    'patch',
    articleData,
    null,
  );
