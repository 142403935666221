import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../BasicModal/BasicModal';

type TContinueEditingModalProps = {
  children: JSX.Element;
  onClick: () => void;
};

function ContinueEditingModal({
  children,
  onClick,
}: TContinueEditingModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('generic.modals.continueEditing.title'),
    okText: t('generic.modals.continueEditing.buttons.continue'),
    cancelText: t('generic.modals.continueEditing.buttons.discard'),
    content: t('generic.modals.continueEditing.content'),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM}
      revertButtons
    />
  );
}

export default ContinueEditingModal;
