import {
  createArticle,
  deleteArticle,
  fetchArticles,
  getArticle,
  publishArticle,
  saveAsDraftArticle,
  unpublishArticle,
  updateArticle,
} from './ArticlesApi';
import {
  TArticleFilled,
  TArticlesResponse,
  TSingleArticleResponse,
} from './ArticlesTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

export const articleSlice = buildSlice<
  TArticleFilled,
  TSingleArticleResponse,
  TArticlesResponse
>('articles', {
  create: createArticle,
  delete: deleteArticle,
  fetchList: fetchArticles,
  getOne: getArticle,
  publish: publishArticle,
  unpublish: unpublishArticle,
  update: updateArticle,
  saveAsDraft: saveAsDraftArticle,
});

export const { selectors, asyncActions, actions } = articleSlice;

export default articleSlice.reducer;
