import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import ModalCenter from '../../../../../components/ModalCenter/ModalCenter';
import {
  selectors,
  asyncActions,
  actions,
} from '../../Leaderboard/LeaderboardSlice';
import Table from '../../../../../components/Table/Table';
import styles from './TeamMembers.module.scss';
import { TTopTeam } from '../../../ChallengesTypes';

const { Search } = Input;

type TTeamMembersProps = {
  challengeId: number;
  team: TTopTeam;
  show: boolean;
  onClose: () => void;
};

type TTableData = {
  key: number;
  name: string;
  email: string;
};

function TeamMembers({ challengeId, team, show, onClose }: TTeamMembersProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* DATA STORE */
  const teamUsers = useSelector(selectors.teamUsers);

  /* EFFECTS */
  // useEffect(() => {
  //   dispatch(asyncActions.fetchTeamMembers({ challengeId, teamId: team.id }));
  // }, [dispatch, challengeId, team]);

  useEffect(() => {
    if (show) {
      dispatch(actions.setTeamUsersSearch({ query: '' }));
      dispatch<any>(asyncActions.fetchTeamMembers({ challengeId, teamId: team.id }));
    }
  }, [show]);

  /* TABLE DATA */
  const tableData: TTableData[] =
    teamUsers.list?.map((item) => ({
      key: item.id,
      name: `${item.firstName} ${item.lastName}`,
      email: item.email,
    })) || [];

  const columns = [
    {
      title: t('collective.members.memberName'),
      dataIndex: 'name',
      render: (value: string) => (
        <div className={styles.memberName}>{value}</div>
      ),
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
    },
  ];

  return (
    <ModalCenter onClose={onClose} show={show} className={styles.modal}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>{team.name}</div>
          <ReactSVG
            className={styles.closeButton}
            onClick={onClose}
            src="/assets/general/close-icon.svg"
          />
        </div>
        <div className={styles.searchBar}>
          <div className={styles.title}>
            {t('challenges.form.leaderboard.teamMembers')}
          </div>
          <Search
            allowClear
            className={styles.searchField}
            placeholder={t('users.placeholder.searchForUser') || ''}
            onSearch={(val: string) => {
              dispatch(actions.setTeamUsersSearch({ query: val }));
              dispatch(
                actions.setTeamUsersPagination({
                  page: 1,
                  perPage: teamUsers.listParams?.pagination?.perPage || 10,
                }),
              );
              dispatch<any>(
                asyncActions.fetchTeamMembers({ challengeId, teamId: team.id }),
              );
            }}
          />
        </div>
        <div className={styles.tableWrap}>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{
              showSizeChanger: false,
              total: teamUsers.listParams?.pagination?.total,
              current: teamUsers.listParams?.pagination?.page,
            }}
            actions={{
              setPagination: actions.setTeamUsersPagination,
              setSearch: actions.setTeamUsersSearch,
            }}
            asyncActions={{
              fetchList: () =>
                asyncActions.fetchTeamMembers({ challengeId, teamId: team.id }),
            }}
          />
        </div>
        <div className={styles.bottomBar}>
          <Button className={styles.okButton} onClick={onClose}>
            {t('generic.ok')}
          </Button>
        </div>
      </div>
    </ModalCenter>
  );
}

export default TeamMembers;
