import {
  fetchClaimedRewards,
  publishClaimedReward,
  unpublishClaimedReward,
} from './ClaimedRewardsApi';
import {
  TClaimedReward,
  TClaimedRewardRewardsResponse,
  TSingleClaimedRewardRewardResponse,
} from './ClaimedTypes';
import buildSlice from '../../general/helpers/genericSliceBuilder';

export const manageClaimedRewardsSlice = buildSlice<
  TClaimedReward,
  TClaimedRewardRewardsResponse,
  TSingleClaimedRewardRewardResponse
>('manageClaimedRewards', {
  fetchList: fetchClaimedRewards,
  publish: publishClaimedReward,
  unpublish: unpublishClaimedReward,
});

export const { selectors, asyncActions, actions } = manageClaimedRewardsSlice;

export default manageClaimedRewardsSlice.reducer;
