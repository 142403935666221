import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../../../../../helpers/apiRequest';
import {
  TProduct,
  TProductResponse,
  TProductListResponse,
  TCreateProductRequest,
  TUpdateProductRequest,
} from './ProductsTypes';

export const getProductsByCompanyId = async (
  listParams?: TListParams,
  companyId?: number,
): Promise<TProductListResponse> =>
  apiRequestGet<TProductListResponse>('/insurance/products', {
    ...listParams,
    customParams: {
      companyId,
    },
  });

export const getProducts = async (
  listParams?: TListParams,
): Promise<TProductListResponse> =>
  apiRequestGet<TProductListResponse>('/insurance/products', listParams);

export const getProduct = async (
  id: TProduct['id'],
): Promise<TProductResponse> =>
  apiRequestGet<TProductResponse>(`/insurance/products/${id}`);

export const createProduct = async (
  data: TCreateProductRequest,
): Promise<TProductResponse> =>
  apiRequest<TProductResponse>('/insurance/products', 'post', data);

export const updateProduct = async (
  id: TProduct['id'],
  data: TUpdateProductRequest,
): Promise<TProductResponse> =>
  apiRequest<TProductResponse>(`/insurance/products/${id}`, 'patch', data);

export const deleteProduct = async (id: TProduct['id']): Promise<any> =>
  apiRequest<any>(`/insurance/products/${id}`, 'delete', null);

export const activateProduct = async (
  id: TProduct['id'],
): Promise<TProductResponse> =>
  apiRequest<TProductResponse>(
    `/insurance/products/${id}/activate`,
    'post',
    null,
  );

export const deactivateProduct = async (
  id: TProduct['id'],
): Promise<TProductResponse> =>
  apiRequest<TProductResponse>(
    `/insurance/products/${id}/deactivate`,
    'post',
    null,
  );

export const getProductCategories = async (): Promise<TProductResponse> =>
  apiRequestGet<TProductResponse>('/products/categories');
