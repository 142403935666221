import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../BasicModal/BasicModal';

type TDeleteChallengeModalProps = {
  name: string;
  children: JSX.Element;
  onClick: () => void;
};

function DeleteGuaranteeModal({
  name,
  children,
  onClick,
}: TDeleteChallengeModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('guarantees.modal.delete.title'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('guarantees.modal.delete.content', { name }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeleteGuaranteeModal;
