import React from 'react';
import { UserRoles } from '../../constants/userRoles';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from '../../features/auth/AuthSlice';

type TRoleWrapperProps = {
  children: JSX.Element;
  roles: Array<UserRoles | null>;
  alternative?: JSX.Element | null;
};

const RoleWrapper = ({
  children,
  roles,
  alternative = null,
}: TRoleWrapperProps) => {
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  return roles.includes(authenticatedUser.role) ? children : alternative;
};

export default RoleWrapper;
