import React, { useEffect, useState } from 'react';
import { Form, Radio, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './CompanySelector.module.scss';
import { enableForRoles } from '../../../helpers/role';
import { UserRoles } from '../../../constants/userRoles';
import EChallengeAssignmentTypes from '../../../features/challenges/EditChallenge/constants/challengeAssignmentTypes';
import { selectors as companiesSelectors } from '../../../features/collective/companies/AllCompaniesSlice';
import { selectors as adminUserAdminSelectors } from '../../../features/adminUsers/AdminUsersAdminSlice';
import { selectAuthenticatedUser } from '../../../features/auth/AuthSlice';

const { Option } = Select;

type TCompanySelectorProps = {
  onSelectCompanies: (
    ids: number[],
    companiesOnly: boolean,
    assignmentType?: EChallengeAssignmentTypes,
  ) => void;
  selectedCompanyIds?: number[];
  onlySelected?: boolean;
  disabled?: boolean;
  isCompaniesOnly?: boolean;
};

function CompanySelector({
  onSelectCompanies,
  selectedCompanyIds,
  onlySelected,
  disabled,
  isCompaniesOnly,
}: TCompanySelectorProps) {
  const { t } = useTranslation();

  /* DATA STORE */
  // current user data
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  // company lists
  const allCompanies = useSelector(companiesSelectors.listData);
  const adminCompanies = useSelector(adminUserAdminSelectors.listData);
  const companiesList = enableForRoles(authenticatedUser.role, [
    UserRoles.COMPANY_MANAGER,
  ])
    ? adminCompanies.list
    : allCompanies.list;

  /* STATE */
  const [selectorState, setState] = useState({
    assignmentType: onlySelected
      ? EChallengeAssignmentTypes.SELECTED_COMPANIES
      : EChallengeAssignmentTypes.ALL,
  });

  // A function that takes in a field and a value and sets the state of the field to the value.
  const setStateP = (field: keyof typeof selectorState, value: any) => {
    setState({ ...selectorState, [field]: value });
  };

  /* EFFECTS */
  useEffect(() => {
    if (isCompaniesOnly) {
      setStateP('assignmentType', EChallengeAssignmentTypes.COMPANIES_ONLY);
    }
  }, [isCompaniesOnly]);

  useEffect(() => {
    if (!companiesList || onlySelected) {
      return;
    }

    if (
      companiesList.length === selectedCompanyIds?.length &&
      selectorState.assignmentType !==
        EChallengeAssignmentTypes.SELECTED_COMPANIES
    ) {
      setStateP('assignmentType', EChallengeAssignmentTypes.COMPANIES_ONLY);
      return;
    }

    if (selectedCompanyIds?.length) {
      setStateP('assignmentType', EChallengeAssignmentTypes.SELECTED_COMPANIES);
    }
  }, [companiesList, selectedCompanyIds]);

  useEffect(() => {
    if (onlySelected && companiesList?.length && !selectedCompanyIds?.length) {
      onSelectCompanies(
        companiesList.map((c) => c.id),
        false,
        EChallengeAssignmentTypes.SELECTED_COMPANIES,
      );
    }
  }, [onlySelected, companiesList]);

  return (
    <Form layout="vertical">
      <Form.Item>
        <Radio.Group
          onChange={(e) => {
            const selectedVal = e.target.value;

            setStateP('assignmentType', selectedVal);

            if (selectedVal === EChallengeAssignmentTypes.ALL) {
              onSelectCompanies([], false, EChallengeAssignmentTypes.ALL);
            }
            if (selectedVal === EChallengeAssignmentTypes.COMPANIES_ONLY) {
              onSelectCompanies(
                companiesList?.map(({ id }) => id) || [],
                true,
                EChallengeAssignmentTypes.COMPANIES_ONLY,
              );
            }
            if (selectedVal === EChallengeAssignmentTypes.SELECTED_COMPANIES) {
              onSelectCompanies(
                [],
                false,
                EChallengeAssignmentTypes.SELECTED_COMPANIES,
              );
            }
          }}
          value={selectorState.assignmentType}
          size="small"
          className={styles.radiosContainer}
        >
          <>
            {!onlySelected && (
              <>
                <Radio
                  key={0}
                  value={EChallengeAssignmentTypes.ALL}
                  className={styles.radioOption}
                  disabled={disabled}
                >
                  {t('challenges.form.challengeAssignment.all')}
                </Radio>
                <Radio
                  key={1}
                  value={EChallengeAssignmentTypes.COMPANIES_ONLY}
                  className={styles.radioOption}
                  disabled={disabled}
                >
                  {t('challenges.form.challengeAssignment.companiesOnly')}
                </Radio>
              </>
            )}
            <Radio
              key={2}
              value={EChallengeAssignmentTypes.SELECTED_COMPANIES}
              className={styles.radioOption}
              disabled={disabled}
            >
              {t('challenges.form.challengeAssignment.selectedCompanies')}
            </Radio>
          </>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t('challenges.form.challengeAssignment.selectCompanies')}
        tooltip={{
          title: t('challenges.form.challengeAssignment.selectCompanies'),
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          mode="multiple"
          optionFilterProp="children"
          onChange={(value: number[]) =>
            onSelectCompanies(
              value,
              selectorState.assignmentType ===
                EChallengeAssignmentTypes.COMPANIES_ONLY,
              selectorState.assignmentType,
            )
          }
          value={
            selectorState.assignmentType !==
            EChallengeAssignmentTypes.SELECTED_COMPANIES
              ? []
              : selectedCompanyIds?.filter((id) =>
                  companiesList?.map((c) => c.id).includes(id),
                )
          }
          style={{ width: '100%' }}
          disabled={
            selectorState.assignmentType !==
              EChallengeAssignmentTypes.SELECTED_COMPANIES || disabled
          }
          className={styles.selectOption}
        >
          {companiesList?.map((company) => (
            <Option key={`${company.id}${Math.random()}`} value={company.id}>
              {company.companyName}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
}

CompanySelector.defaultProps = {
  selectedCompanyIds: [],
  onlySelected: false,
  disabled: false,
  isCompaniesOnly: false,
};

export default CompanySelector;
