import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Form,
  Input,
  Select,
  Switch,
  Divider,
  Tooltip,
  Col,
} from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectors, asyncActions, actions } from '../ArticlesSlice';
import styles from './EditArticle.module.scss';
import FormCard from '../../../components/FormCard/FormCard';
import FormTitle from '../../../components/Forms/FormTitle/FormTitle';
import FormItemTwoColumn from '../../../components/Forms/FormItemTwoColumn/FormItemTwoColumn';
import {
  selectors as categoriesSelectors,
  asyncActions as categoriesAsyncActions,
  actions as categoriesActions,
} from '../../categories/CategoriesSlice';
import DeleteArticleModal from '../../../components/Modals/ArticlesModals/DeleteArticleModal/DeleteArticleModal';
import PublishArticleModal from '../../../components/Modals/ArticlesModals/PublishArticleModal/PublishArticleModal';
import ImageUploadForm from '../../../components/ImageUploadForm/ImageUploadForm';
import CkEditor from '../../../components/CkEditor/CkEditor';
import { EArticleStatuses } from '../ArticlesTypes';
import { getArticleStatus, getArticleStatusRendered } from '../ArticlesHelpers';
import RequestStatuses from '../../../constants/requestStatuses';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';

const { Option } = Select;
const { TextArea } = Input;

function EditArticle() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: articleId } = useParams();

  const isExistingArticle = useMemo(() => articleId !== 'new', [articleId]);

  // selectors
  const currentArticle = useSelector(selectors.currentItem);
  const categories = useSelector(categoriesSelectors.listData);
  const statuses = useSelector(selectors.statuses);

  // state
  const [title, setTitle] = useState(null as string | null);
  const [titleImage, setTitleImage] = useState(null as string | File | null);
  const [featured, setFeatured] = useState(false);
  const [categoryId, setCategoryId] = useState(null as number | null);
  const [shortDescription, setShortDescription] = useState(
    null as string | null,
  );
  const [relatedActivity, setRelatedActivity] = useState(null as number | null);
  const [content, setContent] = useState('');
  const [imageBase64, setImageBase64] = useState(null as string | null);
  const [createdAt, setCreatedAt] = useState(null as string | null);
  const [updatedAt, setUpdatedAt] = useState(null as string | null);

  const [searchCategory, setSearchCategory] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [somethingChanged, setChangedFlag] = useState(false);

  // effects
  useEffect(() => {
    dispatch<any>(categoriesAsyncActions.fetchList());

    return () => {
      clearValues();

      dispatch(actions.clearCurrentItem());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.clearCurrentItem());

    if (articleId && isExistingArticle) {
      dispatch<any>(asyncActions.get({ id: +articleId }));
    }
  }, [dispatch, articleId]);

  useEffect(() => {
    dispatch(categoriesActions.setPagination({ page: 1, perPage: 20 }));
    dispatch<any>(categoriesAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(categoriesActions.setPagination({ page: 1, perPage: 20 }));
    dispatch(categoriesActions.setSearch({ query: searchCategory }));
    if (searchCategory.length > 1 || !searchCategory.length) {
      dispatch<any>(categoriesAsyncActions.fetchList());
    }
  }, [searchCategory, dispatch]);

  useEffect(() => {
    if (statuses.create === RequestStatuses.SUCCESS && currentArticle?.id) {
      navigate(`/articles/${currentArticle.id}`);

      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  const clearValues = () => {
    setTitle(null);
    setTitleImage(null);
    setFeatured(false);
    setCategoryId(null);
    setShortDescription(null);
    setRelatedActivity(null);
    setContent('');
    setImageBase64(null);
    setCreatedAt(null);
    setUpdatedAt(null);
  };

  // map existing values
  useEffect(() => {
    if (currentArticle) {
      setTitle(currentArticle.title);
      setTitleImage(currentArticle.titleImage);
      setFeatured(currentArticle.featured);
      setCategoryId(currentArticle.categoryId);
      setShortDescription(currentArticle.shortDescription);
      setRelatedActivity(currentArticle.activityTagId);
      setContent(currentArticle.content);
      setImageBase64(currentArticle?.titleImage || null);
      const cAtDate = new Date(currentArticle?.createdAt || '');
      const uAtDate = new Date(currentArticle?.updatedAt || '');
      setCreatedAt(
        `${cAtDate.toLocaleDateString()} ${cAtDate.toLocaleTimeString()}`,
      );
      setUpdatedAt(
        `${uAtDate.toLocaleDateString()} ${uAtDate.toLocaleTimeString()}`,
      );
    } else {
      clearValues();
    }
  }, [currentArticle, articleId]);

  const onBack = (locationState: string = '') =>
    navigate(
      '/articles',
      { state: {
        search: location.state as string,
        state: locationState
      }}
    );
  
  const onCancelPress = () => {

    if (somethingChanged) {
      setShowConfirmModal(true)
    } else {
      onBack()
    }
  }

  const validateFields = () => {
    let allFilled = true;
    [title, categoryId, shortDescription, titleImage, content].forEach((f) => {
      allFilled = allFilled && !!f;
    });

    return allFilled;
  };

  const somethingFilled = () =>
    title !== (currentArticle?.title || null) ||
    titleImage !== (currentArticle?.titleImage || null) ||
    categoryId !== (currentArticle?.categoryId || null) ||
    shortDescription !== (currentArticle?.shortDescription || null) ||
    content !== (currentArticle?.content || '') ||
    featured !== (currentArticle?.featured || false);

  const onSave = (publish?: boolean) => {
    if (!validateFields()) return;

    const formData = new FormData();

    const articleFields = {
      title,
      ...(typeof titleImage === 'string' ? {} : { titleImage }),
      featured,
      categoryId,
      shortDescription,
      activityTagId: relatedActivity,
      content,
    };
    Object.keys(articleFields).forEach((k: string) => {
      const value = articleFields[k as keyof typeof articleFields];
      if (value) {
        formData.append(k, value as string);
      }
    });
    if (currentArticle?.id) {
      const { publishDate, unpublishDate, id } = currentArticle;
      if (publish && (!publishDate || (publishDate && unpublishDate))) {
        dispatch<any>(asyncActions.updateAndPublish({ id, data: formData }));
      } else if (!publish && !currentArticle?.unpublishDate) {
        dispatch<any>(asyncActions.updateAndDraft({ id, data: formData }));
      } else {
        dispatch<any>(asyncActions.update({ id, data: formData }));
      }
    } else {
      if (publish) {
        dispatch<any>(asyncActions.createAndPublish({ data: formData }));
      } else {
        dispatch<any>(asyncActions.create({ data: formData }));
      }
    }
    if (publish) {
      onBack('published');
    }
    clearValues();
  };

  const onUnPublish = () => {
    if (currentArticle) {
      dispatch<any>(asyncActions.unpublish({ item: currentArticle }));
    }
  };

  const disablePublishButton = !!(
    currentArticle &&
    [EArticleStatuses.DRAFT, EArticleStatuses.ARCHIVED].indexOf(
      getArticleStatus(currentArticle),
    ) === -1
  );

  // components
  const headerMenu = (
    <Menu className={styles.dropdown}>
      <Menu.Item
        key={Math.random()}
        onClick={onUnPublish}
        disabled={!currentArticle || !disablePublishButton}
      >
        {t('generic.buttons.archive')}
      </Menu.Item>
      <Menu.Item key={Math.random()}>
        {t('generic.buttons.duplicate')}
      </Menu.Item>
      <DeleteArticleModal
        articleName={title ?? ''}
        onClick={() => {
          if (currentArticle) {
            dispatch<any>(asyncActions.delete({ item: currentArticle }));
            onBack();
          }
        }}
      >
        <Menu.Item
          className={styles.deleteButton}
          key={Math.random()}
          disabled={!currentArticle}
        >
          {t('generic.buttons.delete')}
        </Menu.Item>
      </DeleteArticleModal>
    </Menu>
  );

  return (
    <>
      <ProPageHeader
        prefixedClassName={styles.pageHeader}
        title={
          articleId
            ? t('articles.edit.header.edit')
            : t('articles.edit.header.create')
        }
        extra={[
          <Button className={styles.defaultButton} onClick={() => onCancelPress()}>
            {t('generic.modals.buttons.cancel')}
          </Button>,
          <Tooltip
            placement="bottom"
            title={
              currentArticle &&
              getArticleStatus(currentArticle) !== EArticleStatuses.DRAFT
                ? 'Removes from live site and save new version as draft'
                : ''
            }
          >
            <Button
              className={styles.defaultButton}
              disabled={!validateFields()}
              onClick={() => onSave()}
            >
              {t('generic.forms.saveAsDraft')}
            </Button>
          </Tooltip>,
          <PublishArticleModal
            articleName={title ?? ''}
            onClick={() => onSave(true)}
          >
            <Button type="primary" disabled={!validateFields()}>
              {t('generic.forms.publish')}
            </Button>
          </PublishArticleModal>,
          <Dropdown overlay={headerMenu} placement="bottomRight">
            <Button style={{ width: '32px', padding: 0 }}>...</Button>
          </Dropdown>,
        ]}
        onBack={() => {}}
        backIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ margin: 0, padding: 0 }}
            onClick={() => onCancelPress()}
          />
        }
      />
      <div className={styles.formContainer}>
        <FormCard className={styles.formCard}>
          <Form layout="vertical">
            <FormTitle>{t('articles.edit.title') || ''}</FormTitle>
            <Form.Item
              className={styles.formItem}
              label={t('articles.edit.articleName')}
            >
              <Input
                onChange={(e) => {
                  setTitle(e.target.value)
                  setChangedFlag(true)
                }}
                placeholder={t('articles.edit.articleName') || ''}
                value={title ?? ''}
              />
            </Form.Item>
            <FormItemTwoColumn>
              <FormItemTwoColumn.Column>
                <Form.Item
                  className={styles.formItem}
                  label={t('articles.category')}
                >
                  <Select
                    onSelect={(val: number) => {
                      setCategoryId(val);
                      setRelatedActivity(null);
                    }}
                    showSearch
                    onSearch={(value) => setSearchCategory(value)}
                    searchValue={searchCategory}
                    value={categoryId}
                    filterOption={false}
                    placeholder={t('generic.placeholder.placeSelect')}
                  >
                    {categories.list?.map((c) => (
                      <Option value={c.id}>{c.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </FormItemTwoColumn.Column>
              <FormItemTwoColumn.Column>
                <Form.Item
                  className={styles.formItem}
                  label={t('articles.relatedActivity')}
                >
                  <Select
                    onSelect={(val: number) => setRelatedActivity(val)}
                    showSearch
                    optionFilterProp="children"
                    value={relatedActivity}
                    placeholder={t('generic.placeholder.placeSelect')}
                  >
                    {categories.list
                      ?.find(({ id }) => id === categoryId)
                      ?.activityTagToCategory?.map((tag) => (
                        <Option value={tag.activityTag.id}>
                          {tag.activityTag.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </FormItemTwoColumn.Column>
            </FormItemTwoColumn>
            <Form.Item
              className={styles.formItem}
              label={t('articles.shortDescription.title')}
            >
              <TextArea
                onChange={(e) => {
                  setShortDescription(e.target.value)
                  setChangedFlag(true)
                }}
                autoSize={{ minRows: 4, maxRows: 10 }}
                showCount
                maxLength={150}
                value={shortDescription ?? ''}
                placeholder={t('articles.shortDescription.placeholder') || ''}
              />
            </Form.Item>
            <div className={styles.formItem}>
              <ImageUploadForm
                onFileChanged={(image, base64) => {
                  setImageBase64(base64);
                  setTitleImage(image);
                }}
                tooltip={t('articles.image.tooltip')}
                currentImage={imageBase64}
              />
            </div>
            <Form.Item
              className={styles.formItem}
              label={t('articles.featured.title')}
              extra={t('articles.featured.tooltip')}
            >
              <Switch
                onChange={(val) => {
                  setFeatured(val)
                  setChangedFlag(true)
                }}
                checked={featured || currentArticle?.featured}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              className={styles.formItem}
              label={t('articles.body.title')}
            >
              <CkEditor
                onChange={(_, editor) => {
                  const data = editor.getData();
                  setContent(data);
                  setChangedFlag(true)
                }}
                currentData={content || currentArticle?.content || ''}
              />
            </Form.Item>
          </Form>
        </FormCard>
        {currentArticle && (
          <Col className={styles.articleDataColumn}>
            <div>{t('articles.edit.forms.articleInfo')}</div>
            <div className={styles.dataBlock}>
              <div>{t('articles.currentStatus.title')}</div>
              {getArticleStatusRendered(currentArticle, t)}
            </div>
            <div className={styles.dataBlock}>
              <div>{t('articles.id.title')}</div>
              <div className={styles.dataBlockValue}>{currentArticle?.id}</div>
            </div>
            <div className={styles.dataBlock}>
              <div>{t('articles.created.title')}</div>
              <div className={styles.dataBlockValue}>{createdAt}</div>
            </div>
            <div className={styles.dataBlock}>
              <div>{t('articles.updated.title')}</div>
              <div className={styles.dataBlockValue}>{updatedAt}</div>
            </div>
          </Col>
        )}
      </div>
      <RouteLeavingGuard
        lastLocation='/articles'
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </>
  );
}

export default EditArticle;
