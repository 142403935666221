import { ActionReducerMapBuilder, AsyncThunk, Draft } from '@reduxjs/toolkit';
import RequestStatuses from '../constants/requestStatuses';
import { TErrorPayload } from './apiRequest';

type TExtraReducerBuilder = <StateType, PayloadType>(
  builder: ActionReducerMapBuilder<StateType>,
  asyncAction: AsyncThunk<any, any, any>,
  statusFieldName: keyof Draft<StateType>,
  events: {
    onSuccess?: (state: Draft<StateType>, payload: PayloadType) => void;
    onLoad?: (state: Draft<StateType>) => void;
    onFail?: (state: Draft<StateType>, payload?: PayloadType) => void;
  },
  skipSuccess?: boolean,
) => void;

const extraReducerBuilder: TExtraReducerBuilder = (
  builder,
  asyncAction,
  statusFieldName,
  events,
  skipSuccess,
) => {
  builder
    .addCase(asyncAction.pending, (state) => {
      (state[statusFieldName] as string) = RequestStatuses.LOADING;
      events.onLoad?.(state);
    })
    .addCase(asyncAction.fulfilled, (state, action) => {
      const { success } = action.payload || {};
      if (success || skipSuccess) {
        (state[statusFieldName] as string) = RequestStatuses.SUCCESS;
        events.onSuccess?.(state, action.payload);
      } else {
        (state[statusFieldName] as string) = RequestStatuses.FAILED;
        events.onFail?.(state, action.payload);
      }
    })
    .addCase(asyncAction.rejected, (state) => {
      (state[statusFieldName] as string) = RequestStatuses.FAILED;
      events.onFail?.(state);
    });
};

export type TReducerEvents<StateType, PayloadType> = {
  onSuccess?: (state: Draft<StateType>, payload: PayloadType) => void;
  onLoad?: (state: Draft<StateType>) => void;
  onFail?: (state: Draft<StateType>, payload?: PayloadType) => void;
};

export type TExtraReducerBuilderV2 = <StateType, PayloadType>(
  builder: ActionReducerMapBuilder<StateType>,
  asyncAction: AsyncThunk<any, any, any>,
  options?: {
    statusChanger?: (
      state: Draft<StateType>,
      status: RequestStatuses,
    ) => void | null;
    events?: TReducerEvents<StateType, PayloadType> | null;
    skipSuccessInResponse?: boolean | null;
  } | null,
) => void;

export const extraReducerBuilderV2: TExtraReducerBuilderV2 = (
  builder,
  asyncAction,
  options,
) => {
  const { statusChanger, events, skipSuccessInResponse } = options || {};
  builder
    .addCase(asyncAction.pending, (state) => {
      statusChanger?.(state, RequestStatuses.LOADING);
      events?.onLoad?.(state);
    })
    .addCase(asyncAction.fulfilled, (state, action) => {
      const { success } = action.payload || {};
      if (success || skipSuccessInResponse) {
        statusChanger?.(state, RequestStatuses.SUCCESS);
        events?.onSuccess?.(state, action.payload);
      } else {
        statusChanger?.(state, RequestStatuses.FAILED);
        events?.onFail?.(state, action.payload);
      }
    })
    .addCase(asyncAction.rejected, (state) => {
      statusChanger?.(state, RequestStatuses.FAILED);
      events?.onFail?.(state);
    });
};

export default extraReducerBuilder;
