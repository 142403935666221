import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  forgotPassword,
  loginRequest,
  resetPassword,
  setUserPassword,
  resetAppPassword,
  resetUserPassword,
  getAuthenticatedUserDetails,
  refreshTokenRequest,
} from './AuthApi';
import extraReducerBuilder from '../../helpers/extraReducerBuilder';
import { TLoginResponse } from './AuthTypes';
import { Config } from '../../config/config';
import requestStatuses from '../../constants/requestStatuses';
import { UserRoles } from '../../constants/userRoles';

export const getTokenDateFromStorage = () => {
  const date = window.localStorage.getItem('tokenCreatedAt');
  if (date) {
    const tokenDate = new Date(date);

    return isNaN(tokenDate.getTime()) ? null : tokenDate;
  }

  return null;
};

export const authActions = {
  LOGIN: 'auth/login',
  FORGOT_PASSWORD: 'auth/forgotPassword',
  RESET_PASSWORD: 'auth/resetPassword',
  SET_USER_PASSWORD: 'auth/activateAccount',
  SET_APP_PASSWORD: 'auth/setAppPassword',
  GET_AUTH_USER: 'auth/getAuthenticatedUserDetails',
  GET_USER_PRODUCT_WELCOME: 'auth/onboarding',
  REFRESH_TOKEN: 'auth/refreshToken',
};

interface IState {
  token: string | null;
  refreshToken: string | null;
  loginStatus?: requestStatuses | null;
  forgotPasswordStatus?: requestStatuses | null;
  tokenCreatedAt: Date | null;
  resetPasswordStatus?: requestStatuses | null;
  resetAppPasswordStatus?: requestStatuses | null;
  setUserPasswordStatus?: requestStatuses | null;
  user: {
    id: number | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    role: UserRoles | null;
  };
  onboardUser: {
    firstName: string | null;
    lastName: string | null;
    dob: string | null;
    guarantees: string[] | null;
    email: string | null;
  };
}

const initialState: IState = {
  token: window.localStorage.getItem('token') || null,
  tokenCreatedAt: getTokenDateFromStorage(),
  refreshToken: window.localStorage.getItem('refreshToken') || null,
  user: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    role: null,
  },
  onboardUser: {
    firstName: null,
    lastName: null,
    dob: null,
    guarantees: null,
    email: null,
  },
};

export const loginAsync = createAsyncThunk(
  authActions.LOGIN,
  async (
    { email, password }: { email: string; password: string },
    { dispatch },
  ) => {
    const result = await loginRequest(email, password);

    dispatch(
      setToken({ token: result.token, refreshToken: result.refreshToken }),
    );
  },
);

export const refreshTokenAsync = createAsyncThunk(
  authActions.REFRESH_TOKEN,
  async ({ refreshToken }: { refreshToken: string }, { dispatch }) => {
    const result = await refreshTokenRequest(refreshToken);

    dispatch(
      setToken({ token: result.token, refreshToken: result.refreshToken }),
    );
  },
);

export const forgotPasswordAsync = createAsyncThunk(
  authActions.FORGOT_PASSWORD,
  async ({ email }: { email: string }) => forgotPassword(email),
);

export const resetPasswordAsync = createAsyncThunk(
  authActions.RESET_PASSWORD,
  async ({ password, token }: { password: string; token: string }) =>
    resetPassword(password, token),
);

export const resetUserPasswordAsync = createAsyncThunk(
  authActions.RESET_PASSWORD,
  async ({ password, code }: { password: string; code: string }) => resetUserPassword(password, code),
);

export const setUserPasswordAsync = createAsyncThunk(
  authActions.SET_USER_PASSWORD,
  async ({ password, token }: { password: string; token: string }) =>
    setUserPassword(password, token),
);

export const resetAppPasswordAsync = createAsyncThunk(
  authActions.SET_APP_PASSWORD,
  async ({ password, token }: { password: string; token: string }) =>
    resetAppPassword(password, token),
);

export const getAuthenticatedUserDetailsAsync = createAsyncThunk(
  authActions.GET_AUTH_USER,
  async (params, { dispatch }) => {
    const result = await getAuthenticatedUserDetails();

    dispatch(setAuthenticatedUser(result.data));
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      const { token, refreshToken } = action.payload || {};
      const tokenCreatedAt = new Date();

      // save to local storage
      window.localStorage.setItem('token', token);
      window.localStorage.setItem('refreshToken', refreshToken);
      window.localStorage.setItem(
        'tokenCreatedAt',
        tokenCreatedAt.toISOString(),
      );

      state.token = token;
      state.tokenCreatedAt = tokenCreatedAt;
    },
    removeToken: (state) => {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('tokenCreatedAt');

      state.token = null;
      state.tokenCreatedAt = null;
    },
    setAuthenticatedUser: (state, action) => {
      const user = action.payload || {};

      state.user = {
        ...user,
        role: user?.role?.title,
      };
    },
    clearResetStatus: (state) => {
      state.resetPasswordStatus = null;
      state.resetAppPasswordStatus = null;
    },
  },
  extraReducers: (builder) => {
    extraReducerBuilder<IState, TLoginResponse>(
      builder,
      loginAsync,
      'loginStatus',
      {},
      true,
    );
    extraReducerBuilder<IState, {}>(
      builder,
      forgotPasswordAsync,
      'forgotPasswordStatus',
      {},
      true,
    );
    extraReducerBuilder<IState, {}>(
      builder,
      resetPasswordAsync,
      'resetPasswordStatus',
      {},
      true,
    );
    extraReducerBuilder<IState, {}>(
      builder,
      resetAppPasswordAsync,
      'resetAppPasswordStatus',
      {},
      true,
    );
    extraReducerBuilder<IState, {}>(
      builder,
      setUserPasswordAsync,
      'setUserPasswordStatus',
      {},
      true,
    );
  },
});

export const {
  setToken,
  removeToken,
  setAuthenticatedUser,
  clearResetStatus,
} = authSlice.actions;

export const userAuthenticated = (state: { auth: IState }) =>
  !!state.auth.token
  && !!state.auth.tokenCreatedAt
  && Math.abs(
    (state.auth.tokenCreatedAt.getTime() - new Date().getTime()) / (1000 * 60 * 60),
  ) < Config.JWT_TOKEN_LIFETIME;

export const selectAuthenticatedUser = (state: { auth: IState }) =>
  state.auth.user;
export const selectLoginStatus = (state: { auth: IState }) =>
  state.auth.loginStatus;
export const selectForgotPasswordStatus = (state: { auth: IState }) =>
  state.auth.forgotPasswordStatus;
export const selectResetPasswordStatus = (state: { auth: IState }) =>
  state.auth.resetPasswordStatus;
export const selectAppResetPasswordStatus = (state: { auth: IState }) =>
  state.auth.resetAppPasswordStatus;
export const selectRefreshToken = (state: { auth: IState }) =>
  state.auth.refreshToken;
export const selectSetUserPasswordStatus = (state: { auth: IState }) =>
  state.auth.setUserPasswordStatus;

export default authSlice.reducer;
