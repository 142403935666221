import React from 'react';
import { ModalFuncProps, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './BasicModal.module.scss';

const { confirm } = Modal;

export enum ModalTypes {
  CONFIRM = 'confirm',
  CONFIRM_DANGER = 'confirmDanger',
}

type TBasicModalProps = {
  modalButton: JSX.Element;
  onClick: () => void;
  modalConfig: ModalFuncProps;
  type: ModalTypes;
  revertButtons?: boolean;
};

function BasicModal({
  modalButton,
  onClick,
  modalConfig,
  type,
  revertButtons,
}: TBasicModalProps) {
  const updatedConfig = {
    ...modalConfig,
    [revertButtons ? 'onCancel' : 'onOk']: onClick,
    ...(type === ModalTypes.CONFIRM
      ? { icon: <ExclamationCircleOutlined className={styles.confirmIcon} /> }
      : {}),
  };

  const onShowModal = () => {
    confirm(updatedConfig);
  };

  return React.cloneElement(modalButton, { onClick: onShowModal });
}

export default BasicModal;
