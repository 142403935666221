import buildSlice from '../../general/helpers/genericSliceBuilder';
import {
  TCompaniesListResponse,
  TMainAdmin,
  TMainContactCreationResponse,
} from './CompanyType';
import { createAdminInCompany, deletAdminIncompany } from './CompaniesApi';

const companyMainContactSlice = buildSlice<
  TMainAdmin,
  TMainContactCreationResponse,
  TCompaniesListResponse
>('companyMainContact', {
  createJson: createAdminInCompany as (data: {
    [key: string]: any;
  }) => Promise<TMainContactCreationResponse>,
  deleteJson: deletAdminIncompany as (data: {
    [key: string]: any;
  }) => Promise<TMainContactCreationResponse>,
});

export const { selectors, asyncActions, actions } = companyMainContactSlice;

export default companyMainContactSlice.reducer;
