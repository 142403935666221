import React, { useEffect } from 'react';
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';
import { Badge, Button, Input, Dropdown, Menu } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Table from '../../../components/Table/Table';
import {
  TTableData,
  TClaimedReward,
  EClaimedRewardStatuses,
} from './ClaimedTypes';
import styles from './ClaimedRewards.module.scss';
import { selectors, asyncActions, actions } from './ManageClaimedRewardsSlice';
import useQuery from '../../../helpers/useQuery';

const { Search } = Input;

function ClaimedRewards() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = useQuery();

  const claimedRewardsData = useSelector(selectors.listData);

  useEffect(() => {
    dispatch(actions.clearFilters());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';
      const sortField = sortOrder.startsWith('-')
        ? sortOrder.substring(1)
        : sortOrder;

      if (sortField === 'name') {
        dispatch(actions.clearOrder());
        dispatch(
          actions.setFilter({
            field: 'orderByName',
            value: sortOrder.startsWith('-') ? 'DESC' : 'ASC',
            custom: true,
          }),
        );
      }

      if (sortField === 'email') {
        dispatch(actions.clearOrder());
        dispatch(
          actions.setFilter({
            field: 'orderByEmail',
            value: sortOrder.startsWith('-') ? 'DESC' : 'ASC',
            custom: true,
          }),
        );
      }

      dispatch(
        actions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    if (query.has('status')) {
      const filterStatus = (query.get('status') || '').split(',');

      for (const status of filterStatus) {
        dispatch(
          actions.setFilter({
            field: 'status',
            value: status,
          }),
        );
      }
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (claimedRewardsData.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, claimedRewardsData]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      ...(query.has('sort') &&
      ['id', '-id'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-id' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('rewards.table.userName'),
      dataIndex: 'name',
      width: '20%',
      sorter: true,
      ...(query.has('sort') &&
      ['name', '-name'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-name' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('rewards.table.email'),
      dataIndex: 'email',
      width: '25%',
      sorter: true,
      ...(query.has('sort') &&
      ['email', '-email'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-email' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('rewards.table.rewardTitle'),
      dataIndex: 'reward',
      width: '20%',
    },
    {
      title: t('rewards.table.claimedDate'),
      dataIndex: 'createdAt',
      width: '15%',
      sorter: true,
      ...(query.has('sort') &&
      ['createdAt', '-createdAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-createdAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('rewards.table.status'),
      dataIndex: 'status',
      filters: [
        { value: 'requested', text: t('generic.operations.requested') },
        { value: 'granted', text: t('generic.operations.granted') },
        { value: 'canceled', text: t('generic.operations.cancelled') },
      ],
      width: '15%',
      render: (_: string, record: TTableData) => {
        const reward = claimedRewardsData.list?.find(
          (it) => it.id === record.key,
        );
        return reward ? getRewardStatusRendered(reward) : <div />;
      },
      ...(query.has('status')
        ? {
            defaultFilteredValue: query.get('status')?.split(','),
          }
        : {}),
    },
  ];

  const getRewardStatus = (reward?: TClaimedReward): EClaimedRewardStatuses => {
    if (!reward?.grantedAt && !reward?.canceledAt) {
      return EClaimedRewardStatuses.REQUESTED;
    }
    if (reward?.grantedAt && !reward?.canceledAt) {
      return EClaimedRewardStatuses.GRANTED;
    }
    if (reward?.canceledAt) {
      return EClaimedRewardStatuses.CANCELED;
    }
    return EClaimedRewardStatuses.REQUESTED;
  };

  function AssignMenu(child: JSX.Element, claimedReward: TClaimedReward) {
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item key="requested" onClick={() => {}} disabled>
              <Badge color="yellow" text={t('generic.operations.requested')} />
            </Menu.Item>
            <Menu.Item
              key="granted"
              onClick={() => {
                dispatch<any>(asyncActions.publish({ item: claimedReward }));
              }}
            >
              <Badge color="green" text={t('generic.operations.granted')} />
            </Menu.Item>
            <Menu.Item
              key="cancelled"
              onClick={() => {
                dispatch<any>(asyncActions.unpublish({ item: claimedReward }));
              }}
            >
              <Badge
                status="default"
                text={t('generic.operations.cancelled')}
              />
            </Menu.Item>
          </Menu>
        }
      >
        {child}
      </Dropdown>
    );
  }

  const getRewardStatusRendered = (reward: TClaimedReward): JSX.Element => {
    switch (getRewardStatus(reward)) {
      case EClaimedRewardStatuses.REQUESTED: {
        return AssignMenu(
          <div className={styles.statusContainer}>
            <div>
              <Badge color="yellow" text={t('generic.operations.requested')} />
            </div>
            <div className={styles.icon}>
              <DownOutlined />
            </div>
          </div>,
          reward,
        );
      }
      case EClaimedRewardStatuses.CANCELED: {
        return AssignMenu(
          <div className={styles.statusContainer}>
            <div>
              <Badge
                status="default"
                text={t('generic.operations.cancelled')}
              />
            </div>
            <div className={styles.icon}>
              <DownOutlined />
            </div>
          </div>,
          reward,
        );
      }
      case EClaimedRewardStatuses.GRANTED: {
        return AssignMenu(
          <div className={styles.statusContainer}>
            <div>
              <Badge color="green" text={t('generic.operations.granted')} />
            </div>
            <div className={styles.icon}>
              <DownOutlined />
            </div>
          </div>,
          reward,
        );
      }
      default: {
        return AssignMenu(
          <div className={styles.statusContainer}>
            <div>
              <Badge color="yellow" text={t('generic.operations.requested')} />
            </div>
            <div className={styles.icon}>
              <DownOutlined />
            </div>
          </div>,
          reward,
        );
      }
    }
  };

  const onBack = () => navigate('/rewards');

  const tableData: TTableData[] =
    claimedRewardsData.list?.map((item: TClaimedReward) => ({
      key: item.id,
      id: item.id,
      name: `${item.user.firstName || ''} ${item.user.lastName || ''}`,
      email: item.user.email,
      reward: item.reward?.name || '',
      createdAt: dayjs(item.createdAt).format('ddd, DD MMMM'),
      status: getRewardStatus(item),
    })) || [];

  return (
    <div key={`claimed-rewards-list-${Array.from(query.keys()).length}`}>
      <ProPageHeader
        prefixedClassName={styles.pageHeader}
        onBack={() => {}}
        backIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ margin: 0, padding: 0 }}
            onClick={onBack}
          />
        }
      />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>
          {t('rewards.manageClaimedRewards.title')}
        </div>
        <div className={styles.buttonContainer}>
          <Search
            allowClear
            defaultValue={query.get('search') || ''}
            className={styles.searchField}
            placeholder={t('rewards.search')|| ''}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                claimedRewardsData.listParams?.pagination?.perPage
                  ? claimedRewardsData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
          />
        </div>
      </div>
      <Table
        actions={actions}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: claimedRewardsData.listParams?.pagination?.total,
          current: claimedRewardsData.listParams?.pagination?.page,
        }}
        customOrderHandler={() => {}}
      />
    </div>
  );
}

export default ClaimedRewards;
