import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge, DatePicker, Form, Input, Select, Tag, Switch,
} from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'antd/es/table/interface';
import TextArea from 'antd/lib/input/TextArea';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { EProductPlans, EProductStatuses } from '../../../ProductsConstants';
import FormTitle from '../../../../../../../../../components/Forms/FormTitle/FormTitle';
import styles from './PriductInfo.module.scss';
import {
  actions as guaranteesActions,
  asyncActions as guaranteesAsyncActions,
  selectors as guaranteesSelectors,
} from '../../../../../../../../guarantees/GuaranteesSlice';
import { asyncActions as productActions } from '../../../ProductsSlice';
import { TGuarantee } from '../../../../../../../../guarantees/GuaranteesTypes';
import Table from '../../../../../../../../../components/Table/Table';
import ImageUploadForm from '../../../../../../../../../components/ImageUploadForm/ImageUploadForm';

const { Option } = Select;
const { Search } = Input;

type TProductData = {
  productName: string | null;
  plan: EProductPlans | null;
  productCategoryId: number | null;
  policyNumber: string | null;
  ctaLink: string | null;
  description: string | null;
  activationDate: string | null;
  guarantees: number[] | null;
  headImage: string | null;
  imageBase64: string | null;
  isVisible: boolean | false;
};

type TProductExtra = {
  statusText?: EProductStatuses;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
};

type TProductInfoProps = {
  productData: TProductData & TProductExtra;
  onChange: (field: keyof TProductData, value: any) => void;
  disabled?: boolean;
};

type TTableData = {
  key: Key;
  id: TGuarantee['id'];
  name: string;
  description: string;
};

function ProductInfo({ productData, onChange, disabled }: TProductInfoProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const guaranteesList = useSelector(guaranteesSelectors.listData);

  useEffect(() => {
    dispatch(guaranteesActions.setSearch({ query: '' }));
    dispatch(guaranteesActions.clearFilters());
    dispatch(guaranteesActions.setPagination({ page: 1, perPage: 10 }));
    dispatch(productActions.singleRequests?.getProductCategories());
    dispatch<any>(guaranteesAsyncActions.fetchList());
  }, [dispatch]);

  const onSelectGuarantees = (selectedIds: number[]) => {
    onChange('guarantees', selectedIds);
  };

  const tableData: TTableData[] = guaranteesList?.list?.map((item: TGuarantee) => ({
    key: item.id,
    id: item.id,
    name: item.name,
    description: item.description,
  })) || [];

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: (productData.guarantees || []) as Key[],
    onChange: (selectedRows: Key[]) => {
      if (disabled) return;
      onSelectGuarantees(selectedRows as number[]);
    },
  };

  const columns = [
    {
      title: t('guarantees.table.name'),
      dataIndex: 'name',
      width: '35%',
      sorter: true,
    },
    {
      title: t('guarantees.table.description'),
      dataIndex: 'description',
    },
  ];

  return (
    <>
      <Form layout="vertical">
        <FormTitle className={styles.formTitle}>
          {t('products.basicInformation') || ''}
        </FormTitle>
        <div className={styles.fieldWrap}>
          <Form.Item
            className={styles.item}
            label={t('users.insurances.productName')}
          >
            <Input
              onChange={(e) => onChange('productName', e.target.value)}
              value={productData.productName || undefined}
              placeholder={t('generic.example') || ''}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            className={styles.item}
            label={t('products.forms.selectPlan')}
          >
            <Select
              disabled={disabled}
              mode="tags"
              showSearch={false}
              searchValue=""
              onSelect={(value: string) => onChange('plan', value)}
              value={productData.plan || undefined}
              tagRender={(props) => {
                // eslint-disable-next-line react/prop-types
                const { label, closable, onClose } = props;

                const onPreventMouseDown = (event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                };

                let color = '#FFFFFF';
                switch (label) {
                  case EProductPlans.BASIC:
                    color = '#EB2F96';
                    break;
                  case EProductPlans.FLEXIBLE:
                    color = '#13C2C2';
                    break;
                  case EProductPlans.INDIVIDUAL:
                    color = '#597EF7';
                    break;
                }

                return (
                  <Tag
                    color={color}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={() => {
                      onChange('plan', undefined);
                      onClose();
                    }}
                    style={{ marginRight: 3 }}
                  >
                    {label}
                  </Tag>
                );
              }}
            >
              <Option key={EProductPlans.BASIC}>{EProductPlans.BASIC}</Option>
              <Option key={EProductPlans.FLEXIBLE}>
                {EProductPlans.FLEXIBLE}
              </Option>
              <Option key={EProductPlans.INDIVIDUAL}>
                {EProductPlans.INDIVIDUAL}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.item}
            label={t('products.activationDate')}
          >
            <DatePicker
              disabled={disabled}
              className={styles.dateInput}
              onChange={(val) => onChange('activationDate', val?.toISOString())}
              value={
                productData.activationDate
                  ? dayjs(productData.activationDate)
                  : null
              }
              placeholder={t('generic.selectDate') || ''}
            />
          </Form.Item>
        </div>
        <div className={styles.fieldWrap}>
          <Form.Item
            className={styles.item}
            label={t('articles.category')}
          >
            <Select
              disabled={disabled}
              mode="tags"
              showSearch={false}
              searchValue=""
              onSelect={(value: string) => onChange('productCategoryId', value)}
              value={productData?.productCategoryId?.toString() || undefined}
              tagRender={(props) => {
                // eslint-disable-next-line react/prop-types
                const { closable, onClose, value, label } = props;

                const onPreventMouseDown = (event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                };

                let color = '#FFFFFF';
                switch (value) {
                  case '1':
                    color = '#EB2F96';
                    break;
                  case '2':
                    color = '#13C2C2';
                    break;
                  case '3':
                    color = '#597EF7';
                    break;
                }

                return (
                  <Tag
                    color={color}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={() => {
                      onChange('productCategoryId', undefined);
                      onClose();
                    }}
                    style={{ marginRight: 3 }}
                  >
                    {label}
                  </Tag>
                );
              }}
            >
              <Option key={1}>{t('products.types.accidents')}</Option>
              <Option key={2}>{t('products.types.health')}</Option>
              <Option key={3}>{t('products.types.life')}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.item}
            label={t('product.policyNumber')}
          >
            <Input
              onChange={(e) => onChange('policyNumber', e.target.value)}
              value={productData.policyNumber || undefined}
              placeholder={t('generic.example') || ''}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            className={styles.item}
            label={t('product.cta.link')}
            tooltip={{
              title: t('product.cta.info'),
              icon: <ExclamationCircleOutlined style={{ color: '#680079' }} />,
            }}
          >
            <Input
              onChange={(e) => onChange('ctaLink', e.target.value)}
              value={productData.ctaLink || undefined}
              placeholder={t('generic.example') || ''}
              disabled={disabled}
            />
          </Form.Item>
        </div>

        <div className={styles.wrgGeneralLabel}>{t('product.visibility')}</div>
        <div className={styles.wrgToggle}>
          <Switch checked={productData.isVisible} onChange={() => onChange('isVisible', !productData.isVisible)} />

          <div className={styles.wrgToggleText}>
            <div className={styles.wrgTextLabel}>{t('product.visibleForEveryOne')}</div>
            <div className={styles.wrgSubLabel}>{t('product.visibilityOnlyForEmployees')}</div>
          </div>
        </div>

        <div className={`${styles.fieldWrap} ${styles.descAndImage}`}>
          <Form.Item
            className={`${styles.formItem} ${styles.textAreaForm}`}
            label={t('challenges.form.description')}
          >
            <TextArea
              onChange={(e) => onChange('description', e.target.value)}
              autoSize={{ minRows: 4, maxRows: 10 }}
              showCount
              maxLength={500}
              value={productData.description || undefined}
              placeholder={t('articles.shortDescription.placeholder') || ''}
            />
          </Form.Item>

          <ImageUploadForm
            onFileChanged={(img, base64) => {
              onChange('headImage', img || undefined);
              onChange('imageBase64', base64 || undefined);
            }}
            currentImage={productData.imageBase64}
            tooltip={t('challenges.tooltips.imageResolution')}
            disabled={false}
          />

        </div>
      </Form>
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>
          {t('products.forms.addGuarantees')}
        </div>
        <div className={styles.buttonContainer}>
          <Search
            allowClear
            className={styles.searchField}
            placeholder={t('guarantees.placeholder.search') || ''}
            onSearch={(val) => {
              dispatch(guaranteesActions.setSearch({ query: val }));
              dispatch(
                guaranteesActions.setPagination({
                  page: 1,
                  perPage: guaranteesList.listParams?.pagination?.perPage || 10,
                }),
              );
              dispatch<any>(guaranteesAsyncActions.fetchList());
            }}
          />
        </div>
      </div>
      <Table
        enableUrlChange={false}
        actions={guaranteesActions}
        asyncActions={guaranteesAsyncActions}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: guaranteesList.listParams?.pagination?.total,
          current: guaranteesList.listParams?.pagination?.page,
        }}
      />
      {productData.id && (
        <div className={styles.productInfo}>
          <div className={styles.title}>{t('products.forms.productInfo')}</div>
          <div className={styles.dataWrap}>
            <div className={styles.dataRow}>
              <div className={styles.dataBlock}>
                <div className={styles.dataTitle}>
                  {t('generic.currentStatus')}
                </div>
                <div className={styles.dataValue}>
                  {((p?: EProductStatuses) => {
                    switch (p) {
                      case EProductStatuses.ACTIVE:
                        return (
                          <Badge status="success" text={t('generic.active')} />
                        );
                      case EProductStatuses.DRAFT:
                        return (
                          <Badge color="yellow" text={t('generic.draft')} />
                        );
                      default:
                        return (
                          <Badge
                            status="default"
                            text={t('generic.inactive')}
                          />
                        );
                    }
                  })(productData?.statusText)}
                </div>
              </div>
              <div className={styles.dataBlock}>
                <div className={styles.dataTitle}>{t('generic.idNumber')}</div>
                <div className={styles.dataValue}>{productData?.id}</div>
              </div>
            </div>
            <div className={styles.dataRow}>
              <div className={styles.dataBlock}>
                <div className={styles.dataTitle}>{t('generic.created')}</div>
                <div className={styles.dataValue}>{productData.createdAt}</div>
              </div>
              <div className={styles.dataBlock}>
                <div className={styles.dataTitle}>
                  {t('generic.lastUpdate')}
                </div>
                <div className={styles.dataValue}>{productData.updatedAt}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ProductInfo.defaultProps = {
  disabled: false,
};

export default ProductInfo;
