import {
  TChallengeEngagement,
  TChallengeEngagementListResponse,
} from './ChallengeEngagementTypes';
import { fetchChallenges } from './ChallengeEngagementApi';
import buildSlice from '../../general/helpers/genericSliceBuilder';

export const challengesSlice = buildSlice<
  TChallengeEngagement,
  any,
  TChallengeEngagementListResponse
>('challengeEngagement', {
  fetchList: fetchChallenges,
});

export const { selectors, asyncActions, actions } = challengesSlice;

export default challengesSlice.reducer;
