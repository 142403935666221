import { EGoalTypes } from '../../ChallengesTypes';

export const goalTypeUnits: {
  [key: string]: {
    name: string;
    func: (num: number) => number;
    refunc: (num: number) => number;
  };
} = {
  [EGoalTypes.POINTS]: {
    name: 'generic.number',
    func: (num) => num,
    refunc: (num) => num,
  },
  [EGoalTypes.DISTANCE]: {
    name: 'generic.km',
    func: (num) => num * 1000,
    refunc: (num) => num / 1000,
  },
  [EGoalTypes.CALORIES]: {
    name: 'generic.calories',
    func: (num) => num,
    refunc: (num) => num,
  },
  [EGoalTypes.DURATION]: {
    name: 'generic.minutes',
    func: (num) => num,
    refunc: (num) => num,
  },
  [EGoalTypes.STEPS]: {
    name: 'generic.steps',
    func: (num) => num,
    refunc: (num) => num,
  },
};
