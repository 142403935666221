import styles from './Progress.module.scss';
import { useTranslation } from 'react-i18next';
import { ProgressProps } from './types';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';
import { TGuarantee } from '../../features/guarantees/GuaranteesTypes';

function Progress({
  checkOne,
  checkTwo,
  checkThree,
  one,
  two,
  three,
  oneText,
  twoText,
  threeText,
  numberOne,
  numberTwo,
  numberThree,
  lineOne,
  lineTwo,
}: ProgressProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const data = location.state as {                            
    guarantees: TGuarantee[],
    email: string,
    token: string,
    activatedAt?: Date
  };
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={one}>
          <ReactSVG src={checkOne} className={numberOne} />
        </div>
        <div className={oneText}>{t('auth.progress.confirmData')}</div>
      </div>

      <div className={lineOne} />
      {
        data.guarantees?.length > 0 ? (
          <>
          <div className={styles.row}>
            <div className={two}>
              <ReactSVG src={checkTwo} className={numberTwo} />
            </div>
            <div className={twoText}>{t('auth.progress.yourInsurance')}</div>
          </div>

          <div className={lineTwo} />

          <div className={styles.row}>
            <div className={three}>
              <ReactSVG src={checkThree} className={numberThree} />
            </div>
            <div className={threeText}>{t('auth.progress.activateAccount')}</div>
          </div>
          </>
        ) : (
          <div className={styles.row}>
            <div className={two}>
              <ReactSVG src={checkTwo} className={numberTwo} />
            </div>
            <div className={twoText}>{t('auth.progress.activateAccount')}</div>
          </div>
        )
      }   
    </div>
  );
}

export default Progress;
