import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Form, Input,
} from 'antd';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Slide, toast, ToastContainer } from 'react-toastify';
import BackgroundWave from '../../../components/BackgoundWave/BackgroundWave';
import SideBackgroundImage from '../../../components/SideBackgroudImage/SideBackgroundImage';
import CentralCard from '../../../components/CentralCard/CentralCard';
import styles from './Login.module.scss';
import {
  clearResetStatus,
  loginAsync,
  selectLoginStatus,
  selectResetPasswordStatus,
} from '../AuthSlice';
import RequestStatuses from '../../../constants/requestStatuses';
import { ROUTES } from '../../../constants/routes';

const { Item } = Form;

function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const status = useSelector(selectLoginStatus);
  const resetPasswordStatus = useSelector(selectResetPasswordStatus);

  const [email, setEmail] = useState(null as string | null);
  const [password, setPassword] = useState(null as string | null);

  useEffect(() => {
    if (resetPasswordStatus === RequestStatuses.SUCCESS) {
      toast(
        <Alert
          className={styles.alertSuccess}
          message={t('auth.successResetMessage')}
          type="success"
          showIcon
          banner
        />,
        {
          autoClose: 2500,
          position: toast.POSITION.TOP_CENTER,
          closeButton: false,
          hideProgressBar: true,
          transition: Slide,
          className: styles.toast,
        },
      );

      dispatch(clearResetStatus());
    }
  }, [resetPasswordStatus]);

  const onLogin = () => {
    if (email && password) {
      dispatch<any>(loginAsync({ email, password }));
    }
  };

  return (
    <>
      <ToastContainer
        style={{
          margin: 0,
          padding: 0,
          boxShadow: 'none',
          top: '-8px',
          width: 'calc(100% + 12px)',
        }}
        autoClose={5000}
      />
      <BackgroundWave>
        <SideBackgroundImage
          leftImage="/assets/auth/finance.svg"
          rightImage="/assets/auth/technologies.svg"
        >
          <div className={styles.wrap}>
            <CentralCard className={styles.centralCard}>
              <>
                <ReactSVG src="/assets/auth/logo.svg" />
                <Form className={styles.loginForm} layout="vertical">
                  <Item label={t('login.forms.email')}>
                    <Input
                      className={styles.input}
                      type="email"
                      placeholder="example@vitaance.com"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Item>
                  <Item label={t('login.forms.password')}>
                    <Input.Password
                      className={styles.input}
                      placeholder="••••••••"
                      onChange={(e) => setPassword(e.target.value)}
                      onPaste={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      required
                    />
                  </Item>
                  {status === RequestStatuses.FAILED && (
                    <div className={styles.errorMessage}>
                      {t('login.forms.invalidPassword')}
                    </div>
                  )}
                  <div className={styles.forgotButtonContainer}>
                    <Button className={styles.forgotButton} type="link">
                      <Link to={ROUTES.forgotPassword}>
                        {t('login.forms.forgotQuestion')}
                      </Link>
                    </Button>
                  </div>
                  <Item>
                    <Button
                      onClick={onLogin}
                      htmlType="submit"
                      className={styles.signInButton}
                      type="primary"
                    >
                      {t('login.forms.signInButton')}
                    </Button>
                  </Item>
                  <div className={styles.or}>{t('generic.or')}</div>
                  <Item>
                    <Button
                      onClick={() => {}}
                      className={styles.signInButton}
                      icon={(
                        <ReactSVG
                          className={styles.googleIcon}
                          src="/assets/auth/google-icon.svg"
                        />
                      )}
                    >
                      {t('login.forms.signInGoogle')}
                    </Button>
                    <Button
                      onClick={() => {}}
                      className={`${styles.signInButton} ${styles.thirdLoginButton}`}
                      icon={(
                        <ReactSVG
                          className={styles.googleIcon}
                          src="/assets/auth/ms-icon.svg"
                        />
                      )}
                    >
                      {t('login.forms.signInMs')}
                    </Button>
                  </Item>
                </Form>
              </>
            </CentralCard>
            <div className={styles.legalBlock}>
              <div>{t('generic.copyright')}</div>
              <div>
                <a href="https://www.vitaance.com/aviso-legal" target="_blank" rel="noreferrer">
                  {t('generic.legalDocs')}
                </a>
                {' '}
                |
                {' '}
                <a
                  href="https://www.vitaance.com/politica-de-privacidad-vitaance"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('generic.privacyPolicy')}
                </a>
              </div>
            </div>
          </div>
        </SideBackgroundImage>
      </BackgroundWave>
    </>
  );
}

export default Login;
