import React from 'react';
import styles from './ModalCenter.module.scss';

interface ModalFullScreenProps {
  children: JSX.Element;
  show: boolean;
  className?: string;
  onClose?: () => void;
}

function ModalCenter({
  children,
  show,
  className,
  onClose,
}: ModalFullScreenProps) {
  if (!show) {
    return null;
  }

  return (
    <div
      onClick={(e) => {
        onClose?.();
      }}
      className={`${styles.modalBack} ${
        show ? '' : styles.modalContainerHidden
      }`}
    >
      <div
        className={`${styles.modalContainer} ${className}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
}

ModalCenter.defaultProps = {
  className: '',
  onClose: undefined,
};

export default ModalCenter;
