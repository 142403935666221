import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TActivateInsightModalProps = {
  insightName: string;
  children: JSX.Element;
  onClick: () => void;
};

function ActivateInsightModal({
  insightName,
  children,
  onClick,
}: TActivateInsightModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('insights.modal.publish.title'),
    okText: t('generic.activate'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('insights.modal.publish.content', { insightName }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM}
    />
  );
}

export default ActivateInsightModal;
