import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../helpers/apiRequest';
import {
  TCategory,
  TCategoryCreate,
  TCategoryListResponse,
  TCategoryResponse,
} from './CategoriesTypes';
import { TActivityTag } from '../activityTags/ActivityTagsTypes';

export const getCategories = async (
  listParams?: TListParams,
): Promise<TCategoryListResponse> =>
  apiRequestGet<TCategoryListResponse>('/categories', listParams);

export const getCategory = async (
  id: TCategory['id'],
): Promise<TCategoryResponse> =>
  apiRequestGet<TCategoryResponse>(`/categories/${id}`);

export const createCategory = async (
  data: TCategoryCreate,
): Promise<TCategoryResponse> =>
  apiRequest<TCategoryResponse>('/categories', 'post', data);

export const updateCategory = async (
  id: TCategory['id'],
  data: TCategory,
): Promise<TCategoryResponse> =>
  apiRequest<TCategoryResponse>(`/categories/${id}`, 'patch', data);

export const attachActivity = async (
  id: TActivityTag['id'],
  { activityTagId }: { activityTagId: number },
): Promise<TCategoryResponse> =>
  apiRequest<TCategoryResponse>(
    `/categories/${id}/activity-tags/${activityTagId}`,
    'post',
    null,
  );

export const detachActivity = async (
  id: TActivityTag['id'],
  { activityTagId }: { activityTagId: number },
): Promise<TCategoryResponse> =>
  apiRequest<TCategoryResponse>(
    `/categories/${id}/activity-tags/${activityTagId}`,
    'delete',
    null,
  );

export const deleteCategory = async (
  id: TCategory['id'],
): Promise<TCategoryResponse> =>
  apiRequest<TCategoryResponse>(`/categories/${id}`, 'delete', null);

export const attachActivityBulk = async (
  id: TActivityTag['id'],
  { activityTagIds }: { activityTagIds: number[] },
): Promise<TCategoryResponse[]> => {
  const requests = [] as Promise<TCategoryResponse>[];
  activityTagIds.forEach((ati) => {
    requests.push(
      apiRequest<TCategoryResponse>(
        `/categories/${id}/activity-tags/${ati}`,
        'post',
        null,
      ),
    );
  });

  return Promise.all(requests);
};

export const detachActivityBulk = async (
  id: TActivityTag['id'],
  { activityTagIds }: { activityTagIds: number[] },
): Promise<TCategoryResponse[]> => {
  const requests = [] as Promise<TCategoryResponse>[];
  activityTagIds.forEach((ati) => {
    requests.push(
      apiRequest<TCategoryResponse>(
        `/categories/${id}/activity-tags/${ati}`,
        'delete',
        null,
      ),
    );
  });

  return Promise.all(requests);
};
