import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';
import TopTeams from '../components/TopTeams/TopTeams';
import { asyncActions, selectors } from './LeaderboardSlice';
import { EGoalTypes, IChallenge, TTopTeam } from '../../ChallengesTypes';
import styles from './Leaderboard.module.scss';
import ChallengeStatusBadge from '../../../../components/Badge/ChallengeStatusBadge/ChallengeStatusBadge';
import { EChallengeStatuses } from '../../ChallengeList/ChallengeListTypes';
import { goalTypeUnits } from '../constants/goalTypeUnits';
import TeamMembers from '../components/TeamMembers/TeamMembers';

type TLeaderboardProps = {
  currentChallenge: IChallenge;
};

type TTableData = {
  key: number;
  number: number;
  name: string;
  total: number;
  members: null;
};

function Leaderboard({ currentChallenge }: TLeaderboardProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* DATA STORE */
  const teams = useSelector(selectors.teams);

  /* STATE */
  const [currentTeam, setCurrentTeam] = useState(null as TTopTeam | null);
  const [showUsersModal, setShowUsersModal] = useState(false);

  /* EFFECTS */
  useEffect(() => {
    dispatch<any>(asyncActions.fetch({ id: currentChallenge.id }));
  }, []);

  /* TABLE DATA */
  const tableData: TTableData[] =
    teams?.map((item, idx) => ({
      key: item.id,
      number: idx + 1,
      name: item.name,
      total: (() => {
        if (currentChallenge.workout) {
          switch (currentChallenge.goalType) {
            case EGoalTypes.STEPS:
              return item.total_workout_steps_progress || 0;
            case EGoalTypes.DISTANCE:
              return item.total_workout_distance_progress || 0;
            case EGoalTypes.CALORIES:
              return item.total_workout_calories_progress || 0;
          }
        }

        return item.total;
      })(),
      members: null,
    })) || [];
  const columns = [
    {
      title: '#',
      dataIndex: 'number',
      render: (value: number) => {
        const numStyles = {
          1: styles.number1,
          2: styles.number2,
          3: styles.number3,
        };

        return (
          <div
            className={`${styles.number} ${
              value <= 3 ? numStyles[value as keyof typeof numStyles] : ''
            }`}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: t('challenges.form.team'),
      dataIndex: 'name',
    },
    {
      title: `${t('generic.total')} ${t(
        goalTypeUnits[currentChallenge.goalType]?.name,
      ).toLocaleLowerCase()}`,
      dataIndex: 'total',
      render: (value: number) => {
        const calcVal = goalTypeUnits[currentChallenge.goalType]?.refunc(value);

        switch (currentChallenge.goalType) {
          case EGoalTypes.DISTANCE:
            return Intl.NumberFormat('de-DE', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }).format(calcVal);
          default:
            return Intl.NumberFormat('de-DE', {
              maximumFractionDigits: 0,
            }).format(calcVal);
        }
      },
    },
    {
      title: t('generic.members'),
      dataIndex: 'members',
      render: (_: null, record: TTableData) => (
        <Button
          type="link"
          onClick={() => {
            setCurrentTeam(
              teams.find((team) => team.id === record.key) || null,
            );
            setShowUsersModal(true);
          }}
        >
          {t('challenges.form.leaderboard.viewMembers')}
        </Button>
      ),
    },
  ];

  const daysLeft = Math.round(
    dayjs(currentChallenge.endDate).diff(dayjs(), 'days', true),
  );

  return (
    <>
      {currentTeam && (
        <TeamMembers
          challengeId={currentChallenge.id}
          team={currentTeam}
          show={showUsersModal}
          onClose={() => {
            setShowUsersModal(false);
          }}
        />
      )}
      <div className={styles.wrap}>
        <div className={styles.leaderboardWrap}>
          <TopTeams
            teams={teams.slice(0, 3)}
            currentChallenge={currentChallenge}
          />
          <div className={styles.title}>
            {t('challenges.form.leaderboard.fullLeaderboardTitle')}
          </div>
          <div className={styles.subtitle}>
            {t('challenges.form.leaderboard.fullLeaderboardSubtitle')}
          </div>
          <Table
            className={styles.table}
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        </div>
        <div className={styles.dataColumn}>
          <div>{t('challenges.form.challengeInformation')}</div>
          <div className={styles.dataBlock}>
            <div>{t('articles.currentStatus.title')}</div>
            {currentChallenge?.status.title === EChallengeStatuses.ONGOING ? (
              <div className={styles.statusWrapper}>
                <ChallengeStatusBadge
                  status={
                    currentChallenge?.status.title || EChallengeStatuses.DRAFT
                  }
                />
                &nbsp;-&nbsp;
                {(daysLeft > 1 || daysLeft < 1) && (
                  <div>
                    {t('challenges.form.leaderboard.daysLeft', {
                      count: daysLeft,
                    })}
                  </div>
                )}
                {daysLeft === 1 && (
                  <div>{t('challenges.form.leaderboard.dayLeft')}</div>
                )}
              </div>
            ) : (
              <ChallengeStatusBadge
                status={
                  currentChallenge?.status.title || EChallengeStatuses.DRAFT
                }
              />
            )}
          </div>
          <div className={styles.dataBlock}>
            <div>{t('articles.id.title')}</div>
            <div className={styles.dataBlockValue}>
              {currentChallenge?.id || '-'}
            </div>
          </div>
          <div className={styles.dataBlock}>
            <div>{t('articles.created.title')}</div>
            <div className={styles.dataBlockValue}>
              {currentChallenge?.createdAt
                ? dayjs(currentChallenge?.createdAt).format('DD/MM/YYYY HH:mm')
                : '-'}
            </div>
          </div>
          <div className={styles.dataBlock}>
            <div>{t('articles.updated.title')}</div>
            <div className={styles.dataBlockValue}>
              {currentChallenge?.updatedAt
                ? dayjs(currentChallenge?.updatedAt).format('DD/MM/YYYY HH:mm')
                : '-'}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Leaderboard;
