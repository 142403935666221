import React from 'react';
import {
  HeartOutlined,
  HomeOutlined,
  SafetyOutlined,
  ShopOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { compact, intersection } from 'lodash';
import NavMenuBase from '../NavMenuBase/NavMenuBase';
import { IMenuItem } from '../NavMenuBase/NavMenuBaseTypes';
import { UserRoles } from '../../constants/userRoles';
import { selectAuthenticatedUser } from '../../features/auth/AuthSlice';

const getSelectedItems = (
  path: string,
  menuItems: IMenuItem[],
): { category: string | null; item: string | null } => {
  let category = null;
  let item = null;

  const currentPathArray = compact(path.split('/'));

  for (const mItem of menuItems) {
    if (mItem.categoryItems) {
      const categoryItem = mItem.categoryItems.find(
        (it) =>
          intersection(compact(it.link.split('/')), currentPathArray).length,
      );

      if (categoryItem) {
        category = mItem.key;
        item = categoryItem.key;
        break;
      }
    }

    if (
      intersection(compact(mItem.link?.split('/')), currentPathArray).length ||
      mItem.link === path
    ) {
      category = mItem.key;
      break;
    }
  }

  return { category, item };
};

type NavMenuProps = {
  location: string;
  collapsed?: boolean;
  onSwitchMode: (collapsed: boolean) => void;
};

function NavMenu({ location, collapsed, onSwitchMode }: NavMenuProps) {
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  let menuItems = [
    {
      category: t('nav.category.home'),
      icon: <HomeOutlined />,
      key: 'home',
      link: '/',
      availableForRoles: [
        UserRoles.ADMIN,
        UserRoles.COMPANY_MANAGER,
        UserRoles.SALES_MANAGER,
        UserRoles.BROKER,
        UserRoles.REFERER,
        UserRoles.CONTENT_MANAGER,
      ],
    },
    {
      category: t('nav.category.products'),
      icon: <ShopOutlined />,
      key: 'products',
      link: '/',
      availableForRoles: [UserRoles.ADMIN],
      categoryItems: [
        {
          key: 'guarantees',
          name: t('nav.item.guarantees'),
          link: '/guarantees',
          availableForRoles: [UserRoles.ADMIN],
        },
      ],
    },
    {
      category: t('nav.category.users'),
      icon: <UserOutlined />,
      key: 'users',
      availableForRoles: [UserRoles.ADMIN],
      categoryItems: [
        {
          key: 'userList',
          name: t('nav.item.appUsers'),
          link: '/users',
          availableForRoles: [UserRoles.ADMIN],
        },
        {
          key: 'adminUsers',
          name: t('nav.item.adminUsers'),
          link: '/admin-users',
          availableForRoles: [UserRoles.ADMIN],
        },
      ],
    },
    {
      category: t('nav.category.insurance'),
      icon: <SafetyOutlined />,
      key: 'insurance',
      availableForRoles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER],
      categoryItems: [
        {
          key: 'membersList',
          name: t('nav.item.memberList'),
          link: '/members',
          availableForRoles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER],
        },
        {
          key: 'collective',
          name: t('nav.item.collective'),
          link: '/collective',
          availableForRoles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER],
        },
      ],
    },
    {
      category: t('nav.category.wellness'),
      icon: <HeartOutlined />,
      key: 'wellness',
      availableForRoles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER],
      categoryItems: [
        {
          key: 'analytics',
          name: t('nav.item.analytics'),
          link: '/wellness/analytics',
          availableForRoles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER],
        },
        {
          key: 'challenges',
          name: t('nav.item.challenges'),
          link: '/challenges',
          availableForRoles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER],
        },
        {
          key: 'quizzes',
          name: t('nav.item.quizzes'),
          link: '/quizzes',
          availableForRoles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER],
        },
        {
          key: 'rewards',
          name: t('nav.item.rewards'),
          link: '/rewards',
          availableForRoles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER],
        },
        {
          key: 'articles',
          name: t('nav.item.articles'),
          link: '/articles',
          availableForRoles: [UserRoles.ADMIN],
        },
        {
          key: 'insights',
          name: t('nav.item.insights'),
          link: '/insights',
          availableForRoles: [UserRoles.ADMIN],
        },
      ],
    },
  ];

  menuItems = menuItems
    .filter((menuItem) =>
      menuItem.availableForRoles.includes(authenticatedUser.role as UserRoles),
    )
    .map((menuItem) => ({
      ...(menuItem as any),
      categoryItems: menuItem?.categoryItems?.filter((categoryMenuItem) =>
        categoryMenuItem.availableForRoles.includes(
          authenticatedUser.role as UserRoles,
        ),
      ),
    }));

  const { category, item } = getSelectedItems(location, menuItems);

  return (
    <NavMenuBase
      items={menuItems}
      selectedCategory={category}
      selectedItem={collapsed ? null : item}
      onSwitchMode={() => onSwitchMode(!collapsed)}
      collapsed={!!collapsed}
    />
  );
}

NavMenu.defaultProps = {
  collapsed: false,
};

export default NavMenu;
