import { IPagination } from '../../general/GeneralTypes';

export type TCompany = {
  id: number;
  companyName: string;
  companyInfo: {
    id: number;
    companyDomain: string;
    nationalId: string;
    riskGroup: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    province: string;
    legalRepresentativeName: string;
    legalRepresentativeSurname: string;
    legalRepresentativeTypeOfDoc: string;
    legalRepresentativeDocNumber: string;
    category: string;
    activity: string;
  };
  logoId: string;
  companyCode: string;
  activatedAt?: string;
  deactivatedAt?: string;
  createdAt: string;
  updatedAt: string;
  admins: TMainAdmin[];
  totalEmployees: number;
  status: TStatus;
};

export type TMainAdmin = {
  id: number;
  createdAt: string;
  lastUpdatedAt?: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: {
    id: number;
    title: string;
  };
  status: {
    id: number;
    adminStatusTitle: string;
  };
};

export enum TCompanyStatus {
  PENDING = 'pending',
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

export type TStatus = {
  id: number;
  companyStatusTitle: TCompanyStatus;
};

export type TCompanyCreate = {
  company: {
    companyName: string;
    companyDomain: string;
    nationalId: string;
    riskGroup: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    province: string;
    category: string;
    activity: string;
    legalRepresentativeName: string;
    legalRepresentativeSurname: string;
    legalRepresentativeTypeOfDoc: string;
    legalRepresentativeDocNumber: string;
  };
};

export type TCompanyUpdate = {
  companyName: string;
  companyDomain: string;
  nationalId: string;
  riskGroup: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  province: string;
  category: string;
  activity: string;
  legalRepresentativeName: string;
  legalRepresentativeSurname: string;
  legalRepresentativeTypeOfDoc: string;
  legalRepresentativeDocNumber: string;
};

export type TCompaniesResponse = {
  data: TCompany;
};

export type TCompaniesListResponse = {
  data: TCompany[];
  pagination: IPagination;
  status?: string[];
};

export type TUser = {
  id: number;
  email: string;
  firstName: string;
  lastName: string | null;
  language: string | null;
  activatedAt: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  bioProfile: TBioProfile;
};

export type TUserCompany = TUser & {
  companyProfile: null;
};

export type TBioProfile = {
  id: number;
  gender: string | null;
  workingStatus: string | null;
  dateOfBirth: string | null;
  height: number | null;
  weight: number | null;
  smoking: string | null;
  exercise: string | null;
  dailySitDown: string | null;
  diet: string | null;
  sleep: string | null;
  alcohol: string | null;
  stress: string | null;
  userId: number | null;
  avatarId: string | null;
  createdAt: string;
  updatedAt: string;
  birth: string | null;
};

export type TCategory = {
  code: string;
  title: string;
  activities?: TActivity[];
};

export type TCategories = {
  data: TCategory[];
};

export type TActivity = {
  code: string;
  title: string;
};

export type TActivities = {
  data: TActivity[];
};

export type TMainContactCreationResponse = {
  data: {
    id: number;
    companyName: string;
    companyDomain: string;
    nationalId: string;
    category: string;
    activity: string;
    riskGroup: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    province: string;
    legalRepresentativeName: string;
    legalRepresentativeSurname: string;
    legalRepresentativeTypeOfDoc: string;
    legalRepresentativeDocNumber: string;
    logoId: number;
    activatedAt: string;
    deactivatedAt: string;
    createdAt: string;
    updatedAt: string;
    admins: [
      {
        id: number;
        createdAt: string;
        lastUpdatedAt: string;
        email: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        role: {
          id: number;
          title: string;
        };
        status: {
          id: number;
          adminStatusTitle: string;
        };
      },
    ];
    totalEmployees: number;
  };
};
export enum ECompanyStatuses {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DRAFT = 'draft',
}
