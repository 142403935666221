import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../helpers/apiRequest';
import {
  TActivityTagCreateRequest,
  TActivityTagListResponse,
  TActivityTagResponse,
} from './ActivityTagsTypes';

export const fetchActivityTags = async (
  listParams?: TListParams,
): Promise<TActivityTagListResponse> =>
  apiRequestGet<TActivityTagListResponse>('/activity-tags', listParams);

export const createActivityTag = async (
  data: TActivityTagCreateRequest,
): Promise<TActivityTagResponse> =>
  apiRequest<TActivityTagResponse>('/activity-tags', 'post', data);
