import React from 'react';
import { Alert } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './ActionAlert.module.scss';

const defaultToastConfig = {
  autoClose: 2500,
  position: toast.POSITION.BOTTOM_RIGHT,
  closeButton: false,
  hideProgressBar: true,
  transition: Slide,
  className: styles.toast,
};

function ActionAlert() {
  return (
    <ToastContainer
      style={{ padding: 0, boxShadow: 'none' }}
      autoClose={5000}
    />
  );
}

ActionAlert.success = (message: string) =>
  toast(
    <Alert
      className={styles.alertSuccess}
      message={message}
      type="success"
      showIcon
      banner
    />,
    defaultToastConfig,
  );

ActionAlert.info = (message: string) =>
  toast(
    <Alert
      className={styles.alertInfo}
      message={message}
      type="success"
      showIcon
      icon={<DeleteFilled className={styles.icon} />}
      banner
    />,
    defaultToastConfig,
  );

export default ActionAlert;
