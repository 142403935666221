import React from 'react';
import { Badge } from 'antd';
import { TFunction } from 'i18next';
import { EInsightStatuses, TInsight, TInsightFilled } from './InsightsTypes';
import { UserRoles } from '../../constants/userRoles';

export const getInsightStatusRendered = (
  article: TInsight | TInsightFilled,
  t: TFunction<'translation', undefined>,
): JSX.Element => {
  switch (article.status) {
    case EInsightStatuses.DRAFT: {
      return <Badge color="yellow" text={t('generic.draft')} />;
    }
    case EInsightStatuses.INACTIVE: {
      return <Badge status="default" text={t('generic.inactive')} />;
    }
    case EInsightStatuses.ACTIVE: {
      return <Badge color="green" text={t('generic.active')} />;
    }
    default: {
      return <Badge color="yellow" text={t('generic.draft')} />;
    }
  }
};

const consistsAllIds = (companyIds: number[], insightIds: number[]) =>
  !insightIds.find((insightId) => !companyIds.includes(insightId));

export const isActionAvailable = (
  role: UserRoles | null,
  userCompaniesIds: number[] = [],
  insightCompaniesIds: number[] = [],
): boolean =>
  role === UserRoles.ADMIN ||
  !insightCompaniesIds.length ||
  consistsAllIds(userCompaniesIds, insightCompaniesIds);
