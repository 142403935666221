import React from 'react';
import styles from './FormItemColumns.module.scss';

type TFormItemTwoColumnProps = {
  children: JSX.Element;
};

function FormItemColumn({ children }: TFormItemTwoColumnProps) {
  return <div className={styles.column}>{children}</div>;
}

type TFormItemTwoColumnsProps = {
  children: JSX.Element | JSX.Element[];
};

function FormItemColumns({ children }: TFormItemTwoColumnsProps) {
  return (
    <div className={styles.columns}>
      {Array.isArray(children)
        ? children.map((col, index) => (
            <>
              {col}
              {index < children.length - 1 && (
                <div className={styles.divider} />
              )}
            </>
          ))
        : children}
    </div>
  );
}

FormItemColumns.Column = FormItemColumn;

export default FormItemColumns;
