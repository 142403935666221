import { Form, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../../constants/userRoles';
import { selectors as categoriesSelectors } from '../../../../features/collective/companies/CompaniesCategoriesSliece';
import { TCategory } from '../../../../features/collective/companies/CompanyType';
import nullOrAlt from '../../../../helpers/nullOrAlt';
import RoleWrapper from '../../../RoleWrapper/RoleWrapper';
import styles from './CompanyCategorySelector.module.scss';

type TCompanyCategorySelectorProps = {
  onChange: (value: string) => void;
  category: string | null;
  alternative?: string;
};

function CompanyCategorySelector({
  onChange,
  category,
  alternative,
}: TCompanyCategorySelectorProps) {
  const { t } = useTranslation();
  const categoriesData = useSelector(categoriesSelectors.listData);
  const [categoriesList, setCategoriesList] = useState<{ value: string }[]>([]);

  useEffect(() => {
    const initialCategories =
      categoriesData && categoriesData.list
        ? categoriesData.list.map((cat) => ({
            value: `[${cat.code}] ${cat.title}`,
          }))
        : [];
    setCategoriesList(initialCategories);
  }, [categoriesData]);

  const searchCategoryResult = (query: string) => {
    const newlist = categoriesData?.list
      ? categoriesData?.list.filter(
          (item) =>
            item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1,
        )
      : [];
    setCategoriesList(
      newlist.map((cat: TCategory) => ({
        value: `[${cat.code}] ${cat.title}`,
      })),
    );
  };

  return (
    <Form.Item
      label={t('collective.company.form.companyInformation.category')}
      className={`${styles.itemRightContainer} ${styles.companyCategory}`}
    >
      <RoleWrapper
        roles={[UserRoles.ADMIN]}
        alternative={
          <div className={styles.companyDetailsValue}>{alternative}</div>
        }
      >
        <Select
          showSearch
          placeholder={t('generic.startTyping')}
          options={categoriesList}
          value={nullOrAlt(category, '')}
          onSearch={searchCategoryResult}
          onChange={(val: string) => onChange(val)}
          allowClear
          onClear={() => {
            onChange('');
            searchCategoryResult('');
          }}
        />
      </RoleWrapper>
    </Form.Item>
  );
}

CompanyCategorySelector.defaultProps = {
  alternative: '',
};

export default CompanyCategorySelector;
