import { TTableData } from './ChallengeListTypes';

export const challengeTypeMap = (key: string): string | undefined => {
  const types = [
    { key: 'INDIVIDUAL', value: 'Individual' },
    { key: 'GLOBAL', value: 'Global' },
    { key: 'TEAM', value: 'Team' },
  ];
  return types.find((t) => t.key === key)?.value;
};

export const challengeGoalMap = (
  key: string,
  t: (key: string, params?: { [key: string]: any }) => string,
): string | undefined => {
  const types = [
    { key: 'POINTS', value: t('challenges.points') },
    { key: 'DISTANCE', value: t('generic.distance') },
    { key: 'CALORIES', value: t('generic.caloriesFull') },
    { key: 'DURATION', value: t('generic.duration') },
    { key: 'STEPS', value: t('generic.steps') },
  ];
  return types.find((t) => t.key === key)?.value || '--';
};

export const challengeEntityMap = (key: string | undefined): string => {
  const types = [
    { key: 'challenge', value: 'challenges.biometrics' },
    { key: 'quiz', value: 'challenges.quiz' },
  ];
  return types.find((t) => t.key === key)?.value || '--';
};

export const columnFilterFactory = (
  fieldName: keyof TTableData,
  tableData: TTableData[],
): {
  filters: { text: string; value: string }[];
  onFilter: (value: string | number | boolean, record: TTableData) => boolean;
} => {
  const filters = tableData.reduce(
    (
      acc: { text: string; value: string }[],
      val: TTableData,
    ): { text: string; value: string }[] => {
      if (!acc.find((item) => item.value === (val[fieldName] as string))) {
        acc.push({
          text: val[fieldName] as string,
          value: val[fieldName] as string,
        });
      }

      return acc;
    },
    [],
  );
  const onFilter = (value: string | number | boolean, record: TTableData) =>
    record[fieldName] === value;

  return { filters, onFilter };
};
