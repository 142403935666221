import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../helpers/apiRequest';
import { TCompaniesListResponse } from '../collective/companies/CompanyType';
import {
  TAdminUser,
  TAdminUserForm,
  TAdminUserResponse,
  TAdminUsersListResponse,
} from './AdminUsersTypes';

export const fetchAdminUsers = async (
  listParams?: TListParams,
): Promise<TAdminUsersListResponse> =>
  apiRequestGet<TAdminUsersListResponse>('/admin-users', {...listParams, version: 'v2'});

export const deleteAdminUser = async (
  id: TAdminUser['id'],
): Promise<TAdminUserResponse> =>
  apiRequest<TAdminUserResponse>(`/admin-users/${id}`, 'delete', null, { version: 'v2' });

export const getAdminUser = async (
  id: TAdminUser['id'],
): Promise<TAdminUserResponse> =>
  apiRequestGet<TAdminUserResponse>(`/admin-users/${id}`, { version: 'v2' });

export const sendInvitation = async (
  id: TAdminUser['id'],
): Promise<TAdminUserResponse> =>
  apiRequest<TAdminUserResponse>(
    `/auth/admins/${id}/invite`,
    'post',
    null,
    { version: 'v2' },
  );

export const updateAdminUser = async (
  id: TAdminUser['id'],
  userData: TAdminUserForm,
): Promise<TAdminUserResponse> =>
  apiRequest<TAdminUserResponse>(`/admin-users/${id}`, 'patch', userData, { version: 'v2' });

export const getAdminUserCompanies =
  async (): Promise<TCompaniesListResponse> =>
    apiRequestGet<TCompaniesListResponse>(`/admin-users/me/companies`, { version: 'v2' });
