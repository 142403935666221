import { Languages } from '../constants/languages';

export const Config = {
  PRIVACY_POLICY: 'https://www.vitaance.com/politica-de-privacidad',
  LEGAL: 'https://www.vitaance.com/aviso-legal',
  APP_STORE: 'https://apps.apple.com/es/app/vitaance/id1589434652',
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=com.vitaance.mobile',
  CONTACT_FORM: 'https://51mfpv9wviz.typeform.com/to/gUarCqAM',
  WEB_SITE: 'https://www.vitaance.com',
  BACKEND_URL: process.env.REACT_APP_ADMIN_SERVER_URL || '',
  BACKEND_MOB_URL: process.env.REACT_APP_MOB_SERVER_URL || '',
  I18N: {
    LOCALES: [Languages.EN, Languages.ES],
    DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANG || Languages.EN,
  },
  ENV: process.env.REACT_APP_ENV || 'production',
  JWT_TOKEN_LIFETIME: parseFloat(
    process.env.REACT_APP_JWT_TOKEN_LIFETIME || '',
  ),
};
