import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';
import { EChallengeStatuses } from '../../../../features/challenges/ChallengeList/ChallengeListTypes';

type TPublishArticleModalProps = {
  children: JSX.Element;
  onClick: () => void;
  status: EChallengeStatuses;
  type: string,
};

function UpdateOngoingChallengeModal({
  children,
  onClick,
  status,
  type
}: TPublishArticleModalProps) {
  const { t } = useTranslation();

  const config = {
    title: type === 'quiz' ? t('quizzes.modal.updateOngoing.title') : t('challenges.modal.updateOngoing.title'),
    okText: t('generic.buttons.update'),
    cancelText: t('generic.modals.buttons.cancel'),
    content:
      status === EChallengeStatuses.UPCOMING
        ? (type === 'quiz'
            ? t('quizzes.modal.updateUpcoming.content')
            : t('challenges.modal.updateUpcoming.content'))
        : (type === 'quiz'
            ? t('quizzes.modal.updateOngoing.content')
            : t('challenges.modal.updateOngoing.content')),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM}
    />
  );
}

export default UpdateOngoingChallengeModal;
