import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Input, InputNumber, Select } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import styles from './EditQuiz.module.scss';
import ConfirmClosureModal from '../../../components/Modals/ConfirmClosureModal/ConfirmClosureModal';
import { actions, asyncActions, selectors } from '../QuizzesSlice';
import FormCard from '../../../components/FormCard/FormCard';
import ImageUploadForm from '../../../components/ImageUploadForm/ImageUploadForm';
import {
  asyncActions as categoriesAsyncActions,
  selectors as categoriesSelectors,
} from '../../categories/CategoriesSlice';
import {
  actions as typeFormActions,
  asyncActions as typeFormsAsyncActions,
  selectors as typeFormsSelectors,
} from '../TypeFormsSlice';
import { selectAuthenticatedUser } from '../../auth/AuthSlice';
import RequestStatuses from '../../../constants/requestStatuses';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import CompanySelector from '../../../components/Forms/CompanySelector/CompanySelector';
import { enableForRoles } from '../../../helpers/role';
import { UserRoles } from '../../../constants/userRoles';
import { EChallengeStatuses } from '../../challenges/ChallengeList/ChallengeListTypes';
import ChallengeStatusBadge from '../../../components/Badge/ChallengeStatusBadge/ChallengeStatusBadge';
import { asyncActions as companiesAsyncActions } from '../../collective/companies/AllCompaniesSlice';
import {
  asyncActions as adminUserAdminAsyncActions,
  selectors as adminUserSelectors,
} from '../../adminUsers/AdminUsersAdminSlice';
import FormItemColumns from '../../../components/Forms/FormItemTwoColumn/FormItemTwoColumn';
import { isActionAvailable } from '../../challenges/ChallengesHelpers';
import { TCompany } from '../../collective/companies/CompanyType';
import HeaderButtons from '../../challenges/EditChallenge/components/HeaderButtons/HeaderButtons';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import nullOrAlt from '../../../helpers/nullOrAlt';

const initialState = {
  title: null as string | null,
  description: null as string | null,
  type: null as string | null,
  rewardInPoints: null as number | null,
  image: null as File | null,
  imageBase64: null as string | null,
  categoryId: null as number | null,
  activityTagId: null as number | null,
  typeFormId: null as number | null,
  companiesIds: null as number[] | null,
  isCompanyOnly: null as boolean | null,
};

function EditQuiz() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: quizId } = useParams();

  const isExistingQuiz = useMemo(() => quizId !== 'new', [quizId]);

  const [state, setState] = useState(initialState);
  const [somethingChanged, setChangedFlag] = useState(false);
  const [activityTags, setActivityTags] = useState([] as any);
  const {
    title,
    description,
    type,
    rewardInPoints,
    categoryId,
    activityTagId,
    image,
    imageBase64,
    typeFormId,
    companiesIds,
    isCompanyOnly,
  } = state;

  const currentQuiz = useSelector(selectors.currentItem);
  const availableCategories = useSelector(categoriesSelectors.listData);
  const availableTypeForms = useSelector(typeFormsSelectors.listData);
  const statuses = useSelector(selectors.statuses);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const adminCompanies = useSelector(adminUserSelectors.listData);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onChange = (field: keyof typeof initialState, value: any) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
    setChangedFlag(true);
  };

  const searchForm = (query: string) => {
    if (!query || query.length > 2) {
      dispatch(typeFormActions.setPagination({ page: 1, perPage: 50 }));
      dispatch(typeFormActions.setSearch({ query }));
      dispatch<any>(typeFormsAsyncActions.fetchList());
    }
  };

  useEffect(() => {
    dispatch(actions.clearCurrentItem());

    dispatch<any>(categoriesAsyncActions.fetchList());

    dispatch(typeFormActions.setPagination({ page: 1, perPage: 50 }));
    dispatch<any>(typeFormsAsyncActions.fetchList());

    if (enableForRoles(authenticatedUser.role, [UserRoles.COMPANY_MANAGER])) {
      dispatch<any>(adminUserAdminAsyncActions.fetchList());
    } else {
      dispatch<any>(companiesAsyncActions.fetchList());
    }

    return () => {
      dispatch(typeFormActions.setSearch({ query: '' }));
      dispatch(actions.clearStatuses());
      dispatch(actions.clearCurrentItem());
    };
  }, [dispatch]);

  useEffect(() => {
    if (quizId && isExistingQuiz) {
      dispatch<any>(asyncActions.get({ id: +quizId }));
    } else {
      dispatch(actions.clearCurrentItem());
    }
  }, [dispatch, quizId]);

  useEffect(() => {
    const category = availableCategories?.list?.find((cat) =>
      [state.categoryId].includes(cat.id),
    );

    if (category?.activityTagToCategory?.length) {
      setActivityTags(category?.activityTagToCategory);
    }
  }, [availableCategories?.list, state.categoryId]);

  useEffect(() => {
    const category = availableCategories?.list?.find((cat) =>
      [currentQuiz?.category?.id].includes(cat.id),
    );

    if (category?.activityTagToCategory?.length) {
      setActivityTags(category?.activityTagToCategory);
    }
  }, [availableCategories?.list, currentQuiz?.category?.id]);

  useEffect(() => {
    if (statuses.unpublish === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        t('quizzes.tooltips.quizzDeactivated', { title: currentQuiz?.title }),
      );
      dispatch(actions.clearStatuses());
    }

    if (
      statuses.publish === RequestStatuses.SUCCESS ||
      statuses.update === RequestStatuses.SUCCESS
    ) {
      onBack('published');
    }

    if (statuses.create === RequestStatuses.SUCCESS && currentQuiz?.id) {
      navigate(`/quizzes/${currentQuiz.id}`);

      dispatch(actions.clearStatuses());
    }

    setChangedFlag(false);
  }, [statuses]);

  useEffect(() => {
    if (currentQuiz?.id) {
      onChange('companiesIds', currentQuiz?.companies?.map((c) => c.id) || []);
      setChangedFlag(false);
    }
  }, [currentQuiz]);

  const validateFields = () => {
    let allFilled = true;
    [
      currentQuiz?.title || title,
      currentQuiz?.rewardInPoints || rewardInPoints,
      currentQuiz?.titleImage || image || imageBase64,
      currentQuiz?.category?.id || categoryId,
      currentQuiz?.activityTag?.id || activityTagId,
      currentQuiz?.typeFormId || typeFormId,
    ].forEach((f) => {
      allFilled = allFilled && !!f;
    });

    return allFilled;
  };

  const onUnPublish = () => {
    if (currentQuiz) {
      dispatch<any>(asyncActions.unpublish({ item: currentQuiz }));
    }
  };

  const onSave = (publish?: boolean) => {
    setChangedFlag(false);

    const formData = new FormData();
    if (image) {
      formData.append('titleImage', image as Blob);
    }

    const quizData = {
      title,
      description,
      type: nullOrAlt(type, currentQuiz ? null : 'INDIVIDUAL'),
      rewardInPoints,
      categoryId,
      typeFormId,
      activityTagId,
      companiesIds,
      isCompanyOnly,
    };

    Object.keys(quizData).forEach((k: string) => {
      const key = k as keyof typeof quizData;

      switch (key) {
        case 'companiesIds':
          if (!isCompanyOnly) {
            companiesIds?.forEach((c) => {
              formData.append('companiesIds[]', c.toString());
            });
          }
          break;

        default:
          if (quizData[key] !== null) {
            formData.append(k, quizData[key] as string);
          }
      }
    });

    if (currentQuiz?.id) {
      const { id } = currentQuiz;

      if (
        publish &&
        [EChallengeStatuses.DRAFT, EChallengeStatuses.INACTIVE].includes(
          currentQuiz.status.title,
        )
      ) {
        dispatch<any>(asyncActions.updateAndPublish({ id, data: formData }));
      } else if (
        !publish &&
        ![EChallengeStatuses.DRAFT, EChallengeStatuses.INACTIVE].includes(
          currentQuiz.status.title,
        )
      ) {
        dispatch<any>(asyncActions.updateAndDraft({ id, data: formData }));
      } else {
        dispatch<any>(asyncActions.update({ id, data: formData }));
      }
    } else {
      dispatch<any>(
        publish
          ? asyncActions.createAndPublish({ data: formData })
          : asyncActions.create({ data: formData }),
      );
    }
  };

  const formDisabled = !isActionAvailable(
    authenticatedUser?.role,
    adminCompanies?.list?.map((company: TCompany) => company.id),
    currentQuiz?.companies?.map((company) => company.id),
  );

  const onBack = (locationState: string = '') =>{
    navigate('/quizzes',
      {
        state: {
          search: location.state as string,
          state: locationState,
      }
    }); }
  
  const onCancelPress = () => {
    setShowConfirmModal(true)
  }

  return (
    <>
      <ActionAlert />
      <RouteLeavingGuard
        lastLocation='/quizzes'
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
      <ProPageHeader
        prefixedClassName={styles.pageHeader}
        title={t('quizzes.form.title')}
        extra={
          formDisabled
            ? []
            : [
                <HeaderButtons
                  challengeType="quiz"
                  isDirty={false}
                  isValid={validateFields()}
                  onBack={() =>{ onCancelPress()
                  }}
                  onSave={onSave}
                  onPublish={() => onSave(true)}
                  onUnpublish={onUnPublish}
                  onDelete={() => {
                    if (currentQuiz) {
                      dispatch<any>(asyncActions.delete({ item: currentQuiz }));
                      onBack()
                    }
                  }}
                  onDraft={() => {
                    if (currentQuiz) {
                      dispatch<any>(asyncActions.draft({ item: currentQuiz }));
                      setChangedFlag(false)
                    }
                  }}
                  currentChallenge={currentQuiz}
                />,
              ]
        }
        onBack={() => {}}
        backIcon={
          <ConfirmClosureModal changed={somethingChanged && !formDisabled} />
        }
      />
      <div className={styles.formWrap}>
        <FormCard className={styles.card}>
          <>
            <div className={styles.title}>{t('quizzes.addBasicInfo')}</div>
            <div className={styles.basicInfoContainer}>
              <div className={styles.basicInfo}>
                <Form className={styles.form} layout="vertical">
                  <Form.Item
                    label={t('quizzes.form.quizName')}
                    tooltip={{
                      title: t('quizzes.form.quizName'),
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Input
                      onChange={(e) => onChange('title', e.target.value)}
                      placeholder={t('quizzes.placeholder.quizName') || ''}
                      value={nullOrAlt(title, currentQuiz?.title || '')}
                      disabled={formDisabled}
                      className={styles.inputWithLengthCounter}
                      maxLength={50}
                      showCount
                    />
                  </Form.Item>
                  <Form.Item label={t('quizzes.form.description')}>
                    <Input.TextArea
                      onChange={(e) => onChange('description', e.target.value)}
                      autoSize={{ minRows: 2, maxRows: 10 }}
                      showCount
                      maxLength={400}
                      value={nullOrAlt(
                        description,
                        currentQuiz?.description || '',
                      )}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                  <div className={styles.typePointsContainer}>
                    <Form.Item
                      className={styles.typeContainer}
                      label={t('quizzes.form.quizType')}
                    >
                      <Select
                        onSelect={(val: string) => onChange('type', val)}
                        showSearch
                        defaultValue="INDIVIDUAL"
                        options={[
                          {
                            label: t('quizzes.form.individual'),
                            value: 'INDIVIDUAL',
                          },
                        ]}
                        value={nullOrAlt(type, currentQuiz?.type)}
                        disabled={formDisabled}
                      />
                    </Form.Item>
                    <Form.Item
                      className={styles.pointsContainer}
                      label={t('quizzes.form.points')}
                      tooltip={{
                        title: t('quizzes.tooltips.totalPoints'),
                        icon: <InfoCircleOutlined />,
                      }}
                      extra={t('quizzes.tooltips.maxPoints')}
                    >
                      <InputNumber
                        onChange={(val: number | null) =>
                          onChange('rewardInPoints', val)
                        }
                        className={styles.pointsInput}
                        placeholder="10"
                        min={0}
                        max={100}
                        value={nullOrAlt(
                          rewardInPoints,
                          currentQuiz?.rewardInPoints || 0,
                        )}
                        disabled={
                          formDisabled ||
                          authenticatedUser.role === UserRoles.COMPANY_MANAGER
                        }
                      />
                    </Form.Item>
                  </div>
                  <Form.Item label={t('quizzes.form.typeFormName')}>
                    <Select
                      filterOption={false}
                      onSearch={debounce(
                        (value: string) => searchForm(value),
                        300,
                      )}
                      onSelect={(val: string) => {
                        onChange('typeFormId', val);
                      }}
                      onClear={() => {
                        onChange('typeFormId', '');

                        dispatch(typeFormActions.setSearch({ query: '' }));
                        dispatch<any>(typeFormsAsyncActions.fetchList());
                      }}
                      showSearch
                      allowClear
                      options={availableTypeForms.list?.map((form) => ({
                        label: form.title,
                        value: form.id,
                      }))}
                      value={nullOrAlt(typeFormId, currentQuiz?.typeFormId)}
                      disabled={
                        formDisabled ||
                        authenticatedUser.role === UserRoles.COMPANY_MANAGER
                      }
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className={styles.imageForm}>
                <ImageUploadForm
                  onFileChanged={(img, base64) => {
                    onChange('image', img);
                    onChange('imageBase64', base64);
                  }}
                  tooltip={t('quizzes.tooltips.imageResolution')}
                  currentImage={nullOrAlt(imageBase64, currentQuiz?.titleImage)}
                  disabled={formDisabled}
                />
              </div>
            </div>
            <div className={styles.title}>
              {t('quizzes.form.quizzAssignment')}
            </div>
            <CompanySelector
              onSelectCompanies={(companyList, companiesOnly) => {
                onChange('companiesIds', companyList);
                onChange('isCompanyOnly', companiesOnly);
              }}
              selectedCompanyIds={nullOrAlt(
                companiesIds,
                currentQuiz?.companies?.map((c) => c.id) || [],
              )}
              onlySelected={enableForRoles(authenticatedUser.role, [
                UserRoles.COMPANY_MANAGER,
              ])}
              disabled={formDisabled}
            />
            <div className={styles.title}>
              {t('quizzes.configureParameters')}
            </div>
            <div className={styles.parametersContainer}>
              <Form layout="vertical">
                <FormItemColumns>
                  <FormItemColumns.Column>
                    <Form.Item label={t('quizzes.form.category')}>
                      <Select
                        onSelect={(val: string) => {
                          onChange('categoryId', val);
                          onChange('activityTagId', undefined);
                        }}
                        showSearch
                        options={availableCategories.list?.map((category) => ({
                          label: category.name,
                          value: category.id,
                        }))}
                        value={nullOrAlt(
                          categoryId,
                          currentQuiz?.category?.id || '',
                        )}
                        disabled={formDisabled}
                      />
                    </Form.Item>
                  </FormItemColumns.Column>
                  <FormItemColumns.Column>
                    <Form.Item label={t('quizzes.form.activityType')}>
                      <Select
                        onSelect={(val: string) =>
                          onChange('activityTagId', val)
                        }
                        showSearch
                        options={activityTags.map((activityTag: any) => ({
                          label: activityTag.activityTag.name,
                          value: activityTag.activityTag.id,
                        }))}
                        value={nullOrAlt(
                          activityTagId,
                          currentQuiz?.activityTag?.id || '',
                        )}
                        disabled={formDisabled}
                      />
                    </Form.Item>
                  </FormItemColumns.Column>
                </FormItemColumns>
              </Form>
            </div>
          </>
        </FormCard>
        <div className={styles.dataColumn}>
          <div>{t('quizzes.form.quizzInformation')}</div>
          <div className={styles.dataBlock}>
            <div>{t('articles.currentStatus.title')}</div>
            <ChallengeStatusBadge
              status={currentQuiz?.status.title || EChallengeStatuses.DRAFT}
            />
          </div>
          <div className={styles.dataBlock}>
            <div>{t('articles.id.title')}</div>
            <div className={styles.dataBlockValue}>
              {currentQuiz?.id || '-'}
            </div>
          </div>
          <div className={styles.dataBlock}>
            <div>{t('articles.created.title')}</div>
            <div className={styles.dataBlockValue}>
              {currentQuiz?.createdAt
                ? dayjs(currentQuiz?.createdAt).format('DD/MM/YYYY HH:mm')
                : '-'}
            </div>
          </div>
          <div className={styles.dataBlock}>
            <div>{t('articles.updated.title')}</div>
            <div className={styles.dataBlockValue}>
              {currentQuiz?.updatedAt
                ? dayjs(currentQuiz?.updatedAt).format('DD/MM/YYYY HH:mm')
                : '-'}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditQuiz;
