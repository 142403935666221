import {
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import RequestStatuses from '../../../constants/requestStatuses';
import { TErrorPayload, TListParams } from '../../../helpers/apiRequest';
import {
  extraReducerBuilderV2,
  TReducerEvents,
} from '../../../helpers/extraReducerBuilder';
import { IPagination } from '../GeneralTypes';

export type TDefaultCreateJsonRequest<TResponse> = (data: {
  [key: string]: any;
}) => Promise<TResponse>;
export type TDefaultUpdateJsonRequest<TResponse> = (
  id: TRecordGeneric['id'],
  data: { [key: string]: any },
) => Promise<TResponse>;

type TRecordGeneric = {
  id: number;
  data?: TRecordGeneric;
};

type TSingleArticleResponseGeneric = {
  data?: TRecordGeneric;
  body?: TRecordGeneric;
  id?: number;
};

type TListResponseGeneric = {
  pagination: IPagination;
  data?: TRecordGeneric[];
  filterData?: { [key: string]: any };
  body?: TRecordGeneric[];
};

const buildSlice = <
  TRecord extends TRecordGeneric,
  TSingeResponse extends TSingleArticleResponseGeneric,
  TListResponse extends TListResponseGeneric,
>(
  name: string,
  api: {
    create?: (data: FormData) => Promise<TSingeResponse>;
    createJson?: (data: { [key: string]: any }) => Promise<TSingeResponse>;
    publish?: (id: TRecord['id']) => Promise<TSingeResponse>;
    unpublish?: (id: TRecord['id']) => Promise<TSingeResponse>;
    saveAsDraft?: (id: TRecord['id']) => Promise<TSingeResponse>;
    fetchList?: (
      params?: TListParams,
      id?: TRecord['id'],
    ) => Promise<TListResponse>;
    delete?: (id: TRecord['id']) => Promise<TSingeResponse>;
    deleteJson?: (data: { [key: string]: any }) => Promise<TSingeResponse>;
    getOne?: (id: TRecord['id']) => Promise<TSingeResponse>;
    update?: (id: TRecord['id'], data: FormData) => Promise<TSingeResponse>;
    updateJson?: (
      id: TRecord['id'],
      data: { [key: string]: any },
    ) => Promise<TSingeResponse>;
    singleRequests?: {
      request: (
        id: TRecord['id'],
        data?: { [key: string]: any },
      ) => Promise<TSingeResponse>;
      name: string;
    }[];
    validate?: (data: { [key: string]: any }) => Promise<void | TErrorPayload>;
  },
) => {
  const asyncActionTypeBuilder = (actions: { [key: string]: string }) =>
    Object.keys(actions).reduce((acc, key) => {
      acc[key] = `${name}/${actions[key]}`;

      return acc;
    }, {} as { [key: string]: string });

  const asyncActions = asyncActionTypeBuilder({
    CREATE: 'create',
    CREATE_BULK: 'createBulk',
    PUBLISH: 'publish',
    PUBLISH_BULK: 'publishBulk',
    UNPUBLISH: 'unpublish',
    UNPUBLISH_BULK: 'unpublishBulk',
    FETCH_LIST: 'fetchList',
    DELETE: 'delete',
    DELETE_BULK: 'deleteBulk',
    GET_ONE: 'getOne',
    UPDATE: 'update',
    UPDATE_BULK: 'updateBulk',
    CREATE_AND_PUBLISH: 'createAndPublish',
    UPDATE_AND_UNPUBLISH: 'updateAndUnpublish',
    UPDATE_AND_PUBLISH: 'updateAndPublish',
    SINGLE_REQUEST: 'singleRequest',
    VALIDATE: 'validate',
    UPDATE_AND_DRAFT: 'updateAndDraft',
    DRAFT: 'draft',
  });

  type TState = {
    validationErrors: string[];
    // statuses
    statuses: {
      fetch?: RequestStatuses | null;
      create?: RequestStatuses | null;
      createBulk?: RequestStatuses | null;
      publish?: RequestStatuses | null;
      publishBulk?: RequestStatuses | null;
      unpublish?: RequestStatuses | null;
      unpublishBulk?: RequestStatuses | null;
      delete?: RequestStatuses | null;
      deleteBulk?: RequestStatuses | null;
      get?: RequestStatuses | null;
      update?: RequestStatuses | null;
      updateBulk?: RequestStatuses | null;
      lastSingle?: RequestStatuses | null;
      validate?: RequestStatuses | null;
      draft?: RequestStatuses | null;
    };
    // data
    items: {
      list: TRecord[] | null;
      listParams?: TListParams;
      outdated?: boolean;
    };
    currentItem: TRecord | null;
    bulkActionItems: {
      delete: any[];
      publish: any[];
      unpublish: any[];
      create: any[];
      update: any[];
      deleteFailed: any[];
      publishFailed: any[];
      unpublishFailed: any[];
      createFailed: any[];
      updateFailed: any[];
    };
    actionItems: {
      delete: any | null;
      publish: any | null;
      unpublish: any | null;
      create: any | null;
      update: any | null;
      draft: any | null;
    };
    filterData?: { [key: string]: any };
  };

  const initialState: TState = {
    validationErrors: [],
    statuses: {},
    items: {
      list: null,
      outdated: false,
    },
    currentItem: null,
    bulkActionItems: {
      delete: [],
      publish: [],
      unpublish: [],
      create: [],
      update: [],
      deleteFailed: [],
      publishFailed: [],
      unpublishFailed: [],
      createFailed: [],
      updateFailed: [],
    },
    actionItems: {
      delete: null,
      publish: null,
      unpublish: null,
      create: null,
      update: null,
      draft: null,
    },
  };

  const createNewItemAsync = createAsyncThunk(
    asyncActions.CREATE,
    async (
      {
        data,
        dataJson,
      }: { data?: FormData; dataJson?: { [key: string]: any } },
      { dispatch },
    ) => {
      dispatch(
        setActionItems({ singleAction: 'create', item: dataJson as TRecord }),
      );

      if (data && api.create) {
        return api.create(data);
      }
      if (dataJson && api.createJson) {
        return api.createJson(dataJson);
      }

      return null;
    },
  );

  const publishItemAsync = createAsyncThunk(
    asyncActions.PUBLISH,
    async ({ item }: { item: TRecord }, { dispatch }) => {
      dispatch(setActionItems({ singleAction: 'publish', item }));

      return api.publish?.(item.id);
    },
  );

  const unpublishItemAsync = createAsyncThunk(
    asyncActions.UNPUBLISH,
    async ({ item }: { item: TRecord }, { dispatch }) => {
      dispatch(setActionItems({ singleAction: 'unpublish', item }));

      return api.unpublish?.(item.id);
    },
  );

  const fetchItemsAsync = createAsyncThunk<
    TListResponse | undefined,
    { id?: TRecord['id'] } | undefined,
    { state: { [key: string]: TState } }
  >(asyncActions.FETCH_LIST, async (args, { getState }) => {
    const { id } = args || {};
    const {
      [name]: {
        items: { listParams },
      },
    } = getState();

    let params = listParams;
    if (!listParams?.order) {
      params = {
        ...listParams,
        order: [{ field: 'createdAt', orderDirection: 'desc' }],
      };
    }

    return api.fetchList?.(params, id);
  });

  const deleteItemAsync = createAsyncThunk(
    asyncActions.DELETE,
    async (
      { item, dataJson }: { item?: TRecord; dataJson?: any },
      { dispatch },
    ) => {
      dispatch(
        setActionItems({ singleAction: 'delete', item: item || dataJson }),
      );
      if (item && api.delete) {
        return api.delete?.(item.id);
      }
      if (dataJson && api.deleteJson) {
        return api.deleteJson(dataJson);
      }

      return null;
    },
  );

  const draftItemAsync = createAsyncThunk(
    asyncActions.DRAFT,
    async ({ item }: { item: TRecord }, { dispatch }) => {
      dispatch(setActionItems({ singleAction: 'draft', item }));

      return api.saveAsDraft?.(item.id);
    },
  );

  const publishItemBulkAsync = createAsyncThunk(
    asyncActions.PUBLISH_BULK,
    async ({ items }: { items: TRecord[] }, { dispatch }) => {
      dispatch(setBulkActionItems({ bulkAction: 'publish', items }));

      let lastResult = null;
      for await (const response of items.map(({ id }) => api.publish?.(id))) {
        lastResult = response;
      }

      return lastResult;
    },
  );

  const unpublishItemBulkAsync = createAsyncThunk(
    asyncActions.UNPUBLISH_BULK,
    async ({ items }: { items: any[] }, { dispatch }) => {
      dispatch(setBulkActionItems({ bulkAction: 'unpublish', items }));

      let lastResult = null;
      for await (const response of items.map(({ id }) => api.unpublish?.(id))) {
        lastResult = response;
      }

      return lastResult;
    },
  );

  const deleteItemBulkAsync = createAsyncThunk(
    asyncActions.DELETE_BULK,
    async ({ items }: { items: any[] }, { dispatch }) => {
      dispatch(setBulkActionItems({ bulkAction: 'delete', items }));

      const failed = [] as any[];
      let lastResult = null;

      for await (const response of items.map((item) => {
        if (api.delete) {
          return api.delete?.(item.id || item).catch(() => failed.push(item));
        }
        if (api.deleteJson) {
          return api
            .deleteJson?.(item.id || item)
            .catch(() => failed.push(item));
        }
        return failed.push(item);
      })) {
        lastResult = response;
      }

      dispatch(
        setBulkActionItems({ bulkAction: 'deleteFailed', items: failed }),
      );

      if (failed.length) throw new Error();

      return lastResult;
    },
  );

  const createItemBulkAsync = createAsyncThunk(
    asyncActions.CREATE_BULK,
    async ({ items }: { items: any[] }, { dispatch }) => {
      dispatch(setBulkActionItems({ bulkAction: 'create', items }));

      const failed = [] as any[];
      let lastResult = null;
      for await (const response of items.map((item) =>
        api.createJson?.(item).catch(() => failed.push(item)),
      )) {
        lastResult = response;
      }

      dispatch(
        setBulkActionItems({ bulkAction: 'createFailed', items: failed }),
      );

      if (failed.length) throw new Error();

      return lastResult;
    },
  );

  const updateItemBulkAsync = createAsyncThunk(
    asyncActions.UPDATE_BULK,
    async (
      { items }: { items: { id: TRecord['id']; data: any }[] },
      { dispatch },
    ) => {
      dispatch(
        setBulkActionItems({
          bulkAction: 'update',
          items: items.map((item) => item.data),
        }),
      );

      const failed = [] as { id: TRecord['id']; data: any }[];
      let lastResult = null;
      for await (const response of items.map((item) =>
        api.updateJson?.(item.id, item.data).catch(() => failed.push(item)),
      )) {
        lastResult = response;
      }

      dispatch(
        setBulkActionItems({
          bulkAction: 'updateFailed',
          items: failed.map((item) => item.data),
        }),
      );

      if (failed.length) throw new Error();

      return lastResult;
    },
  );

  const getItemAsync = createAsyncThunk(
    asyncActions.GET_ONE,
    async ({ id }: { id: TRecord['id'] }) => api.getOne?.(id),
  );

  const updateItemAsync = createAsyncThunk(
    asyncActions.UPDATE,
    async (
      {
        id,
        data,
        dataJson,
      }: {
        id: TRecord['id'];
        data?: FormData;
        dataJson?: { [key: string]: any };
      },
      { dispatch },
    ) => {
      dispatch(
        setActionItems({ singleAction: 'update', item: dataJson as TRecord }),
      );
      if (data && api.update) {
        return api.update?.(id, data);
      }
      if (dataJson && api.updateJson) {
        return api.updateJson?.(id, dataJson);
      }

      return null;
    },
  );

  const createAndPublish = createAsyncThunk(
    asyncActions.CREATE_AND_PUBLISH,
    async (
      {
        data,
        dataJson,
      }: { data?: FormData; dataJson?: { [key: string]: any } },
      { dispatch },
    ) => {
      let response;
      if (data && api.create) {
        response = await api.create(data);
      } else if (dataJson && api.createJson) {
        response = await api.createJson(dataJson);
      }

      if (response) {
        dispatch(
          setActionItems({
            singleAction: 'publish',
            item: response.data as TRecord,
          }),
        );

        const { id: rId = -1 } =
          response.data || response.body || response || {};
        return api.publish?.(rId);
      }

      return null;
    },
  );

  const updateAndUnpublish = createAsyncThunk(
    asyncActions.UPDATE_AND_UNPUBLISH,
    async (
      { id, data }: { id: TRecord['id']; data: FormData },
      { dispatch },
    ) => {
      const response = await api.update?.(id, data);

      if (response) {
        dispatch(
          setActionItems({
            singleAction: 'unpublish',
            item: response.data as TRecord,
          }),
        );
        const { id: rId = -1 } =
          response.data || response.body || response || {};
        return api.unpublish?.(rId);
      }

      return null;
    },
  );

  const updateAndDraft = createAsyncThunk(
    asyncActions.UPDATE_AND_DRAFT,
    async (
      { id, data }: { id: TRecord['id']; data: FormData },
      { dispatch },
    ) => {
      const response = await api.update?.(id, data);

      if (response) {
        dispatch(
          setActionItems({
            singleAction: 'draft',
            item: response.data as TRecord,
          }),
        );

        const { id: rId = -1 } =
          response.data || response.body || response || {};
        return api.saveAsDraft?.(rId);
      }

      return null;
    },
  );

  const updateAndPublish = createAsyncThunk(
    asyncActions.UPDATE_AND_PUBLISH,
    async (
      { id, data }: { id: TRecord['id']; data: FormData },
      { dispatch },
    ) => {
      const response = await api.update?.(id, data);

      if (response) {
        dispatch(
          setActionItems({
            singleAction: 'publish',
            item: response.data as TRecord,
          }),
        );

        const { id: rId = -1 } =
          response.data || response.body || response || {};
        return api.publish?.(rId);
      }

      return null;
    },
  );

  const validateAsync = createAsyncThunk(
    asyncActions.VALIDATE,
    async (
      { dataJson }: { dataJson: { [key: string]: any } },
      { dispatch },
    ) => {
      try {
        await api.validate?.(dataJson);
      } catch (e) {
        return (e as any)?.response?.data?.data || e;
      }
      return null;
    },
  );

  const singleRequestsThunks = api.singleRequests?.reduce((acc, val) => {
    acc[val.name] = createAsyncThunk(
      `${asyncActions.SINGLE_REQUEST}_${val.name}`,
      async ({
        id,
        dataJson,
      }: {
        id: TRecord['id'] | TRecord['id'][];
        dataJson?: { [key: string]: any };
      }) => {
        if (Array.isArray(id)) {
          const requests = [] as Promise<any>[];
          id.forEach((rId) => {
            requests.push(val.request(rId, dataJson));
          });
          return Promise.all(requests);
        }
        return val.request(id, dataJson);
      },
    );

    return acc;
  }, {} as { [key: string]: any });

  const itemsSlice = createSlice({
    name,
    initialState,
    reducers: {
      clearCurrentItem: (state) => {
        state.currentItem = null;
      },
      clearList: (state) => {
        state.items = { list: null, listParams: undefined, outdated: false };
      },
      setPagination: (
        state,
        action: PayloadAction<{ page: number; perPage: number }>,
      ) => {
        const { page, perPage } = action.payload;
        const { listParams } = state.items;

        state.items.listParams = {
          ...listParams,
          pagination: {
            page,
            perPage,
            total: listParams?.pagination?.total || 0,
          },
        };
      },
      setFilter: (
        state,
        action: PayloadAction<{
          field: string;
          value: string;
          exact?: boolean;
          custom?: boolean;
        }>,
      ) => {
        const { field, value, exact, custom } = action.payload;
        const { listParams } = state.items;

        state.items.listParams = {
          ...listParams,
          filter: [
            ...(listParams?.filter ?? []),
            {
              field: field as string,
              value,
              exact,
              custom,
            },
          ],
        };
      },
      setBulkActionItems: (
        state,
        action: PayloadAction<{
          bulkAction: keyof TState['bulkActionItems'];
          items: TRecord[];
        }>,
      ) => {
        const { bulkAction, items } = action.payload;

        state.bulkActionItems[bulkAction] = items as Draft<TRecord>[];
      },
      setActionItems: (
        state,
        action: PayloadAction<{
          singleAction: keyof TState['actionItems'];
          item: TRecord;
        }>,
      ) => {
        const { singleAction, item } = action.payload;

        state.actionItems[singleAction] = item as Draft<TRecord>;
      },
      clearStatuses: (state) => {
        state.statuses = {};
      },
      setCreatedAtRange: (
        state,
        action: PayloadAction<{ createdAtStart: string; createdAtEnd: string }>,
      ) => {
        const { createdAtStart, createdAtEnd } = action.payload;
        const { listParams } = state.items;

        state.items.listParams = {
          ...listParams,
          createdAt: { createdAtStart, createdAtEnd },
        };
      },
      clearCreatedAtRange: (state) => {
        const { listParams } = state.items;

        state.items.listParams = {
          ...listParams,
          createdAt: undefined,
        };
      },
      setSearch: (state, action: PayloadAction<{ query: string }>) => {
        const { listParams } = state.items;
        const { query } = action.payload;

        state.items.listParams = {
          ...listParams,
          search: query,
        };
      },
      setOrder: (
        state,
        action: PayloadAction<{ field: string; direction: string }>,
      ) => {
        const { listParams } = state.items;
        const { field, direction } = action.payload;

        state.items.listParams = {
          ...listParams,
          order: [
            {
              field,
              orderDirection: direction,
            },
          ],
        };
      },
      addOrder: (
        state,
        action: PayloadAction<{ field: string; direction: string }>,
      ) => {
        const { listParams } = state.items;
        const { field, direction } = action.payload;

        state.items.listParams = {
          ...listParams,
          order: [
            ...(listParams?.order || []),
            {
              field,
              orderDirection: direction,
            },
          ],
        };
      },
      clearOrder: (state) => {
        const { listParams } = state.items;

        state.items.listParams = {
          ...listParams,
          order: undefined,
        };
      },
      clearFilters: (state) => {
        const { listParams } = state.items;

        state.items.listParams = {
          ...listParams,
          filter: [],
        };
      },
      resetValidation: (state) => {
        state.validationErrors = [];
      },
    },
    extraReducers: (builder) => {
      const reducerBuilderBasic = <TPayload>(
        events: TReducerEvents<TState, TPayload>,
        asyncAction: AsyncThunk<any, any, any>,
        statusName: keyof TState['statuses'],
      ) => {
        const statusChanger = (
          state: Draft<TState>,
          status: RequestStatuses,
        ) => {
          state.statuses[statusName] = status;
        };
        return extraReducerBuilderV2<TState, TPayload>(builder, asyncAction, {
          statusChanger,
          events,
          skipSuccessInResponse: true,
        });
      };

      // create item
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        createNewItemAsync,
        'create',
      );
      // create item bulk
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.items.outdated = true;
          },
        },
        createItemBulkAsync,
        'createBulk',
      );
      // update item bulk
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.items.outdated = true;
          },
        },
        updateItemBulkAsync,
        'updateBulk',
      );
      // publish item
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        publishItemAsync,
        'publish',
      );
      // unpublish item
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        unpublishItemAsync,
        'unpublish',
      );
      // delete item
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state) => {
            state.items.outdated = true;
          },
        },
        deleteItemAsync,
        'delete',
      );
      // delete item bulk
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state) => {
            state.items.outdated = true;
          },
        },
        deleteItemBulkAsync,
        'deleteBulk',
      );
      // publish item bulk `
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state) => {
            state.items.outdated = true;
          },
        },
        publishItemBulkAsync,
        'publishBulk',
      );
      // unpublish item bulk
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state) => {
            state.items.outdated = true;
          },
        },
        unpublishItemBulkAsync,
        'unpublishBulk',
      );
      // fetch items
      reducerBuilderBasic<TListResponse>(
        {
          onSuccess: (state, payload) => {
            const { listParams } = state.items;
            const {
              offset = 0,
              limit = 10,
              total = 10,
            } = payload.pagination || {};

            state.items.list = payload.data as Draft<TRecord[]>;

            if (payload.filterData) {
              state.filterData = payload.filterData;
            }

            state.items.listParams = {
              ...listParams,
              pagination: { page: offset / limit + 1, perPage: limit, total },
            };
            state.items.outdated = false;
          },
        },
        fetchItemsAsync,
        'fetch',
      );
      // get item
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body ||
              payload) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        getItemAsync,
        'get',
      );
      // update item
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body ||
              payload) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        updateItemAsync,
        'update',
      );
      // create and publish
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body ||
              payload) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        createAndPublish,
        'publish',
      );
      // update and unpublish
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body ||
              payload) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        updateAndUnpublish,
        'unpublish',
      );
      // update and unpublish
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body ||
              payload) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        updateAndPublish,
        'publish',
      );
      // update and draft
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body ||
              payload) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        updateAndDraft,
        'draft',
      );
      // draft
      reducerBuilderBasic<TSingeResponse>(
        {
          onSuccess: (state, payload) => {
            state.currentItem = (payload.data?.data ||
              payload.data ||
              payload.body ||
              payload) as Draft<TRecord>;
            state.items.outdated = true;
          },
        },
        draftItemAsync,
        'draft',
      );
      // validate
      reducerBuilderBasic<void | TErrorPayload>(
        {
          onSuccess: (state, payload) => {
            if (
              !payload ||
              (payload.statusCode && payload.statusCode !== 400)
            ) {
              state.validationErrors = [];
            } else {
              state.validationErrors = Array.isArray(payload.message)
                ? payload.message
                : [payload.message];
            }
          },
        },
        validateAsync,
        'validate',
      );
      // singleRequests
      Object.values(singleRequestsThunks || {}).forEach((thnk) => {
        reducerBuilderBasic<TSingeResponse>(
          {
            onSuccess: (state, payload) => {
              if (payload) {
                state.currentItem = (payload.data ||
                  payload.body) as Draft<TRecord>;
              }
              state.items.outdated = true;
            },
          },
          thnk,
          'lastSingle',
        );
      });
    },
  });

  const { setBulkActionItems, setActionItems } = itemsSlice.actions;

  const selectors = {
    currentItem: (state: { [key: string]: TState }) => state[name].currentItem,
    listData: (state: { [key: string]: TState }) => state[name].items,
    statuses: (state: { [key: string]: TState }) => state[name].statuses,
    bulkItems: (state: { [key: string]: TState }) =>
      state[name].bulkActionItems,
    actionItems: (state: { [key: string]: TState }) => state[name].actionItems,
    validationError: (param: string) => (state: { [key: string]: TState }) =>
      state[name].validationErrors.find(
        (message: string) => message.indexOf(param) !== -1,
      ),
    filterData: (state: { [key: string]: TState }) => state[name].filterData,
  };

  return {
    actions: itemsSlice.actions,
    selectors,
    reducer: itemsSlice.reducer,
    asyncActions: {
      create: createNewItemAsync,
      publish: publishItemAsync,
      unpublish: unpublishItemAsync,
      fetchList: fetchItemsAsync,
      delete: deleteItemAsync,
      publishBulk: publishItemBulkAsync,
      unpublishBulk: unpublishItemBulkAsync,
      deleteBulk: deleteItemBulkAsync,
      get: getItemAsync,
      update: updateItemAsync,
      createAndPublish,
      updateAndUnpublish,
      updateAndPublish,
      singleRequests: singleRequestsThunks,
      createItemBulk: createItemBulkAsync,
      updateItemBulk: updateItemBulkAsync,
      validate: validateAsync,
      updateAndDraft,
      draft: draftItemAsync,
    },
  };
};

export default buildSlice;
