import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteChallengeModalProps = {
  children: JSX.Element;
  onClick: () => void;
};

function DeleteEmployeeCertificateModal({
  children,
  onClick,
}: TDeleteChallengeModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('modal.certificate.delete.title'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('modal.certificate.delete.content'),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeleteEmployeeCertificateModal;
