import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Badge, Button, Dropdown, Input, Menu, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Key } from 'antd/es/table/interface';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { actions, asyncActions, selectors } from '../CompaniesSlice';
import PageHeaderDefault from '../../../../components/PageHeaderDefault/PageHeaderDefault';
import { ECompanyStatuses, TCompany } from '../CompanyType';
import styles from './CompaniesList.module.scss';
import DeleteCompanyModal from '../../../../components/Modals/CollectiveModals/DeleteCompanyModal/DeleteCompanyModal';
import { TBadges } from './CompaniesListTypes';
import ActionAlert from '../../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../../constants/requestStatuses';
import RoleWrapper from '../../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../../constants/userRoles';
import { selectAuthenticatedUser } from '../../../auth/AuthSlice';
import { enableForRoles } from '../../../../helpers/role';
import useQuery from '../../../../helpers/useQuery';
import Table from '../../../../components/Table/Table';

const { Search } = Input;
const { TabPane } = Tabs;

type TTableData = {
  key: Key;
  id: TCompany['id'];
  companyName: string;
  numberOfEmployees: number;
  activatedAt: TCompany['activatedAt'];
  status: string;
  actions: JSX.Element;
};

function CompaniesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useQuery();

  // selectors
  const companiesData = useSelector(selectors.listData);
  const statuses = useSelector(selectors.statuses);
  const actioncompanies = useSelector(selectors.actionItems);
  const bulkCompanies = useSelector(selectors.bulkItems);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  // state
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [bulkOption, setBulkOption] = useState('desactive');

  // effects
  useLayoutEffect(() => {
    dispatch(actions.clearFilters());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';
      const sortField = sortOrder.startsWith('-')
        ? sortOrder.substring(1)
        : sortOrder;

      if (sortField === 'numberOfEmployees') {
        dispatch(actions.clearOrder());
        dispatch(
          actions.setFilter({
            field: 'orderByTotalEmployees',
            value: sortOrder.startsWith('-') ? 'DESC' : 'ASC',
            custom: true,
          }),
        );
      }

      dispatch(
        actions.setOrder({
          field: sortField,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    if (query.has('status')) {
      const filterStatus = (query.get('status') || '').split(',');

      for (const status of filterStatus) {
        dispatch(
          actions.setFilter({
            field: 'status',
            value: status,
            custom: true,
          }),
        );
      }
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (companiesData?.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, companiesData]);

  // status popup
  const bulkPopup = (
    status: RequestStatuses | null | undefined,
    companies: TCompany[],
    operation: string,
    success?: boolean,
  ) => {
    const { length } = companies;
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        length > 1
          ? t('collective.company.alerts.bulkPopupPrural', {
              length,
              operation,
            })
          : t('collective.company.alerts.bulkPopupSingular', {
              length,
              operation,
            }),
      );
      dispatch(actions.clearStatuses());
    }
  };

  const singlePopup = (
    status: RequestStatuses | null | undefined,
    company: TCompany | null,
    operation: string,
    success?: boolean,
  ) => {
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        t('collective.company.alerts.singualarPopup', {
          title: company?.companyName,
          operation,
        }),
      );
      dispatch(actions.clearStatuses());
    }
  };

  useEffect(
    () =>
      bulkPopup(
        statuses.deleteBulk,
        bulkCompanies.delete,
        t('generic.operations.deleted'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      bulkPopup(
        statuses.publishBulk,
        bulkCompanies.publish,
        t('generic.operations.activated'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      bulkPopup(
        statuses.unpublishBulk,
        bulkCompanies.unpublish,
        t('generic.operations.deactivated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.delete,
        actioncompanies.delete,
        t('generic.operations.deleted'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.publish,
        actioncompanies.publish,
        t('generic.operations.activated'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.unpublish,
        actioncompanies.unpublish,
        t('generic.operations.deactivated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.update,
        actioncompanies.update,
        t('generic.operations.updated'),
        true,
      ),
    [statuses],
  );
  useEffect(() => setSelectedIds([]), [statuses]);

  // actions
  const onDelete = (id: TCompany['id']) => () => {
    const company = companiesData.list?.find((a: TCompany) => a.id === id);
    if (company) {
      dispatch<any>(asyncActions.delete({ item: company }));
    }
  };

  const getStatus = (company: TCompany) => company.status.companyStatusTitle;

  const statusToBadge = (status: keyof TBadges) => {
    const statusBadgeMap = {
      pending: <Badge color="yellow" text={t('generic.pending')} />,
      active: <Badge color="green" text={t('generic.active')} />,
      inactive: <Badge status="default" text={t('generic.inactive')} />,
    } as TBadges;

    return statusBadgeMap[status];
  };

  // actions buttons
  const actionsBlock = (id: number, title: string) => (
    <div className={styles.tableActions}>
      <Link
        to={`/collective/${id}?${query.toString()}`}
        className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
      >
        <EditOutlined />
      </Link>
      <DeleteCompanyModal companyName={title} onClick={onDelete(id)}>
        <div className={styles.actionButton}>
          <DeleteOutlined />
        </div>
      </DeleteCompanyModal>
    </div>
  );

  // table data
  const tableData: TTableData[] =
    companiesData.list?.map((item: TCompany) => ({
      key: item.id,
      id: item.id,
      companyName: item.companyName,
      numberOfEmployees: item.totalEmployees,
      activatedAt: item.activatedAt
        ? dayjs(item.activatedAt).format('DD/MM/YYYY')
        : '-',
      status: getStatus(item),
      actions: actionsBlock(item.id, item.companyName),
    })) || [];

  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };

  const columns = [
    {
      title: t('collective.company.table.id'),
      dataIndex: 'id',
      sorter: true,
      ...(query.has('sort') &&
      ['id', '-id'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-id' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('collective.company.table.name'),
      dataIndex: 'companyName',
      render: (name: string, record: TTableData) => (
        <RoleWrapper roles={[UserRoles.ADMIN, UserRoles.COMPANY_MANAGER]}>
          <Link
            to={`/collective/${record.id}?${query.toString()}`}
          >
            {name}
          </Link>
        </RoleWrapper>
      ),
      sorter: true,
      ...(query.has('sort') &&
      ['companyName', '-companyName'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-companyName' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('collective.company.table.numberOfEmployees'),
      dataIndex: 'numberOfEmployees',
      sorter: true,
      ...(query.has('sort') &&
      ['numberOfEmployees', '-numberOfEmployees'].includes(
        query.get('sort') as string,
      )
        ? {
            defaultSortOrder:
              query.get('sort') === '-numberOfEmployees' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('collective.company.table.activationDate'),
      dataIndex: 'activatedAt',
      sorter: true,
      ...(query.has('sort') &&
      ['activatedAt', '-activatedAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-activatedAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
  ];

  if (enableForRoles(authenticatedUser.role as UserRoles, [UserRoles.ADMIN])) {
    columns.push({
      title: t('collective.company.table.creationStatus'),
      dataIndex: 'status',
      filters: [
        { value: ECompanyStatuses.ACTIVE, text: t('generic.active') },
        { value: ECompanyStatuses.PENDING, text: t('generic.pending') },
        { value: ECompanyStatuses.INACTIVE, text: t('generic.inactive') },
      ],
      render: (status: keyof TBadges) => statusToBadge(status),
      ...(query.has('status')
        ? {
            defaultFilteredValue: query.get('status')?.split(','),
          }
        : {}),
    } as any);

    columns.push({
      title: t('generic.actions'),
      dataIndex: 'actions',
      width: 50,
    } as any);
  }

  // bulk menu dropdown
  const dropdownOptions = [
    { key: 'desactive', text: t('generic.inactive') },
    { key: 'delete', text: t('generic.delete') },
  ];
  const dropdownItems = dropdownOptions.map((item) => (
    <Menu.Item key={item.key}> {item.text}</Menu.Item>
  ));
  const onBulkOperation = () => {
    const selectedCompanies =
      companiesData.list?.filter(({ id }) => selectedIds.indexOf(id) !== -1) ||
      [];
    switch (bulkOption) {
      case 'delete': {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedCompanies }));
        break;
      }
      case 'desactive': {
        dispatch<any>(asyncActions.unpublishBulk({ items: selectedCompanies }));
        break;
      }
      default: {
        // do nothing
      }
    }
    setSelectedIds([]);
  };

  return (
    <div key={`companies-list-${Array.from(query.keys()).length}`}>
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('nav.category.insurance')]: '/collective',
          [t('collective.tabs.companies')]: '',
        }}
        title={t('collective.title')}
      />

      <Tabs>
        <TabPane tab={t('collective.tabs.companies')} key="1">
          <div className={styles.toolBar}>
            <div className={styles.toolBarTitle}>
              {t('collective.company.companiesList')}
            </div>
            <div className={styles.buttonContainer}>
              <Search
                allowClear
                defaultValue={query.get('search') || ''}
                className={styles.searchField}
                placeholder={t('collective.company.searchCompany') || ''}
                onSearch={(val) => {
                  if (val) {
                    query.set('search', val);
                  } else {
                    query.delete('search');
                  }

                  query.set('page', '1');
                  query.set(
                    'perPage',
                    companiesData.listParams?.pagination?.perPage
                      ? companiesData.listParams?.pagination?.perPage.toString()
                      : '10',
                  );

                  navigate({
                    search: `?${query.toString()}`,
                  });
                }}
              />
              <RoleWrapper roles={[UserRoles.ADMIN]}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => navigate('/collective/new')}
                >
                  {t('collective.company.addCompany')}
                </Button>
              </RoleWrapper>
            </div>
          </div>

          {!!selectedIds.length && (
            <RoleWrapper roles={[UserRoles.ADMIN]}>
              <div className={styles.bulkMenu}>
                <div className={styles.bulkMenuCounter}>
                  {t('generic.bulk.selected', { length: selectedIds.length })}
                </div>
                <Dropdown
                  className={styles.bulkMenuDropdown}
                  overlay={
                    <Menu onClick={(e) => setBulkOption(e.key)}>
                      {dropdownItems}
                    </Menu>
                  }
                >
                  <Button>
                    {dropdownOptions.find((o) => o.key === bulkOption)?.text}{' '}
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <Button type="primary" onClick={onBulkOperation}>
                  {t('generic.confirm')}
                </Button>
              </div>
            </RoleWrapper>
          )}
          <Table
            actions={actions}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData}
            pagination={{
              total: companiesData.listParams?.pagination?.total,
              current: companiesData.listParams?.pagination?.page,
            }}
            customFiltersHandler={(filters, urlQuery) => {
              Object.keys(filters).forEach((field: any) => {
                if (filters[field]?.length) {
                  filters[field].forEach(() => {
                    urlQuery.set(field, filters[field].join(','));
                  });
                } else {
                  urlQuery.delete(field);
                }
              });
            }}
            customOrderHandler={() => {}}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default withTranslation()(CompaniesList);
