import buildSlice from '../general/helpers/genericSliceBuilder';
import {
  TCategory,
  TCategoryListResponse,
  TCategoryResponse,
} from './CategoriesTypes';
import {
  attachActivity,
  attachActivityBulk,
  createCategory,
  deleteCategory,
  detachActivity,
  detachActivityBulk,
  getCategories,
  getCategory,
  updateCategory,
} from './CategoriesApi';

const categoriesSlice = buildSlice<
  TCategory,
  TCategoryResponse,
  TCategoryListResponse
>('categories', {
  fetchList: getCategories,
  createJson: createCategory as (data: {
    [key: string]: any;
  }) => Promise<TCategoryResponse>,
  updateJson: updateCategory as (
    id: TCategory['id'],
    data: { [key: string]: any },
  ) => Promise<TCategoryResponse>,
  getOne: getCategory,
  delete: deleteCategory,
  singleRequests: [
    {
      request: attachActivity as (
        id: TCategory['id'],
        data?: { [key: string]: any },
      ) => Promise<TCategoryResponse>,
      name: 'attachActivity',
    },
    {
      request: detachActivity as (
        id: TCategory['id'],
        data?: { [key: string]: any },
      ) => Promise<TCategoryResponse>,
      name: 'detachActivity',
    },
    {
      request: attachActivityBulk as (
        id: TCategory['id'],
        data?: { [key: string]: any },
      ) => Promise<any>,
      name: 'attachActivityBulk',
    },
    {
      request: detachActivityBulk as (
        id: TCategory['id'],
        data?: { [key: string]: any },
      ) => Promise<any>,
      name: 'detachActivityBulk',
    },
  ],
});

export const { selectors, asyncActions, actions } = categoriesSlice;

export default categoriesSlice.reducer;
