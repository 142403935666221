import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';

import ContinueEditingModal from '../ContinueEditingModal/ContinueEditingModal';

export enum LinkType {
  ICON = 'icon',
  BUTTON = 'button',
}

type ConfirmClosureModalProps = {
  type?: LinkType | null;
  changed?: boolean | null;
  returnPath?: string | null;
};

function ConfirmClosureModal({
  type = LinkType.ICON,
  changed = false,
  returnPath = '',
}: ConfirmClosureModalProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const showAsIcon = type === LinkType.ICON;

  const goBack = () => {
    if (returnPath) {
      navigate(returnPath);
    } else {
      navigate('/quizzes');
    }
  };

  if (changed) {
    return (
      <ContinueEditingModal onClick={goBack}>
        {showAsIcon ? (
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ margin: 0, padding: 0 }}
          />
        ) : (
          <Button>{t('generic.modals.buttons.cancel')}</Button>
        )}
      </ContinueEditingModal>
    );
  }

  if (showAsIcon) {
    return (
      <Button
        icon={<ArrowLeftOutlined />}
        type="text"
        style={{ margin: 0, padding: 0 }}
        onClick={goBack}
      />
    );
  }

  return <Button onClick={goBack}>{t('generic.modals.buttons.cancel')}</Button>;
}

export default ConfirmClosureModal;
