import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, Input, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import codes from 'country-calling-code';
import validator from 'validator';
import RoleWrapper from '../../../../../../components/RoleWrapper/RoleWrapper';
import styles from './CompanyAdmins.module.scss';
import { actions, asyncActions, selectors } from './CompanyAdminsSlice';
import {
  asyncActions as asyncCompaniesActions,
  selectors as companiesSelectors,
} from '../../../CompaniesSlice';
import { UserRoles } from '../../../../../../constants/userRoles';
import { TAdminRequesteFields } from './CompanyAdminsType';
import {
  actions as companyMainContactActions,
  asyncActions as asyncCompanyMainContactActions,
  selectors as companyMainContactSelectors,
} from '../../../CompanyMainContactSlice';
import { TMainAdmin } from '../../../CompanyType';
import RequestStatuses from '../../../../../../constants/requestStatuses';
import ActionAlert from '../../../../../../components/ActionAlert/ActionAlert';
import { enableForRoles } from '../../../../../../helpers/role';
import { selectAuthenticatedUser } from '../../../../../auth/AuthSlice';

type TEmployeesProps = {
  companyId: number;
  onSomethingChange: () => void;
  onAdminsSave: () => void;
};

const { Option } = Select;
const { Search } = Input;

type TUserCreate = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country?: string;
  phoneNumber?: string;
  role: {
    id: number;
    title: string;
  };
};

function CompanyAdmins({
  companyId,
  onSomethingChange,
  onAdminsSave,
}: TEmployeesProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // selectors
  const currentCompany = useSelector(companiesSelectors.currentItem);
  const adminsData = useSelector(selectors.listData);
  const statuses = useSelector(companyMainContactSelectors.statuses);
  const bulkItems = useSelector(selectors.bulkItems);
  const newAdminStatuses = useSelector(selectors.statuses);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const initialAdmins =
    adminsData && adminsData.list
      ? adminsData.list
          .filter(
            (admin) =>
              admin.status.adminStatusTitle !== 'deleted' &&
              !currentCompany?.admins.find((item) => item.id === admin?.id),
          )
          .map((admin) => ({
            value: `${admin.firstName} ${admin.lastName} ( ${admin.email} )`,
          }))
      : [];

  const [companyCurrentAdmins, setCompanyCurrentAdmins] = useState(
    [] as TMainAdmin[],
  );
  const [usersCreate, setUsersCreate] = useState([] as TUserCreate[]);
  const [usersAdd, setUsersAdd] = useState([] as TUserCreate[]);
  const [usersDelete, setUsersDelete] = useState([] as TTableData[]);

  const [editable, setEditable] = useState([] as number[]);
  const [adminsOptions, setAdminsOptions] = useState(initialAdmins);
  const [adminSearch, setAdminSearch] = useState('');

  useEffect(() => {
    dispatch(companyMainContactActions.clearStatuses());
  }, []);

  useEffect(() => {
    setCompanyCurrentAdmins(currentCompany?.admins || []);

    const failedEmails = bulkItems.createFailed.map(
      (failedUser) => failedUser.email,
    );

    setUsersAdd([]);
    setUsersCreate(
      usersCreate?.filter((createdUser) =>
        failedEmails.includes(createdUser.email),
      ),
    );
    setUsersDelete([]);
  }, [currentCompany?.admins]);

  useEffect(() => {
    const aux = companyCurrentAdmins.map((admin) => admin.id);
    setEditable(editable.concat(aux));
  }, [companyCurrentAdmins]);

  useEffect(() => {
    let updatedAdminOptions: Array<{ value: string }> = [];

    if (adminsData?.list) {
      updatedAdminOptions = adminsData.list
        .filter(
          (admin) =>
            admin.status.adminStatusTitle !== 'deleted' &&
            ![...companyCurrentAdmins, ...usersAdd].find(
              (item) => item.id === admin?.id,
            ),
        )
        .map((admin) => ({
          value: `${admin.firstName} ${admin.lastName} ( ${admin.email} )`,
        }));
    }

    setAdminsOptions(updatedAdminOptions);
  }, [usersAdd]);

  useEffect(() => {
    const { createBulk, deleteBulk } = statuses;
    const { createBulk: newAdminCreateBulk, deleteBulk: newAdminDeleteBulk } =
      newAdminStatuses;

    const activeStatuses = [
      deleteBulk,
      createBulk,
      newAdminCreateBulk,
      newAdminDeleteBulk,
    ].filter((s) => s);
    const successStatuses = activeStatuses.filter(
      (s) => s === RequestStatuses.SUCCESS,
    );
    const failStatuses = activeStatuses.filter(
      (s) => s === RequestStatuses.FAILED,
    );

    if (!activeStatuses.length) return;

    if (
      successStatuses.length &&
      successStatuses.length === activeStatuses.length
    ) {
      ActionAlert.success(t('employees.modals.successfullyChanges'));
      dispatch<any>(asyncCompaniesActions.get({ id: companyId }));
      dispatch(companyMainContactActions.clearStatuses());
      dispatch(actions.clearStatuses());
    }

    if ([deleteBulk].filter((s) => s).length) {
      dispatch(companyMainContactActions.clearStatuses());
    }

    if ([newAdminDeleteBulk].filter((s) => s).length) {
      dispatch(actions.clearStatuses());
    }

    if (!failStatuses.length) return;

    if (
      failStatuses.length + successStatuses.length ===
      activeStatuses.length
    ) {
      const failedElements = bulkItems.createFailed.concat(
        bulkItems.deleteFailed.concat(bulkItems.updateFailed),
      );
      const failedNames = failedElements.map(
        (e) => `${e.firstName} ${e.lastName}`,
      );

      ActionAlert.info(
        t('employees.modals.failedChanges', { users: failedNames.join(', ') }),
      );
      dispatch<any>(asyncCompaniesActions.get({ id: companyId }));
      dispatch(companyMainContactActions.clearStatuses());
      dispatch(actions.clearStatuses());
    }
  }, [statuses, newAdminStatuses]);

  // handler
  const onNewUser = () => {
    onSomethingChange();
    const user = {
      id: (usersCreate.length + 1) * -1,
    } as TUserCreate;

    onSetEditable(user.id);

    setUsersCreate([...usersCreate, user]);
  };

  const onSave = () => {
    if (usersAdd.length && currentCompany) {
      dispatch<any>(
        asyncCompanyMainContactActions.createItemBulk({
          items: usersAdd.map((item) => ({
            companyId: currentCompany.id,
            adminId: item.id,
          })),
        }),
      );
    }
    if (usersCreate.length && currentCompany) {
      dispatch<any>(
        asyncActions.createItemBulk({
          items: usersCreate.map((user) => ({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.country
              ? user.country.concat('-').concat(user.phoneNumber || '')
              : user.phoneNumber || undefined,
            roleEnum: UserRoles.COMPANY_MANAGER,
            status: 'invited',
            companyId: currentCompany.id,
          })),
        }),
      );
    }
    if (usersDelete.length && currentCompany) {
      dispatch<any>(
        asyncCompanyMainContactActions.deleteBulk({
          items: usersDelete.map((ud) => ({
            companyId: currentCompany.id,
            adminId: ud.id,
          })),
        }),
      );
    }
    onAdminsSave();
  };

  const onSetEditable = (id: number) =>
    setEditable([...editable.filter((eId) => eId !== id), id]);

  type TTableData = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    phoneNumber: string;
    actions: null;
  };

  const tableData: TTableData[] = (
    companyCurrentAdmins.map((u) => ({
      id: u.id,
      firstName: u.firstName,
      lastName: u.lastName,
      email: u.email,
      role: u.role?.title,
      country:
        u.phoneNumber && u.phoneNumber.includes('-')
          ? `+${u.phoneNumber.split('-')[0]}`
          : '',
      phoneNumber: u.phoneNumber
        ? u.phoneNumber.split('-')[u.phoneNumber.includes('-') ? 1 : 0]
        : '',
      actions: null,
    })) || []
  )
    .concat(
      usersCreate.map((u) => ({
        id: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        email: u.email,
        role: u.role?.title,
        country: u.country || '',
        phoneNumber: u.phoneNumber || '',
        actions: null,
      })),
    )
    .concat(
      usersAdd.map((u) => ({
        id: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        email: u.email,
        role: u.role?.title,
        country:
          u.phoneNumber && u.phoneNumber.includes('-')
            ? `+${u.phoneNumber.split('-')[0]}`
            : '',
        phoneNumber: u.phoneNumber
          ? u.phoneNumber.split('-')[u.phoneNumber.includes('-') ? 1 : 0]
          : '',
        actions: null,
      })),
    )
    .sort((a, b) => (a.id < b.id ? a.id : -1 * a.id));

  const onEdit = (
    id: number,
    field: keyof TAdminRequesteFields,
    value: string,
  ) => {
    if (id < 0) {
      const userForUpdate = usersCreate.find((u) => u.id === id);
      if (!userForUpdate) return;

      const userData = { ...userForUpdate, [field]: value } as TUserCreate;

      setUsersCreate(usersCreate.map((u) => (u.id === id ? userData : u)));
    }
  };

  const validateData = () => {
    let somethingEmpty = false;

    tableData.forEach((tD) =>
      [
        tD.firstName,
        tD.lastName,
        tD.email && validator.isEmail(tD.email),
      ].forEach((f) => {
        if (!f) somethingEmpty = true;
      }),
    );

    return !somethingEmpty;
  };

  const onDeleteSingle = (admin: TTableData) => {
    onSomethingChange();
    if (
      companyCurrentAdmins.filter((user) => admin.id === user.id).length === 1
    ) {
      setUsersDelete(usersDelete.concat(admin));
      setCompanyCurrentAdmins(
        companyCurrentAdmins.filter((user) => user.id !== admin.id),
      );
    } else if (
      usersCreate.filter((user) => admin.id === user.id).length === 1
    ) {
      setUsersCreate(usersCreate.filter((user) => user.id !== admin.id));
    } else if (usersAdd.filter((user) => admin.id === user.id).length === 1) {
      setUsersAdd(usersAdd.filter((user) => user.id !== admin.id));
    }
  };

  enum EInputType {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Input = 'input',
    Country = 'country',
  }

  const editableColumn = (
    id: number,
    placeholder: string,
    currentValue: string,
    displayValue: string,
    field: keyof TAdminRequesteFields,
    inputType: EInputType,
    disabled: boolean,
    dropdownOptions?: { value: string | number; title: string }[] | null,
    validationFn?: (val: string) => 'error' | 'success',
  ) => {
    let input = (
      <Input
        placeholder={placeholder}
        value={currentValue}
        defaultValue={currentValue}
        onChange={(e) => onEdit(id, field, e.target.value)}
        disabled={disabled}
      />
    );

    if (inputType === EInputType.Country) {
      input = (
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          placeholder="Please select"
          defaultValue={currentValue}
          value={currentValue}
          onChange={(value) => onEdit(id, field, value)}
          disabled={disabled}
          dropdownMatchSelectWidth={110}
        >
          {codes.map((code, index) => (
            <Option
              key={`country-${code.isoCode2}`}
              value={code.countryCodes.toString()}
            >
              {`${code.isoCode2} (+${code.countryCodes})`}
            </Option>
          ))}
        </Select>
      );
    }

    return editable.indexOf(id) !== -1 ? (
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={validationFn?.(currentValue)}
      >
        {input}
      </Form.Item>
    ) : (
      displayValue
    );
  };

  const disableField = (record: TTableData) =>
    companyCurrentAdmins.map((a) => a.id).includes(record.id) ||
    usersAdd.map((u) => u.id).includes(record.id);

  const columns = [
    {
      title: t('employees.name'),
      dataIndex: 'firstName',
      render: (firstName: string, record: TTableData) =>
        disableField(record)
          ? firstName
          : editableColumn(
              record.id,
              t('employees.name'),
              record.firstName,
              record.firstName,
              'firstName',
              EInputType.Input,
              false,
            ),
    },
    {
      title: t('employees.surname'),
      dataIndex: 'lastName',
      render: (lastName: string, record: TTableData) =>
        disableField(record)
          ? lastName
          : editableColumn(
              record.id,
              t('employees.surname'),
              record.lastName,
              record.lastName,
              'lastName',
              EInputType.Input,
              false,
            ),
    },
    {
      title: t('employees.email'),
      dataIndex: 'email',
      render: (email: string, record: TTableData) =>
        disableField(record)
          ? email
          : editableColumn(
              record.id,
              t('employees.email'),
              record.email,
              record.email,
              'email',
              EInputType.Input,
              false,
              null,
              (val) => {
                if (!val) return 'success';
                return val && validator.isEmail(val) ? 'success' : 'error';
              },
            ),
    },
    {
      title: t('collective.company.form.mainPerson.country'),
      dataIndex: 'country',
      render: (country: string, record: TTableData) =>
        disableField(record)
          ? country
          : editableColumn(
              record.id,
              t('collective.company.form.mainPerson.country'),
              record.country,
              record.country,
              'country',
              EInputType.Country,
              false,
            ),
    },
    {
      title: t('collective.company.form.mainPerson.phone'),
      dataIndex: 'phoneNumber',
      render: (phoneNumber: string, record: TTableData) =>
        disableField(record)
          ? phoneNumber
          : editableColumn(
              record.id,
              t('collective.company.form.mainPerson.phone'),
              record.phoneNumber,
              record.phoneNumber,
              'phoneNumber',
              EInputType.Input,
              companyCurrentAdmins.filter((admin) => admin.id === record.id)
                .length >= 1 ||
                usersAdd.filter((admin) => admin.id === record.id).length >= 1,
            ),
    },
    ...(enableForRoles(authenticatedUser.role, [UserRoles.COMPANY_MANAGER])
      ? []
      : [
          {
            title: t('generic.actions'),
            dataIndex: 'actions',
            width: '104px',
            className: styles.actionColumn,
            render: (_: null, record: TTableData) => (
              <RoleWrapper roles={[UserRoles.ADMIN]}>
                <div className={styles.tableActions}>
                  <div className={styles.actionButton}>
                    <Button
                      type="text"
                      onClick={() => onDeleteSingle(record)}
                      disabled={
                        (usersCreate.length < 1 &&
                          usersAdd.length < 1 &&
                          companyCurrentAdmins.length === 1) ||
                        (usersCreate.length < 1 &&
                          usersAdd.length === 1 &&
                          companyCurrentAdmins.length < 1) ||
                        (usersCreate.length === 1 &&
                          usersAdd.length < 1 &&
                          companyCurrentAdmins.length < 1)
                      }
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                </div>
              </RoleWrapper>
            ),
          },
        ]),
  ];

  const handleSearchSelected = async (newSearch: string) => {
    adminsOptions.forEach((admin, index) => {
      if (admin.value === newSearch) {
        const currentAdmin = adminsData.list?.find((a) =>
          newSearch.includes(a.email),
        );
        const adminAlredayExist =
          companyCurrentAdmins.filter((item) => item.id === currentAdmin?.id)
            .length >= 1;
        if (currentAdmin && !adminAlredayExist) {
          onSetEditable(currentAdmin.id);
          setUsersAdd([...usersAdd, currentAdmin]);
        }
      }
    });
    searchMainContactResult('');
  };

  const searchMainContactResult = (query: string) => {
    onSomethingChange();
    setAdminSearch(query);
    const newlist =
      adminsData.list !== null
        ? adminsData.list
            .filter(
              (admin) =>
                admin.status.adminStatusTitle !== 'deleted' &&
                ![...companyCurrentAdmins, ...usersAdd].find(
                  (item) => item.id === admin?.id,
                ),
            )
            .filter(
              (item) =>
                item.firstName.toLowerCase().indexOf(query.toLowerCase()) !==
                  -1 ||
                item.lastName.toLowerCase().indexOf(query.toLowerCase()) !==
                  -1 ||
                item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1,
            )
        : [];
    newlist.length > 0 &&
      setAdminsOptions(
        newlist.map((admin) => ({
          value: `${admin.firstName} ${admin.lastName} ( ${admin.email} )`,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {(() => {
                if (
                  admin.firstName.toLowerCase().includes(query.toLowerCase()) &&
                  query !== ''
                ) {
                  return (
                    <span>
                      <a className={styles.searchResutl}>{admin.firstName}</a>
                      {` ${admin.lastName} ( ${admin.email} )`}
                    </span>
                  );
                }
                if (
                  admin.lastName.toLowerCase().includes(query.toLowerCase()) &&
                  query !== ''
                ) {
                  return (
                    <span>
                      {`${admin.firstName} `}
                      <a className={styles.searchResutl}>{admin.lastName}</a>
                      {` ( ${admin.email} )`}
                    </span>
                  );
                }
                if (
                  admin.email.toLowerCase().includes(query.toLowerCase()) &&
                  query !== ''
                ) {
                  return (
                    <span>
                      {`${admin.firstName} ${admin.lastName} ( `}
                      <a className={styles.searchResutl}>{admin.email}</a>
                      {' )'}
                    </span>
                  );
                }
                return (
                  <span>
                    {`${admin.firstName} ${admin.lastName} ( ${admin.email} )`}
                  </span>
                );
              })()}
            </div>
          ),
        })),
      );
  };

  return (
    <div>
      <div className={styles.buttonBar}>
        <div className={styles.title}>
          {t('collective.company.admin.personOfContact')}
        </div>
        <div>
          <RoleWrapper roles={[UserRoles.ADMIN]}>
            <>
              <AutoComplete
                dropdownMatchSelectWidth={400}
                options={adminsOptions}
                onSearch={searchMainContactResult}
                onSelect={handleSearchSelected}
                className={styles.autocompleteField}
                value={adminSearch}
              >
                <Search
                  allowClear
                  value={adminSearch}
                  className={styles.searchField}
                  onSearch={searchMainContactResult}
                  placeholder={t('collective.company.admin.searchUser') || ''}
                />
              </AutoComplete>
              <Button
                className={styles.addButton}
                onClick={onNewUser}
                icon={<PlusOutlined />}
              >
                {t('collective.company.admin.addAdmin')}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onSave();
                }}
                disabled={!validateData()}
              >
                {t('generic.modals.save')}
              </Button>
            </>
          </RoleWrapper>
        </div>
      </div>
      <ActionAlert />
      <Form>
        <Table
          className={styles.table}
          dataSource={tableData}
          columns={columns}
          pagination={false}
        />
      </Form>
    </div>
  );
}

export default CompanyAdmins;
