import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './Information.module.scss';
import FormTitle from '../../../../../components/Forms/FormTitle/FormTitle';
import FormItemTwoColumn from '../../../../../components/Forms/FormItemTwoColumn/FormItemTwoColumn';
import { useSelector } from 'react-redux';
import { selectors } from '../../../AdminUsersSlice';
import { useTranslation } from 'react-i18next';
import { UserRoles } from '../../../../../constants/userRoles';
import { TAdminUserForm } from '../../../AdminUsersTypes';
import codes from 'country-calling-code';
import { TCompany } from '../../../../collective/companies/CompanyType';

type InformationProps = {
  error: string;
};

function Information({ error }: InformationProps) {
  const { t } = useTranslation();
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext<TAdminUserForm>();

  const currentUserData = useSelector(selectors.currentItem);

  useEffect(() => {
    return () => reset({});
  }, []);

  useEffect(() => {
    const [phoneCountryCode, phoneNumber] =
      currentUserData?.phoneNumber?.split('-') || [];

    reset({
      firstName: currentUserData?.firstName,
      lastName: currentUserData?.lastName,
      email: currentUserData?.email,
      phoneCountryCode,
      phoneNumber,
    });
  }, [currentUserData]);

  return (
    <div className={styles.personalInfo}>
      <Form layout="vertical">
        <FormTitle className={styles.formTitle}>
          {t('adminUsers.form.personalInformation') || ''}
        </FormTitle>
        <FormItemTwoColumn>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('adminUsers.form.firstName')}
            >
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder={t('generic.example') || ''} />
                )}
              />
              {errors.firstName && (
                <span className={styles.validationError}>
                  {t(errors.firstName.message as string)}
                </span>
              )}
            </Form.Item>
          </FormItemTwoColumn.Column>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('adminUsers.form.surnames')}
            >
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder={t('generic.example') || ''} />
                )}
              />
              {errors.lastName && (
                <span className={styles.validationError}>
                  {t(errors.lastName.message as string)}
                </span>
              )}
            </Form.Item>
          </FormItemTwoColumn.Column>
        </FormItemTwoColumn>
        <FormItemTwoColumn>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('adminUsers.form.professionalEmail')}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="email@email.com"
                    type="email"
                  />
                )}
              />
              {error && <span className={styles.validationError}>{error}</span>}
              {errors.email && (
                <span className={styles.validationError}>
                  {t(errors.email.message as string)}
                </span>
              )}
            </Form.Item>
          </FormItemTwoColumn.Column>
          <FormItemTwoColumn.Column>
            <div className={styles.phoneNumberContainer}>
              <Form.Item
                label={t('adminUsers.form.country')}
                className={`${styles.formItem} ${styles.phoneCountryCode}`}
              >
                <Controller
                  name="phoneCountryCode"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      placeholder={t('generic.placeholder.placeSelect')}
                    >
                      {codes.map((code, index) => {
                        return (
                          <Select.Option
                            key={`country-${code.isoCode2}`}
                            value={code.countryCodes.toString()}
                          >
                            {code.isoCode2 + ' (+' + code.countryCodes + ')'}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                />
              </Form.Item>
              <Form.Item
                label={t('adminUsers.form.phone')}
                className={styles.formItem}
              >
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="00000000" />
                  )}
                />
              </Form.Item>
            </div>
          </FormItemTwoColumn.Column>
        </FormItemTwoColumn>
        <FormItemTwoColumn>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('adminUsers.form.typeOfUser')}
            >
              <Select
                value={currentUserData?.role?.title}
                disabled={true}
                placeholder={t('generic.example')}
                options={[
                  {
                    value: UserRoles.ADMIN,
                    label: t('adminUsers.admin'),
                  },
                  {
                    value: UserRoles.COMPANY_MANAGER,
                    label: t('adminUsers.companyManager'),
                  },
                ]}
              />
            </Form.Item>
          </FormItemTwoColumn.Column>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('adminUsers.form.companiesAssigned')}
            >
              <Select
                value={currentUserData?.companies?.map((company: TCompany) => {
                  return company.id;
                })}
                disabled={true}
                mode="multiple"
                optionLabelProp="label"
                placeholder={t('generic.example')}
                options={currentUserData?.companies?.map(
                  (company: TCompany) => {
                    return {
                      value: company.id,
                      label: company.companyName,
                    };
                  },
                )}
              />
            </Form.Item>
          </FormItemTwoColumn.Column>
        </FormItemTwoColumn>
      </Form>
    </div>
  );
}

export default Information;
