import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Select,
  Tabs,
  Upload,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
// import { isEmpty } from 'lodash';
import { actions, asyncActions, selectors } from './EmployeesSlice';
import {
  asyncActions as companiesAsyncActions,
  selectors as companiesSelectors,
} from '../../../CompaniesSlice';
import {
  asyncActions as usersAsyncActions,
  selectors as usersSelectors,
  actions as usersActions,
} from '../../../../../users/UsersSlice';
import {
  TUserCompanyProfile,
  TUserCompanyProfileRequest,
} from './EmployeesType';
import styles from './EmployeeList.module.scss';
import DeleteUserModal from '../../../../../../components/Modals/UsersModals/DeleteUserModal/DeleteUserModal';
import ActionAlert from '../../../../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../../../../constants/requestStatuses';
import RoleWrapper from '../../../../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../../../../constants/userRoles';
import { enableForRoles } from '../../../../../../helpers/role';
import { selectAuthenticatedUser } from '../../../../../auth/AuthSlice';
import ProductsList from './ProductsList/ProductsList';
import UserForm from '../../../../../users/UserForm/UserForm';
import Table from '../../../../../../components/Table/Table';
import { config } from '../../../../../../config';
import { defaultHeaders } from '../../../../../../helpers/apiRequest';
import getMinDateOfBirth from '../../../../../../helpers/getMinDateOfBirth';

const { Option } = Select;
const { TabPane } = Tabs;
const { Search } = Input;

type TEmployeesProps = {
  companyId: number;
};

function EmployeeList({ companyId }: TEmployeesProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [fieldsKeys, setFieldsKeys] = useState({} as { [key: string]: string });

  // selectors
  const usersData = useSelector(selectors.listData);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const currentCompany = useSelector(companiesSelectors.currentItem);
  const statuses = useSelector(selectors.statuses);
  const bulkItems = useSelector(selectors.bulkItems);
  // const actionItems = useSelector(selectors.actionItems);
  const usersStatuses = useSelector(usersSelectors.statuses);

  const [employeeList, setEmployeeList] = useState(
    [] as Array<TUserCompanyProfile>,
  );
  const [origianlEmployeeList, setOriginalEmployeeList] = useState(
    [] as Array<TUserCompanyProfile>,
  );

  // effects
  useEffect(() => {
    usersData?.list?.forEach((user) => {
      if (user.user === null) {
        dispatch<any>(asyncActions.delete({ item: user }));
      }
    });

    setEmployeeList(
      usersData?.list?.map((user, index) => ({
        ...user,
        position: index,
      })) || [],
    );
    setOriginalEmployeeList(
      usersData?.list?.map((user, index) => ({
        ...user,
        position: index,
      })) || [],
    );
  }, [usersData]);

  useEffect(() => {
    dispatch(actions.setSearch({ query: '' }));
    dispatch(actions.setOrder({ field: 'id', direction: 'desc' }));
    dispatch(actions.setPagination({ page: 1, perPage: 10 }));
    dispatch<any>(asyncActions.fetchList({ id: companyId }));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (usersData.outdated) {
      // if (usersData.list?.length === 1 && !isEmpty(actionItems.delete)) {
      //   dispatch(
      //     actions.setPagination({
      //       page: 1,
      //       perPage: manageMode
      //         ? tableData.length
      //         : usersData.listParams?.pagination?.perPage || 0,
      //     }),
      //   );
      // }

      dispatch<any>(companiesAsyncActions.get({ id: companyId }));
      dispatch<any>(asyncActions.fetchList({ id: companyId }));
    }
  }, [companyId, dispatch, usersData]);

  useEffect(() => {
    const { deleteBulk, updateBulk, createBulk } = statuses;

    const activeStatuses = [deleteBulk, updateBulk, createBulk].filter(
      (s) => s,
    );
    const successStatuses = activeStatuses.filter(
      (s) => s === RequestStatuses.SUCCESS,
    );
    const failStatuses = activeStatuses.filter(
      (s) => s === RequestStatuses.FAILED,
    );

    if (!activeStatuses.length) return;

    if (successStatuses.length === activeStatuses.length) {
      ActionAlert.success(t('employees.modals.successfullyChanges'));

      dispatch(actions.clearStatuses());
    }

    if (!failStatuses.length) return;

    if (
      failStatuses.length + successStatuses.length ===
      activeStatuses.length
    ) {
      const failedElements = bulkItems.createFailed.concat(
        bulkItems.deleteFailed.concat(bulkItems.updateFailed),
      );
      const failedNames = failedElements.map(
        (e) => `${e.firstName} ${e.lastName}`,
      );

      ActionAlert.info(
        t('employees.modals.failedChanges', { users: failedNames.join(', ') }),
      );

      dispatch(actions.clearStatuses());
    }
  }, [statuses, bulkItems, dispatch]);

  useEffect(() => {
    if (usersStatuses.delete === RequestStatuses.SUCCESS) {
      dispatch<any>(asyncActions.fetchList({ id: companyId }));
      dispatch(usersActions.clearStatuses());
    }
  }, [usersStatuses]);

  // state
  const [usersCreate, setUsersCreate] = useState(
    [] as TUserCompanyProfileRequest[],
  );
  const [usersRemove, setUsersRemove] = useState([] as number[]);
  const [usersEdit, setUsersEdit] = useState(
    [] as TUserCompanyProfileRequest[],
  );

  const [editable, setEditable] = useState([] as number[]);
  const [manageMode, setManageMode] = useState(false);

  const [currentUser, setCurrentUser] = useState(null as number | null);
  const [editWindow, setEditWindow] = useState(false);

  // handlers
  const onNewUser = () => {
    const employee = {
      id: (usersCreate.length + 1) * -1,
      position: (employeeList.length + 1) * -1,
      gender: 'none',
    } as TUserCompanyProfileRequest;

    onSetEditable(employee.id);

    setUsersCreate([...usersCreate, employee]);
  };

  const onRemoveUser = (id: number) => {
    if (id < 0) {
      setUsersCreate(usersCreate.filter((u) => u.id !== id));
      return;
    }
    setUsersRemove([...usersRemove, id]);
  };

  const onEdit = (
    id: number,
    field: keyof TUserCompanyProfileRequest,
    value: any,
  ) => {
    if (id < 0) {
      const userForUpdate = usersCreate.find((u) => u.id === id);
      if (!userForUpdate) return;

      const userData = {
        ...userForUpdate,
        [field]: value,
      } as TUserCompanyProfileRequest;

      setUsersCreate(usersCreate.map((u) => (u.id === id ? userData : u)));
      return;
    }

    const existingUserForUpdate = employeeList?.find((u) => u.id === id);
    const updatedUser = usersEdit.find((u) => u.id === id);
    if (!existingUserForUpdate && !updatedUser) return;

    const userData = {
      id: existingUserForUpdate?.id,
      ...updatedUser,
      [field]: value,
    } as TUserCompanyProfileRequest;

    setUsersEdit([...usersEdit.filter((u) => u.id !== id), userData]);
  };

  const onSave = () => {
    if (usersCreate.length) {
      dispatch<any>(
        asyncActions.createItemBulk({
          items: usersCreate
            .sort((a, b) => (a.id < b.id ? 1 : -1))
            .map((uc) => ({ ...uc, companyId })),
        }),
      );
    }

    if (usersEdit.length) {
      const dataForUpdate = usersEdit.map((u) => ({
        id: u.id,
        data: { ...u, id: undefined },
      }));
      dispatch<any>(asyncActions.updateItemBulk({ items: dataForUpdate }));
    }

    if (usersRemove.length) {
      dispatch<any>(
        asyncActions.deleteBulk({
          items: usersRemove.map((ur) => ({
            id: ur,
            ...(employeeList?.find((u) => u.id === ur) || {}),
          })),
        }),
      );
    }

    setUsersCreate([]);
    setUsersEdit([]);
    setUsersRemove([]);
  };

  const onSetEditable = (id: number) =>
    setEditable([...editable.filter((eId) => eId !== id), id]);

  const onRemoveEditable = (id: number) =>
    setEditable(editable.filter((eId) => eId !== id));

  const onDeleteSingle = (id: number) => {
    const user = employeeList?.find((u) => u.id === id);

    if (user) {
      dispatch<any>(asyncActions.delete({ item: user }));
    }
  };

  type TTableData = {
    id: number;
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    birthDate: string | null;
    gender: string;
    docType: string;
    docNumber: string;
    admissionDate: string;
    position: number;
    actions: null;
  };

  const tableData: TTableData[] = (
    employeeList
      ?.filter((u) => u.user !== null)
      .map((u) => ({
        id: u.id,
        userId: u.userId,
        firstName: u.user?.firstName,
        lastName: u.user?.lastName,
        email: u.companyEmail,
        birthDate: u.user?.birthDate || u.user?.bioProfile?.birth || '',
        gender: u.user?.gender || u.user?.bioProfile?.gender || 'none',
        docType: u.user?.doc?.docType || '',
        docNumber: u.user?.doc?.docNumber || '',
        admissionDate: u.admissionDate,
        position:
          u.position ||
          origianlEmployeeList.find((item) => item.id === u.id)?.position ||
          0,
        actions: null,
      })) || []
  )
    .filter(
      (u) =>
        usersEdit.map((ue) => ue.id).indexOf(u.id) === -1 &&
        usersRemove.indexOf(u.id) === -1,
    )
    .concat(
      usersEdit.map((u) => {
        const existingEmployee = employeeList?.find((ee) => ee.id === u.id);
        return {
          id: u.id,
          userId: u.userId,
          firstName: u.firstName || existingEmployee?.user?.firstName || '',
          lastName: u.lastName || existingEmployee?.user?.lastName || '',
          email: u.companyEmail || existingEmployee?.companyEmail || '',
          birthDate: u.birthDate || existingEmployee?.user?.birthDate || '',
          gender: u.gender || existingEmployee?.user?.gender || '',
          docType: u.docType || existingEmployee?.user?.doc?.docType || '',
          docNumber:
            u.docNumber || existingEmployee?.user?.doc?.docNumber || '',
          admissionDate:
            u.admissionDate || existingEmployee?.admissionDate || '',
          position:
            u.position ||
            origianlEmployeeList.find((item) => item.id === u.id)?.position ||
            0,
          actions: null,
        };
      }),
    )
    .concat(
      usersCreate.map((u) => ({
        id: u.id,
        userId: u.userId,
        firstName: u.firstName,
        lastName: u.lastName,
        email: u.companyEmail,
        birthDate: u.birthDate,
        gender: u.gender,
        docType: u.docType,
        docNumber: u.docNumber,
        admissionDate: u.admissionDate,
        position: u.position || 0,
        actions: null,
      })),
    )
    .sort((a, b) => a.position - b.position);

  const validateData = (id: number | null = null) => {
    let somethingEmpty = false;

    if (id) {
      const userData = employeeList?.find((user) => user.id === id);
      const dataForUpdate = {
        firstName: userData?.user?.firstName,
        lastName: userData?.user?.lastName,
        companyEmail: userData?.companyEmail,
        dateOfBirth:
          userData?.user?.birthDate || userData?.user?.bioProfile?.dateOfBirth,
        gender: userData?.user?.gender || userData?.user?.bioProfile?.gender,
        docType: userData?.user?.doc?.docType,
        docNumber: userData?.user?.doc?.docNumber,
        admissionDate: userData?.admissionDate,
        ...usersEdit.find((user) => user.id === id),
      };

      [
        dataForUpdate.firstName,
        dataForUpdate.lastName,
        dataForUpdate.companyEmail &&
          validator.isEmail(dataForUpdate.companyEmail),
        dataForUpdate.admissionDate,
      ].forEach((f) => {
        if (!f) somethingEmpty = true;
      });
    } else {
      tableData.forEach((tD) =>
        [
          tD.firstName,
          tD.lastName,
          tD.email && validator.isEmail(tD.email),
          tD.admissionDate,
        ].forEach((f) => {
          if (!f) somethingEmpty = true;
        }),
      );
    }

    return !somethingEmpty;
  };

  enum EInputType {
    INPUT = 'input',
    DROPDOWN = 'dropdown',
    DATE = 'date',
  }
  const editableColumn = (
    id: number,
    placeholder: string,
    currentValue: string,
    displayValue: string,
    field: keyof TUserCompanyProfileRequest,
    inputType: EInputType,
    dropdownOptions?: { value: string | number; title: string }[] | null,
    validationFn?: (val: any) => 'error' | 'success',
  ) => {
    let input = (
      <Input
        placeholder={placeholder}
        defaultValue={currentValue}
        onChange={(e) => onEdit(id, field, e.target.value)}
      />
    );

    if (inputType === EInputType.DROPDOWN) {
      input = (
        <Select
          defaultValue={currentValue}
          onChange={(value) => onEdit(id, field, value)}
          placeholder={placeholder}
        >
          {dropdownOptions?.map((dO) => (
            <Option value={dO.value}>{dO.title}</Option>
          ))}
        </Select>
      );
    }
    if (inputType === EInputType.DATE) {
      const inputKey = `${field}-${id}`;
      const minDate = getMinDateOfBirth();
      const additionalProps = field === 'birthDate' ? {
        defaultPickerValue: dayjs(minDate),
        disabledDate: (d: any) => !d || d.isAfter(minDate),
      } : {
        defaultPickerValue: dayjs(),
      };

      input = (
        <DatePicker
          key={fieldsKeys[inputKey] || inputKey}
          placeholder={placeholder}
          allowClear={false}
          defaultValue={
            dayjs(displayValue, 'DD/MM/YYYY').isValid()
              ? dayjs(displayValue, 'DD/MM/YYYY')
              : undefined
          }
          format="DD/MM/YYYY"
          onChange={(val) => {
            onEdit(id, field, val ? dayjs(val).format('YYYY-MM-DD') : val);

            if (!val) {
              setFieldsKeys({
                ...fieldsKeys,
                [inputKey]: `${inputKey}-${+new Date()}`,
              });
            }
          }}
          {...additionalProps}
        />
      );
    }

    return editable.indexOf(id) !== -1 || manageMode ? (
      <Form.Item
        key={id}
        style={{ margin: 0 }}
        validateStatus={validationFn?.(currentValue)}
      >
        {input}
      </Form.Item>
    ) : (
      displayValue
    );
  };

  const columns = [
    ...(manageMode || editable.length
      ? [
          {
            title: t('employees.name'),
            dataIndex: 'firstName',
            render: (_: string, record: TTableData) =>
              editableColumn(
                record.id,
                t('employees.name'),
                record.firstName,
                record.firstName,
                'firstName',
                EInputType.INPUT,
              ),
          },
          {
            title: t('employees.surname'),
            dataIndex: 'lastName',
            render: (_: string, record: TTableData) =>
              editableColumn(
                record.id,
                t('employees.surname'),
                record.lastName,
                record.lastName,
                'lastName',
                EInputType.INPUT,
              ),
          },
        ]
      : [
          {
            title: t('employees.userId'),
            dataIndex: 'userId',
            sorter: true,
          },
          {
            title: t('employees.employee'),
            dataIndex: 'firstName',
            render: (_: string, record: TTableData) => (
              <Button
                type="link"
                onClick={() => {
                  setCurrentUser(record.userId);
                  setEditWindow(true);
                }}
              >
                {`${record.firstName} ${record.lastName}`}
              </Button>
            ),
            sorter: true,
          },
        ]),
    {
      title: t('employees.email'),
      dataIndex: 'companyEmail',
      render: (_: string, record: TTableData) =>
        editableColumn(
          record.id,
          t('employees.email'),
          record.email,
          record.email,
          'companyEmail',
          EInputType.INPUT,
          null,
          (val) => {
            if (!val) return 'success';
            return val && validator.isEmail(val) ? 'success' : 'error';
          },
        ),
      sorter: true,
    },
    {
      title: t('employees.birthdate'),
      dataIndex: 'birthDate',
      render: (_: string, record: TTableData) =>
        editableColumn(
          record.id,
          '[dd/mm/yyyy]',
          record.birthDate || '',
          record.birthDate && record.birthDate !== null
            ? dayjs(record.birthDate).toDate().toLocaleDateString()
            : '',
          'birthDate',
          EInputType.DATE,
        ),
      sorter: true,
    },
    {
      title: t('employees.gender'),
      dataIndex: 'gender',
      render: (_: string, record: TTableData) => (
        <div className={styles.genderField}>
          {editableColumn(
            record.id,
            t('employees.placeholder.pleaseSelect'),
            record.gender || 'none',
            t(`employees.${record.gender || 'none'}`),
            'gender',
            EInputType.DROPDOWN,
            [
              { title: t('employees.none'), value: 'none' },
              { title: t('employees.male'), value: 'male' },
              { title: t('employees.female'), value: 'female' },
            ],
          )}
        </div>
      ),
      filters: [
        { value: 'male', text: t('employees.male') },
        { value: 'female', text: t('employees.female') },
        { value: 'none', text: t('employees.none') },
      ],
    },
    {
      title: t('employees.docType'),
      dataIndex: 'docType',
      render: (_: string, record: TTableData) =>
        editableColumn(
          record.id,
          t('employees.docType'),
          record.docType,
          record.docType,
          'docType',
          EInputType.DROPDOWN,
          [
            { title: 'NIE', value: 'NIE' },
            { title: 'NIF', value: 'NIF' },
          ],
        ),
      filters: [
        { value: 'NIE', text: 'NIE' },
        { value: 'NIF', text: 'NIF' },
      ],
    },
    {
      title: t('employees.docNumber'),
      dataIndex: 'docNumber',
      render: (_: string, record: TTableData) =>
        editableColumn(
          record.id,
          t('employees.docNumber'),
          record.docNumber,
          record.docNumber,
          'docNumber',
          EInputType.INPUT,
        ),
    },
    {
      title: t('employees.admissionDate'),
      dataIndex: 'admissionDate',
      render: (_: string, record: TTableData) =>
        editableColumn(
          record.id,
          '[dd/mm/yyyy]',
          record.admissionDate,
          new Date(record.admissionDate).toLocaleDateString(),
          'admissionDate',
          EInputType.DATE,
        ),
      sorter: true,
    },
  ];

  const tableActionsRender = (record: TTableData) => {
    if (editable.indexOf(record.id) !== -1 && !manageMode) {
      return (
        <Button
          type="link"
          disabled={!validateData(record.id)}
          onClick={() => {
            onRemoveEditable(record.id);
            onSave();
          }}
        >
          {t('generic.modals.save')}
        </Button>
      );
    }
    if (manageMode) {
      return (
        <div
          className={styles.actionButton}
          onClick={() => onRemoveUser(record.id)}
        >
          <DeleteOutlined />
        </div>
      );
    }
    return (
      <>
        <div
          className={styles.actionButtonPrimary}
          onClick={() => onSetEditable(record.id)}
        >
          <EditOutlined />
        </div>
        <DeleteUserModal
          userName={`${record.firstName} ${record.lastName}`}
          onClick={() => onDeleteSingle(record.id)}
        >
          <div className={styles.actionButton}>
            <DeleteOutlined />
          </div>
        </DeleteUserModal>
      </>
    );
  };

  if (enableForRoles(authenticatedUser.role as UserRoles, [UserRoles.ADMIN])) {
    columns.push({
      title: t('generic.actions'),
      dataIndex: 'actions',
      width: '104px',
      className: styles.actionColumn,
      render: (_: null, record: TTableData) => (
        <div className={styles.tableActions}>{tableActionsRender(record)}</div>
      ),
    } as any);
  }

  return (
    <>
      <UserForm
        currentUser={currentUser}
        show={editWindow}
        onClose={() => {
          setEditWindow(false);
          setCurrentUser(null);
        }}
        onDelete={(id: number) => () => {
          const user = employeeList?.find((u) => u.userId === id);
          if (user) {
            dispatch<any>(usersAsyncActions.delete({ item: user.user as any }));
            setEditWindow(false);
          }
        }}
      />
      <Tabs type="card" className={styles.tabs}>
        <TabPane
          tab={t('employees.employeesList')}
          key="1"
          className={styles.tab}
        >
          {currentCompany?.totalEmployees || usersCreate.length ? (
            <div>
              <div className={styles.buttonBar}>
                <div className={styles.title}>
                  {t('employees.allEmployees')} (
                  {currentCompany?.totalEmployees})
                </div>
                <div>
                  {!manageMode ? (
                    <>
                      <Search
                        allowClear
                        className={styles.searchField}
                        placeholder={t('employees.search') || ''}
                        onSearch={(val: string) => {
                          dispatch(actions.setSearch({ query: val }));
                          dispatch(
                            actions.setPagination({
                              page: 1,
                              perPage:
                                usersData.listParams?.pagination?.perPage || 10,
                            }),
                          );
                          dispatch<any>(
                            asyncActions.fetchList({ id: companyId }),
                          );
                        }}
                      />
                      <RoleWrapper roles={[UserRoles.ADMIN]}>
                        <Upload
                          name="file"
                          action={`${config.BACKEND_URL}/v2/user-company-profiles/${companyId}/import-employees`}
                          headers={defaultHeaders()}
                          showUploadList={false}
                          accept=".xlsx, .xls, .csv, .numbers"
                          onChange={(info: any) => {
                            if (info.file.status === 'done') {
                              dispatch<any>(
                                companiesAsyncActions.get({ id: companyId }),
                              );
                              dispatch<any>(
                                asyncActions.fetchList({ id: companyId }),
                              );
                            }
                          }}
                        >
                          <Button className={styles.importButton}>
                            {t('collective.company.buttons.importEmployees')}
                          </Button>
                        </Upload>
                      </RoleWrapper>
                      <RoleWrapper roles={[UserRoles.ADMIN]}>
                        <Button
                          className={styles.manageButton}
                          onClick={() => setManageMode(true)}
                        >
                          {t('collective.company.buttons.manageEmployees')}
                        </Button>
                      </RoleWrapper>
                    </>
                  ) : (
                    <>
                      <Button
                        className={styles.addButton}
                        onClick={onNewUser}
                        icon={<PlusOutlined />}
                      >
                        {t('employees.addEmployee')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          onSave();
                          setManageMode(false);
                          setEditable([]);
                        }}
                        disabled={!validateData()}
                      >
                        {t('generic.modals.save')}
                      </Button>
                      <Button
                        className={styles.cancelButton}
                        onClick={() => {
                          setManageMode(false);
                          setEditable([]);
                          setUsersRemove([]);
                          setUsersEdit([]);
                          setUsersCreate([]);
                          setFieldsKeys({});
                        }}
                      >
                        {t('generic.modals.buttons.cancel')}
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <Form>
                <Table
                  enableUrlChange={false}
                  actions={actions}
                  asyncActions={asyncActions}
                  className={styles.table}
                  dataSource={tableData}
                  columns={columns}
                  pagination={{
                    total: manageMode
                      ? tableData.length
                      : usersData.listParams?.pagination?.total,
                    current: usersData.listParams?.pagination?.page,
                    pageSize: manageMode
                      ? tableData.length
                      : usersData.listParams?.pagination?.perPage || 0,
                  }}
                  fetchListActionParams={{ id: companyId }}
                  customOrderHandler={(sorter) => {
                    if (sorter.field === 'firstName') {
                      dispatch(actions.clearOrder());
                      dispatch(
                        actions.setFilter({
                          field: 'orderByName',
                          value: sorter.order === 'descend' ? 'DESC' : 'ASC',
                          custom: true,
                        }),
                      );
                    }

                    if (sorter.field === 'birthDate') {
                      dispatch(actions.clearOrder());
                      dispatch(
                        actions.setFilter({
                          field: 'orderByBirthdate',
                          value: sorter.order === 'descend' ? 'DESC' : 'ASC',
                          custom: true,
                        }),
                      );
                    }

                    dispatch(
                      actions.setOrder({
                        field: sorter.field,
                        direction: sorter.order === 'descend' ? 'desc' : 'asc',
                      }),
                    );
                  }}
                  customFiltersHandler={(filters) => {
                    Object.keys(filters).forEach((field: any) => {
                      if (filters[field]?.length) {
                        if (field === 'gender') {
                          dispatch(
                            actions.setFilter({
                              field: 'filterByGender',
                              value: JSON.stringify(filters[field]),
                              custom: true,
                            }),
                          );
                        } else if (field === 'docType') {
                          dispatch(
                            actions.setFilter({
                              field: 'filterDocType',
                              value: JSON.stringify(filters[field]),
                              custom: true,
                            }),
                          );
                        } else {
                          filters[field].forEach((filter: any) => {
                            dispatch(
                              actions.setFilter({ field, value: filter }),
                            );
                          });
                        }
                      }
                    });
                  }}
                />
              </Form>
            </div>
          ) : (
            <Empty
              className={styles.empty}
              image="/assets/user/no-users-icon.svg"
              description={t('employees.noEmployees')}
            >
              <RoleWrapper roles={[UserRoles.ADMIN]}>
                <>
                  <Button
                    className={styles.noUserButton}
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      setManageMode(true);
                      onNewUser();
                    }}
                  >
                    {t('employees.addEmployees')}
                  </Button>
                  <Upload
                    name="file"
                    action={`${config.BACKEND_URL}/v2/user-company-profiles/${companyId}/import-employees`}
                    headers={defaultHeaders()}
                    showUploadList={false}
                    accept=".xlsx, .xls, .csv, .numbers"
                    onChange={(info: any) => {
                      if (info.file.status === 'done') {
                        dispatch<any>(
                          companiesAsyncActions.get({ id: companyId }),
                        );
                        dispatch<any>(
                          asyncActions.fetchList({ id: companyId }),
                        );
                      }
                    }}
                  >
                    <Button className={styles.importButton}>
                      {t('collective.company.buttons.importEmployees')}
                    </Button>
                  </Upload>
                </>
              </RoleWrapper>
            </Empty>
          )}
        </TabPane>
        <TabPane
          tab={t('employees.productbyEmployee')}
          key="2"
          className={styles.tab}
        >
          <ProductsList companyId={companyId} />
        </TabPane>
      </Tabs>
    </>
  );
}

export default EmployeeList;
