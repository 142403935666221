import React from 'react';
import { ReactSVG } from 'react-svg';
import styles from './SideBackgroundImage.module.scss';

type TSideBackgroundImageProps = {
  leftImage?: string;
  rightImage?: string;
  children: JSX.Element;
};

function SideBackgroundImage({
  leftImage,
  rightImage,
  children,
}: TSideBackgroundImageProps) {
  return (
    <div>
      {leftImage && <ReactSVG className={styles.leftImage} src={leftImage} />}
      {rightImage && (
        <ReactSVG className={styles.rightImage} src={rightImage} />
      )}
      {children}
    </div>
  );
}

export default SideBackgroundImage;
