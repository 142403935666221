import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Badge, Button, DatePicker, Input, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'antd/es/table/interface';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { actions, asyncActions, selectors } from '../UsersSlice';
import { TUser, TUserCompany } from '../UsersTypes';
import styles from './UserList.module.scss';
import PageHeaderDefault from '../../../components/PageHeaderDefault/PageHeaderDefault';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../constants/requestStatuses';
import BulkActions from '../../../components/BulkActions/BulkActions';
import DeleteUserModal from '../../../components/Modals/UsersModals/DeleteUserModal/DeleteUserModal';
import UserForm from '../UserForm/UserForm';
import ResendInvitationModal from '../../../components/Modals/UsersModals/ResendInvitationModal/ResendInvitationModal';
import DeleteUserBulkModal from '../../../components/Modals/UsersModals/DeleteUserBulkModal/DeleteUserBulkModal';
import {
  asyncActions as companiesAsyncActions,
  selectors as companiesSelectors,
} from '../../collective/companies/AllCompaniesSlice';
import Table from '../../../components/Table/Table';
import useQuery from '../../../helpers/useQuery';

const { Search } = Input;
const { RangePicker } = DatePicker;

type TTableData = {
  key: Key;
  id: TUser['id'];
  userName: string;
  email: TUser['email'];
  type: string;
  companyName: string;
  createdAt: string;
  invitationStatus: string;
  actions: null;
};

type TFilter = {
  value: number | string;
  text: string;
}[];

function UserList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { id: currentUserId } = useParams();

  const query = useQuery();

  const isExistingUser = useMemo(
    () => !!currentUserId && currentUserId !== 'new',
    [currentUserId],
  );

  // selectors
  const usersData = useSelector(selectors.listData);
  const statuses = useSelector(selectors.statuses);
  const bulkRewards = useSelector(selectors.bulkItems);
  const actionRewards = useSelector(selectors.actionItems);
  const companiesData = useSelector(companiesSelectors.listData);

  // effects
  useLayoutEffect(() => {
    dispatch(actions.clearCreatedAtRange());
    dispatch(actions.clearFilters());
    dispatch<any>(companiesAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';

      dispatch(
        actions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    if (query.has('createdAtStart') && query.has('createdAtEnd')) {
      dispatch(
        actions.setCreatedAtRange({
          createdAtStart: query.get('createdAtStart') || '',
          createdAtEnd: query.get('createdAtEnd') || '',
        }),
      );
    } else {
      dispatch(actions.clearCreatedAtRange());
    }

    if (query.has('invitationStatus')) {
      const filterStatuses = (query.get('invitationStatus') || '').split(',');

      for (const status of filterStatuses) {
        dispatch(
          actions.setFilter({
            field: 'invitationStatus',
            value: status,
            custom: true,
          }),
        );
      }
    }

    if (query.has('isEmployee')) {
      dispatch(
        actions.setFilter({
          field: 'isEmployee',
          value: query.get('isEmployee') || '0',
          custom: true,
        }),
      );
    }

    if (query.has('companies')) {
      const filterCompanies = (query.get('companies') || '').split(',');

      dispatch(
        actions.setFilter({
          field: 'filterByCompanyName',
          value: JSON.stringify(filterCompanies),
          custom: true,
        }),
      );
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearCreatedAtRange());
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (usersData?.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, usersData]);

  // status popup
  useEffect(() => {
    const { length } = bulkRewards.delete;
    if (statuses.deleteBulk === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        length > 1
          ? t('users.alerts.bulkPopupPrural', {
              length,
              operation: t('generic.operations.deleted'),
            })
          : t('users.alerts.bulkPopupSingular', {
              length,
              operation: t('generic.operations.deleted'),
            }),
      );
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  useEffect(() => {
    const user = actionRewards.delete;
    if (statuses.delete === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        t('users.alerts.singualarPopup', {
          title: `${user?.firstName} ${user?.lastName}`,
          operation: t('generic.operations.deleted'),
        }),
      );
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  // state
  const [editWindow, setEditWindow] = useState(false);
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [currentUser, setCurrentUser] = useState(null as TUser['id'] | null);
  const [companiesList, setCompaniesList] = useState([] as TFilter);

  useEffect(() => {
    if (companiesData?.list) {
      const companiesNames: TFilter = companiesData.list.map((company) => ({
        value: company.companyName,
        text: company.companyName,
      }));

      setCompaniesList(companiesNames);
    }
  }, [companiesData]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(actions.clearCurrentItem());

      setCurrentUser(isExistingUser ? +currentUserId : null);
      setEditWindow(true);
    }
  }, [currentUserId]);

  const selectedUsers =
    usersData.list?.filter(({ id }) => selectedIds.indexOf(id) !== -1) || [];

  // actions
  const onDelete = (id: TUser['id']) => () => {
    const user = usersData.list?.find((a) => a.id === id);
    if (user) {
      dispatch<any>(asyncActions.delete({ item: user }));
    }
  };

  // table data
  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };
  const actionsBlock = (id: TUser['id'], userName: string) => (
    <div className={styles.tableActions}>
      <Link
        to={`/users/${id}?${query.toString()}`}
        className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
      >
        <EditOutlined />
      </Link>
      <DeleteUserModal userName={userName} onClick={onDelete(id)}>
        <div className={styles.actionButton}>
          <DeleteOutlined />
        </div>
      </DeleteUserModal>
    </div>
  );

  const getInvitationStatus = (
    user: TUserCompany,
  ): 'pending' | 'expired' | 'inactive' | 'activated' => {
    if (user.userPasswordResetToken) {
      const { expires } = user.userPasswordResetToken;
      const now = dayjs();
      if (dayjs(expires).diff(now, 'seconds', true) < 0) {
        return 'expired';
      }
      return 'pending';
    }

    if (user.activatedAt) {
      return 'activated';
    }

    return 'inactive';
  };

  const tableData: TTableData[] =
    usersData.list?.map((item) => ({
      key: item.id,
      id: item.id,
      userName: `${item.firstName} ${item.lastName}`,
      email: item.email,
      type: item.companyProfile
        ? t('generic.companyUser')
        : t('generic.appUser'),
      companyName: item.company?.companyName || '-',
      createdAt: dayjs(item.createdAt).format('DD/MM/YYYY'),
      invitationStatus: getInvitationStatus(item),
      actions: null,
    })) || [];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      ...(query.has('sort') &&
      ['id', '-id'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-id' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('users.userName'),
      dataIndex: 'firstName',
      sorter: true,
      render: (_: string, record: TTableData) => (
        <Link
          to={`/users/${record.id}?${query.toString()}`}
          className={styles.userTitle}
        >
          {record.userName.length > 30 ? (
            <Tooltip
              placement="top"
              title={record.userName}
            >{`${record.userName.slice(0, 30)}...`}</Tooltip>
          ) : (
            record.userName
          )}
        </Link>
      ),
      ...(query.has('sort') &&
      ['firstName', '-firstName'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-firstName' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
      sorter: true,
      ...(query.has('sort') &&
      ['email', '-email'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-email' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('users.typeOfUser'),
      dataIndex: 'type',
      filters: [
        { value: 'appUser', text: t('generic.appUser') },
        { value: 'companyUser', text: t('generic.companyUser') },
      ],
      filterMultiple: false,
      ...(query.has('isEmployee')
        ? {
            defaultFilteredValue:
              query.get('isEmployee') === '1' ? 'companyUser' : 'appUser',
          }
        : {}),
    },
    {
      title: t('users.companyName'),
      dataIndex: 'companies',
      filters: companiesList,
      render: (_: string, record: TTableData) => record.companyName,
      ...(query.has('companies') && companiesList?.length
        ? {
            defaultFilteredValue: query.get('companies')?.split(','),
          }
        : {}),
    },
    {
      title: t('generic.creationDate'),
      dataIndex: 'createdAt',
      sorter: true,
      ...(query.has('sort') &&
      ['createdAt', '-createdAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-createdAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('users.invitationStatus'),
      dataIndex: 'invitationStatus',
      filters: [
        { value: 'pending', text: t('generic.pending') },
        { value: 'expired', text: t('generic.expired') },
        { value: 'activated', text: t('generic.activated') },
      ],
      render: (
        status: 'pending' | 'expired' | 'inactive' | 'activated',
        record: TTableData,
      ) => {
        switch (status) {
          case 'pending': {
            return (
              <div className={styles.statusWrap}>
                <Badge
                  className={styles.statusBadge}
                  color="yellow"
                  text={t('generic.pending')}
                />
                <ResendInvitationModal
                  userName={record.userName}
                  onClick={() => {
                    dispatch(
                      asyncActions.singleRequests?.resendInvitation?.({
                        id: record.id,
                      }),
                    );
                    ActionAlert.success(
                      `Invitation email sent to ${record.userName}`,
                    );
                  }}
                >
                  <Button
                    className={styles.resendButton}
                    icon={<MailOutlined />}
                    type="link"
                  />
                </ResendInvitationModal>
              </div>
            );
          }
          case 'expired': {
            return (
              <div className={styles.statusWrap}>
                <Badge
                  className={styles.statusBadge}
                  status="error"
                  text={t('generic.expired')}
                />
                <ResendInvitationModal
                  userName={record.userName}
                  onClick={() => {
                    dispatch(
                      asyncActions.singleRequests?.resendInvitation?.({
                        id: record.id,
                      }),
                    );
                    ActionAlert.success(
                      t('users.tooltips.invitationSent', {
                        userName: record.userName,
                      }),
                    );
                  }}
                >
                  <Button
                    className={styles.resendButton}
                    icon={<MailOutlined />}
                    type="link"
                  />
                </ResendInvitationModal>
              </div>
            );
          }
          case 'inactive': {
            return <Badge status="default" text={t('generic.inactive')} />;
          }
          default: {
            return <Badge status="success" text={t('generic.activated')} />;
          }
        }
      },
      ...(query.has('invitationStatus')
        ? {
            defaultFilteredValue: query.get('invitationStatus')?.split(','),
          }
        : {}),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      render: (_: null, record: TTableData) =>
        actionsBlock(parseInt(record.key.toString(), 10), record.userName),
    },
  ];

  // bulk menu dropdown
  const dropdownOptions = [
    {
      key: 'delete',
      text: t('generic.delete'),
      action: () => {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedUsers }));
        setSelectedIds([]);
      },
      modal: (children: React.ReactElement, action: () => void) => (
        <DeleteUserBulkModal count={selectedIds.length} onClick={action}>
          {children}
        </DeleteUserBulkModal>
      ),
    },
  ];

  return (
    <div
      key={`users-list-${Array.from(query.keys()).length}-${
        companiesList.length
      }`}
    >
      <UserForm
        currentUser={currentUser}
        show={editWindow}
        onClose={() => {
          setEditWindow(false);
          setCurrentUser(null);

          navigate({
            pathname: '/users',
            search: location.state as string,
          });
        }}
        onDelete={onDelete}
      />
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('nav.category.users')]: '/users',
          [t('users.breadcrumbs.appUsers')]: '',
        }}
        title={t('users.breadcrumbs.usersList')}
      />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>{t('nav.item.userList')}</div>
        <div className={styles.buttonContainer}>
          <Search
            allowClear
            defaultValue={query.get('search') || ''}
            className={styles.searchField}
            placeholder={t('users.placeholder.searchForUser') || ''}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                usersData.listParams?.pagination?.perPage
                  ? usersData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
          />
          <RangePicker
            placeholder={[
              t('challenges.form.startDate'),
              t('challenges.form.endDate'),
            ]}
            defaultValue={[
              query.get('createdAtStart')
                ? dayjs(query.get('createdAtStart') as string)
                : null,
              query.get('createdAtEnd')
                ? dayjs(query.get('createdAtEnd') as string)
                : null,
            ]}
            className={styles.searchField}
            onChange={(val) => {
              if (val && !val.find((v) => !v)) {
                query.set(
                  'createdAtStart',
                  val[0]
                    ?.set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0)
                    .toISOString() || '',
                );
                query.set(
                  'createdAtEnd',
                  val[1]
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59)
                    .toISOString() || '',
                );
              } else {
                query.delete('createdAtStart');
                query.delete('createdAtEnd');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                usersData.listParams?.pagination?.perPage
                  ? usersData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
            format="DD/MM/YYYY"
          />
          <Button className={styles.manageButton} disabled>
            {t('users.buttons.manageRoles')}
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/users/new')}
          >
            {t('users.buttons.addUser')}
          </Button>
        </div>
      </div>
      {!!selectedIds.length && (
        <BulkActions
          dropdownOptions={dropdownOptions}
          selectedKeys={selectedIds.map((key) => key.toString())}
        />
      )}
      <Table
        actions={actions}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: usersData.listParams?.pagination?.total,
          current: usersData.listParams?.pagination?.page,
        }}
        customFiltersHandler={(filters, urlQuery) => {
          Object.keys(filters).forEach((field: any) => {
            if (filters[field]?.length) {
              filters[field].forEach((filter: any) => {
                if (field === 'type') {
                  urlQuery.set(
                    'isEmployee',
                    filter === 'companyUser' ? '1' : '0',
                  );
                } else {
                  urlQuery.set(field, filters[field].join(','));
                }
              });
            } else if (field === 'type') {
              urlQuery.delete('isEmployee');
            } else {
              urlQuery.delete(field);
            }
          });
        }}
      />
    </div>
  );
}

export default withTranslation()(UserList);
