import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import styles from './ResetPasswordUser.module.scss';
import {
  resetUserPasswordAsync,
  selectResetPasswordStatus,
} from '../AuthSlice';
import requestStatuses from '../../../constants/requestStatuses';
import { ROUTES } from '../../../constants/routes';
import characters from '../../../assets/svg/characters.svg';

const { Item } = Form;

function ResetPasswordUser() {
  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
  const isContainsNumber = /^(?=.*[0-9])/;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useParams() as { token: string };
  const navigate = useNavigate();

  const status = useSelector(selectResetPasswordStatus);

  const [password, setPassword] = useState(null as string | null);
  const [confirmPassword, setConfirmPassword] = useState(null as string | null);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (status === requestStatuses.SUCCESS && sent) {
      navigate(ROUTES.finish, {
        state: {
          screen: 'ResetPasswordUser',
        },
      });
    }
  });

  const onSend = () => {
    if (token && password && !isSubmitButtonDisabled()) {
      dispatch<any>(resetUserPasswordAsync({ password, code: token }));
    }
  };

  const isSubmitButtonDisabled = () => {
    if (
      password !== null
      && password !== ''
      && password.length >= 8
      && isContainsSymbol.test(password)
      && isContainsNumber.test(password)
      && password === confirmPassword
    ) return false;
    return true;
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.wrapperLeft}>
        <div style={{ width: '100%' }}>
          <Link to="/" className={styles.logoContainer}>
            <ReactSVG className={styles.logo} src="/assets/general/logo.svg" />
            <div className={styles.logoText}>Vitaance</div>
          </Link>
        </div>
        <div className={styles.centralCard}>
          <div className={styles.title}>
            {t('resetPassword.resetUserTitle')}
          </div>
          <div className={styles.helpText}>
            {t('resetPassword.userResetHelpText')}
          </div>
          <Form className={styles.form} layout="vertical">
            <Item label={t('auth.newPassword')}>
              <Input.Password
                className={styles.input}
                placeholder={t('auth.inputPassword') || ''}
                onChange={(e) => setPassword(e.target.value)}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                required
              />
            </Item>
            <Item label={t('auth.CreateAccount.CreateAccount.repeatPassword')}>
              <Input.Password
                className={styles.input}
                placeholder={t('auth.inputPassword') || ''}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                required
              />
              <div className={styles.hintText}>
                {t('resetPassword.userResetHint')}
              </div>
            </Item>
            {status === requestStatuses.FAILED && (
              <div className={styles.errorMessage}>{t('auth.expiredLink')}</div>
            )}
            {confirmPassword && confirmPassword !== password && (
              <div className={styles.errorMessage}>
                {t('auth.passwordMatchError')}
              </div>
            )}
            <Item>
              <Button
                onClick={() => {
                  setSent(true);
                  onSend();
                }}
                htmlType="submit"
                className={
                  isSubmitButtonDisabled()
                    ? styles.myDisabledButton
                    : styles.signInButton
                }
                type="primary"
                disabled={isSubmitButtonDisabled()}
              >
                {t('generic.reset')}
              </Button>
            </Item>
          </Form>
        </div>

        <div className={styles.legalBlock}>
          <div>{t('generic.copyright')}</div>
          <div>
            <a
              href="https://www.vitaance.com/aviso-legal"
              target="_blank"
              rel="noreferrer"
            >
              {t('generic.legalDocs')}
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.vitaance.com/politica-de-privacidad-vitaance"
              target="_blank"
              rel="noreferrer"
            >
              {t('generic.privacyPolicy')}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.wrapperRight}>
        <span className={styles.mainTitle}>
          {t('auth.onboarding.rightColumnText')}
        </span>
        <img src={characters} style={{ width: '90%' }} />
      </div>
    </div>
  );
}

export default ResetPasswordUser;
