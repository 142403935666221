import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import AppStoresBadges from '../../../components/Badge/AppStoresBadges/AppStoresBadges';
import ContactLink from '../../../components/ContactLink/ContactLink';
import VisitWebsite from '../../../components/VisitWebsite/VisitWebsite';
import VLogo from '../../../components/VLogo/VLogo';
import { Config } from '../../../config/config';
import styles from './AlreadyRegistered.module.scss';
import character from '../../../assets/svg/character-1.svg';
import { selectCurrentLang, setCurrentLanguage } from '../../i18n/i18nSlice';
const { Option } = Select;

import SpainFlag from '../../../../src/assets/svg/spain.svg'
import UKFlag from '../../../assets/svg/united-kingdom.svg'
import EarthIcon from '../../../assets/svg/earth-home-world.svg'

function AlreadyRegistered() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector(selectCurrentLang);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.rowLeft}>
          <div className={styles.header}>
            <div className={styles.logoPos}>
              <VLogo />
            </div>
            <div className={styles.headerContainer}>
              <Select
                className={styles.langSelector}
                defaultValue={Config.I18N.DEFAULT_LANGUAGE}
                value={
                  lang ||
                  Config.I18N.DEFAULT_LANGUAGE ||
                  Config.I18N.LOCALES[0]
                }
                onSelect={(language) => {
                  dispatch(setCurrentLanguage({ language }));
                }}
              >
                {Config.I18N.LOCALES.map((loc) => (
                  <Option value={loc} key={loc}>{
                    <div style={{ width: windowSize.width < 876 ? 40 : 80, flex: 1, flexDirection: 'row', display: 'flex' }}>
                      <div style={{ width: windowSize.width < 876 ? '60%' : '30%' }}>
                        <img
                          src={loc === 'es' ? SpainFlag : UKFlag}
                          alt=''
                          height={20}
                          width={20}
                        />
                      </div>
                      <div style={{ width: windowSize.width < 876 ? '40%' : '50%' }}>
                        <a className={styles.langText}>
                          {
                            windowSize.width < 876
                              ? loc.toUpperCase()
                              : (loc === 'es' ? 'Español' : 'English')
                          }
                        </a>
                      </div>
                    </div>
                  }
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div className={styles.innerRowLeft}>

            <div className={styles.textContainer}>
              <span className={styles.titleText}>
                {t('auth.onboarding.alreadyRegistered.title')}
              </span>
            </div>

            <div className={styles.textContainer}>
              <span className={styles.subtitleText}>
                {t('auth.onboarding.alreadyRegistered.subtitle')}
              </span>
            </div>

            <div className={styles.textContainer}>
              <span className={styles.subtitleText}>
                {t('auth.onboarding.alreadyRegistered.storesInfo')}
              </span>
            </div>

            <AppStoresBadges showScanQrCodeText={false} />

            <div className={styles.contacts}>
              <ContactLink
                message={t('auth.onboarding.alreadyRegistered.contact')}
              />
            </div>

            <div className={styles.footer}>
              <VisitWebsite />
              <div className={styles.footerContainer}>
                <p className={styles.policy}>{t('generic.copyright')}</p>
                <p className={styles.copyrightUnderline}>
                  <a href={Config.LEGAL} target="_blank" rel="noreferrer">
                    <span>{t('generic.legalDocs')}</span>
                  </a>
                  {' | '}
                  <a
                    href={Config.PRIVACY_POLICY}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{t('generic.privacyPolicy')}</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rowRight}>
          <img
            src={character}
            alt={`${t('auth.onboarding.alreadyRegistered.title')}`}
          />
        </div>
      </div>
    </div>
  );
}

export default AlreadyRegistered;
