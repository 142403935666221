import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TPublishArticleModalProps = {
  challengeName: string;
  children: JSX.Element;
  onClick: () => void;
  type: string,
};

function PublishChallengeModal({
  challengeName,
  children,
  onClick,
  type
}: TPublishArticleModalProps) {
  const { t } = useTranslation();

  const config = {
    title: type === 'quiz' ? t('quizzes.modals.publishModalTitle') : t('challenges.modal.publish.title'),
    okText: t('generic.modals.buttons.publish'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: type === 'quiz' ? t('quizzes.modals.publishModalContent', {quizName: challengeName}) : t('challenges.modal.publish.content', { name: challengeName }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM}
    />
  );
}

export default PublishChallengeModal;
