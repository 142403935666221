import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import styles from './ChallengeEngagement.module.scss';
import { actions, selectors, asyncActions } from './ChallengeEngagementSlice';
import { EChallengeTypes } from '../../challenges/ChallengesTypes';
import ModalCenter from '../../../components/ModalCenter/ModalCenter';
import Table from '../../../components/Table/Table';
import { TCategory } from '../../categories/CategoriesTypes';

const { Search } = Input;

type TChallengeEngagementProps = {
  show: boolean;
  onClose: () => void;
  totalUsers: number;
  filterType: 'allUsers' | 'companyUsers' | 'appUsers';
  createdAtStart: string;
  createdAtEnd: string;
  companyId?: number | null;
};

type TTableData = {
  key: number;
  titleImage: string | null;
  title: string;
  usersNumber: number;
  category: string;
  goalType: string;
  rewardInPoints: number;
  challengeType: EChallengeTypes;
};

function ChallengeEngagement({
  show,
  onClose,
  totalUsers,
  filterType,
  companyId,
  createdAtStart,
  createdAtEnd,
}: TChallengeEngagementProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* DATA STORE */
  const challenges = useSelector(selectors.listData);
  const filterData = useSelector(selectors.filterData) as
    | { categories: TCategory[] }
    | undefined;

  /* STATE */
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  /* EFFECTS */
  useEffect(() => {
    if (show) {
      dispatch(actions.setSearch({ query: '' }));
      dispatch(actions.setPagination({ page: 1, perPage: 10 }));
      dispatch(actions.clearOrder());
      dispatch(actions.clearFilters());
      dispatch(actions.setOrder({ field: 'usersNumber', direction: 'desc' }));
      dispatch(actions.addOrder({ field: 'title', direction: 'asc' }));
      dispatch(actions.setFilter({ field: 'filterType', value: filterType }));
      dispatch(
        actions.setFilter({
          field: 'createdAtStart',
          value: createdAtStart,
          custom: true,
        }),
      );
      dispatch(
        actions.setFilter({
          field: 'createdAtEnd',
          value: createdAtEnd,
          custom: true,
        }),
      );
      if (companyId) {
        dispatch(
          actions.setFilter({
            field: 'companyId',
            value: companyId.toString(),
          }),
        );
      }
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, show]);

  useEffect(() => {
    function setHeight() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  /* TABLE DATA */
  const tableData: TTableData[] =
    challenges.list?.map((item) => ({
      key: item.id,
      titleImage: item.titleImage,
      title: item.title,
      usersNumber: item.numberOfJoinedUsers,
      category: item.category?.name,
      goalType: item.goalType,
      rewardInPoints: item.rewardInPoints,
      challengeType: item.type,
    })) || [];

  const columns = [
    {
      title: t('challenges.challengeName'),
      dataIndex: 'title',
      render: (value: string, record: TTableData) => (
        <div className={styles.challengeTitle}>
          <img
            className={styles.image}
            src={
              record.titleImage
                ? record.titleImage
                : '/assets/wellness/image-placeholder.png'
            }
            alt={value}
          />
          {`${value.slice(0, 26)}${value.length >= 26 ? '...' : ''}`}
        </div>
      ),
      sorter: true,
      width: 300,
    },
    {
      title: t('wellness.analytics.totalUsers'),
      dataIndex: 'usersNumber',
      render: (value: number) => (
        <div className={styles.totalUsers}>
          <div className={styles.usersNumber}>{value}</div>(
          <div className={styles.usersPercent}>
            {(() => {
              const usersNum = (100 / totalUsers) * value;
              return usersNum > 0 && usersNum < 1
                ? usersNum.toFixed(1)
                : Math.round((100 / totalUsers) * value);
            })()}
            %
          </div>
          )
        </div>
      ),
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: t('challenges.category'),
      dataIndex: 'category',
      filters:
        filterData?.categories?.map((c) => ({
          value: c.id,
          text: c.name,
        })) || null,
    },
    {
      title: t('challenges.goal'),
      dataIndex: 'goalType',
      render: (value: string) =>
        `${value.charAt(0)}${value.toLowerCase().slice(1)}`,
      filters: [
        { value: 'POINTS', text: t('challenges.points') },
        { value: 'DISTANCE', text: t('generic.distance') },
        { value: 'CALORIES', text: t('generic.caloriesFull') },
        { value: 'DURATION', text: t('generic.duration') },
        { value: 'STEPS', text: t('generic.steps') },
      ],
    },
    {
      title: t('challenges.points'),
      dataIndex: 'rewardInPoints',
    },
    {
      title: t('challenges.form.challengeType'),
      dataIndex: 'challengeType',
      render: (type: EChallengeTypes) => {
        const typeMap = {
          [EChallengeTypes.PERSONAL]: (
            <div>
              <UserOutlined className={styles.typeIcon} />
              {t('challenges.form.individual')}
            </div>
          ),
          [EChallengeTypes.TEAM]: (
            <div>
              <TeamOutlined className={styles.typeIcon} />
              {t('challenges.form.team')}
            </div>
          ),
          [EChallengeTypes.GROUP]: null,
        };

        return typeMap[type];
      },
      filters: [
        {
          value: EChallengeTypes.PERSONAL,
          text: t('challenges.form.individual'),
        },
        { value: EChallengeTypes.TEAM, text: t('challenges.form.team') },
      ],
    },
  ];

  return (
    <ModalCenter onClose={onClose} show={show} className={styles.modal}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>
            {t('wellness.analytics.allChallengeEngagement')}
          </div>
          <ReactSVG
            className={styles.closeButton}
            onClick={onClose}
            src="/assets/general/close-icon.svg"
          />
        </div>
        <div className={styles.searchBar}>
          <div className={styles.title}>{t('nav.item.challenges')}</div>
          <Search
            allowClear
            className={styles.searchField}
            placeholder={t('challenges.placeholder.searchChallenge')  || ''}
            onSearch={(val: string) => {
              dispatch(actions.setSearch({ query: val }));
              dispatch(
                actions.setPagination({
                  page: 1,
                  perPage: challenges.listParams?.pagination?.perPage || 10,
                }),
              );
              dispatch<any>(asyncActions.fetchList());
            }}
          />
        </div>
        <div className={styles.tableWrap}>
          <Table
            scroll={{ y: windowHeight - 279 - 214 }}
            columns={columns}
            dataSource={tableData}
            pagination={{
              showSizeChanger: false,
              total: challenges.listParams?.pagination?.total,
              current: challenges.listParams?.pagination?.page,
            }}
            actions={{
              ...actions,
              clearFilters: () => {
                dispatch(actions.clearFilters());
                dispatch(
                  actions.setFilter({ field: 'filterType', value: filterType }),
                );
                dispatch(
                  actions.setFilter({
                    field: 'createdAtStart',
                    value: createdAtStart,
                    custom: true,
                  }),
                );
                return actions.setFilter({
                  field: 'createdAtEnd',
                  value: createdAtEnd,
                  custom: true,
                });
              },
            }}
            asyncActions={asyncActions}
            customFiltersHandler={(filters) => {
              Object.keys(filters).forEach((field: any) => {
                if (filters[field]?.length) {
                  filters[field].forEach((filter: any) => {
                    dispatch(
                      actions.setFilter({ field, value: filter, custom: true }),
                    );
                  });
                }
              });
            }}
            withFilters={() => {
              dispatch(
                actions.setFilter({ field: 'filterType', value: filterType }),
              );
              if (companyId) {
                dispatch(
                  actions.setFilter({
                    field: 'companyId',
                    value: companyId.toString(),
                  }),
                );
              }
            }}
          />
        </div>
      </div>
    </ModalCenter>
  );
}

ChallengeEngagement.defaultProps = {
  companyId: null,
};

export default ChallengeEngagement;
