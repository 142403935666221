import {
  createProduct,
  deleteProduct,
  getProduct,
  updateProduct,
  activateProduct,
  deactivateProduct,
  getProductsByCompanyId,
  getProductCategories,
} from './ProductsApi';
import {
  TProduct,
  TProductResponse,
  TProductListResponse,
} from './ProductsTypes';
import buildSlice from '../../../../../general/helpers/genericSliceBuilder';

export const guaranteesSlice = buildSlice<
TProduct,
TProductResponse,
TProductListResponse
>('products', {
  createJson: createProduct as (data: { [key: string]: any }) => Promise<any>,
  create: createProduct as unknown as (data: FormData) => Promise<any>,
  update: updateProduct as unknown as (data: any) => Promise<any>,
  delete: deleteProduct,
  fetchList: getProductsByCompanyId,
  getOne: getProduct,
  updateJson: updateProduct as (
    id: number,
    data: { [key: string]: any },
  ) => Promise<any>,
  singleRequests: [
    {
      request: activateProduct,
      name: 'activateProduct',
    },
    {
      request: deactivateProduct,
      name: 'deactivateProduct',
    },
    {
      request: getProductCategories,
      name: 'getProductCategories',
    },
    // {
    //   request: () => {},
    //   name: 'saveAsDraft'
    // }
  ],
});

export const { selectors, asyncActions, actions } = guaranteesSlice;

export default guaranteesSlice.reducer;
