import React from 'react';
import { useTranslation } from 'react-i18next';
import { SendOutlined } from '@ant-design/icons';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';
import styles from './ResendInvitationModal.module.scss';

type TResendInvitationModalProps = {
  userName: string;
  children: JSX.Element;
  onClick: () => void;
};

function ResendInvitationModal({
  userName,
  children,
  onClick,
}: TResendInvitationModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('adminUsers.modals.resendInvitation.title', { userName }),
    okText: t('adminUsers.modals.resendInvitation.sendButton'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('adminUsers.modals.resendInvitation.content'),
    icon: <SendOutlined className={styles.icon} />,
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default ResendInvitationModal;
