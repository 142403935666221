import { TActiveUsers, TStatistics } from './HomePageTypes';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchStatistics, getActiveUsers } from './HomePageApi';

const actions = {
  GET_STATISTICS: 'getStatistics',
  GET_ACTIVE_USERS: 'getActiveUsers',
};

type TStatisticsState = {
  statistics: TStatistics | null;
  activeUsers: TActiveUsers | null;
};

const initialState: TStatisticsState = {
  statistics: null,
  activeUsers: null,
};

export const getStatisticsAsync = createAsyncThunk(
  actions.GET_STATISTICS,
  async () => fetchStatistics(),
);

export const getActiveUsersAsync = createAsyncThunk(
  actions.GET_ACTIVE_USERS,
  async () => getActiveUsers(),
);

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatisticsAsync.fulfilled, (state, action) => {
      const { data } = action.payload;

      state.statistics = data;
    });
    builder.addCase(getActiveUsersAsync.fulfilled, (state, action) => {
      const { data } = action.payload;

      state.activeUsers = data;
    });
  },
});

export const getStatistics = (state: { statistics: TStatisticsState }) =>
  state.statistics.statistics;
export const getActiveUsersSelector = (state: {
  statistics: TStatisticsState;
}) => state.statistics.activeUsers;

export default statisticsSlice.reducer;
