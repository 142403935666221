import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteAdminUserBulkModalProps = {
  count: number;
  children: JSX.Element;
  onClick: () => void;
};

function DeleteAdminUserBulkModal({
  count,
  children,
  onClick,
}: TDeleteAdminUserBulkModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('adminUsers.modals.delete.titleBulk'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t(
      count === 1
        ? 'adminUsers.modals.delete.contentBulkSingular'
        : 'adminUsers.modals.delete.contentBulk',
      { count },
    ),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
      revertButtons
    />
  );
}

export default DeleteAdminUserBulkModal;
