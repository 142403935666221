import styles from './Copyrights.module.scss';
import { useTranslation } from 'react-i18next';
import { Config } from '../../config/config';
import VisitWebsite from '../VisitWebsite/VisitWebsite';

function CopyrightsShort() {
  const { t } = useTranslation();
  return (
    <div>
      <VisitWebsite />
      <div className={styles.container}>
        <p className={styles.policy}>{t('generic.copyright')}</p>
        <p>
          <a href={Config.LEGAL} target="_blank">
            <span>{t('generic.legalDocs')}</span>
          </a>{' '}
          |{' '}
          <a href={Config.PRIVACY_POLICY} target="_blank">
            <span>{t('generic.privacyPolicy')}</span>
          </a>
        </p>
      </div>
    </div>
  );
}

export default CopyrightsShort;
