import { fetchAssignedProducts } from './UsersApi';
import {
  TAssignedProduct,
  TAssignedProductListResponse,
  TAssignedProductResponse,
} from './UsersTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

const assignedProductsSlice = buildSlice<
  TAssignedProduct,
  TAssignedProductResponse,
  TAssignedProductListResponse
>('assignedProducts', {
  fetchList: fetchAssignedProducts,
});

export const { selectors, asyncActions, actions } = assignedProductsSlice;

export default assignedProductsSlice.reducer;
