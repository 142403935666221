import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteCategoryModalProps = {
  categoryTitle: string;
  children: JSX.Element;
  onClick: () => void;
};

function DeleteCategoryModal({
  categoryTitle,
  children,
  onClick,
}: TDeleteCategoryModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('categories.modal.delete.title'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('categories.modal.delete.content', { categoryTitle }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeleteCategoryModal;
