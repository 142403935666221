import styles from './Heading.module.scss';

type HeadingProps = {
  heading: string;
};

function Heading({ heading }: HeadingProps) {
  return (
    <div className={styles.container}>
      <span className={styles.heading}>{heading}</span>
    </div>
  );
}

export default Heading;
