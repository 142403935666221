import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES } from '../../../constants/routes';
import styles from './CreateAccount.module.scss';
import Progress from '../../../components/Progress/Progress';
import VisitWebsite from '../../../components/VisitWebsite/VisitWebsite';
import { Config } from '../../../config/config';
import LeftRowText from '../../../components/LeftRowText/LeftRowText';
import VLogo from '../../../components/VLogo/VLogo';
import requestStatuses from '../../../constants/requestStatuses';
import {
  setUserPasswordAsync,
  selectSetUserPasswordStatus,
} from '../AuthSlice';

// Svg assets
import check from '../../../assets/svg/check.svg';
import two from '../../../assets/svg/two.svg';
import three from '../../../assets/svg/threeWhite.svg';
import characters from '../../../assets/svg/characters.svg';

function CreateAccount() {
  const { t } = useTranslation();
  const location = useLocation();
  const [password, setPassword] = useState(null as string | null);
  const [createPassword, setCreatePassword] = useState(null as string | null);
  const [sent, setSent] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
  const isContainsNumber = /^(?=.*[0-9])/;

  const status = useSelector(selectSetUserPasswordStatus);
  const data = location.state as any;
  const token = data?.token as string;
  const email = data?.email as string;

  useEffect(() => {
    if (status === requestStatuses.SUCCESS && sent) {
      navigate(ROUTES.finish);
    }
  }, [status]);

  const onSend = () => {
    if (
      token
      && password
      && password === createPassword
      && password.length >= 7
      && isContainsSymbol.test(password)
      && isContainsNumber.test(password)
    ) {
      dispatch<any>(setUserPasswordAsync({ password, token }));
    }
  };

  const isSubmitted = password === null
    || password === ''
    || password !== createPassword
    || password.length <= 7
    || !isContainsSymbol.test(password)
    || !isContainsNumber.test(password);

  const hasNumbers = (str: string) => /\d/.test(str);
  const hasSpecialCharacters = (str: string) => /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(str);

  return (
    <Row className={styles.container}>
      <div className={styles.row}>
        <Col className={styles.rowLeft}>
          <div className={styles.logoPos}>
            <VLogo />
          </div>
          <div className={styles.progressPosition}>
            <Progress
              checkOne={check}
              checkTwo={two}
              checkThree={three}
              one={styles.circularCompleted}
              two={styles.circularActive}
              three={styles.circularActive}
              oneText={styles.completedText}
              twoText={styles.activeText}
              threeText={styles.unActiveText}
              numberOne={styles.numberActive}
              numberTwo={styles.numberActive}
              numberThree={styles.numberActive}
              lineOne={styles.lineActive}
              lineTwo={styles.lineActive}
            />
          </div>

          <div className={styles.innerRowLeft}>
            <div className={styles.createAccContainer}>
              <span className={styles.createAccText}>
                {t('auth.CreateAccount.CreateAccount.title')}
              </span>
            </div>

            <div className={styles.userContainer}>
              <span className={styles.userText}>
                {`${t('auth.CreateAccount.CreateAccount.text')} `}
              </span>
              <span className={styles.userEmailText}>
                {email}
              </span>
            </div>

            <div className={styles.passwordSpacing}>
              <span className={styles.password}>
                {t('auth.CreateAccount.CreateAccount.password')}
              </span>

              <Form.Item>
                <Input.Password
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onPaste={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  required
                  style={{
                    borderRadius: 2,
                    marginTop: 8,
                  }}
                />
              </Form.Item>
            </div>

            <div className={styles.passwordSpacing}>
              <span className={styles.password}>
                {t('auth.CreateAccount.CreateAccount.repeatPassword')}
              </span>

              <Form.Item>
                <Input.Password
                  type="password"
                  onChange={(e) => setCreatePassword(e.target.value)}
                  onPaste={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  required
                  style={{
                    borderRadius: 2,
                    marginTop: 8,
                  }}
                />
              </Form.Item>

              <div className={styles.rowCheckItem}>
                <div className={
                  createPassword && createPassword.length >= 8
                    ? styles.checkItemActive
                    : styles.checkItemUnactive
                }>
                  <img src={check} className={styles.checkLine} />
                </div>
                <div className={styles.checkText}>{t('auth.validations.pswEigthCharLong')}</div>
              </div>

              <div className={styles.rowCheckItem}>
                <div className={createPassword && hasNumbers(createPassword)
                  ? styles.checkItemActive
                  : styles.checkItemUnactive}
                >
                  <img src={check} className={styles.checkLine} />
                </div>
                <div className={styles.checkText}>{t('auth.validations.pswOneNumber')}</div>
              </div>

              <div className={styles.rowCheckItem}>
                <div className={createPassword && hasSpecialCharacters(createPassword)
                  ? styles.checkItemActive
                  : styles.checkItemUnactive}
                >
                  <img src={check} className={styles.checkLine} />
                </div>
                <div className={styles.checkText}>{t('auth.validations.pswOneSpecChar')}</div>
              </div>
            </div>

            <div className={styles.bottom}>
              <Button
                onClick={() => {
                  setSent(true);
                  onSend();
                }}
                htmlType="submit"
                className={
                  isSubmitted ? styles.myDisabledButton : styles.button
                }
                type="primary"
                disabled={password !== createPassword}
              >
                {t('auth.CreateAccount.CreateAccount.button')}
              </Button>
            </div>
          </div>

          <div className={styles.footer}>
            <VisitWebsite />
            <div className={styles.footerContainer}>
              <p className={styles.policy}>{t('generic.copyright')}</p>
              <p className={styles.copyrightUnderline}>
                <a href={Config.LEGAL} target="_blank" rel="noreferrer">
                  <span>{t('generic.legalDocs')}</span>
                </a>
                {' '}
                |
                {' '}
                <a href={Config.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                  <span>{t('generic.privacyPolicy')}</span>
                </a>
              </p>
            </div>
          </div>
        </Col>

        <Col className={styles.rowRight}>
          <LeftRowText
            textPos={styles.textPos}
            textStyle={styles.textStyle}
            text={t('auth.onboarding.rightColumnText')}
          />
          <img src={characters} style={{ width: '90%' }} />
        </Col>
      </div>
    </Row>
  );
}

export default CreateAccount;
