import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TPublishArticleModalProps = {
  children: JSX.Element;
  onClick: () => void;
};

function Update48hChallengeModal({
  children,
  onClick,
}: TPublishArticleModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('challenges.modal.update48h.title'),
    okText: t('generic.buttons.update'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('challenges.modal.update48h.content'),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default Update48hChallengeModal;
