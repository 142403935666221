import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import './App.css';
import { ConfigProvider } from 'antd';
import PageLayout from './features/layout/Layout';
import {
  userAuthenticated,
  getAuthenticatedUserDetailsAsync,
  getTokenDateFromStorage,
  refreshTokenAsync,
  selectRefreshToken,
} from './features/auth/AuthSlice';
import Login from './features/auth/Login/Login';
import OnBoarding from './features/auth/OnBoarding/OnBoarding';
import AlreadyRegistered from './features/auth/AlreadyRegistered/AlreadyRegistered';
import ForgotPassword from './features/auth/ForgotPassword/ForgotPassword';
import ResetPassword from './features/auth/ResetPassword/ResetPassword';
import UserResetPassword from './features/auth/ResetPasswordUser/ResetPasswordUser';
import { ROUTES } from './constants/routes';
import SetNewPassword from './features/auth/SetNewPassword/SetNewPassword';
import NewUserConfirmation from './features/auth/NewUserConfirmation/NewUserConfirmation';
import SetAppNewPassword from './features/auth/SetAppNewPassword/SetAppNewPassword';
import NewAppUserConfirmation from './features/auth/NewAppUserConfirmation/NewAppUserConfirmation';
import ConfirmData from './features/auth/ConfirmData/ConfirmData';
import DateOfBirth from './features/auth/DateOfBirth/DateOfBirth';
import YourInsurance from './features/auth/YourInsurance/YourInsurance';
import CreateAccount from './features/auth/CreateAccount/CreateAccount';
import Finish from './features/auth/Finish/Finish';
import './index.scss';
import { Config } from './config/config';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import RedirectAppStores from './features/auth/RedirectAppStores/RedirectAppStores';
import InvitationExpired from './features/auth/InvitationExpired/InvitationExpired';

function App() {
  const dispatch = useDispatch();
  const authenticated = useSelector(userAuthenticated);
  const refreshToken = useSelector(selectRefreshToken);

  useEffect(() => {
    if (authenticated) {
      dispatch<any>(getAuthenticatedUserDetailsAsync());
    }
  }, [dispatch, authenticated]);

  useEffect(() => {
    const refreshTokenInterval = setInterval(() => {
      const tokenDate = getTokenDateFromStorage();
      if (!tokenDate || !refreshToken) return;

      if (
        Math.abs(
          (tokenDate.getTime() - new Date().getTime()) / (1000 * 60 * 60),
        ) +
          0.5 >
        Config.JWT_TOKEN_LIFETIME
      ) {
        dispatch<any>(refreshTokenAsync({ refreshToken }));
      }
    }, 1000 * 60 * 30);

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, []);

  const routeToShow = (path: string) => {
    return (
      <Route
        path={path}
        caseSensitive
        element={
          !authenticated ? (
            <Navigate to={ROUTES.login} replace />
          ) : (
            <Suspense fallback="loading">
              <PageLayout />
            </Suspense>
          )
        }
      />
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#680079',
          colorLink: '#680079',
          colorPrimaryBg: '#F8F2F8',
          colorPrimaryBgHover: '#F8F2F8',
          colorBorderSecondary: '#FFFFFF',
          fontFamily: 'Poppins',
        },
      }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path={ROUTES.login}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <Login />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.forgotPassword}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <ForgotPassword />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.resetPassword}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <ResetPassword />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.userResetPassword}
            element={
              <Suspense fallback="loading">
                <UserResetPassword />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.onboarding}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <OnBoarding />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.alreadyRegistered}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <AlreadyRegistered />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.invitationExpired}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <InvitationExpired />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.confirmData}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <ConfirmData />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.yourInsurance}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <YourInsurance />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.dateOfBirth}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <DateOfBirth />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.activateAccount}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <CreateAccount />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.finish}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <Finish />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.redirectAppStores}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <RedirectAppStores />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.setNewPassword}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <SetNewPassword />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.setNewPasswordConfirmation}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <NewUserConfirmation />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.setAppNewPassword}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <SetAppNewPassword />
                </Suspense>
              )
            }
          />
          <Route
            path={ROUTES.setAppNewPasswordConfirmation}
            element={
              authenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback="loading">
                  <NewAppUserConfirmation />
                </Suspense>
              )
            }
          />
          <Route
            path="/"
            element={
              !authenticated ? (
                <Navigate to={ROUTES.login} replace />
              ) : (
                <Suspense fallback="loading">
                  <PageLayout />
                </Suspense>
              )
            }
          />
          {routeToShow('rewards/:id')}
          {routeToShow('rewards/claimed')}
          {routeToShow('rewards')}
          {routeToShow('users/:id?')}
          {routeToShow('guarantees/:id')}
          {routeToShow('guarantees')}
          {routeToShow('quizzes/:id/:tab?')}
          {routeToShow('challenges/biometrics/:id/:tab?')}
          {routeToShow('quizzes')}
          {routeToShow('challenges')}
          {routeToShow('admin-users')}
          {routeToShow('admin-users/:id')}
          {routeToShow('collective/new')}
          {routeToShow('collective/:id')}
          {routeToShow('collective')}
          {routeToShow('articles/:id')}
          {routeToShow('articles')}
          {routeToShow('insights/:id')}
          {routeToShow('insights')}
          {routeToShow('wellness/analytics')}
          {routeToShow('members')}
          {routeToShow('categories/:section/:id')}
          {routeToShow('categories/:section')}
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
