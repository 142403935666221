import { TProduct } from '../../../ProductsTypes';
import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../../../../../../../../helpers/apiRequest';
import { TProductEmployeeListResponse } from './AssignEmployeesTypes';

export const assignEmployees = async (
  id: TProduct['id'],
  data: { employeeIds: number[] },
): Promise<TProductEmployeeListResponse> =>
  apiRequest<TProductEmployeeListResponse>(
    `/insurance/products/${id}/users`,
    'post',
    data,
  );

export const removeEmployee = async (
  id: TProduct['id'],
  data: { employeeId: string },
): Promise<TProductEmployeeListResponse> =>
  apiRequest<TProductEmployeeListResponse>(
    `/insurance/products/${id}/users/${data.employeeId}`,
    'delete',
    null,
  );

export const getEmployees = async (
  listParams?: TListParams,
  id?: TProduct['id'],
): Promise<TProductEmployeeListResponse> =>
  apiRequestGet<TProductEmployeeListResponse>(
    `/insurance/products/${id}/users`,
    listParams,
  );
