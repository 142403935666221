import styles from './GrayBox.module.scss';
import { GrayBoxProps } from './types';
import { useTranslation } from 'react-i18next';

function GrayBox({ children }: GrayBoxProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.container}>
        <div>
          <span className={styles.heading}>
            {t('auth.yourInsurance.yourInsurance.whatIsCovered.title')}
          </span>
          <p className={styles.paragraph}>
            {t('auth.yourInsurance.yourInsurance.whatIsCovered.text')}
          </p>
          <span className={styles.heading}>
            {t('auth.yourInsurance.yourInsurance.howMuchIsCovered.title')}
          </span>
          <p className={styles.paragraph}>
            {t('auth.yourInsurance.yourInsurance.howMuchIsCovered.text')}
          </p>
          <span className={styles.heading}>
            {t('auth.yourInsurance.yourInsurance.whatIsIncluded.title')}
          </span>
          <p className={styles.paragraph}>
            {t('auth.yourInsurance.yourInsurance.whatIsIncluded.text')}
          </p>
        </div>

        <div className={styles.children}>{children}</div>
      </div>
    </>
  );
}

export default GrayBox;
