import {
  createReward,
  deleteReward,
  fetchRewards,
  getReward,
  publishReward,
  saveAsDraftReward,
  unpublishReward,
  updateReward,
} from './RewardsApi';
import {
  TReward,
  TRewardsResponse,
  TSingleRewardResponse,
} from './RewardsTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

const rewardsSlice = buildSlice<
  TReward,
  TSingleRewardResponse,
  TRewardsResponse
>('rewards', {
  create: createReward,
  delete: deleteReward,
  fetchList: fetchRewards,
  getOne: getReward,
  publish: publishReward,
  unpublish: unpublishReward,
  update: updateReward,
  saveAsDraft: saveAsDraftReward,
});

export const { selectors, asyncActions, actions } = rewardsSlice;

export default rewardsSlice.reducer;
