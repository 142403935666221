import React from 'react';
import { EAssigmentType, ERewardStatuses, TReward } from './RewardsTypes';
import { Badge } from 'antd';
import { TFunction } from 'i18next';
import { UserRoles } from '../../constants/userRoles';

export const getRewardStatus = (reward: TReward): ERewardStatuses => {
  if (!reward.publishDate && !reward.unpublishDate) {
    return ERewardStatuses.DRAFT;
  }
  if (reward.publishDate && !reward.unpublishDate) {
    return ERewardStatuses.ACTIVE;
  }
  if (reward.unpublishDate) {
    return ERewardStatuses.INACTIVE;
  }
  return ERewardStatuses.DRAFT;
};

export const getRewardStatusRendered = (
  reward: TReward,
  t: TFunction<'translation', undefined>,
): JSX.Element => {
  switch (getRewardStatus(reward)) {
    case ERewardStatuses.DRAFT: {
      return <Badge color="yellow" text={t('generic.draft')} />;
    }
    case ERewardStatuses.INACTIVE: {
      return <Badge status="default" text={t('generic.inactive')} />;
    }
    case ERewardStatuses.ACTIVE: {
      return <Badge color="green" text={t('generic.active')} />;
    }
    default: {
      return <Badge color="yellow" text={t('generic.draft')} />;
    }
  }
};

export const isActionAvailable = (
  role: UserRoles | null,
  userCompaniesIds: number[] = [],
  rewardCompaniesIds: number[] = [],
  assignmentType: EAssigmentType = EAssigmentType.ALL,
): boolean =>
  role === UserRoles.ADMIN ||
  (role === UserRoles.COMPANY_MANAGER &&
    assignmentType === EAssigmentType.SELECTED_COMPANIES &&
    !rewardCompaniesIds.find((cId) => !userCompaniesIds.includes(cId)));

export {};
