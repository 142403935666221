import buildSlice from '../../general/helpers/genericSliceBuilder';
import { TMember, TMembersListResponse, TMembersResponse } from './MembersType';
import { getAllMembersList } from './MembersApi';

const membersSlice = buildSlice<
  TMember,
  TMembersResponse,
  TMembersListResponse
>('members', {
  fetchList: getAllMembersList as (
    params: any,
  ) => Promise<TMembersListResponse>,
});

export const { selectors, asyncActions, actions } = membersSlice;

export default membersSlice.reducer;
