import styles from './LargeButton.module.scss';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { LargeButtonProps } from './types';

function LargeButton({ text, onCLick }: LargeButtonProps) {
  const isMobile = window.screen.width < 600;

  return (
    <div className={isMobile ? styles.marginMobile : styles.margin}>
      <Button
        className={isMobile ? styles.buttonMobile : styles.button}
        type="link"
        onClick={onCLick}
      >
        <span>{text}</span>
      </Button>
    </div>
  );
}

export default LargeButton;
