import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLocalizationResources } from './i18nApi';
import requestStatuses from '../../constants/requestStatuses';
import { Config } from '../../config/config';
import initLocalization from './i18n';

const { I18N } = Config;

export const i18nActions = {
  GET_RESOURCES: 'i18n/getResources',
};

type TResource = {
  [key: string]: {
    translation: any;
  };
};
type TState = {
  resources: TResource;
  getResourceStatus: string | null;
  currentLanguage: string | null;
};
const initialState: TState = {
  resources: I18N.LOCALES.reduce((acc: TResource, val) => {
    acc[val] = { translation: {} };

    return acc;
  }, {}),
  getResourceStatus: null,
  currentLanguage: localStorage.getItem('lang') || null,
};

export const getI18nResources = createAsyncThunk(
  i18nActions.GET_RESOURCES,
  async (_) => {
    return getLocalizationResources('');
  },
);

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      const { language } = action.payload;

      localStorage.setItem('lang', language);
      initLocalization(language);

      state.currentLanguage = language;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getI18nResources.pending, (state) => {
        state.getResourceStatus = requestStatuses.LOADING;
      })
      .addCase(getI18nResources.fulfilled, (state, action) => {
        const { data } = action.payload || {};
        if (data) {
          state.getResourceStatus = requestStatuses.SUCCESS;
          state.resources = data;
        } else {
          state.getResourceStatus = requestStatuses.FAILED;
        }
      });
  },
});

export const { setCurrentLanguage } = i18nSlice.actions;

export const selectI18nResources = (state: { i18n: TState }) =>
  state.i18n.resources;
export const selectCurrentLang = (state: { i18n: TState }) =>
  state.i18n.currentLanguage;

export default i18nSlice.reducer;
