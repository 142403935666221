import {
  apiRequest,
  apiRequestFormData,
  apiRequestGet,
  TListParams,
} from '../../helpers/apiRequest';
import {
  TInsight,
  TInsightsResponse,
  TSingleInsightResponse,
} from './InsightsTypes';

export const createInsight = async (
  insightData: FormData,
): Promise<TSingleInsightResponse> =>
  apiRequestFormData<TSingleInsightResponse>('/insights', 'post', insightData, {
    version: 'v2',
  });

export const fetchInsights = async (
  listParams?: TListParams,
): Promise<TInsightsResponse> =>
  apiRequestGet<TInsightsResponse>('/insights', {
    ...listParams,
    version: 'v2',
  });

export const deleteInsight = async (
  id: TInsight['id'],
): Promise<TSingleInsightResponse> =>
  apiRequest<TSingleInsightResponse>(`/insights/${id}`, 'delete', null, {
    version: 'v2',
  });

export const getInsight = async (
  id: TInsight['id'],
): Promise<TSingleInsightResponse> =>
  apiRequestGet<TSingleInsightResponse>(`/insights/${id}`, { version: 'v2' });

export const updateInsight = async (
  id: TInsight['id'],
  insightData: FormData,
): Promise<TSingleInsightResponse> =>
  apiRequestFormData<TSingleInsightResponse>(
    `/insights/${id}`,
    'put',
    insightData,
    { version: 'v2' },
  );

export const activateInsight = async (
  id: number,
): Promise<TSingleInsightResponse> =>
  apiRequest<TSingleInsightResponse>(`/insights/${id}/active`, 'patch', null, {
    version: 'v2',
  });

export const deactivateInsight = async (
  id: number,
): Promise<TSingleInsightResponse> =>
  apiRequest<TSingleInsightResponse>(
    `/insights/${id}/inactive`,
    'patch',
    null,
    { version: 'v2' },
  );
