import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Tabs } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProPageHeader } from '@ant-design/pro-layout';
import styles from '../../../components/PageHeaderDefault/PageHeaderDefault.module.scss';

import { actions, asyncActions, selectors } from '../ChallengesSlice';
import { asyncActions as companiesAsyncActions } from '../../collective/companies/AllCompaniesSlice';
import {
  asyncActions as adminUserAdminAsyncActions,
  selectors as adminUserSelectors,
} from '../../adminUsers/AdminUsersAdminSlice';
import { selectAuthenticatedUser } from '../../auth/AuthSlice';
import { enableForRoles } from '../../../helpers/role';
import { UserRoles } from '../../../constants/userRoles';
import PersonalForm from './forms/PersonalForm/PersonalForm';
import {
  EChallengeTypes,
  IChallenge,
  TChallengeSharedForm,
} from '../ChallengesTypes';
import TeamForm from './forms/TeamForm/TeamForm';
import HeaderButtons from './components/HeaderButtons/HeaderButtons';
import Leaderboard from './Leaderboard/Leaderboard';
import { EChallengeStatuses } from '../ChallengeList/ChallengeListTypes';
import { isActionAvailable } from '../ChallengesHelpers';
import { TCompany } from '../../collective/companies/CompanyType';
import RequestStatuses from '../../../constants/requestStatuses';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import { useLocation, useNavigate, useParams } from 'react-router';
import { selectCurrentLang } from '../../i18n/i18nSlice';
import { Config } from '../../../config/config';

const { TabPane } = Tabs;

export type TSaveFunc = (publish?: boolean) => void;

function EditChallenge() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: challengeId, tab: currentTab = 'info' } = useParams();
  const formRef = useRef<{
    onSave: TSaveFunc;
    isValid: boolean;
    isDirty: boolean;
  }>();

  const isExistingChallenge = useMemo(
    () => challengeId !== 'new',
    [challengeId],
  );

  /* DATA STORE */
  const currentChallenge = useSelector(selectors.currentItem);
  // current user data
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const adminCompanies = useSelector(adminUserSelectors.listData);
  const statuses = useSelector(selectors.statuses);
  
  const lang = useSelector(selectCurrentLang);

  /* STATE */
  const [formType, setFormType] = useState(EChallengeTypes.PERSONAL);
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [sharedFields, setSharedFields] = useState(
    null as TChallengeSharedForm | null,
  );
  const [startDate, setStartDate] = useState(null as string | null | undefined);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

   // Translations
   const [selectedLanguage, setSelectedLanguage] = useState(
     lang || Config.I18N.DEFAULT_LANGUAGE,
   );

  // fetch companies from different sources
  // for different user roles
  useEffect(() => {
    if (authenticatedUser.role) {
      if (enableForRoles(authenticatedUser.role, [UserRoles.COMPANY_MANAGER])) {
        dispatch<any>(adminUserAdminAsyncActions.fetchList());
      } else {
        dispatch<any>(companiesAsyncActions.fetchList());
      }
    }

    return () => {
      dispatch(actions.clearCurrentItem());
    };
  }, []);

  // clear the current item in the store
  // get the challenge from the API
  useEffect(() => {
    dispatch(actions.clearCurrentItem());

    if (challengeId && isExistingChallenge) {
      dispatch<any>(asyncActions.get({ id: +challengeId }));
    }
  }, [dispatch, challengeId]);

  useEffect(() => {
    if (currentChallenge) {
      setFormType(currentChallenge.type);
    } else {
      setFormType(EChallengeTypes.PERSONAL);
    }
  }, [currentChallenge]);

  useEffect(() => {
    if (statuses.create === RequestStatuses.SUCCESS && currentChallenge?.id) {
      navigate(`/challenges/biometrics/${currentChallenge.id}`);

      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  const onBack = (state: string = '') =>
    navigate('/challenges',
      { state: {
          search: location.state as string,
          state
        }
      }
    );

  const onCancelPress = () => {
    setShowConfirmModal(true)
  }

  const onSave = (publish?: boolean) => {
    formRef.current?.onSave(publish);

    if (publish) {
      onBack('published');
    }
  };

  const onUnPublish = () => {
    if (currentChallenge) {
      dispatch<any>(asyncActions.unpublish({ item: currentChallenge }));
    }
  };

  const onPublish = () => {
    if (currentChallenge) {
      dispatch<any>(asyncActions.publish({ item: currentChallenge }));
    }
  };

  const onDelete = () => {
    if (currentChallenge) {
      dispatch<any>(asyncActions.delete({ item: currentChallenge }));
      onBack();
    }
  };

  const onDraft = () => {
    if (currentChallenge) {
      dispatch<any>(asyncActions.draft({ item: currentChallenge }));
      onBack();
    }
  };

  return (
    <div>
      <ProPageHeader
        prefixedClassName={styles.pageHeader}
        title={t('challenges.createChallenge')}
        extra={
          isActionAvailable(
            authenticatedUser?.role,
            adminCompanies?.list?.map((company: TCompany) => company.id),
            currentChallenge?.companies?.map((company) => company.id),
          )
            ? [
                <HeaderButtons
                  challengeType="biometrics"
                  isDirty={false}
                  isValid={isValid}
                  onBack={() => onCancelPress()}
                  onSave={onSave}
                  onPublish={onPublish}
                  onUnpublish={onUnPublish}
                  onDelete={onDelete}
                  onDraft={onDraft}
                  currentChallenge={
                    currentChallenge || ({ type: formType } as IChallenge)
                  }
                  startDate={startDate || sharedFields?.startDate}
                />,
              ]
            : []
        }
        onBack={() => {}}
        backIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ margin: 0, padding: 0 }}
            onClick={() => onBack()}
          />
        }
      />
      {formType === EChallengeTypes.PERSONAL && (
        <PersonalForm
          ref={formRef}
          currentChallenge={currentChallenge}
          onDataChanged={(isDirtyParam, isValidParam) => {
            setIsDirty(isDirtyParam);
            setIsValid(isValidParam);
          }}
          onChallengeTypeChange={(type, sharedFieldsParam) => {
            setFormType(type);
            setSharedFields(sharedFieldsParam);
          }}
          sharedFields={sharedFields}
          onSelectStartDate={setStartDate}
          selectedChallengeLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      )}
      {formType === EChallengeTypes.TEAM && (
        <Tabs
          activeKey={currentTab}
          onTabClick={(tabKey: string) => {
            const tabUrl = tabKey === 'info' ? '' : `/${tabKey}`;

            navigate(`/challenges/biometrics/${currentChallenge?.id}${tabUrl}`);
          }}
        >
          <TabPane key="info" tab={t('challenges.challengeInfo')}>
            <TeamForm
              ref={formRef}
              currentChallenge={currentChallenge}
              onDataChanged={(isDirtyParam, isValidParam) => {
                setIsDirty(isDirtyParam);
                setIsValid(isValidParam);
              }}
              onChallengeTypeChange={(type, sharedFieldsParam) => {
                setFormType(type);
                setSharedFields(sharedFieldsParam);
              }}
              sharedFields={sharedFields}
              onSelectStartDate={setStartDate}
              selectedChallengeLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          </TabPane>
          {currentChallenge &&
            [EChallengeStatuses.ONGOING, EChallengeStatuses.FINISHED].includes(
              currentChallenge.status.title,
            ) && (
              <TabPane key="leaderboard" tab={t('challenges.leaderboard')}>
                <Leaderboard currentChallenge={currentChallenge} />
              </TabPane>
            )}
        </Tabs>
      )}
      <RouteLeavingGuard
        lastLocation='/challenges'
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </div>
  );
}

EditChallenge.defaultProps = {
  challengeId: null,
};

export default EditChallenge;
