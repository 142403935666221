import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Button, DatePicker, Input, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'antd/es/table/interface';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'
import { actions, asyncActions, selectors } from '../AdminUsersSlice';
import {
  asyncActions as companiesAsyncActions,
  selectors as companiesSelectors,
} from '../../collective/companies/AllCompaniesSlice';
import { TAdminUser } from '../AdminUsersTypes';
import styles from './AdminUsersList.module.scss';
import PageHeaderDefault from '../../../components/PageHeaderDefault/PageHeaderDefault';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../constants/requestStatuses';
import BulkActions from '../../../components/BulkActions/BulkActions';
import DeleteAdminUserModal from '../../../components/Modals/AdminUsersModals/DeleteAdminUserModal/DeleteAdminUserModal';
import AdminUserForm from '../AdminUserForm/AdminUserForm';
import ResendInvitationModal from '../../../components/Modals/AdminUsersModals/ResendInvitationModal/ResendInvitationModal';
import DeleteAdminUserBulkModal from '../../../components/Modals/AdminUsersModals/DeleteAdminUserBulkModal/DeleteAdminUserBulkModal';
import { TCompany } from '../../collective/companies/CompanyType';
import { UserRoles } from '../../../constants/userRoles';
import Table from '../../../components/Table/Table';
import useQuery from '../../../helpers/useQuery';

const sortFieldsMappings: any = {
  userName: 'firstName',
};

const { Search } = Input;
const { RangePicker } = DatePicker;

type TTableData = {
  key: Key;
  id: TAdminUser['id'];
  userName: string;
  email: TAdminUser['email'];
  role?: string | null;
  companies: TCompany[] | null;
  createdAt: string;
  status: string;
  actions: null;
};

type EditUserParams = {
  id?: string;
};

function AdminUsersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { id: currentUserId }: Readonly<EditUserParams> = useParams();

  const query = useQuery();

  const isExistingUser = useMemo(
    () => !!currentUserId && currentUserId !== 'new',
    [currentUserId],
  );

  const adminUsersData = useSelector(selectors.listData);
  const statuses = useSelector(selectors.statuses);
  const bulkAdminUsers = useSelector(selectors.bulkItems);
  const actionAdminUser = useSelector(selectors.actionItems);
  const companies = useSelector(companiesSelectors.listData);

  useEffect(() => {
    dispatch(actions.clearCreatedAtRange());
    dispatch(actions.clearFilters());
    dispatch<any>(companiesAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';

      dispatch(
        actions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    if (query.has('createdAtStart') && query.has('createdAtEnd')) {
      dispatch(
        actions.setCreatedAtRange({
          createdAtStart: query.get('createdAtStart') || '',
          createdAtEnd: query.get('createdAtEnd') || '',
        }),
      );
    } else {
      dispatch(actions.clearCreatedAtRange());
    }

    if (query.has('role')) {
      const filterRoles = (query.get('role') || '').split(',');

      for (const role of filterRoles) {
        dispatch(actions.setFilter({ field: 'role', value: role }));
      }
    }

    if (query.has('status')) {
      const filterStatuses = (query.get('status') || '').split(',');

      for (const status of filterStatuses) {
        dispatch(actions.setFilter({ field: 'status', value: status }));
      }
    }

    if (query.has('companies')) {
      const filterCompanies = (query.get('companies') || '').split(',');

      for (const company of filterCompanies) {
        dispatch(actions.setFilter({ field: 'companies', value: company }));
      }
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearCreatedAtRange());
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (adminUsersData?.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, adminUsersData]);

  useEffect(() => {
    const deletedUsersNumber = bulkAdminUsers.delete.length;
    const adminUser = actionAdminUser.delete;

    if (statuses.update === RequestStatuses.SUCCESS) {
      setEditWindow(false);
      setCurrentAdminUser(null);

      dispatch(actions.clearStatuses());
    }

    if (statuses.delete === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        t('adminUsers.alerts.singularPopup', {
          title: `${adminUser?.firstName} ${adminUser?.lastName}`,
          operation: t('generic.operations.deleted'),
        }),
      );

      dispatch(actions.clearStatuses());
    }

    if (statuses.deleteBulk === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        deletedUsersNumber > 1
          ? t('adminUsers.alerts.bulkPopupPlural', {
              length: deletedUsersNumber,
              operation: t('generic.operations.deleted'),
            })
          : t('adminUsers.alerts.bulkPopupSingular', {
              length: deletedUsersNumber,
              operation: t('generic.operations.deleted'),
            }),
      );

      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  const [editWindow, setEditWindow] = useState(false);
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [currentAdminUser, setCurrentAdminUser] = useState(
    null as TAdminUser['id'] | null,
  );

  const selectedAdminUsers =
    adminUsersData.list?.filter(({ id }) => selectedIds.indexOf(id) !== -1) ||
    [];

  const handleTableChange = (
    tablePagination: any,
    filters: any,
    sorter: any,
  ) => {
    if (sorter?.order) {
      dispatch(
        actions.setOrder({
          field: sortFieldsMappings[sorter.field] || sorter.field,
          direction: sorter.order === 'descend' ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.setOrder({ field: 'id', direction: 'desc' }));
    }

    dispatch(actions.clearFilters());
    Object.keys(filters).forEach((field: any) => {
      if (filters[field]?.length) {
        filters[field].forEach((filter: any) => {
          dispatch(actions.setFilter({ field, value: filter }));
        });
      }
    });

    dispatch(
      actions.setPagination({
        page: tablePagination.current,
        perPage: tablePagination.pageSize,
      }),
    );

    dispatch<any>(asyncActions.fetchList());
  };

  const onDelete = (id: TAdminUser['id']) => () => {
    const adminUserToDelete = adminUsersData.list?.find(
      (adminUser) => adminUser.id === id,
    );

    if (adminUserToDelete) {
      dispatch<any>(asyncActions.delete({ item: adminUserToDelete }));
    }
  };

  useEffect(() => {
    if (currentUserId) {
      setCurrentAdminUser(isExistingUser ? +currentUserId : null);
      setEditWindow(true);
    }
  }, [currentUserId]);

  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };

  const actionsBlock = (record: TTableData) => (
    <div className={styles.tableActions}>
      <Link
        to={{
          pathname: `/admin-users/${record.id}`,
          search:`?${query.toString()}`
        }}
        className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
      >
        <EditOutlined />
      </Link>
      {record.status !== 'deleted' && (
        <DeleteAdminUserModal
          userName={record.userName}
          onClick={onDelete(record.id)}
        >
          <div className={styles.actionButton}>
            <DeleteOutlined />
          </div>
        </DeleteAdminUserModal>
      )}
    </div>
  );

  const tableData: TTableData[] =
    adminUsersData.list?.map((item: TAdminUser) => ({
      key: item.id,
      id: item.id,
      userName: `${item.firstName} ${item.lastName}`,
      email: item.email,
      role: item?.role?.title,
      companies: item?.companies,
      createdAt: dayjs(item.createdAt).format('DD/MM/YYYY'),
      status: item.status.adminStatusTitle,
      actions: null,
    })) || [];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: t('adminUsers.userName'),
      dataIndex: 'userName',
      render: (_: string, record: TTableData) => (
        <Link
          to={{
            pathname: `/admin-users/${record.id}`,
            search:`?${query.toString()}`
          }}
          className={styles.userTitle}
        >
          {record.userName.length > 30 ? (
            <Tooltip
              placement="top"
              title={record.userName}
            >{`${record.userName.slice(0, 30)}...`}</Tooltip>
          ) : (
            record.userName
          )}
        </Link>
      ),
    },
    {
      title: t('adminUsers.email'),
      dataIndex: 'email',
    },
    {
      title: t('adminUsers.typeOfUser'),
      dataIndex: 'role',
      filters: [
        { value: UserRoles.COMPANY_MANAGER, text: t('generic.companyManager') },
        { value: UserRoles.ADMIN, text: t('generic.admin') },
      ],
      render: (_: null, record: TTableData) => {
        if (!record.role) {
          return '-';
        }

        return record.role === UserRoles.COMPANY_MANAGER
          ? t('generic.companyManager')
          : t('generic.admin');
      },
      ...(query.has('role')
        ? {
            defaultFilteredValue: query.get('role')?.split(','),
          }
        : {}),
    },
    {
      title: t('adminUsers.companyName'),
      dataIndex: 'companies',
      render: (_: string, record: TTableData) => {
        let companiesList = '-';

        if (record?.companies?.length) {
          companiesList = record?.companies
            ?.map((company: TCompany) => company.companyName)
            .join(', ');
        }

        return <span className={styles.companiesList}>{companiesList}</span>;
      },
      filters: companies?.list?.map((company: TCompany) => ({
        value: company.id,
        text: company.companyName,
      })),
      ...(query.has('companies')
        ? {
            defaultFilteredValue: query.get('companies')?.split(','),
          }
        : {}),
    },
    {
      title: t('adminUsers.invitationDate'),
      dataIndex: 'createdAt',
      sorter: true,
      ...(query.has('sort') &&
      ['createdAt', '-createdAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-createdAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('adminUsers.invitationStatus'),
      dataIndex: 'status',
      render: (
        status: 'invited' | 'registered' | 'deactivated' | 'deleted',
        record: TTableData,
      ) => {
        switch (status) {
          case 'invited': {
            return (
              <div className={styles.statusWrap}>
                <Badge
                  className={styles.statusBadge}
                  color="yellow"
                  text={t('generic.pending')}
                />
                <ResendInvitationModal
                  userName={record.userName}
                  onClick={() => {
                    dispatch(
                      asyncActions.singleRequests?.sendInvitation?.({
                        id: record.id,
                      }),
                    );
                    ActionAlert.success(
                      t('adminUsers.tooltips.invitationSent', {
                        userName: record.userName,
                      }),
                    );
                  }}
                >
                  <Button
                    className={styles.resendButton}
                    icon={<MailOutlined />}
                    type="link"
                  />
                </ResendInvitationModal>
              </div>
            );
          }
          case 'deactivated': {
            return <Badge status="default" text={t('generic.inactive')} />;
          }
          case 'deleted': {
            return <Badge status="default" text={t('adminUsers.archived')} />;
          }
          default: {
            return <Badge status="success" text={t('generic.accepted')} />;
          }
        }
      },
      filters: [
        {
          value: 'invited',
          text: t('generic.pending'),
        },
        {
          value: 'registered',
          text: t('generic.accepted'),
        },
        {
          value: 'deactivated',
          text: t('generic.inactive'),
        },
        {
          value: 'deleted',
          text: t('adminUsers.archived'),
        },
      ],
      ...(query.has('status')
        ? {
            defaultFilteredValue: query.get('status')?.split(','),
          }
        : {}),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      render: (_: null, record: TTableData) => actionsBlock(record),
    },
  ];

  const dropdownOptions = [
    {
      key: 'delete',
      text: t('generic.delete'),
      action: () => {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedAdminUsers }));
        setSelectedIds([]);
      },
      modal: (children: React.ReactElement, action: () => void) => (
        <DeleteAdminUserBulkModal count={selectedIds.length} onClick={action}>
          {children}
        </DeleteAdminUserBulkModal>
      ),
    },
  ];

  return (
    <div
      key={`admin-users-list-${Array.from(query.keys()).length}-${
        companies?.list?.length
      }`}
    >
      <AdminUserForm
        currentAdminUser={currentAdminUser}
        show={editWindow}
        onClose={() => {
          setEditWindow(false);
          setCurrentAdminUser(null);

          navigate({
            pathname: '/admin-users',
            search: location.state as string,
          });
        }}
        onDelete={onDelete}
      />
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('adminUsers.breadcrumbs.users')]: '/admin-users',
          [t('nav.category.adminUsers')]: '',
        }}
        title={t('adminUsers.breadcrumbs.adminUsers')}
      />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>{t('nav.item.userList')}</div>
        <div className={styles.buttonContainer}>
          <Search
            allowClear
            defaultValue={query.get('search') || ''}
            className={styles.searchField}
            placeholder={t('adminUsers.placeholder.searchForUser') || ''}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                adminUsersData.listParams?.pagination?.perPage
                  ? adminUsersData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
          />
          {/* TODO: day .js. is necesary in this component */}
          <RangePicker
            placeholder={[
              t('adminUsers.placeholder.startDate'),
              t('adminUsers.placeholder.endDate'),
            ]}
            defaultValue={[
              query.get('createdAtStart')
                ? dayjs(query.get('createdAtStart') as string)
                : null,
              query.get('createdAtEnd')
                ? dayjs(query.get('createdAtEnd') as string)
                : null,
            ]}
            className={styles.searchField}
            onChange={(val) => {
              if (val && !val.find((v) => !v)) {
                query.set(
                  'createdAtStart',
                  val[0]
                    ?.set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0)
                    .toISOString() || '',
                );
                query.set(
                  'createdAtEnd',
                  val[1]
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59)
                    .toISOString() || '',
                );
              } else {
                query.delete('createdAtStart');
                query.delete('createdAtEnd');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                adminUsersData.listParams?.pagination?.perPage
                  ? adminUsersData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
            format="ll"
          />
        </div>
      </div>
      {!!selectedIds.length && (
        <BulkActions
          dropdownOptions={dropdownOptions}
          selectedKeys={selectedIds.map((key) => key.toString())}
        />
      )}
      <Table
        actions={actions}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: adminUsersData.listParams?.pagination?.total,
          current: adminUsersData.listParams?.pagination?.page,
        }}
      />
    </div>
  );
}

export default AdminUsersList;
