import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { UserRoles } from '../../../../constants/userRoles';
import nullOrAlt from '../../../../helpers/nullOrAlt';

import RoleWrapper from '../../../RoleWrapper/RoleWrapper';

import styles from './CompanyPostalCodeInput.module.scss';

type TCompanyPostalCodeInputProps = {
  alternative?: string;
  onChange: (value: string) => void;
  postalCode: string | null;
};

const onlyDigitsRegex = /^[0-9]*$/g;

function CompanyPostalCodeInput({
  alternative,
  onChange,
  postalCode,
}: TCompanyPostalCodeInputProps) {
  const { t } = useTranslation();

  const isPostalCodeValid = () =>
    !postalCode ||
    yup
      .string()
      .length(5)
      .matches(onlyDigitsRegex)
      .nullable()
      .isValidSync(postalCode);

  return (
    <Form.Item
      label={t('collective.company.form.companyInformation.postalCode')}
      className={styles.itemLeftContainer}
    >
      <RoleWrapper
        roles={[UserRoles.ADMIN]}
        alternative={
          <div className={styles.companyDetailsValue}>{alternative}</div>
        }
      >
        <Input
          min={0}
          className={isPostalCodeValid() ? '' : styles.inputError}
          onChange={(e) => onChange(e.target.value)}
          placeholder={
            t('collective.company.form.companyInformation.postalCode') || ''
          }
          value={nullOrAlt(postalCode, '')}
        />
      </RoleWrapper>
    </Form.Item>
  );
}

CompanyPostalCodeInput.defaultProps = {
  alternative: '',
};

export default CompanyPostalCodeInput;
