import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Dayjs } from 'dayjs';
import { DatePicker, Button } from 'antd';
import { ROUTES } from '../../../constants/routes';
import styles from './DateOfBirth.module.scss';
import Progress from '../../../components/Progress/Progress';
import Heading from '../../../components/Heading/Heading';
import CopyrightsShort from '../../../components/Copyrights/CopyrightsShort';
import ContactLink from '../../../components/ContactLink/ContactLink';
import LeftRowText from '../../../components/LeftRowText/LeftRowText';
import VLogo from '../../../components/VLogo/VLogo';

// Svg assets
import one from '../../../assets/svg/one.svg';
import two from '../../../assets/svg/two.svg';
import three from '../../../assets/svg/three.svg';
import { TUser } from '../../users/UsersTypes';
import { apiMobRequest } from '../../../helpers/apiRequest';
import characters from '../../../assets/svg/characters.svg';

function DateOfBirth(props: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [birthDate, setBirthDate] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const data = location.state as any;
  const token = data?.token;

  const handleClick = async (): Promise<{ data: TUser }> =>
    apiMobRequest<{ data: TUser }>(
      `/users/${token}`,
      'patch',
      {
        birthDate,
        userProfile: {
          dateOfBirth,
        },
      },
      {
        version: 'v2',
      },
    );

  function onChange(date: any) {
    setBirthDate(date);
    setDateOfBirth(date);
  }

  const disabledDate = (current: Dayjs) => current.year() > 2004;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.rowLeft}>
          <div className={styles.progressPosition}>
            <div className={styles.logoPos}>
              <VLogo />
            </div>
            <Progress
              checkOne={one}
              checkTwo={two}
              checkThree={three}
              one={styles.circularActive}
              two={styles.circularUnActive}
              three={styles.circularUnActive}
              oneText={styles.activeText}
              twoText={styles.unActiveText}
              threeText={styles.unActiveText}
              numberOne={styles.numberActive}
              numberTwo={styles.numberUnActive}
              numberThree={styles.numberUnActive}
              lineOne={styles.line}
              lineTwo={styles.line}
            />
          </div>

          <div className={styles.innerRowLeft}>
            <div className={styles.HeadingRes}>
              <Heading
                heading={t('auth.onboarding.dateOfBirth.dateOfBirth.title')}
              />
            </div>

            <div className={styles.calContainer}>
              <span className={styles.selectDate}>
                {t('auth.onboarding.dateOfBirth.dateOfBirth.input')}
              </span>
              <DatePicker
                disabledDate={disabledDate}
                format="DD/MM/YYYY"
                onChange={onChange}
                suffixIcon={false}
              />
            </div>

            <div className={styles.align}>
              <Button
                onClick={() => {
                  handleClick();
                  {
                    data.guarantees?.length > 0
                      ? navigate(ROUTES.yourInsurance, {
                        state: {
                          guarantees: data?.guarantees,
                          email: data?.email,
                          token: data?.token,
                          activatedAt: data?.activatedAt,
                        },
                      })
                      : navigate(ROUTES.activateAccount, {
                        state: {
                          guarantees: data?.guarantees,
                          email: data?.email,
                          token: data?.token,
                        },
                      });
                  }
                }}
                htmlType="submit"
                className={
                      birthDate === null
                        ? styles.myDisabledButton
                        : styles.button
                    }
                type="primary"
                disabled={birthDate === null}
              >
                {t('auth.confirmData.confirmData.button')}
              </Button>
            </div>

            <div className={styles.margin}>
              <ContactLink message={t('auth.confirmData.confirmData.contact')} />
            </div>

            <div className={styles.footer}>
              <CopyrightsShort />
            </div>
          </div>
        </div>

        <div className={styles.rowRight}>
          <LeftRowText
            textPos={styles.textPos}
            textStyle={styles.textStyle}
            text={t('auth.onboarding.rightColumnText')}
          />
          <img src={characters} style={{}} />
        </div>
      </div>
    </div>
  );
}

export default DateOfBirth;
