import { useState, useEffect, useLayoutEffect } from 'react';
import { Key } from 'antd/es/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Layout, Menu } from 'antd';
import { ReactSVG } from 'react-svg';
import dayjs from 'dayjs';
import { TGuarantee } from '../GuaranteesTypes';
import { actions, asyncActions, selectors } from '../GuaranteesSlice';
import styles from './GuaranteesList.module.scss';
import DeleteGuaranteeModal from '../../../components/Modals/GuaranteesModals/DeleteGuaranteeModal';
import PageHeaderDefault from '../../../components/PageHeaderDefault/PageHeaderDefault';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../constants/requestStatuses';
import Table from '../../../components/Table/Table';
import useQuery from '../../../helpers/useQuery';

const { Search } = Input;

type TTableData = {
  key: Key;
  id: TGuarantee['id'];
  name: string;
  description: string;
  creationDate?: string;
  actions: JSX.Element;
};

function GuaranteesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useQuery();

  // selectors
  const guaranteesData = useSelector(selectors.listData);
  const statuses = useSelector(selectors.statuses);
  const bulkGuarantees = useSelector(selectors.bulkItems);
  const actionGuarantees = useSelector(selectors.actionItems);

  // effects
  useLayoutEffect(() => {
    dispatch(actions.clearFilters());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';

      dispatch(
        actions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (guaranteesData.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, guaranteesData]);

  // status popups
  const bulkPopup = (
    status: RequestStatuses | null | undefined,
    guarantees: TGuarantee[],
    operation: string,
    success?: boolean,
  ) => {
    const { length } = guarantees;
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        length > 1
          ? t('guarantees.alerts.bulkPopupPrural', { length, operation })
          : t('guarantees.alerts.bulkPopupSingular', { length, operation }),
      );
      dispatch(actions.clearStatuses());
    }
  };
  const singlePopup = (
    status: RequestStatuses | null | undefined,
    guarantee: TGuarantee,
    operation: string,
    success?: boolean,
  ) => {
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        t('guarantees.alerts.singualarPopup', {
          title: guarantee?.name,
          operation,
        }),
      );
      dispatch(actions.clearStatuses());
    }
  };

  useEffect(
    () =>
      bulkPopup(
        statuses.deleteBulk,
        bulkGuarantees.delete,
        t('generic.operations.deleted'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      bulkPopup(
        statuses.publishBulk,
        bulkGuarantees.publish,
        t('generic.operations.activated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      bulkPopup(
        statuses.unpublishBulk,
        bulkGuarantees.unpublish,
        t('generic.operations.deactivated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.delete,
        actionGuarantees.delete,
        t('generic.operations.deleted'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.publish,
        actionGuarantees.publish,
        t('generic.operations.activated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.unpublish,
        actionGuarantees.unpublish,
        t('generic.operations.deactivated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.create,
        actionGuarantees.create,
        t('generic.operations.created'),
        true,
      ),
    [statuses],
  );

  // states
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [bulkOption, setBulkOption] = useState('delete');
  const [search, setSearch] = useState(null as string | null);

  useEffect(() => {
    setTimeout(() => {
      if (guaranteesData?.list?.length === 0 && search === '') {
        setSearch(null);
        dispatch(actions.clearStatuses());
      }
    }, 2000);
  }, [guaranteesData]);

  const onCopy = (id: TGuarantee['id']) => () => {
    // Implement in the future
  };

  const onDelete = (id: TGuarantee['id']) => () => {
    const guarantee = guaranteesData.list?.find((a) => a.id === id);
    if (guarantee) {
      dispatch<any>(asyncActions.delete({ item: guarantee }));
    }
  };

  // actions buttons
  const actionsBlock = (id: number, title: string) => (
    <div className={styles.tableActions}>
      <Link
        to={`/guarantees/${id}?${query.toString()}`}
        className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
      >
        <EditOutlined />
      </Link>
      {[{ icon: <CopyOutlined />, action: onCopy(id), primary: false }].map(
        (b) => (
          <div className={styles.actionButton} onClick={b.action}>
            {b.icon}
          </div>
        ),
      )}
      <DeleteGuaranteeModal name={title} onClick={onDelete(id)}>
        <div className={styles.actionButton}>
          <DeleteOutlined />
        </div>
      </DeleteGuaranteeModal>
    </div>
  );

  // table data
  const tableData: TTableData[] =
    guaranteesData.list?.map((item: TGuarantee, index: number) => ({
      key: item.id,
      id: item.id,
      name: item.name,
      description: item.description,
      createdAt: item.createdAt
        ? dayjs(item.createdAt).format('DD/MM/YYYY')
        : undefined,
      actions: actionsBlock(item.id, item.name),
    })) || [];

  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };

  const columns = [
    {
      title: t('guarantees.table.id'),
      dataIndex: 'id',
      sorter: true,
      ...(query.has('sort') &&
      ['id', '-id'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-id' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('guarantees.table.name'),
      dataIndex: 'name',
      width: '35%',
      render: (name: string, record: TTableData) => (
        <Link
          to={`/guarantees/${record.id}?${query.toString()}`}
        >
          {name}
        </Link>
      ),
      sorter: true,
      ...(query.has('sort') &&
      ['name', '-name'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-name' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('guarantees.table.description'),
      dataIndex: 'description',
    },
    {
      title: t('guarantees.table.creationDate'),
      dataIndex: 'createdAt',
      sorter: true,
      ...(query.has('sort') &&
      ['createdAt', '-createdAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-createdAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      width: 50,
    },
  ];

  const onBulkOperation = () => {
    const selectedGuarantees =
      guaranteesData.list?.filter(({ id }) => selectedIds.indexOf(id) !== -1) ||
      [];
    switch (bulkOption) {
      case 'delete': {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedGuarantees }));
        break;
      }
      default: {
        // do nothing
      }
    }
    setSelectedIds([]);
  };

  // bulk menu dropdown
  const dropdownOptions = [{ key: 'delete', text: t('generic.delete') }];
  const dropdownItems = dropdownOptions.map((item) => (
    <Menu.Item key={item.key}> {item.text}</Menu.Item>
  ));

  return (
    <div key={`guarantees-list-${Array.from(query.keys()).length}`}>
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('nav.category.products')]: '/guarantees',
          [t('nav.item.guarantees')]: '',
        }}
        title={t('guarantees.manageTitle')}
      />
      {guaranteesData.list?.length === 0 && search === null ? (
        <Layout className={styles.layout}>
          <ReactSVG
            className={styles.emptyIcon}
            src="/assets/guarantees/noExistGuarantees.svg"
          />
          <span className={styles.emptyText}>{t('guarantees.empty.text')}</span>
          <Button
            onClick={() => navigate('/guarantees/new')}
            className={styles.signInButton}
            type="primary"
          >
            {t('guarantees.createGuarantee')}
          </Button>
        </Layout>
      ) : (
        <>
          <div className={styles.toolBar}>
            <div className={styles.toolBarTitle}>
              {t('guarantees.toolBarTitle')}
            </div>
            <div className={styles.buttonContainer}>
              <Search
                allowClear
                defaultValue={query.get('search') || ''}
                className={styles.searchField}
                placeholder={t('guarantees.placeholder.search') || ''}
                onSearch={(val) => {
                  setSearch(val);

                  if (val) {
                    query.set('search', val);
                  } else {
                    query.delete('search');
                  }

                  query.set('page', '1');
                  query.set(
                    'perPage',
                    guaranteesData.listParams?.pagination?.perPage
                      ? guaranteesData.listParams?.pagination?.perPage.toString()
                      : '10',
                  );

                  navigate({
                    search: `?${query.toString()}`,
                  });
                }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate('/guarantees/new')}
              >
                {t('guarantees.createGuarantee')}
              </Button>
            </div>
          </div>
          {!!selectedIds.length && (
            <div className={styles.bulkMenu}>
              <div className={styles.bulkMenuCounter}>
                {t('generic.bulk.selected', { length: selectedIds.length })}
              </div>
              <Dropdown
                className={styles.bulkMenuDropdown}
                overlay={
                  <Menu onClick={(e) => setBulkOption(e.key)}>
                    {dropdownItems}
                  </Menu>
                }
              >
                <Button>
                  {dropdownOptions.find((o) => o.key === bulkOption)?.text}{' '}
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Button type="primary" onClick={onBulkOperation}>
                {t('generic.confirm')}
              </Button>
            </div>
          )}
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData}
            pagination={{
              total: guaranteesData.listParams?.pagination?.total,
              current: guaranteesData.listParams?.pagination?.page,
            }}
            actions={actions}
          />
        </>
      )}
    </div>
  );
}

export default GuaranteesList;
