export type TTableData = {
  id: number;
  key: number | string;
  title: string;
  category: string;
  goalType: string;
  rewardInPoints: number;
  type: string;
  status: string;
  entity: string;
  actions: JSX.Element;
};

export type TTypesElements = {
  Individual: JSX.Element;
  Global: JSX.Element;
  Team: JSX.Element;
};

export enum EChallengeStatuses {
  DRAFT = 'draft',
  ONGOING = 'ongoing',
  UPCOMING = 'upcoming',
  FINISHED = 'finished',
  INACTIVE = 'inactive',
}
