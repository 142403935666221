import {
  apiRequest,
  apiRequestFormData,
  apiRequestGet,
  TListParams,
} from '../../../../../../helpers/apiRequest';
import {
  TAdminUser,
  TCompanyAdminCreate,
  TCompanyAdminResponse,
  TCompanyAdminsListResponse,
} from './CompanyAdminsType';

export const createCompanyAdmin = async (
  mainContactData: TCompanyAdminCreate,
): Promise<TCompanyAdminResponse> =>
  apiRequest<TCompanyAdminResponse>(
    '/admin-users',
    'post',
    mainContactData,
    { version: 'v2' },
  );

export const deleteCompanyAdmin = async (
  id: TAdminUser['id'],
): Promise<TCompanyAdminResponse> =>
  apiRequest<TCompanyAdminResponse>(`/admin-users/${id}`, 'delete', null, { version: 'v2' });

export const updateCompanyAdmin = async (
  id: TAdminUser['id'],
  mainContactData: TAdminUser,
): Promise<TCompanyAdminResponse> =>
  apiRequest<TCompanyAdminResponse>(
    `/admin-users/${id}`,
    'patch',
    mainContactData,
    { version: 'v2' },
  );

export const fetchCompanyAdmin = async (
  listParams?: TListParams,
): Promise<TCompanyAdminsListResponse> =>
  apiRequestGet<TCompanyAdminsListResponse>('/admin-users', {...listParams, version: 'v2'});
