import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import styles from './ColorPicker.module.scss';

type TColorPickerProps = {
  currentColor: string;
  onColorSelect?: (color: string) => void;
  displayOnly?: boolean;
};

function ColorPicker({
  currentColor,
  displayOnly,
  onColorSelect,
}: TColorPickerProps) {
  const [showPicker, setShowPicker] = useState(false);

  const switchPicker = () => {
    if (displayOnly) return;
    setShowPicker(!showPicker);
  };

  return (
    <>
      <div
        className={`${styles.colorWrap} ${
          displayOnly ? '' : styles.cursorPointer
        }`}
        onClick={switchPicker}
      >
        <div
          className={styles.color}
          style={{ backgroundColor: currentColor }}
        />
      </div>
      {showPicker ? (
        <>
          <div
            className={styles.globalButton}
            onClick={(e) => {
              e.preventDefault();
              switchPicker();
            }}
          />
          <div className={styles.colorPicker}>
            <SketchPicker
              color={currentColor}
              onChange={(color) => onColorSelect?.(color.hex)}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ColorPicker;
