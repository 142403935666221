import { Button} from "antd";
import styles from "./TranslationTabs.module.scss"
import { Config } from "../../config/config";
import { useTranslation } from "react-i18next";

function TranslationTabs({
  selectedLanguage,
  onLanguageTab
}:{
  selectedLanguage: string,
  onLanguageTab: (value: string) => void,
}) {
  const { t } = useTranslation();

  const languages = Config.I18N.LOCALES;

  return (
    <>
      {languages.map((appLanguage) => (
        <Button
          className={styles.languageButton}
          type={
            appLanguage === selectedLanguage
              ? 'primary'
              : 'default'
          }
          onClick={() => onLanguageTab(appLanguage)}
        >
          {t(`languages.${appLanguage}`)}
        </Button>
      ))}
    </>
  )
}

export default TranslationTabs;