import { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Key } from 'antd/es/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import ModalCenter from '../../../../../../../../../../components/ModalCenter/ModalCenter';
import { TProductEmployee } from '../AssignEmployeesTypes';
import {
  actions,
  asyncActions,
  selectors,
} from '../../../../../Employees/EmployeesSlice';
import { selectors as companySelectors } from '../../../../../../../CompaniesSlice';
import { TUserCompanyProfile } from '../../../../../Employees/EmployeesType';
import styles from './EmployeesModal.module.scss';
import Table from '../../../../../../../../../../components/Table/Table';

const { Search } = Input;

type TEmployeesModalProps = {
  show: boolean;
  selectedIds: number[];
  onSave: (ids: Key[], employees: TUserCompanyProfile[]) => void;
  onClose: () => void;
  companyId: number;
};

type TEmployeesTableData = {
  key: Key;
  id: TProductEmployee['id'];
  name: string;
  companyEmail: string;
  admissionDate: string;
};

function EmployeesModal({
  show,
  selectedIds,
  onSave,
  onClose,
  companyId,
}: TEmployeesModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const employees = useSelector(selectors.listData);
  const currentCompany = useSelector(companySelectors.currentItem);

  useEffect(() => {
    if (show) {
      dispatch(actions.setSearch({ query: '' }));
      dispatch(actions.clearFilters());
      dispatch(actions.setPagination({ page: 1, perPage: 10 }));
      dispatch(actions.setOrder({ field: 'id', direction: 'desc' }));
      dispatch<any>(asyncActions.fetchList({ id: companyId }));
    }
  }, [dispatch, companyId, show]);

  useEffect(() => {
    if (employees.outdated || !employees.list) {
      dispatch<any>(asyncActions.fetchList({ id: companyId }));
    }
  }, [companyId, dispatch, employees]);

  const [selectedEmployeesIds, setSelectedEmployeesIds] = useState(
    null as Array<number> | null,
  );
  const [selectedEmployees, setSelectedEmployees] = useState(
    null as Array<TUserCompanyProfile> | null,
  );

  const onChangeSelection = (selected: number[]) => {
    const employeesList = uniqBy(
      [...(selectedEmployees || []), ...(employees.list || [])],
      'userId',
    );

    setSelectedEmployeesIds(selected);
    setSelectedEmployees(
      employeesList.filter((emp) => selected.includes(emp.user.id)) || [],
    );
  };

  const onSaveChanges = () => {
    if (selectedEmployeesIds && selectedEmployees) {
      onSave(selectedEmployeesIds, selectedEmployees);
    }
  };

  const clearValues = () => {
    setSelectedEmployees(null);
    setSelectedEmployeesIds(null);
    dispatch(actions.clearFilters());
    dispatch(actions.clearList());
  };

  const onCancel = () => {
    clearValues();
    onClose();
  };

  const tableData: TEmployeesTableData[] = (employees?.list || []).map((item: TUserCompanyProfile) => ({
    key: item.user?.id,
    id: item.user?.id,
    name: `${item.user?.firstName} ${item.user?.lastName}`,
    companyEmail: item.companyEmail,
    admissionDate: item.admissionDate
      ? new Date(item.admissionDate).toLocaleDateString()
      : '-',
  })) || [];

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedEmployeesIds || selectedIds,
    getCheckboxProps: (record: any) => ({ disabled: selectedIds.includes(record.id) }),
    onChange: (selectedRows: Key[]) =>
      onChangeSelection(selectedRows as number[]),
  };
  const columns = [
    {
      title: t('guarantees.table.id'),
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: t('users.userName'),
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: t('generic.email'),
      dataIndex: 'companyEmail',
      sorter: true,
    },
    {
      title: t('generic.creationDate'),
      dataIndex: 'admissionDate',
      sorter: true,
    },
  ];

  return (
    <ModalCenter show={show}>
      <div className={styles.wrap}>
        <ProPageHeader
          prefixedClassName={styles.pageHeader}
          title={t('products.assignEmployeesTitle')}
          onBack={() => {}}
          backIcon={(
            <Button
              icon={<ArrowLeftOutlined />}
              type="text"
              style={{ margin: 0, padding: 0 }}
              onClick={onClose}
            />
          )}
        />
        <div className={styles.toolBar}>
          <div className={styles.toolBarTitle}>
            {t('products.assignedEmployeesCount', {
              count: selectedEmployeesIds
                ? selectedEmployeesIds.length
                : selectedIds.length,
              total: currentCompany?.totalEmployees || 0,
            })}
          </div>
          <div className={styles.buttonContainer}>
            <Search
              allowClear
              className={styles.searchField}
              placeholder={t('products.searchForEmployee') || ''}
              onSearch={(val) => {
                dispatch(
                  actions.setPagination({
                    page: 1,
                    perPage: employees.listParams?.pagination?.perPage || 10,
                  }),
                );
                dispatch(actions.setSearch({ query: val }));
                dispatch<any>(asyncActions.fetchList({ id: companyId }));
              }}
            />
          </div>
        </div>
        <Table
          enableUrlChange={false}
          actions={actions}
          asyncActions={asyncActions}
          defaultOrder={{ field: 'id', direction: 'desc' }}
          fetchListActionParams={{ id: companyId }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: employees.listParams?.pagination?.total,
            current: employees.listParams?.pagination?.page,
          }}
          customOrderHandler={(sorter) => {
            if (sorter.field === 'name') {
              dispatch(actions.clearOrder());
              dispatch(
                actions.setFilter({
                  field: 'orderByName',
                  value: sorter.order === 'descend' ? 'DESC' : 'ASC',
                  custom: true,
                }),
              );
            }

            dispatch(
              actions.setOrder({
                field: sorter.field,
                direction: sorter.order === 'descend' ? 'desc' : 'asc',
              }),
            );
          }}
        />
        <div className={styles.buttons}>
          <Button className={styles.cancelButton} onClick={onCancel}>
            {t('generic.cancel')}
          </Button>
          <Button type="primary" onClick={onSaveChanges}>
            {t('generic.confirmChanges')}
          </Button>
        </div>
      </div>
    </ModalCenter>
  );
}

export default EmployeesModal;
