import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Empty, Table } from 'antd';
import {
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Key } from 'antd/es/table/interface';
import { actions, asyncActions, selectors } from './AssignEmployeesSlice';
import { selectors as companySelectors } from '../../../../../../CompaniesSlice';
import styles from './AssignedEmployees.module.scss';
import RoleWrapper from '../../../../../../../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../../../../../../../constants/userRoles';
import { TProductEmployee } from './AssignEmployeesTypes';
import EmployeesModal from './EmployeesModal/EmployeesModal';
import { TUserCompanyProfile } from '../../../../Employees/EmployeesType';
import BulkActions from '../../../../../../../../../components/BulkActions/BulkActions';

type TAssignEmployeesProps = {
  productId: number;
  companyId: number;
  onSelectEmployees: (ids: number[]) => void;
  onDeleteEmployees: (ids: number) => void;
  disabled?: boolean;
};

type TTableData = {
  key: Key;
  id: TProductEmployee['id'];
  firstName: string;
  email: string;
  createdAt: string;
};

function AssignEmployees({
  productId,
  companyId,
  onSelectEmployees,
  onDeleteEmployees,
  disabled,
}: TAssignEmployeesProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const assignedEmployees = useSelector(selectors.listData);
  const currentCompany = useSelector(companySelectors.currentItem);

  useEffect(() => {
    dispatch(actions.setPagination({ page: 1, perPage: 1000000 }));
    dispatch<any>(asyncActions.fetchList({ id: productId }));
  }, [productId, dispatch]);

  useEffect(
    () => () => {
      setSelectedEmployees([]);
      setAssignEmployeeIds([]);
    },
    [],
  );

  useEffect(() => {
    if (assignedEmployees.outdated) {
      dispatch<any>(asyncActions.fetchList({ id: productId }));
      setSelectedEmployees([]);
    }
  }, [assignedEmployees, dispatch]);

  const [showAddEmployees, setShowAddEmployees] = useState(false);
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [assignEmployeeIds, setAssignEmployeeIds] = useState(
    null as Key[] | null,
  );
  const [selectedEmployees, setSelectedEmployees] = useState(
    [] as TUserCompanyProfile[],
  );

  const assignedEmployeesManger = (
    addEmployees: Key[] | null,
    deleteEmployees: Key[] | null,
  ) => {
    let newList: Key[];
    if (!assignEmployeeIds) {
      newList = assignedEmployees.list?.map((empl) => empl.id) || [];
    } else {
      newList = assignEmployeeIds;
    }

    let unassignedEmployees = null;

    if (addEmployees) {
      unassignedEmployees = addEmployees.filter((item) => !newList.includes(item));

      newList = newList
        .filter((l) => addEmployees.indexOf(l) === -1)
        .concat(addEmployees);
    }
    if (deleteEmployees) {
      newList = newList.filter((l) => deleteEmployees.indexOf(l) === -1);
      setSelectedEmployees(
        selectedEmployees.filter((se) => newList.indexOf(se.user.id) !== -1),
      );
    }

    setAssignEmployeeIds(newList);
    onSelectEmployees(unassignedEmployees as number[]);

    return newList;
  };

  const deleteEmployeesManager = (id: number) => {
    const newList = assignEmployeeIds?.filter((item) => item !== id);
    if (newList) {
      setSelectedEmployees(
        selectedEmployees.filter((se) => newList.indexOf(se.user.id) !== -1),
      );
    }
    onDeleteEmployees(id);
  };

  const tableData: TTableData[] = (
    (assignedEmployees?.list || [])
      .filter((item) =>
        (assignEmployeeIds ? assignEmployeeIds.indexOf(item.id) !== -1 : true))
      .filter(
        (item) =>
          selectedEmployees.map((se) => se.user.id).indexOf(item.id) === -1,
      )
      .map((item: TProductEmployee) => ({
        key: item.id,
        id: item.id,
        firstName: `${item.firstName} ${item.lastName}`,
        email: item.email,
        createdAt: new Date(
          item.productToUser[0]?.createdAt,
        ).toLocaleDateString(),
      })) || []
  ).concat(
    (selectedEmployees || []).map((item: TUserCompanyProfile) => ({
      key: item.userId,
      id: item.userId,
      firstName: `${item.user.firstName} ${item.user.lastName}`,
      email: item.companyEmail,
      createdAt: new Date().toLocaleDateString(),
    })) || [],
  );
  // TODO: uncomit
  // const rowSelection = {
  //   selectedRowKeys: selectedIds,
  //   onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  // };
  const columns = [
    {
      title: t('generic.id'),
      dataIndex: 'id',
      sorter: (a: TTableData, b: TTableData) => (a.id < b.id ? -1 : 1),
    },
    {
      title: t('generic.companyUser'),
      dataIndex: 'firstName',
      sorter: (a: TTableData, b: TTableData) =>
        (a.firstName < b.firstName ? -1 : 1),
    },
    {
      title: t('generic.email'),
      dataIndex: 'email',
      sorter: (a: TTableData, b: TTableData) => (a.email < b.email ? -1 : 1),
    },
    {
      title: t('collective.company.products.additionDate'),
      dataIndex: 'createdAt',
      sorter: (a: TTableData, b: TTableData) =>
        (dayjs(a.createdAt) < dayjs(b.createdAt) ? -1 : 1),
    },
    ...(disabled
      ? []
      : [
        {
          title: t('generic.actions'),
          dataIndex: 'actions',
          render: (_: null, record: TTableData) => (
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => {
                deleteEmployeesManager(record.id);
              }}
            />
          ),
        },
      ]),
  ];

  const dropdownOptions = [
    {
      key: 'delete',
      text: t('generic.delete'),
      action: () => {
        assignedEmployeesManger(null, selectedIds);
        setSelectedIds([]);
      },
    },
  ];

  if (!currentCompany?.totalEmployees) {
    return (
      <div className={styles.wrap}>
        <div className={styles.emptyBack}>
          <div className={styles.title}>{t('products.assignedEmployees')}</div>
          <Empty
            className={styles.empty}
            image="/assets/products/no_company_employees.svg"
            description={t('products.placeholder.noCompanyEmployees')}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <EmployeesModal
        show={showAddEmployees}
        selectedIds={
          (assignEmployeeIds
            || assignedEmployees?.list?.map((e) => e.id)
            || []) as number[]
        }
        onSave={(ids: Key[], employees: TUserCompanyProfile[]) => {
          const deleteIds = (
            assignEmployeeIds
            || assignedEmployees.list?.map((empl) => empl.id)
            || []
          ).filter((ae) => ids.indexOf(ae) === -1);
          assignedEmployeesManger(ids, deleteIds);
          setSelectedEmployees(employees);
          setShowAddEmployees(false);
        }}
        onClose={() => setShowAddEmployees(false)}
        companyId={companyId}
      />
      <div className={styles.wrap}>
        {tableData.length ? (
          <>
            <div className={styles.toolBar}>
              <div className={styles.toolBarTitle}>
                {t('products.assignedEmployeesTotal', {
                  count: tableData.length || 0,
                })}
              </div>
              {!disabled && (
                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.assignButton}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setShowAddEmployees(true);
                    }}
                  >
                    {t('products.assignEmployees')}
                  </Button>
                </div>
              )}
            </div>
            {!!selectedIds.length && (
              <BulkActions
                dropdownOptions={dropdownOptions}
                selectedKeys={selectedIds.map((key) => key.toString())}
              />
            )}
            <Table
              // TODO: uncomment when API for multiple delete will be implemented
              // rowSelection={disabled ? undefined : rowSelection}
              columns={columns}
              dataSource={tableData}
              pagination={{
                showQuickJumper: true,
                defaultPageSize: 10,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '50', '100'],
                showSizeChanger: true,
              }}
            />
          </>
        ) : (
          <div className={styles.emptyBack}>
            <div className={styles.title}>
              {t('products.assignedEmployees')}
            </div>
            <Empty
              className={styles.empty}
              image="/assets/products/no_employees.svg"
              description={t('products.placeholder.noAssignedEmployees')}
            >
              <RoleWrapper roles={[UserRoles.ADMIN]}>
                <Button
                  className={styles.noUserButton}
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    setShowAddEmployees(true);
                  }}
                >
                  Assign employees
                </Button>
              </RoleWrapper>
            </Empty>
          </div>
        )}
      </div>
    </>
  );
}

AssignEmployees.defaultProps = {
  disabled: false,
};

export default AssignEmployees;
