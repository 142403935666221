import {
  getEmployees,
  assignEmployees,
  removeEmployee,
} from './AssignEmployeesApi';
import buildSlice from '../../../../../../../../general/helpers/genericSliceBuilder';
import {
  TProductEmployee,
  TProductEmployeeListResponse,
  TProductEmployeeResponse,
} from './AssignEmployeesTypes';

export const productsEmployeesSlice = buildSlice<
  TProductEmployee,
  TProductEmployeeResponse,
  TProductEmployeeListResponse
>('productsEmployees', {
  fetchList: getEmployees,
  singleRequests: [
    {
      request: assignEmployees as (
        id: number,
        data?: { [key: string]: any },
      ) => Promise<any>,
      name: 'assignEmployees',
    },
    {
      request: removeEmployee as (
        id: number,
        data?: { [key: string]: any },
      ) => Promise<any>,
      name: 'removeEmployee',
    },
  ],
});

export const { selectors, asyncActions, actions } = productsEmployeesSlice;

export default productsEmployeesSlice.reducer;
