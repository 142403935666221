import {
  TListParams,
  apiRequest,
  apiRequestFormData,
  apiRequestGet,
} from '../../helpers/apiRequest';
import { IFetchQuizzesResponse, TNewQuizResponse } from './QuizzesTypes';

export const createQuiz = async (
  quizData: FormData,
): Promise<TNewQuizResponse> =>
  apiRequestFormData<TNewQuizResponse>('/quizzes', 'post', quizData, null);

export const publishQuiz = async (id: number): Promise<TNewQuizResponse> =>
  apiRequest<TNewQuizResponse>(`/quizzes/${id}/publish`, 'post', null, null);

export const unPublishQuiz = async (id: number): Promise<TNewQuizResponse> =>
  apiRequest<TNewQuizResponse>(`/quizzes/${id}/unpublish`, 'post', null, null);

export const saveAsDraftQuiz = async (id: number): Promise<TNewQuizResponse> =>
  apiRequest<TNewQuizResponse>(
    `/quizzes/${id}/save-as-draft`,
    'post',
    null,
    null,
  );

export const fetchQuizzes = async (
  listParams?: TListParams,
): Promise<IFetchQuizzesResponse> =>
  apiRequestGet<IFetchQuizzesResponse>('/quizzes/quizzes-listing', {
    ...listParams,
  });

export const getQuiz = async (id: number): Promise<TNewQuizResponse> =>
  apiRequestGet<TNewQuizResponse>(`/quizzes/${id}`, null);

export const updateQuiz = async (
  id: number,
  quizData: FormData,
): Promise<TNewQuizResponse> =>
  apiRequestFormData<TNewQuizResponse>(
    `/quizzes/${id}`,
    'patch',
    quizData,
    null,
  );

export const deleteQuiz = async (id: number): Promise<TNewQuizResponse> =>
  apiRequest<TNewQuizResponse>(`/quizzes/${id}`, 'delete', null, null);
