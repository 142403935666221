import { useEffect, useMemo, useState } from 'react';
import { Button, Menu, Dropdown, Layout, Form, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ProPageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './EditGuarantee.module.scss';
import { actions, asyncActions, selectors } from '../GuaranteesSlice';
import DeleteGuaranteeModal from '../../../components/Modals/GuaranteesModals/DeleteGuaranteeModal';
import FormCard from '../../../components/FormCard/FormCard';
import RequestStatuses from '../../../constants/requestStatuses';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';

const initialState = {
  name: null as string | null,
  description: null as string | null,
};

const { Content } = Layout;
const { TextArea } = Input;

function EditGuarantee() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: guaranteeId } = useParams();

  const isExistingGuarantee = useMemo(
    () => guaranteeId !== 'new',
    [guaranteeId],
  );

  // selectors
  const currentGuarantee = useSelector(selectors.currentItem);
  const statuses = useSelector(selectors.statuses);

  const [state, setState] = useState(initialState);
  const [somethingChanged, setChangedFlag] = useState(false);
  const [nameError, setNameError] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (guaranteeId && isExistingGuarantee) {
      dispatch<any>(asyncActions.get({ id: +guaranteeId }));
    } else {
      dispatch(actions.clearCurrentItem());
    }
  }, [dispatch, guaranteeId]);

  useEffect(() => {
    dispatch(actions.clearCurrentItem());
    dispatch<any>(asyncActions.fetchList());

    return () => {
      dispatch(actions.clearCurrentItem());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      statuses.create === RequestStatuses.FAILED ||
      statuses.update === RequestStatuses.FAILED
    ) {
      setNameError('This name already exist');
    } else if (
      statuses.create === RequestStatuses.SUCCESS ||
      statuses.update === RequestStatuses.SUCCESS ||
      name === ''
    ) {
      setNameError('');
      clearState();
      onCancel();
    }
  }, [statuses]);

  const { name, description } = state;

  const onBack = (locationState: string = '') =>
    navigate('/guarantees',{
      state: {
        search: location.state as string,
        state: locationState,
      }
    });

  const onChange = (field: keyof typeof initialState, value: any) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
    setChangedFlag(true);
    nameError !== '' && setNameError('');
  };

  const validateFields = () => {
    let allFilled = true;
    [
      currentGuarantee?.name || name,
      currentGuarantee?.description || description,
    ].forEach((f) => {
      allFilled = allFilled && !!f;
    });

    return allFilled;
  };

  const clearState = () => {
    setState({ ...initialState });
  };

  const onSave = () => {
    const values = {
      name: name === null ? currentGuarantee?.name || '' : name,
      description:
        description === null
          ? currentGuarantee?.description || ''
          : description,
    };

    if (currentGuarantee?.id) {
      dispatch<any>(
        asyncActions.update({
          id: currentGuarantee.id,
          dataJson: values,
        }),
      );
    } else {
      dispatch<any>(asyncActions.create({ dataJson: values }));
    }
    dispatch<any>(asyncActions.fetchList());
    setChangedFlag(false);
  };

  const onCancel = () => {
    dispatch(actions.clearStatuses());
    clearState();
    onBack();
  };

  const onCancelPress = () => {
    if (somethingChanged) {
      setShowConfirmModal(true)
    } else {
      onCancel()
    }
  }

  // component to dropdown
  const headerMenu = (
    <Menu className={styles.dropdown}>
      <DeleteGuaranteeModal
        name={state.name ?? ''}
        onClick={() => {
          if (currentGuarantee) {
            dispatch<any>(asyncActions.delete({ item: currentGuarantee }));
            onCancel();
          }
        }}
      >
        <Menu.Item
          className={styles.deleteButton}
          key={Math.random()}
          disabled={!currentGuarantee}
        >
          {t('generic.buttons.delete')}
        </Menu.Item>
      </DeleteGuaranteeModal>
    </Menu>
  );
  return (
    <div>
      <></>
      <ProPageHeader
        prefixedClassName={styles.pageHeader}
        title={t('guarantees.form.creatEdit')}
        extra={[
          <Button className={styles.defaultButton} onClick={() => onCancelPress()}>
            {t('generic.modals.buttons.cancel')}
          </Button>,
          <Button
            disabled={!validateFields()}
            type="primary"
            onClick={() => onSave()}
          >
            {t('generic.forms.saveGuarantee')}
          </Button>,
          ...(currentGuarantee
            ? [
                <Dropdown overlay={headerMenu} placement="bottomRight">
                  <Button style={{ width: '32px', padding: 0 }}>...</Button>
                </Dropdown>,
              ]
            : []),
        ]}
        onBack={() => {}}
        backIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ margin: 0, padding: 0 }}
            onClick={() => onCancelPress()}
          />
        }
      />
      <div className={styles.container}>
        <div style={{ width: '75%' }}>
          <Layout className={styles.layout}>
            <Content className={styles.content}>
              <FormCard className={styles.card}>
                <Form className={styles.form} layout="vertical">
                  <div className={styles.title}>
                    {t('guarantees.form.cardTitle')}
                  </div>
                  <Form.Item
                    label={t('guarantees.form.name')}
                    className={styles.input}
                  >
                    <Input
                      onChange={(e) => onChange('name', e.target.value)}
                      placeholder={t('generic.example') || ''}
                      value={
                        name === null ? currentGuarantee?.name || '' : name
                      }
                    />
                    {nameError !== '' && (
                      <span style={{ color: 'red' }}>
                        {t('guarantees.form.nameValidation')}
                      </span>
                    )}
                  </Form.Item>
                  <Form.Item
                    label={t('guarantees.form.description')}
                    className={styles.input}
                  >
                    <TextArea
                      onChange={(e) => onChange('description', e.target.value)}
                      autoSize={{ minRows: 4, maxRows: 10 }}
                      showCount
                      maxLength={100}
                      value={
                        description === null
                          ? currentGuarantee?.description || ''
                          : description
                      }
                      placeholder={t('generic.placeholder.addDescription') || ''}
                    />
                  </Form.Item>
                </Form>
              </FormCard>
            </Content>
          </Layout>
        </div>
        {currentGuarantee && (
          <div style={{ width: '25%' }} className={styles.overViewContainer}>
            <span className={styles.overviewTitle}>
              {t('generic.overview')}
            </span>
            <div className={styles.overviewOptionContainer}>
              <div className={styles.overviewOptionTitle}>
                <span>
                  {t('guarantees.form.guaranteeInformation.guaranteeId')}
                </span>
              </div>
              <span>{currentGuarantee.id}</span>
            </div>
            <div className={styles.overviewOptionContainer}>
              <div className={styles.overviewOptionTitle}>
                <span>{t('guarantees.form.guaranteeInformation.created')}</span>
              </div>
              <span>
                {dayjs(currentGuarantee.createdAt).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
            <div className={styles.overviewOptionContainer}>
              <div className={styles.overviewOptionTitle}>
                <span>
                  {t('guarantees.form.guaranteeInformation.lastUpdate')}
                </span>
              </div>
              <span>
                {dayjs(currentGuarantee.updatedAt).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
          </div>
        )}
      </div>
      <RouteLeavingGuard
        lastLocation='/guarantees'
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </div>
  );
}

export default EditGuarantee;
