import { apiRequestGet, TListParams } from '../../../helpers/apiRequest';
import { TMembersListResponse } from './MembersType';

export const getAllMembersList = async (
  listParams?: TListParams,
): Promise<TMembersListResponse> =>
  apiRequestGet<TMembersListResponse>(
    `/insurance/products/users`,
    listParams,
  );
