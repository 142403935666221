import {
  createQuiz,
  publishQuiz,
  getQuiz,
  updateQuiz,
  unPublishQuiz,
  deleteQuiz,
  saveAsDraftQuiz,
  fetchQuizzes,
} from './QuizzesApi';
import { IQuiz, TNewQuizResponse, IFetchQuizzesResponse } from './QuizzesTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

export const quizzesSlice = buildSlice<
  IQuiz,
  TNewQuizResponse,
  IFetchQuizzesResponse
>('quizzes', {
  create: createQuiz,
  publish: publishQuiz,
  unpublish: unPublishQuiz,
  fetchList: fetchQuizzes,
  getOne: getQuiz,
  update: updateQuiz,
  delete: deleteQuiz,
  saveAsDraft: saveAsDraftQuiz,
});

export const { selectors, asyncActions, actions } = quizzesSlice;

export default quizzesSlice.reducer;
