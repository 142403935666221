import buildSlice from '../../general/helpers/genericSliceBuilder';
import {
  TCompany,
  TCompaniesListResponse,
  TCompaniesResponse,
} from './CompanyType';
import { fetchAllCompanies } from './CompaniesApi';

const allCompaniesSlice = buildSlice<
  TCompany,
  TCompaniesResponse,
  TCompaniesListResponse
>('allCompanies', {
  fetchList: fetchAllCompanies as (
    params: any,
  ) => Promise<TCompaniesListResponse>,
});

export const { selectors, asyncActions, actions } = allCompaniesSlice;

export default allCompaniesSlice.reducer;
