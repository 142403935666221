import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Bar, RingProgress } from '@ant-design/charts';

import PageHeaderDefault from '../../components/PageHeaderDefault/PageHeaderDefault';
import styles from './WellnessAnalytics.module.scss';
import {
  getCompaniesList,
  getCompanyAnalytics,
  getDailyActivity,
  getEngagementStatistics,
  selectCompaniesList,
  selectCompanyStatistics,
  selectDailyActivity,
  selectEngagementStatistics,
} from './WellnessAnalyticsSlice';
import { TCompany } from '../collective/companies/CompanyType';
import Badge from '../../components/Badge/Badge';
import { TChallenge } from './WellnessAnalyticsType';
import RoleWrapper from '../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../constants/userRoles';
import { selectAuthenticatedUser } from '../auth/AuthSlice';
import ChallengeEngagement from './ChallengeEngagement/ChallengeEngagement';

enum EFilterBy {
  ALL_USERS = 'allUsers',
  APP_USERS = 'appUsers',
  COMPANY_USERS = 'companyUsers',
}

type TSelectedCompany = {
  id: number | null;
  companyName: string | null;
};

function WellnessAnalytics() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const [filterBy, setFilterBy] = useState(
    authenticatedUser.role === UserRoles.ADMIN
      ? EFilterBy.ALL_USERS
      : EFilterBy.COMPANY_USERS,
  );
  const [selectedPeriod, setSelectedPeriod] = useState(7);
  const [selectedCompany, setSelectedCompany] = useState({
    id: null,
    companyName: t('wellness.analytics.allCompanies'),
  } as TSelectedCompany);
  const [showChallengeModal, setShowChallengeModal] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(selectedPeriod, 'days').startOf('day'),
  );
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  const companies = useSelector(selectCompaniesList);
  const companyStatistics = useSelector(selectCompanyStatistics);
  const dailyActivity = useSelector(selectDailyActivity);
  const engagementStatistics = useSelector(selectEngagementStatistics);

  useEffect(() => {
    dispatch<any>(getCompaniesList());
  }, [dispatch]);

  useEffect(() => {
    const startDateC = dayjs().subtract(selectedPeriod, 'days').startOf('day');
    const endDateC = dayjs().endOf('day');

    setStartDate(startDateC);
    setEndDate(endDateC);

    const filters = [];
    if (selectedCompany?.id) {
      filters.push({
        field: 'companyId',
        value: selectedCompany.id,
      });
    }
    if ([EFilterBy.APP_USERS, EFilterBy.ALL_USERS].includes(filterBy)) {
      filters.push({ field: 'notCompanyUsers', value: filterBy });
    }
    const params = {
      ...(filters.length ? { filter: filters } : {}),
      createdAt: {
        createdAtStart: startDateC.toISOString(),
        createdAtEnd: endDateC.toISOString(),
      },
    };

    dispatch<any>(getCompanyAnalytics(params));
    dispatch<any>(getDailyActivity(params));
    dispatch<any>(getEngagementStatistics(params));
  }, [dispatch, selectedPeriod, selectedCompany, filterBy]);

  let registeredUsersPercent = 0;
  if (companyStatistics.totalEmployees) {
    registeredUsersPercent =
      (companyStatistics.registeredUsers * 100) /
      companyStatistics.totalEmployees;
  }

  let activeUsersPercent = 0;
  if (companyStatistics.registeredUsers) {
    activeUsersPercent =
      (companyStatistics.activeUsers.currentActivity * 100) /
      companyStatistics.registeredUsers;
  }

  let activeUsersDiffPercent = 0;
  if (companyStatistics.activeUsers.currentActivity) {
    const activeUsersDiff =
      companyStatistics.activeUsers.currentActivity -
      companyStatistics.activeUsers.previousActivity;
    activeUsersDiffPercent =
      (activeUsersDiff * 100) / companyStatistics.activeUsers.currentActivity;
  }

  let stepsDiffValue = 0;
  if (dailyActivity.current.steps) {
    stepsDiffValue = dailyActivity.current.steps - dailyActivity.history.steps;
  }

  let caloriesDiffValue = 0;
  if (dailyActivity.current.calories) {
    caloriesDiffValue =
      dailyActivity.current.calories - dailyActivity.history.calories;
  }

  let distanceDiffValue = 0;
  if (dailyActivity.current.distance) {
    distanceDiffValue =
      dailyActivity.current.distance - dailyActivity.history.distance;
  }

  let rewardDiffPercent = 0;
  if (engagementStatistics?.mostClaimedReward?.current?.usersClaimed) {
    const { current, previous } = engagementStatistics.mostClaimedReward;
    const usersDiff = current.usersClaimed - previous.usersClaimed;
    rewardDiffPercent =
      (usersDiff * 100) /
      engagementStatistics.mostClaimedReward.current.usersClaimed;
  }

  let mostCompletedChallengePercent = 0;
  if (engagementStatistics?.mostCompletedChallenge?.usersCompleted) {
    const { usersCompleted = 0, usersJoined = 1 } =
      engagementStatistics?.mostCompletedChallenge || {};
    mostCompletedChallengePercent = (usersCompleted * 100) / usersJoined;
  }

  let leastCompletedChallengePercent = 0;
  if (engagementStatistics?.leastCompletedChallenge?.usersCompleted) {
    const { usersCompleted = 0, usersJoined = 1 } =
      engagementStatistics?.leastCompletedChallenge || {};
    leastCompletedChallengePercent = (usersCompleted * 100) / usersJoined;
  }

  return (
    <>
      <ChallengeEngagement
        show={showChallengeModal}
        onClose={() => setShowChallengeModal(false)}
        totalUsers={companyStatistics.totalEmployees || 0}
        filterType={filterBy}
        companyId={selectedCompany?.id}
        createdAtStart={startDate.toISOString()}
        createdAtEnd={endDate.toISOString()}
      />
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <PageHeaderDefault
                routes={{
                  [t('nav.category.home')]: '/',
                  [t('nav.category.wellness')]: '/wellness/analytics',
                  [t('nav.item.analytics')]: '',
                }}
                title={t('wellness.analytics.title')}
              />
            </Col>
          </Row>
          <div className={styles.filtersContainer}>
            <RoleWrapper roles={[UserRoles.ADMIN, UserRoles.COMPANY_MANAGER]}>
              <Form className={styles.form} layout="horizontal">
                <RoleWrapper roles={[UserRoles.ADMIN]}>
                  <Form.Item
                    className={styles.formItem}
                    label={t('wellness.analytics.filterBy')}
                  >
                    <Select
                      className={styles.select}
                      value={filterBy}
                      onChange={(value: EFilterBy) => {
                        setFilterBy(value);
                        if (value !== EFilterBy.COMPANY_USERS) {
                          setSelectedCompany({
                            id: null,
                            companyName: t('wellness.analytics.allCompanies'),
                          });
                        }
                      }}
                      options={[
                        {
                          value: EFilterBy.ALL_USERS,
                          label: t('wellness.analytics.allUsers'),
                        },
                        {
                          value: EFilterBy.APP_USERS,
                          label: t('wellness.analytics.appUsers'),
                        },
                        {
                          value: EFilterBy.COMPANY_USERS,
                          label: t('wellness.analytics.companyUsers'),
                        },
                      ]}
                    />
                  </Form.Item>
                </RoleWrapper>
                <Form.Item
                  className={styles.formItem}
                  label={t('wellness.analytics.company')}
                >
                  <Select
                    className={styles.select}
                    disabled={filterBy !== EFilterBy.COMPANY_USERS}
                    defaultValue={selectedCompany?.id}
                    value={selectedCompany?.id}
                    onChange={(value) => {
                      const selected = companies.find(
                        (company: TCompany) => company.id === value,
                      );

                      setSelectedCompany({
                        id: value,
                        companyName:
                          selected?.companyName ||
                          t('wellness.analytics.allCompanies'),
                      });
                    }}
                    options={[
                      {
                        id: null,
                        companyName: t('wellness.analytics.allCompanies'),
                      },
                      ...companies,
                    ]?.map((company: TCompany) => ({
                      value: company.id,
                      label: company.companyName,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.formItem}
                  label={t('wellness.analytics.dates')}
                >
                  <Select
                    className={styles.select}
                    defaultValue={selectedPeriod}
                    onChange={(value) => setSelectedPeriod(value)}
                    options={[
                      {
                        value: 7,
                        label: t('wellness.analytics.last7Days'),
                      },
                      {
                        value: 14,
                        label: t('wellness.analytics.last14Days'),
                      },
                      {
                        value: 30,
                        label: t('wellness.analytics.lastMonth'),
                      },
                    ]}
                  />
                </Form.Item>
              </Form>
            </RoleWrapper>
          </div>
          <Row className={styles.analyticsSection}>
            <Col span={24}>
              <Row className={styles.subtitleContainer}>
                <Col span={24}>
                  <div className={styles.subtitle}>
                    {filterBy === EFilterBy.ALL_USERS &&
                      t('wellness.analytics.allUsers')}
                    {filterBy === EFilterBy.APP_USERS &&
                      t('wellness.analytics.appUsers')}
                    {filterBy === EFilterBy.COMPANY_USERS &&
                      selectedCompany?.companyName}
                  </div>
                  <div className={styles.subtitleDescription}>
                    {t('wellness.analytics.companyDataDesc')}
                  </div>
                </Col>
              </Row>
              <Row align="middle" gutter={24} justify="space-between">
                <Col span={8}>
                  <div className={styles.block}>
                    <Tooltip
                      placement="top"
                      title={t('wellness.analytics.tooltips.totalEmployees')}
                      className={styles.infoIcon}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <div className={styles.iconContainer}>
                      <img src="/assets/user/profile.svg" alt="profile" />
                    </div>
                    <div>
                      <div className={styles.blockTitle}>
                        {companyStatistics.totalEmployees}
                      </div>
                      <div className={styles.blockSubtitle}>
                        {[EFilterBy.APP_USERS, EFilterBy.ALL_USERS].includes(
                          filterBy,
                        )
                          ? t('wellness.analytics.totalUsers')
                          : t('wellness.analytics.totalEmployees')}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.block}>
                    <Tooltip
                      placement="top"
                      title={t('wellness.analytics.tooltips.registeredUsers')}
                      className={styles.infoIcon}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <div className={styles.iconContainer}>
                      <img src="/assets/user/user-plus.svg" alt="profile" />
                    </div>
                    <div>
                      <div className={styles.blockTitle}>
                        {registeredUsersPercent.toFixed(0)}%
                      </div>
                      <div className={styles.blockSubtitle}>
                        {t('wellness.analytics.registeredUsers')}
                      </div>
                      <div className={styles.blockInfo}>
                        {t('wellness.analytics.registeredUsersTotal', {
                          current: companyStatistics.registeredUsers,
                          total: companyStatistics.totalEmployees,
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.block}>
                    <Tooltip
                      placement="top"
                      title={t('wellness.analytics.tooltips.activeUsers', {
                        days: selectedPeriod,
                      })}
                      className={styles.infoIcon}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <div className={styles.iconContainer}>
                      <img
                        src="/assets/user/user-checkmark.svg"
                        alt="profile"
                      />
                    </div>
                    <div>
                      <div className={styles.blockTitle}>
                        {activeUsersPercent.toFixed(0)}%
                      </div>
                      <div className={styles.blockSubtitle}>
                        {t('wellness.analytics.activeUsers')}
                      </div>
                      <div className={styles.blockInfo}>
                        {t('wellness.analytics.activeUsersTotal', {
                          current:
                            companyStatistics.activeUsers.currentActivity,
                          total: companyStatistics.registeredUsers,
                        })}
                      </div>
                    </div>
                    <Badge
                      value={`${Math.abs(activeUsersDiffPercent).toFixed(0)}%`}
                      type={
                        activeUsersDiffPercent >= 0 ? 'positive' : 'negative'
                      }
                      className={styles.diffBadge}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={styles.analyticsSection}>
            <Col span={24}>
              <Row className={styles.subtitleContainer}>
                <Col span={24}>
                  <div className={styles.subtitle}>
                    {t('wellness.analytics.dailyActivity')}
                  </div>
                  <div className={styles.subtitleDescription}>
                    {t('wellness.analytics.dailyActivityDesc')}
                  </div>
                </Col>
              </Row>
              <Row align="middle" gutter={24} justify="space-between">
                <Col span={8}>
                  <div className={`${styles.block} ${styles.blockSmall}`}>
                    <Tooltip
                      placement="top"
                      title={t('wellness.analytics.tooltips.steps')}
                      className={styles.infoIcon}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <div className={styles.iconContainer}>
                      <img src="/assets/wellness/steps.svg" alt="steps" />
                    </div>
                    <div>
                      <div className={styles.blockTitle}>
                        {t('wellness.analytics.steps')}
                      </div>
                      <div className={styles.blockSubtitle}>
                        {dailyActivity.current.steps.toFixed(0)}
                      </div>
                    </div>
                    <Badge
                      value={t('wellness.analytics.stepsDiff', {
                        value: Math.abs(stepsDiffValue).toFixed(0),
                      })}
                      type={stepsDiffValue >= 0 ? 'positive' : 'negative'}
                      className={styles.diffBadge}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className={`${styles.block} ${styles.blockSmall}`}>
                    <Tooltip
                      placement="top"
                      title={t('wellness.analytics.tooltips.calories')}
                      className={styles.infoIcon}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <div className={styles.iconContainer}>
                      <img
                        src="/assets/wellness/burned-cal.svg"
                        alt="burned-cal"
                      />
                    </div>
                    <div>
                      <div className={styles.blockTitle}>
                        {t('wellness.analytics.burnedCalories')}
                      </div>
                      <div className={styles.blockSubtitle}>
                        {dailyActivity.current.calories.toFixed(0)}
                      </div>
                    </div>
                    <Badge
                      value={`${Math.abs(caloriesDiffValue).toFixed(0)} cal`}
                      type={caloriesDiffValue >= 0 ? 'positive' : 'negative'}
                      className={styles.diffBadge}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className={`${styles.block} ${styles.blockSmall}`}>
                    <Tooltip
                      placement="top"
                      title={t('wellness.analytics.tooltips.distance')}
                      className={styles.infoIcon}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <div className={styles.iconContainer}>
                      <img src="/assets/wellness/distance.svg" alt="distance" />
                    </div>
                    <div>
                      <div className={styles.blockTitle}>
                        {t('wellness.analytics.distance')}
                      </div>
                      <div className={styles.blockSubtitle}>
                        {(dailyActivity.current.distance / 1000).toFixed(2)} Km
                      </div>
                    </div>
                    <Badge
                      value={`${Math.abs(distanceDiffValue / 1000).toFixed(
                        2,
                      )} Km`}
                      type={distanceDiffValue >= 0 ? 'positive' : 'negative'}
                      className={styles.diffBadge}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row className={styles.subtitleContainer}>
                <Col span={24}>
                  <div className={styles.subtitle}>
                    {t('wellness.analytics.engagement')}
                  </div>
                  <div className={styles.subtitleDescription}>
                    {t('wellness.analytics.engagementDesc')}
                  </div>
                </Col>
              </Row>
              <Row
                justify="space-between"
                className={styles.engagementContainer}
              >
                <Row className={styles.block} align="top">
                  <Col span={16} className={styles.popularChallengesContainer}>
                    <Row>
                      <div className={styles.engagementBlockTitle}>
                        {t('wellness.analytics.mostJoinedChallenges')}
                        {!!engagementStatistics?.popularChallenges?.length && (
                          <Button
                            className={styles.showMoreButton}
                            type="link"
                            onClick={() => setShowChallengeModal(true)}
                          >
                            {t('generic.showMore')}
                          </Button>
                        )}
                      </div>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {engagementStatistics?.popularChallenges?.length ? (
                          <div
                            className={styles.popularChallengesDataContainer}
                          >
                            <div
                              className={styles.popularChallengesListContainer}
                            >
                              {engagementStatistics?.popularChallenges?.map(
                                (challenge: TChallenge) => (
                                  <div
                                    key={`challenge-${challenge.id}`}
                                    className={styles.popularChallenge}
                                  >
                                    <div
                                      className={styles.bulletSign}
                                      style={{
                                        backgroundColor: challenge.color,
                                      }}
                                    />
                                    <div
                                      className={styles.challengeInfoContainer}
                                    >
                                      <div
                                        className={styles.popularChallengeTitle}
                                      >
                                        {challenge.title}
                                      </div>
                                      <div
                                        className={styles.popularChallengeUsers}
                                      >
                                        {t('wellness.analytics.joinedUsers', {
                                          users: challenge.usersJoined,
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                ),
                              )}
                            </div>
                            <div>
                              <Bar
                                data={engagementStatistics?.popularChallenges?.map(
                                  (challenge: TChallenge, index: number) => {
                                    let percent = 0;
                                    if (challenge?.usersJoined) {
                                      percent =
                                        ((challenge?.usersJoined || 0) * 100) /
                                        companyStatistics.totalEmployees;
                                    }

                                    return {
                                      percent: +percent.toFixed(0),
                                      label: challenge.title,
                                    };
                                  },
                                )}
                                appendPadding={[0, 30, 5, 0]}
                                autoFit
                                height={200}
                                width={300}
                                xField="percent"
                                yField="label"
                                seriesField="label"
                                tooltip={false}
                                legend={false}
                                label={{
                                  fields: ['percent'],
                                  position: 'right',
                                  formatter: (data) => `${data.percent}%`,
                                  style: {
                                    fontWeight: 600,
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: 12,
                                  },
                                }}
                                xAxis={{
                                  label: {
                                    style: {
                                      fontFamily: "'Poppins', sans-serif",
                                    },
                                    formatter: (data) => `${data}%`,
                                  },
                                }}
                                yAxis={{
                                  label: {
                                    formatter: () => '',
                                  },
                                }}
                                meta={{
                                  percent: {
                                    min: 0,
                                    max: 100,
                                  },
                                }}
                                renderer="svg"
                                color={engagementStatistics?.popularChallenges?.map(
                                  (challenge: TChallenge) =>
                                    challenge?.color || '#69AA9E',
                                )}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className={styles.engagementEmpty}>
                            {t('wellness.analytics.noActivity')}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8} className={styles.challengesCompletion}>
                    <Row>
                      <Col span={24} className={styles.engagementBlockTitle}>
                        {t('wellness.analytics.challengesCompletion')}
                      </Col>
                    </Row>
                    <Row className={styles.challengesCompletionData}>
                      <Col span={24}>
                        <div className={styles.challengesCompletionBlock}>
                          {engagementStatistics?.mostCompletedChallenge ? (
                            <>
                              <div
                                className={`${styles.completedChallengeChart} ${
                                  mostCompletedChallengePercent >= 50
                                    ? styles.completedChallengeChartGreen
                                    : styles.completedChallengeChartRed
                                }`}
                              >
                                <div
                                  className={
                                    styles.completedChallengeChartLabel
                                  }
                                >
                                  {mostCompletedChallengePercent.toFixed(0)}%
                                </div>
                                <RingProgress
                                  height={75}
                                  width={75}
                                  autoFit={false}
                                  percent={mostCompletedChallengePercent / 100}
                                  renderer="svg"
                                  innerRadius={0.88}
                                  color={[
                                    mostCompletedChallengePercent >= 50
                                      ? '#69AA9E'
                                      : '#EF7065',
                                    '#F4F3F3',
                                  ]}
                                />
                              </div>
                              <div>
                                <div className={styles.completedChallengeTitle}>
                                  {
                                    engagementStatistics?.mostCompletedChallenge
                                      ?.title
                                  }
                                </div>
                                <div className={styles.completedChallengeDesc}>
                                  {t('wellness.analytics.completedBy')}{' '}
                                  <span
                                    className={styles.completedChallengeUsers}
                                  >
                                    {
                                      engagementStatistics
                                        ?.mostCompletedChallenge?.usersCompleted
                                    }{' '}
                                    {t('wellness.analytics.of')}{' '}
                                    {
                                      engagementStatistics
                                        ?.mostCompletedChallenge?.usersJoined
                                    }
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className={styles.engagementEmpty}>
                              {t('wellness.analytics.noActivity')}
                            </div>
                          )}
                        </div>
                        <div className={styles.challengesCompletionBlock}>
                          {engagementStatistics?.leastCompletedChallenge ? (
                            <>
                              <div
                                className={`${styles.completedChallengeChart} ${
                                  leastCompletedChallengePercent >= 50
                                    ? styles.completedChallengeChartGreen
                                    : styles.completedChallengeChartRed
                                }`}
                              >
                                <div
                                  className={
                                    styles.completedChallengeChartLabel
                                  }
                                >
                                  {leastCompletedChallengePercent.toFixed(0)}%
                                </div>
                                <RingProgress
                                  height={75}
                                  width={75}
                                  autoFit={false}
                                  percent={leastCompletedChallengePercent / 100}
                                  renderer="svg"
                                  innerRadius={0.88}
                                  color={[
                                    leastCompletedChallengePercent >= 50
                                      ? '#69AA9E'
                                      : '#EF7065',
                                    '#F4F3F3',
                                  ]}
                                />
                              </div>
                              <div>
                                <div className={styles.completedChallengeTitle}>
                                  {
                                    engagementStatistics
                                      ?.leastCompletedChallenge?.title
                                  }
                                </div>
                                <div className={styles.completedChallengeDesc}>
                                  {t('wellness.analytics.completedBy')}{' '}
                                  <span
                                    className={styles.completedChallengeUsers}
                                  >
                                    {
                                      engagementStatistics
                                        ?.leastCompletedChallenge
                                        ?.usersCompleted
                                    }{' '}
                                    {t('wellness.analytics.of')}{' '}
                                    {
                                      engagementStatistics
                                        ?.leastCompletedChallenge?.usersJoined
                                    }
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className={styles.engagementEmpty}>
                              {t('wellness.analytics.noActivity')}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className={styles.mostClaimedReward}>
                  <div className={styles.rewardsBlock}>
                    <div className={styles.rewardTitleContainer}>
                      <div className={styles.iconContainer}>
                        <img src="/assets/wellness/star.svg" alt="star" />
                      </div>
                      <div className={styles.rewardTitle}>
                        {t('wellness.analytics.mostClaimedReward')}
                      </div>
                    </div>
                    {engagementStatistics?.mostClaimedReward?.current ? (
                      <div className={styles.rewardNameContainer}>
                        <div className={styles.rewardName}>
                          {
                            engagementStatistics?.mostClaimedReward?.current
                              ?.name
                          }
                        </div>
                        <Badge
                          className={styles.diffBadge}
                          value={t('wellness.analytics.usersPercent', {
                            percent: Math.abs(rewardDiffPercent).toFixed(0),
                          })}
                          type={
                            rewardDiffPercent >= 0 ? 'positive' : 'negative'
                          }
                        />
                      </div>
                    ) : (
                      <div className={styles.engagementEmpty}>
                        {t('wellness.analytics.noActivity')}
                      </div>
                    )}
                  </div>
                  <div className={styles.rewardsBlockEmpty} />
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default WellnessAnalytics;
