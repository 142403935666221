import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import authReducer from '../features/auth/AuthSlice';
import challengeReducer from '../features/challenges/ChallengesSlice';
import navReducer from '../features/navigation/NavigationSlice';
import i18nReducer from '../features/i18n/i18nSlice';
import articleReducer from '../features/articles/ArticlesSlice';
import insightsReducer from '../features/insights/InsightsSlice';
import tagsReducer from '../features/tags/TagsSlice';
import categoriesReducer from '../features/categories/CategoriesSlice';
import rewardReducer from '../features/rewards/RewardsSlice';
import placesReducer from '../features/places/PlacesSlice';
import usersReducer from '../features/users/UsersSlice';
import activeChallengesReducer from '../features/users/ActiveChallengesSlice';
import claimedRewardsReducer from '../features/users/ClaimedRewardsSlice';
import assignedProductsSlice from '../features/users/AssignedProductsSlice';
import companiesReducer from '../features/collective/companies/CompaniesSlice';
import companiesCategoriesReducer from '../features/collective/companies/CompaniesCategoriesSliece';
import activityTagsReducer from '../features/activityTags/ActivityTagsSlice';
import typeFormsReducer from '../features/quizzes/TypeFormsSlice';
import quizzesReducer from '../features/quizzes/QuizzesSlice';
import guaranteesReducer from '../features/guarantees/GuaranteesSlice';
import employeesReducer from '../features/collective/companies/EditCompany/screens/Employees/EmployeesSlice';
import companyProductsByEmployeesReducer from '../features/collective/companies/EditCompany/screens/Employees/ProductsList/ProductsSlice';
import manageClaimedRewardsReducer from '../features/rewards/ClaimedRewards/ManageClaimedRewardsSlice';
import productsReducer from '../features/collective/companies/EditCompany/screens/Products/ProductsSlice';
import productEmployeesReducer from '../features/collective/companies/EditCompany/screens/Products/EditProduct/screens/AssignEmployees/AssignEmployeesSlice'; // eslint-disable-next-line max-len
import statisticsReducer from '../features/home/HomePageSlice';
import wellnessAnalyticsReducer from '../features/analytics/WellnessAnalyticsSlice';
import companyAdminsReducer from '../features/collective/companies/EditCompany/screens/Admins/CompanyAdminsSlice';
import adminUsersSlice from '../features/adminUsers/AdminUsersSlice';
import allCompaniesSlice from '../features/collective/companies/AllCompaniesSlice';
import companyMainContactSlice from '../features/collective/companies/CompanyMainContactSlice';
import adminUsersAdminSlice from '../features/adminUsers/AdminUsersAdminSlice';
import membersSlice from '../features/collective/members/MembersSlice';
import challengeEnrolledUsersSlice from '../features/challenges/ChallengeEnrolledUsersSlice';
import challengeLeaderboardSlice from '../features/challenges/EditChallenge/Leaderboard/LeaderboardSlice';
import challengeEngagementSlice from '../features/analytics/ChallengeEngagement/ChallengeEngagementSlice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    challenges: challengeReducer,
    nav: navReducer,
    i18n: i18nReducer,
    articles: articleReducer,
    insights: insightsReducer,
    tags: tagsReducer,
    categories: categoriesReducer,
    rewards: rewardReducer,
    places: placesReducer,
    users: usersReducer,
    activeChallenges: activeChallengesReducer,
    claimedRewards: claimedRewardsReducer,
    manageClaimedRewards: manageClaimedRewardsReducer,
    assignedProducts: assignedProductsSlice,
    companies: companiesReducer,
    companiesCategories: companiesCategoriesReducer,
    activityTags: activityTagsReducer,
    typeForms: typeFormsReducer,
    quizzes: quizzesReducer,
    guarantees: guaranteesReducer,
    employees: employeesReducer,
    products: productsReducer,
    companyProductsByEmployees: companyProductsByEmployeesReducer,
    productsEmployees: productEmployeesReducer,
    statistics: statisticsReducer,
    wellnessAnalytics: wellnessAnalyticsReducer,
    companyAdmins: companyAdminsReducer,
    adminUsers: adminUsersSlice,
    allCompanies: allCompaniesSlice,
    companyMainContact: companyMainContactSlice,
    adminUsersAdmin: adminUsersAdminSlice,
    members: membersSlice,
    challengeEnrolledUsers: challengeEnrolledUsersSlice,
    challengeLeaderboard: challengeLeaderboardSlice,
    challengeEngagement: challengeEngagementSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
