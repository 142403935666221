import {
  apiRequestGet,
  apiRequest,
  apiRequestFormData,
  TListParams,
} from '../../helpers/apiRequest';
import {
  IFetchChallengesResponse,
  TEnrolledUserListResponse,
  TNewChallengeResponse,
  TTeamUserListResponse,
  TTopTeamListResponse,
} from './ChallengesTypes';

export const fetchChallenges = async (
  listParams?: TListParams,
): Promise<IFetchChallengesResponse> =>
  apiRequestGet<IFetchChallengesResponse>('/challenge-v2', { ...listParams });

export const createChallenge = async (
  challengeData: FormData,
): Promise<TNewChallengeResponse> =>
  apiRequestFormData<TNewChallengeResponse>(
    '/challenge-v2',
    'post',
    challengeData,
    null,
  );

export const publishChallenge = async (
  id: number,
): Promise<TNewChallengeResponse> =>
  apiRequest<TNewChallengeResponse>(
    `/challenge-v2/${id}/publish`,
    'post',
    null,
    null,
  );

export const unpublishChallenge = async (
  id: number,
): Promise<TNewChallengeResponse> =>
  apiRequest<TNewChallengeResponse>(
    `/challenge-v2/${id}/unpublish`,
    'post',
    null,
    null,
  );

export const saveAsDraftChallenge = async (
  id: number,
): Promise<TNewChallengeResponse> =>
  apiRequest<TNewChallengeResponse>(
    `/challenge-v2/${id}/save-as-draft`,
    'post',
    null,
    null,
  );

export const getChallenge = async (
  id: number,
): Promise<TNewChallengeResponse> =>
  apiRequestGet<TNewChallengeResponse>(`/challenge-v2/${id}`, null);

export const updateChallenge = async (
  id: number,
  challengeData: FormData,
): Promise<TNewChallengeResponse> =>
  apiRequestFormData<TNewChallengeResponse>(
    `/challenge-v2/${id}`,
    'patch',
    challengeData,
    null,
  );

export const deleteChallenge = async (
  id: number,
): Promise<TNewChallengeResponse> =>
  apiRequest<TNewChallengeResponse>(
    `/challenge-v2/${id}`,
    'delete',
    null,
    null,
  );

export const fetchEnrolledUsers = async (
  listParams: TListParams,
  id: number,
): Promise<TEnrolledUserListResponse> =>
  apiRequestGet<TEnrolledUserListResponse>(
    `/challenge-v2/${id}/users`,
    listParams,
  );

export const fetchTopTeams = async (
  id: number,
): Promise<TTopTeamListResponse> =>
  apiRequestGet<TTopTeamListResponse>(`/challenge-v2/${id}/teams`, null);

export const fetchTeamUsers = async (
  challengeId: number,
  teamId: number,
  listParams: TListParams,
): Promise<TTeamUserListResponse> =>
  apiRequestGet<TTeamUserListResponse>(
    `/challenge-v2/${challengeId}/teams/${teamId}/users`,
    listParams,
  );
