export enum EProductPlans {
  FLEXIBLE = 'Flexible',
  INDIVIDUAL = 'Individual',
  BASIC = 'Basic',
}

export enum EProductStatuses {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
