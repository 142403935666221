import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Empty, Input, Tag } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Key } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { actions, asyncActions, selectors } from '../ProductsSlice';
import { selectors as employeesSelectors } from '../EditProduct/screens/AssignEmployees/AssignEmployeesSlice';
import styles from './ProductsList.module.scss';
import RoleWrapper from '../../../../../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../../../../../constants/userRoles';
import { TCompany } from '../../../../CompanyType';
import { EProductPlans, EProductStatuses } from '../ProductsConstants';
import { TProduct } from '../ProductsTypes';
import BulkActions from '../../../../../../../components/BulkActions/BulkActions';
import DeleteProductsBulkModal from '../../../../../../../components/Modals/ProductsModals/DeleteProductsBulkModal/DeleteProductsBulkModal';
import EditProduct from '../EditProduct/EditProduct';
import DeleteProductModal from '../../../../../../../components/Modals/ProductsModals/DeleteProductModal/DeleteProductModal';
import ActionAlert from '../../../../../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../../../../../constants/requestStatuses';
import { enableForRoles } from '../../../../../../../helpers/role';
import { selectAuthenticatedUser } from '../../../../../../auth/AuthSlice';
import Table from '../../../../../../../components/Table/Table';

const { Search } = Input;

type TProductListProps = {
  companyId: TCompany['id'];
};

type TTableData = {
  id: number;
  key: number;
  name: string;
  plan: EProductPlans;
  totalEmployees: number;
  activatedAt: string;
  activatedAtRaw: string | null;
  statusText: JSX.Element;
  actions: null;
};

function ProductsList({ companyId }: TProductListProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const products = useSelector(selectors.listData);
  const statuses = useSelector(selectors.statuses);
  const actionItems = useSelector(selectors.actionItems);
  const bulkItems = useSelector(selectors.bulkItems);
  const assigneEmployees = useSelector(employeesSelectors.listData);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  useEffect(
    () => () => {
      setProductsExist(false);
      dispatch(actions.clearList());
    },
    [],
  );

  useEffect(() => {
    dispatch(actions.clearFilters());
    dispatch<any>(asyncActions.fetchList({ id: companyId }));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (products.outdated) {
      dispatch<any>(asyncActions.fetchList({ id: companyId }));
    }
  }, [dispatch, products]);

  useEffect(() => {
    if (assigneEmployees.outdated) {
      dispatch<any>(asyncActions.fetchList({ id: companyId }));
    }
  }, [dispatch, assigneEmployees]);

  useEffect(() => {
    if (products.list?.length && !productsExist) {
      setProductsExist(true);
    }
  }, [dispatch, products]);

  useEffect(() => {
    const isSuccess = (status?: string | null) =>
      status === RequestStatuses.SUCCESS;

    let catched = false;

    const reactions = [
      {
        status: statuses.create,
        reaction: () => {
          const prd = actionItems.create;
          ActionAlert.success(
            t('products.tooltips.create', { productName: prd?.name }),
          );
        },
      },
      {
        status: statuses.update,
        reaction: () => {
          const prd = actionItems.update;
          ActionAlert.success(
            t('products.tooltips.update', { productName: prd?.name }),
          );
        },
      },
      {
        status: statuses.delete,
        reaction: () => {
          const prd = actionItems.delete;
          ActionAlert.info(
            t('products.tooltips.delete', { productName: prd?.name }),
          );
        },
      },
      {
        status: statuses.deleteBulk,
        reaction: () => {
          const prds = bulkItems.delete;
          ActionAlert.info(
            t(
              prds?.length === 1
                ? 'products.tooltips.deleteBulkSingular'
                : 'products.tooltips.deleteBulk',
              { number: prds?.length },
            ),
          );
        },
      },
      {
        status: statuses.lastSingle,
        reaction: () => {
          if (alertConfig.action === 'activate') {
            ActionAlert.success(alertConfig.label);
          } else if (alertConfig.action === 'deactivate') {
            ActionAlert.info(alertConfig.label);
          }
        },
      },
    ];

    reactions.forEach((r) => {
      if (isSuccess(r.status)) {
        r.reaction();
        catched = true;
      }
    });

    if (catched) {
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [showEdit, setShowEdit] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(
    null as number | null,
  );
  const [productsExist, setProductsExist] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    action: '',
    label: '',
  });

  const selectedProducts =
    products.list?.filter((p: TProduct) => selectedIds.indexOf(p.id) !== -1) ||
    [];

  const handleTableChange = (
    tablePagination: any,
    filters: any,
    sorter: any,
  ) => {
    if (sorter?.order) {
      dispatch(
        actions.setOrder({
          field: sorter.field,
          direction: sorter.order === 'descend' ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.setOrder({ field: 'id', direction: 'desc' }));
    }

    dispatch(actions.clearFilters());
    Object.keys(filters).forEach((field: any) => {
      if (filters[field]?.length) {
        filters[field].forEach((filter: any) => {
          dispatch(actions.setFilter({ field, value: filter, exact: true }));
        });
      }
    });

    dispatch(
      actions.setPagination({
        page: tablePagination.current,
        perPage: tablePagination.pageSize,
      }),
    );

    dispatch<any>(asyncActions.fetchList({ id: companyId }));
  };

  // table data
  const tableData: TTableData[] =
    products.list?.map((p: TProduct) => ({
      id: p.id,
      key: p.id,
      name: p.name,
      plan: p.plan as EProductPlans,
      totalEmployees: p.totalUsers,
      activatedAt: p.activatedAt
        ? dayjs(p.activatedAt).format('DD/MM/YYYY')
        : '-',
      activatedAtRaw: p.activatedAt,
      statusText: ((prod: TProduct) => {
        switch (prod.statusText) {
          case EProductStatuses.ACTIVE:
            return <Badge status="success" text={t('generic.active')} />;
          case EProductStatuses.DRAFT:
            return <Badge color="yellow" text={t('generic.draft')} />;
          case EProductStatuses.INACTIVE:
            return <Badge status="default" text={t('generic.inactive')} />;
          default:
            return <Badge />;
        }
      })(p),
      actions: null,
    })) || [];
  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };
  const columns = [
    {
      title: t('generic.id'),
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: t('products.profuctName'),
      dataIndex: 'name',
      sorter: true,
      render: (name: string, record: TTableData) => (
        <Button
          onClick={() => {
            setCurrentProductId(record.id);
            setShowEdit(true);
          }}
          type="link"
        >
          {name}
        </Button>
      ),
    },
    {
      title: t('collective.company.products.plan'),
      dataIndex: 'plan',
      render: (plan: EProductPlans) => {
        switch (plan) {
          case EProductPlans.BASIC:
            return (
              <Tag color="#EB2F96">
                {t('collective.company.products.basic')}
              </Tag>
            );
          case EProductPlans.FLEXIBLE:
            return (
              <Tag color="#13C2C2">
                {t('collective.company.products.flexible')}
              </Tag>
            );
          case EProductPlans.INDIVIDUAL:
            return (
              <Tag color="#597EF7">
                {t('collective.company.products.individual')}
              </Tag>
            );
          default:
            return <Badge />;
        }
      },
      filters: [
        {
          value: EProductPlans.BASIC,
          text: t('collective.company.products.basic'),
        },
        {
          value: EProductPlans.FLEXIBLE,
          text: t('collective.company.products.flexible'),
        },
        {
          value: EProductPlans.INDIVIDUAL,
          text: t('collective.company.products.individual'),
        },
      ],
      filterMultiple: false,
    },
    {
      title: t('products.noEmployees'),
      dataIndex: 'totalEmployees',
      sorter: true,
    },
    {
      title: t('products.activationDate'),
      dataIndex: 'activatedAt',
      sorter: true,
    },
    {
      title: t('generic.status'),
      dataIndex: 'statusText',
      filters: [
        { value: t('generic.active'), text: t('generic.active') },
        { value: t('generic.inactive'), text: t('generic.inactive') },
        { value: t('generic.draft'), text: t('generic.draft') },
      ],
      filterMultiple: false,
    },
  ];

  if (enableForRoles(authenticatedUser.role as UserRoles, [UserRoles.ADMIN])) {
    columns.push({
      title: t('generic.actions'),
      dataIndex: 'actions',
      sorter: false,
      render: (_: string, record: TTableData) => (
        <div className={styles.actions}>
          <Button
            className={styles.editButton}
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setCurrentProductId(record.id);
              setShowEdit(true);
            }}
          />
          <DeleteProductModal
            productName={record.name}
            onClick={() => {
              const product = products.list?.find((p) => p.id === record.id);
              if (product) {
                dispatch<any>(asyncActions.delete({ item: product }));
              }
            }}
          >
            <Button
              className={styles.deleteButton}
              type="link"
              icon={<DeleteOutlined />}
            />
          </DeleteProductModal>
        </div>
      ),
    });
  }

  // bulk menu dropdown
  const dropdownOptions = [
    {
      key: 'delete',
      text: t('generic.delete'),
      action: () => {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedProducts }));
        setSelectedIds([]);
      },
      modal: (children: React.ReactElement, action: () => void) => (
        <DeleteProductsBulkModal count={selectedIds.length} onClick={action}>
          {children}
        </DeleteProductsBulkModal>
      ),
    },
    {
      key: 'activate',
      text: t('generic.activate'),
      action: () => {
        dispatch(
          asyncActions.singleRequests?.activateProduct?.({ id: selectedIds }),
        );
        setAlertConfig({
          action: 'activate',
          label: t(
            selectedIds.length === 1
              ? 'products.tooltips.activate'
              : 'products.tooltips.activateBulk',
            { number: selectedIds.length },
          ),
        });
        setSelectedIds([]);
      },
    },
    {
      key: 'deactivate',
      text: t('generic.deactivate'),
      action: () => {
        dispatch(
          asyncActions.singleRequests?.deactivateProduct?.({ id: selectedIds }),
        );
        setAlertConfig({
          action: 'deactivate',
          label: t(
            selectedIds.length === 1
              ? 'products.tooltips.deactivate'
              : 'products.tooltips.deactivateBulk',
            { number: selectedIds.length },
          ),
        });
        setSelectedIds([]);
      },
    },
  ];

  return (
    <>
      <EditProduct
        id={currentProductId}
        show={showEdit}
        onClose={() => {
          setShowEdit(false);
          setCurrentProductId(null);
        }}
        companyId={companyId}
      />
      <div className={styles.wrap}>
        {productsExist ? (
          <>
            <div className={styles.toolBar}>
              <div className={styles.toolBarTitle}>
                {t('products.productsCreated')}
              </div>
              <div className={styles.buttonContainer}>
                <Search
                  allowClear
                  className={styles.searchField}
                  placeholder={t('products.search.placeholder') || ''}
                  onSearch={(val) => {
                    dispatch(
                      actions.setPagination({
                        page: 1,
                        perPage: products.listParams?.pagination?.perPage || 10,
                      }),
                    );
                    dispatch(actions.setFilter({ field: 'name', value: val }));
                    dispatch<any>(asyncActions.fetchList({ id: companyId }));
                  }}
                />
                <RoleWrapper roles={[UserRoles.ADMIN]}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setCurrentProductId(null);
                      dispatch(actions.clearCurrentItem());
                      setShowEdit(true);
                    }}
                  >
                    {t('products.createProduct')}
                  </Button>
                </RoleWrapper>
              </div>
            </div>
            {!!selectedIds.length && (
              <RoleWrapper roles={[UserRoles.ADMIN]}>
                <BulkActions
                  dropdownOptions={dropdownOptions}
                  selectedKeys={selectedIds.map((key) => key.toString())}
                />
              </RoleWrapper>
            )}
            <Table
              enableUrlChange={false}
              actions={actions}
              asyncActions={asyncActions}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={tableData}
              pagination={{
                total: products.listParams?.pagination?.total,
                current: products.listParams?.pagination?.page,
              }}
              customOrderHandler={(sorter) => {
                if (sorter.field === 'totalEmployees') {
                  dispatch(actions.clearOrder());
                  dispatch(
                    actions.setFilter({
                      field: 'orderByTotalEmployees',
                      value: sorter.order === 'descend' ? 'DESC' : 'ASC',
                      custom: true,
                    }),
                  );
                }
                dispatch(
                  actions.setOrder({
                    field: sorter.field,
                    direction: sorter.order === 'descend' ? 'desc' : 'asc',
                  }),
                );
              }}
            />
          </>
        ) : (
          <Empty
            className={styles.empty}
            image={<ShopOutlined className={styles.shopIcon} />}
            description={t('products.noProductsAssigned')}
          >
            <RoleWrapper roles={[UserRoles.ADMIN]}>
              <Button
                className={styles.noUserButton}
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setShowEdit(true);
                }}
              >
                {t('products.createNewProduct')}
              </Button>
            </RoleWrapper>
          </Empty>
        )}
      </div>
    </>
  );
}

export default ProductsList;
