import { useTranslation } from 'react-i18next';
import { Config } from '../../../config/config';
import appstore from './images/apple.svg';
import playstore from './images/playstore.svg';
import productionQrCode from './images/production-redirect-app-stores-qr-code.svg';
import stagingQrCode from './images/staging-redirect-app-stores-qr-code.svg';
import styles from './AppStoresBadges.module.scss';

type TAppStoreBadgesProps = {
  showScanQrCodeText: boolean;
};

function AppStoresBadges({ showScanQrCodeText }: TAppStoreBadgesProps) {
  const { t } = useTranslation();
  return (
    <div>
      {showScanQrCodeText && (
        <div className={styles.scanQrCodeTextContainer}>
          <span className={styles.scanQrCodeText}>{t('auth.scanQrCode')}</span>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.qrContainer}>
          <img
            src={Config.ENV === 'production' ? productionQrCode : stagingQrCode}
            alt=""
            className={styles.qrCode}
            style={{width: 141}}
          />
        </div>
        
        <div className={styles.rightContainer}>
          <a href={Config.PLAY_STORE} target="_blank" rel="noreferrer">
            <img src={playstore} alt="" className={styles.firstImage} />
          </a>
          <a href={Config.APP_STORE} target="_blank" rel="noreferrer">
            <img src={appstore} className={styles.lastImage} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default AppStoresBadges;
