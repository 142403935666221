import { getAdminUserCompanies } from './AdminUsersApi';
import buildSlice from '../general/helpers/genericSliceBuilder';
import {
  TCompaniesListResponse,
  TCompaniesResponse,
  TCompany,
} from '../collective/companies/CompanyType';

const adminUsersAdminSlice = buildSlice<
  TCompany,
  TCompaniesResponse,
  TCompaniesListResponse
>('adminUsersAdmin', {
  fetchList: getAdminUserCompanies,
});

export const { selectors, asyncActions, actions } = adminUsersAdminSlice;

export default adminUsersAdminSlice.reducer;
