import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Dropdown, Input, Menu, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'antd/es/table/interface';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import _, { uniqBy } from 'lodash';
import { selectors, asyncActions, actions } from '../InsightsSlice';
import {
  selectors as tagsSelectors,
  asyncActions as tagsAsyncActions,
  actions as tagsActions,
} from '../../tags/TagsSlice';
import { EInsightStatuses, TInsight, TInsightFilled } from '../InsightsTypes';
import { getInsightStatusRendered } from '../InsightsHelpers';
import styles from './InsightList.module.scss';
import PageHeaderDefault from '../../../components/PageHeaderDefault/PageHeaderDefault';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../constants/requestStatuses';
import useQuery from '../../../helpers/useQuery';
import Table from '../../../components/Table/Table';
import DeleteInsightModal from '../../../components/Modals/InsightsModals/DeleteInsightModal/DeleteInsightModal';
import { selectCurrentLang } from '../../i18n/i18nSlice';
import { TTag } from '../../tags/TagsTypes';
import { Languages } from '../../../constants/languages';

const { Search } = Input;

type TTableData = {
  key: Key;
  image: TInsightFilled['titleImage'];
  title: string;
  tags: TTag[];
  createdAt: string;
  updatedAt: string;
  status: string;
  actions: null;
};

function InsightList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useQuery();

  // selectors
  const lang = useSelector(selectCurrentLang);
  const insightsData = useSelector(selectors.listData);
  const tagListSelector = useSelector(tagsSelectors.listData);
  const statuses = useSelector(selectors.statuses);
  const bulkInsights = useSelector(selectors.bulkItems);
  const actionInsights = useSelector(selectors.actionItems);

  // effects
  useLayoutEffect(() => {
    dispatch(actions.clearFilters());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('tag')) {
      const filterTags = (query.get('tag') || '').split(',');

      filterTags.forEach((aTag, index) =>
        dispatch(
          actions.setFilter({
            field: `tags[${index}]`,
            value: aTag,
            custom: true,
          }),
        ),
      );
    }

    if (query.has('status')) {
      const filterStatus = (query.get('status') || '').split(',');

      for (const status of filterStatus) {
        dispatch(
          actions.setFilter({
            field: 'statuses',
            value: status,
            custom: true,
          }),
        );
      }
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    dispatch(tagsActions.setPagination({ page: 1, perPage: 5000 }));
    dispatch<any>(tagsAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    if (insightsData.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, insightsData]);

  const bulkPopup = (
    status: RequestStatuses | null | undefined,
    insights: TInsightFilled[],
    operation: string,
    isDeleted?: boolean,
  ) => {
    const { length } = insights;
    if (status === RequestStatuses.SUCCESS) {
      if (isDeleted) {
        ActionAlert.info(
          length > 1
            ? t('insights.alerts.bulkPopupPlural', { length, operation })
            : t('insights.alerts.bulkPopupSingular', { length, operation }),
        );
      } else {
        ActionAlert.success(
          length > 1
            ? t('insights.alerts.bulkPopupPlural', { length, operation })
            : t('insights.alerts.bulkPopupSingular', { length, operation }),
        );
      }
      dispatch(actions.clearStatuses());
    }
  };
  const singlePopup = (
    status: RequestStatuses | null | undefined,
    insight: TInsightFilled | null,
    operation: string,
    isDeleted?: boolean,
  ) => {
    if (status === RequestStatuses.SUCCESS) {
      if (isDeleted) {
        ActionAlert.info(
          t('insights.alerts.singularPopup', {
            title:
              insight?.insightTranslations.find(
                (aTranslation) => aTranslation.language === lang,
              )?.name || '',
            operation,
          }),
        );
      } else {
        ActionAlert.success(
          t('insights.alerts.singularPopup', {
            title:
              insight?.insightTranslations.find(
                (aTranslation) => aTranslation.language === lang,
              )?.name || '',
            operation,
          }),
        );
      }
      dispatch(actions.clearStatuses());
    }
  };
  useEffect(
    () =>
      bulkPopup(
        statuses.deleteBulk,
        bulkInsights.delete,
        t('generic.operations.deleted'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      bulkPopup(
        statuses.publishBulk,
        bulkInsights.publish,
        t('generic.operations.activated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      bulkPopup(
        statuses.unpublishBulk,
        bulkInsights.unpublish,
        t('generic.operations.deactivated'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.delete,
        actionInsights.delete,
        t('generic.operations.deleted'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.publish,
        actionInsights.publish,
        t('generic.operations.activated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.unpublish,
        actionInsights.unpublish,
        t('generic.operations.deactivated'),
        true,
      ),
    [statuses],
  );
  useEffect(() => setSelectedIds([]), [statuses]);

  // state
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [bulkOption, setBulkOption] = useState('delete');

  // actions
  const onDelete = (id: TInsight['id']) => () => {
    const insight = insightsData.list?.find((a) => a.id === id);
    if (insight) {
      dispatch<any>(asyncActions.delete({ item: insight }));
    }
  };

  // table data
  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };
  const actionsBlock = (id: TInsight['id'], title: string) => (
    <div className={styles.tableActions}>
      <Link
        to={{
          pathname: `/insights/${id}`,
          search: `?${query.toString()}`,
        }}
        className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
      >
        <EditOutlined />
      </Link>
      <DeleteInsightModal insightName={title} onClick={onDelete(id)}>
        <div className={styles.actionButton}>
          <DeleteOutlined />
        </div>
      </DeleteInsightModal>
    </div>
  );
  const tableData: TTableData[] =
    insightsData.list?.map((item) => ({
      key: item.id,
      image: item.titleImage,
      title:
        item.insightTranslations.find(
          (aTranslation) => aTranslation.language === lang,
        )?.name || '',
      createdAt: new Date(item.createdAt).toLocaleDateString(),
      updatedAt: new Date(item.lastUpdatedAt).toLocaleDateString(),
      status: item.status,
      actions: null,
      tags: item.tags,
    })) || [];
  const columns = [
    {
      title: t('insights.edit.insightName'),
      dataIndex: 'title',
      render: (text: string, record: TTableData) => (
        <Link
          to={{
            pathname: `/insights/${record.key}`,
            search: `?${query.toString()}`,
          }}
          className={styles.insightTitle}
        >
          <img
            className={styles.titleImage}
            src={record.image || '/assets/insights/title-photo-placeholder.png'}
            alt={record.title}
          />
          {record.title.length > 30 ? (
            <Tooltip
              placement="top"
              title={record.title}
            >{`${record.title.slice(0, 30)}...`}</Tooltip>
          ) : (
            record.title
          )}
        </Link>
      ),
    },
    {
      title: t('insights.edit.tags'),
      dataIndex: 'tag',
      render: (text: string, record: TTableData) =>
        record.tags
          .map(
            (aTag) =>
              aTag.translation[`name${_.startCase(lang || Languages.EN)}`],
          )
          .join(', '),
      filters: uniqBy(
        tagListSelector.list
          ?.filter((tag) => tag.id)
          .map((tag) => ({
            value: tag?.id,
            text: tag.translation[`name${_.startCase(lang || Languages.EN)}`],
          })),
        'value',
      ),
      ...(query.has('tag')
        ? {
            defaultFilteredValue: query.get('tag')?.split(','),
          }
        : {}),
    },
    {
      title: t('insights.creationDate'),
      dataIndex: 'createdAt',
      sorter: true,
      ...(query.has('sort') &&
      ['createdAt', '-createdAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-createdAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('insights.lastUpdate'),
      dataIndex: 'updatedAt',
      sorter: true,
      ...(query.has('sort') &&
      ['updatedAt', '-updatedAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-updatedAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('insights.status'),
      dataIndex: 'status',
      render: (text: string, record: TTableData) => {
        const insight = insightsData.list?.find((it) => it.id === record.key);
        return insight ? getInsightStatusRendered(insight, t) : <div />;
      },
      filters: [
        { value: EInsightStatuses.ACTIVE, text: t('generic.active') },
        { value: EInsightStatuses.DRAFT, text: t('generic.draft') },
        { value: EInsightStatuses.INACTIVE, text: t('generic.inactive') },
      ],
      ...(query.has('status')
        ? {
            defaultFilteredValue: query.get('status')?.split(','),
          }
        : {}),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      render: (text: null, record: TTableData) =>
        actionsBlock(parseInt(record.key.toString(), 10), record.title),
    },
  ];

  // bulk menu dropdown
  const dropdownOptions = [{ key: 'delete', text: t('generic.delete') }];
  const dropdownItems = dropdownOptions.map((item) => (
    <Menu.Item key={item.key}> {item.text}</Menu.Item>
  ));
  const onBulkOperation = () => {
    let selectedInsights =
      insightsData.list?.filter(({ id }) => selectedIds.indexOf(id) !== -1) ||
      [];
    switch (bulkOption) {
      case 'delete': {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedInsights }));
        setSelectedIds([]);
        break;
      }
      default: {
        // do nothing
      }
    }
    setSelectedIds([]);
  };
  return (
    <div
      key={`insights-list-${Array.from(query.keys()).length}-${
        tagListSelector.list?.length
      }`}
    >
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('nav.category.wellness')]: '/insights',
          [t('nav.item.insights')]: '',
        }}
        title={t('nav.item.insights')}
      />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>{t('insights.toolBarTitle')}</div>
        <div className={styles.buttonContainer}>
          <Search
            allowClear
            defaultValue={query.get('search') || ''}
            className={styles.searchField}
            placeholder={t('insights.search.placeholder') || ''}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                insightsData.listParams?.pagination?.perPage
                  ? insightsData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/insights/new')}
          >
            {t('insights.createInsight')}
          </Button>
        </div>
      </div>
      {!!selectedIds.length && (
        <div className={styles.bulkMenu}>
          <div className={styles.bulkMenuCounter}>
            {t('generic.bulk.selected', { length: selectedIds.length })}
          </div>
          <Dropdown
            className={styles.bulkMenuDropdown}
            overlay={
              <Menu onClick={(e) => setBulkOption(e.key)}>{dropdownItems}</Menu>
            }
          >
            <Button>
              {dropdownOptions.find((o) => o.key === bulkOption)?.text}{' '}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button type="primary" onClick={onBulkOperation}>
            {t('generic.confirm')}
          </Button>
        </div>
      )}
      <Table
        actions={actions}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: insightsData.listParams?.pagination?.total,
          current: insightsData.listParams?.pagination?.page,
        }}
      />
    </div>
  );
}

export default withTranslation()(InsightList);
