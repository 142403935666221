import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

export const useCallbackPrompt = (when: boolean, lastLocation: string | number) => {
  
  const navigate = useNavigate()
  const location = useLocation()
  const [showPrompt, setShowPrompt] = useState(true)
  const [nextLocation, setNextLocation] = useState<any>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false)
    setNextLocation(null)
  }, [])

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false)
    setConfirmedNavigation(true)
    setNextLocation(lastLocation)
  }, [])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(nextLocation)
      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false)
      setNextLocation(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextLocation])

  return {showPrompt, confirmNavigation, cancelNavigation}
}