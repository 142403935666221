import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TPublishArticleModalProps = {
  rewardName: string;
  children: JSX.Element;
  onClick: () => void;
};

function PublishRewardModal({
  rewardName,
  children,
  onClick,
}: TPublishArticleModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('rewards.modals.publish.title'),
    okText: t('generic.modals.buttons.publish'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('rewards.modals.publish.content', { name: rewardName }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM}
    />
  );
}

export default PublishRewardModal;
