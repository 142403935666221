import axios, { Method } from 'axios';
import { notification } from 'antd';
import i18n from 'i18next';
import { config } from '../config';

export type TErrorPayload = {
  statusCode: number;
  message: string | string[];
  error: string;
};

export type TListParams = {
  pagination?: {
    page: number;
    perPage: number;
    total: number;
  };
  order?: {
    field: string;
    orderDirection: string;
  }[];
  filter?: {
    field: string;
    value: string;
    exact?: boolean;
    custom?: boolean;
  }[];
  createdAt?: { createdAtStart: string; createdAtEnd: string };
  search?: string;
  status?: string[];
  customFilters?: {
    field: string;
    value: string;
  }[];
};

type TParams = {
  token?: string;
  customParams?: { 
    [key: string]: string | number | undefined,
  };
  version?: string
} & TListParams;

const showErrorNotification = (error: any) => {
  if (!error.response || [422, 429, 502, 503, 409, 500].includes(error?.response?.status)) {
    /* Show validation message */
    let dataMessage = error?.response?.status === 422
      && error.response?.data?.data?.message?.map((item: string) => item).join('');
    dataMessage = error?.response?.status === 409 && error.response?.data?.data?.message;

    notification.open({
      key: 'error-notification',
      message: dataMessage || i18n.t('generic.error'),
      duration: 2.5,
      placement: 'bottomRight',
      className: 'errorNotification',
    });
  }
};

const handleValidationError = (error: any) => {
  if (error && error.response?.status === 400) {
    const message = error.response.data?.data?.message;
    throw new Error(message);
  }
};

export const defaultHeaders = () => ({
  'Bypass-Tunnel-Reminder': '*',
  Authorization: `Bearer ${window.localStorage.getItem('token')}`,
});

/** Admin Panel endpoinds */
export const axiosInstance = axios.create({
  baseURL: `${config.BACKEND_URL}`,
  headers: {
    'Bypass-Tunnel-Reminder': '*',
  },
});

/** Mobile app endpoinds */
export const axiosMobInstance = axios.create({
  baseURL: `${config.BACKEND_MOB_URL}`,
  headers: {
    'Bypass-Tunnel-Reminder': '*',
  },
});

const transformResponse = [
  function transform(data: string) {
    return { success: true, data: data ? JSON.parse(data) : null };
  },
];

export const apiRequest = async <Type>(
  path: string,
  method: Method,
  body: object | null,
  params?: TParams | null,
): Promise<Type> => {
  const {
    pagination,
    order,
    filter,
    createdAt,
    search,
    customParams,
    customFilters,
    status,
    version,
  } = params || {};

  const reqParams = new URLSearchParams();
  if (pagination) {
    const { page, perPage } = pagination;
    reqParams.append('offset', ((page - 1) * perPage).toString());
    reqParams.append('limit', perPage.toString());
  }
  if (filter) {
    filter.forEach(({ field, value, exact, custom }) => {
      if (custom) {
        reqParams.append(field, value);
      } else {
        reqParams.append(
          exact ? 'filterByExclusive' : 'filterBy',
          `${field},${value}`,
        );
      }
    });
  }
  if (customFilters) {
    customFilters.forEach((f) => {
      reqParams.append(f.field, f.value);
    });
  }
  if (order) {
    order.forEach(({ field, orderDirection }) => {
      reqParams.append('orderBy', `${field},${orderDirection}`);
    });
  }
  if (createdAt) {
    reqParams.append('createdAtStart', createdAt.createdAtStart);
    reqParams.append('createdAtEnd', createdAt.createdAtEnd);
  }
  if (search) {
    reqParams.append('search', search);
  }
  if (status) {
    status.forEach((item) => {
      reqParams.append('status[]', item);
    });
  }
  if (customParams) {
    Object.keys(customParams).forEach((key) => {
      if (!customParams[key]) return;
      reqParams.append(key, customParams[key] as string);
    });
  }

  const data = body instanceof FormData ? body : { ...(body || {}) };

  try {
    const response = await axiosInstance.request({
      url: version ? `/${version}${path}` : `/v1${path}`,
      method,
      params: reqParams,
      data,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      transformResponse,
    });

    return response.data?.data as Type;
  } catch (error: any) {
    showErrorNotification(error);
    handleValidationError(error);
    throw new Error(error);
  }
};

export const apiMobRequest = async <Type>(
  path: string,
  method: Method,
  body: object | null,
  params?: TParams | null,
): Promise<Type> => {
  const {
    pagination,
    order,
    filter,
    createdAt,
    search,
    customParams,
    customFilters,
    status,
    version,
  } = params || {};

  const reqParams = new URLSearchParams();
  if (pagination) {
    const { page, perPage } = pagination;
    reqParams.append('offset', ((page - 1) * perPage).toString());
    reqParams.append('limit', perPage.toString());
  }
  if (filter) {
    filter.forEach(({ field, value, exact, custom }) => {
      if (custom) {
        reqParams.append(field, value);
      } else {
        reqParams.append(
          exact ? 'filterByExclusive' : 'filterBy',
          `${field},${value}`,
        );
      }
    });
  }
  if (customFilters) {
    customFilters.forEach((f) => {
      reqParams.append(f.field, f.value);
    });
  }
  if (order) {
    order.forEach(({ field, orderDirection }) => {
      reqParams.append('orderBy', `${field},${orderDirection}`);
    });
  }
  if (createdAt) {
    reqParams.append('createdAtStart', createdAt.createdAtStart);
    reqParams.append('createdAtEnd', createdAt.createdAtEnd);
  }
  if (search) {
    reqParams.append('search', search);
  }
  if (status) {
    status.forEach((item) => {
      reqParams.append('status[]', item);
    });
  }
  if (customParams) {
    Object.keys(customParams).forEach((key) => {
      if (!customParams[key]) return;
      reqParams.append(key, customParams[key] as string);
    });
  }

  try {
    const response = await axiosMobInstance.request({
      url: version ? `/${version}${path}` : `/v1${path}`,
      method,
      params: reqParams,
      data: { ...(body || {}) },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      transformResponse,
    });

    return response.data?.data as Type;
  } catch (error: any) {
    showErrorNotification(error);

    throw new Error(error);
  }
};

export const apiMobRequestGet = <Type>(
  path: string,
  params?: TParams | null,
): Promise<Type> =>{ 
  return apiMobRequest(
  path,
  'get',
  null,
  params
);}

export const apiRequestGet = <Type>(
  path: string,
  params?: TParams | null,
): Promise<Type> =>{ 
  return apiRequest(
  path,
  'get',
  null,
  params
);}

export const apiRequestFormData = async <Type>(
  path: string,
  method: Method,
  formData: object,
  params?: TParams | null,
): Promise<Type> => {
  try {
    const response = await axiosInstance.request({
      url: params?.version ? `/${params?.version}${path}` : `/v1${path}`,
      method,
      data: formData,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
      transformResponse,
    });

    return response.data?.data as Type;
  } catch (error: any) {
    showErrorNotification(error);

    throw new Error(error);
  }
};
