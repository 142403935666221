import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Input, Menu, Select, Tree } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Key } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { omit, uniqBy } from 'lodash';
import {
  actions,
  asyncActions,
  selectors,
} from '../../challenges/ChallengesSlice';
import {
  actions as quizzesActions,
  asyncActions as asyncQuizzesActions,
  selectors as quizzesSelectors,
} from '../../quizzes/QuizzesSlice';
import styles from './QuizzesList.module.scss';
import { challengeGoalMap, challengeTypeMap } from './QuizzesListHelper';
import {
  EChallengeStatuses,
  TTableData,
  TTypesElements,
} from './QuizzesListTypes';
import DeleteChallengeModal from '../../../components/Modals/ChallengesModals/DeleteChallengeModal/DeleteChallengeModal';
import PageHeaderDefault from '../../../components/PageHeaderDefault/PageHeaderDefault';
import RequestStatuses from '../../../constants/requestStatuses';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import DeleteQuizModal from '../../../components/Modals/QuizzesModals/DeleteQuizModal/DeleteQuizModal';
import { IQuiz } from '../../quizzes/QuizzesTypes';
import useQuery from '../../../helpers/useQuery';
import Table from '../../../components/Table/Table';
import ChallengeStatusBadge from '../../../components/Badge/ChallengeStatusBadge/ChallengeStatusBadge';
import { isActionAvailable } from '../QuizzesHelpers';
import { selectAuthenticatedUser } from '../../auth/AuthSlice';
import {
  asyncActions as adminUserAsyncActions,
  selectors as adminUserSelectors,
} from '../../adminUsers/AdminUsersAdminSlice';
import { TCompany } from '../../collective/companies/CompanyType';
import { TCategory } from '../../categories/CategoriesTypes';
import EChallengeAssignmentTypes from '../../challenges/EditChallenge/constants/challengeAssignmentTypes';

const { Search } = Input;

function QuizzesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useQuery();

  // selectors
  const quizzesData = useSelector(quizzesSelectors.listData);
  const filterData = useSelector(quizzesSelectors.filterData) as
    | { categories: TCategory[] }
    | undefined;
  const statuses = useSelector(quizzesSelectors.statuses);
  const bulkChallenges = useSelector(selectors.bulkItems);
  const actionChallenge = useSelector(selectors.actionItems);
  const quizzesStatuses = useSelector(quizzesSelectors.statuses);
  const bulkQuizzes = useSelector(quizzesSelectors.bulkItems);
  const actionQuiz = useSelector(quizzesSelectors.actionItems);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const adminCompanies = useSelector(adminUserSelectors.listData);

  // list values
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [bulkOption, setBulkOption] = useState('activate');

  let selectedChallenges =
    quizzesData.list?.filter((ch) =>
      selectedIds.includes(`challenge-${ch.id}`),
    ) || [];
  let selectedQuizzes =
    quizzesData.list?.filter((ch) => selectedIds.includes(`quiz-${ch.id}`)) ||
    [];

  const filteredList = quizzesData.list;

  const assignChallengeTypeFilter = () => {
    dispatch(
      quizzesActions.setFilter({
        field: 'entity',
        value: 'quiz',
      }),
    );
  };

  // effects
  useLayoutEffect(() => {
    dispatch(quizzesActions.clearFilters());
    dispatch<any>(adminUserAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      quizzesActions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(quizzesActions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';

      dispatch(
        quizzesActions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(quizzesActions.clearOrder());
    }

    if (query.has('companies')) {
      dispatch(quizzesActions.clearFilters());

      const filterCompanies = (query.get('companies') || '').split(',');

      for (const company of filterCompanies) {
        dispatch(
          quizzesActions.setFilter({
            field: 'company',
            value: company,
          }),
        );
      }
    } else {
      dispatch(quizzesActions.clearFilters());
    }

    if (query.has('entity')) {
      const filterEntities = (query.get('entity') || '').split(',');

      for (const entity of filterEntities) {
        dispatch(
          quizzesActions.setFilter({
            field: 'entity',
            value: entity,
          }),
        );
      }
    }

    if (query.has('category')) {
      const filterCategories = (query.get('category') || '').split(',');

      for (const category of filterCategories) {
        dispatch(
          quizzesActions.setFilter({
            field: 'category',
            value: category,
          }),
        );
      }
    }

    if (query.has('status')) {
      const filterStatuses = (query.get('status') || '').split(',');

      for (const status of filterStatuses) {
        dispatch(
          quizzesActions.setFilter({
            field: 'status',
            value: status,
          }),
        );
      }
    }

    if (query.has('type')) {
      const filterTypes = (query.get('type') || '').split(',');

      for (const type of filterTypes) {
        dispatch(
          quizzesActions.setFilter({
            field: 'type',
            value: type,
          }),
        );
      }
    }

    if (!Array.from(query.keys()).length) {
      dispatch(quizzesActions.clearFilters());
      dispatch(quizzesActions.clearOrder());
    }

    assignChallengeTypeFilter();
    dispatch<any>(asyncQuizzesActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (quizzesData.outdated) {
      dispatch<any>(asyncQuizzesActions.fetchList());
    }
  }, [dispatch, quizzesData]);

  useEffect(() => {
    if (quizzesStatuses.delete === RequestStatuses.SUCCESS) {
      dispatch<any>(asyncQuizzesActions.fetchList());
    }
  }, [dispatch, quizzesStatuses.delete]);

  // status popup
  const bulkPopup = (
    status: RequestStatuses | null | undefined,
    quizzes: IQuiz[],
    operation: string,
    success?: boolean,
  ) => {
    const { length } = quizzes;
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        length > 1
          ? t('quizzes.alerts.bulkPopupPrural', { length, operation })
          : t('quizzes.alerts.bulkPopupSingular', { length, operation }),
      );
      dispatch(actions.clearStatuses());
      dispatch(quizzesActions.clearStatuses());
    }
    assignChallengeTypeFilter();
  };
  const singlePopup = (
    status: RequestStatuses | null | undefined,
    quiz: IQuiz | null,
    operation: string,
    success?: boolean,
  ) => {
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        t('challenge.alerts.singualarPopup', {
          title: quiz?.title,
          operation,
        }),
      );
      dispatch(actions.clearStatuses());
      dispatch(quizzesActions.clearStatuses());
    }
    assignChallengeTypeFilter();
  };
  useEffect(() => {
    bulkPopup(
      statuses.deleteBulk,
      uniqBy([...bulkChallenges.delete, ...bulkQuizzes.delete], 'id'),
      t('generic.operations.deleted'),
    );
  }, [statuses, quizzesStatuses]);
  useEffect(() => {
    bulkPopup(
      statuses.publishBulk,
      uniqBy([...bulkChallenges.publish, ...bulkQuizzes.publish], 'id'),
      t('generic.operations.activated'),
      true,
    );
  }, [statuses, quizzesStatuses]);
  useEffect(() => {
    bulkPopup(
      statuses.unpublishBulk,
      uniqBy([...bulkChallenges.unpublish, ...bulkQuizzes.unpublish], 'id'),
      t('generic.operations.deactivated'),
    );
  }, [statuses, quizzesStatuses]);
  useEffect(
    () =>
      singlePopup(
        statuses.delete,
        actionChallenge.delete,
        t('generic.operations.deleted'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.publish,
        actionChallenge.publish,
        t('generic.operations.activated'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.unpublish,
        actionChallenge.unpublish,
        t('generic.operations.deactivated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        quizzesStatuses.delete,
        actionQuiz.delete,
        t('generic.operations.deleted'),
      ),
    [quizzesStatuses],
  );
  useEffect(
    () =>
      singlePopup(
        quizzesStatuses.publish,
        actionQuiz.publish,
        t('generic.operations.activated'),
        true,
      ),
    [quizzesStatuses],
  );
  useEffect(() => setSelectedIds([]), [statuses, quizzesStatuses]);

  // events
  const onDelete = (id: number) => () => {
    const challenge = quizzesData.list?.find((ch) => ch.id === id);
    if (challenge) {
      const entity = 'quiz';

      dispatch<any>(
        entity === 'quiz'
          ? asyncQuizzesActions.delete({ item: challenge as any })
          : asyncActions.delete({ item: challenge as any }),
      );
    }
  };
  const onCopy = (id: number) => () => console.log('Copy', id);
  const onEdit = (item: any) => () =>
    navigate(
      `/${item.entity === 'quiz' ? 'quizzes' : 'biometrics'}/${
        item.id
      }?${query.toString()}`,
    );

  const onBulkOperation = () => {
    switch (bulkOption) {
      case 'delete': {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedChallenges }));
        dispatch<any>(
          asyncQuizzesActions.deleteBulk({ items: selectedQuizzes as any }),
        );
        setSelectedIds([]);
        break;
      }
      case 'activate': {
        selectedChallenges = selectedChallenges.filter((challenge) => {
          if (
            (challenge.publishDate !== null &&
              challenge.unpublishDate !== null) ||
            challenge.publishDate === null
          ) {
            return challenge;
          }
          return null;
        });
        selectedQuizzes = selectedQuizzes.filter(
          (quiz) =>
            (quiz.publishDate !== null && quiz.unpublishDate !== null) ||
            quiz.publishDate === null,
        );

        dispatch<any>(
          asyncActions.publishBulk({ items: selectedChallenges as any }),
        );
        dispatch<any>(
          asyncQuizzesActions.publishBulk({ items: selectedQuizzes as any }),
        );
        break;
      }
      case 'deactivate': {
        selectedChallenges = selectedChallenges.filter(
          (challenge) => challenge.status.title !== EChallengeStatuses.INACTIVE,
        );
        selectedQuizzes = selectedQuizzes.filter(
          (challenge) => challenge.status.title !== EChallengeStatuses.INACTIVE,
        );
        dispatch<any>(
          asyncActions.unpublishBulk({ items: selectedChallenges }),
        );
        dispatch<any>(
          asyncQuizzesActions.unpublishBulk({ items: selectedQuizzes as any }),
        );
        break;
      }
    }
  };

  const actionsBlock = (item: any) => {
    const canPerformActions = isActionAvailable(
      authenticatedUser?.role,
      adminCompanies?.list?.map((company: TCompany) => company.id),
      item.companiesIds,
    );

    let challengeActions = [
      { icon: <EditOutlined />, action: onEdit(item), primary: true },
      { icon: <CopyOutlined />, action: onCopy(item.id) },
    ];

    if (!canPerformActions) {
      challengeActions = [{ icon: <EyeOutlined />, action: onEdit(item) }];
    }

    return (
      <div className={styles.tableActions}>
        {challengeActions.map((b) => (
          <div
            key={Math.random()}
            className={`${styles.actionButton} ${
              b.primary ? styles.actionButtonPrimary : ''
            }`}
            onClick={b.action}
          >
            {b.icon}
          </div>
        ))}
        {canPerformActions &&
          (item.entity === 'quiz' ? (
            <DeleteQuizModal quizTitle={item.title} onClick={onDelete(item.id)}>
              <div className={styles.actionButton}>
                <DeleteOutlined />
              </div>
            </DeleteQuizModal>
          ) : (
            <DeleteChallengeModal
              type="quiz"
              challengeTitle={item.title}
              onClick={onDelete(item.id)}
            >
              <div className={styles.actionButton}>
                <DeleteOutlined />
              </div>
            </DeleteChallengeModal>
          ))}
      </div>
    );
  };

  const tableData = (filteredList || []).map((item) => {
    const entity = 'quiz';

    return {
      key: `${entity}-${item.id}`,
      id: item.id,
      title: item.title,
      category: item.category?.name,
      rewardInPoints: item.rewardInPoints,
      type: challengeTypeMap(item.type),
      status: item.status.title,
      actions: actionsBlock({
        ...item,
        entity,
      }),
      entity,
    } as TTableData;
  });

  const columns = [
    {
      title: t('quizzes.quizName'),
      dataIndex: 'title',
      width: '30%',
      render: (title: string, record: TTableData) => {
        if (record.entity === 'quiz') {
          return (
            <Link to={`/quizzes/${record.id}?${query.toString()}`}>
              {title}
            </Link>
          );
        }

        return <a onClick={onEdit(record)}>{title}</a>;
      },
      sorter: true,
      ...(query.has('sort') &&
      ['title', '-title'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-title' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('challenges.category'),
      dataIndex: 'category',
      filters: uniqBy(
        filterData?.categories
          ?.filter((c) => c.id)
          .map((category) => ({
            value: category?.id,
            text: category?.name,
          })),
        'value',
      ),
      ...(query.has('category')
        ? {
            defaultFilteredValue: query.get('category')?.split(','),
          }
        : {}),
    },
    {
      title: t('challenges.points'),
      dataIndex: 'rewardInPoints',
      sorter: true,
      ...(query.has('sort') &&
      ['rewardInPoints', '-rewardInPoints'].includes(
        query.get('sort') as string,
      )
        ? {
            defaultSortOrder:
              query.get('sort') === '-rewardInPoints' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('challenges.status'),
      dataIndex: 'status',
      filters: [
        { value: EChallengeStatuses.DRAFT, text: t('generic.draft') },
        { value: EChallengeStatuses.UPCOMING, text: t('generic.upcoming') },
        { value: EChallengeStatuses.ONGOING, text: t('generic.ongoing') },
        { value: EChallengeStatuses.FINISHED, text: t('generic.finished') },
        { value: EChallengeStatuses.INACTIVE, text: t('generic.inactive') },
      ],
      render: (status: EChallengeStatuses) => (
        <ChallengeStatusBadge status={status} />
      ),
      ...(query.has('status')
        ? {
            defaultFilteredValue: query.get('status')?.split(','),
          }
        : {}),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      width: 100,
    },
  ];
  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };

  const dropdownOptions = [
    { key: 'activate', text: t('generic.activate') },
    { key: 'deactivate', text: t('generic.deactivate') },
    { key: 'delete', text: t('generic.delete') },
  ];
  const dropdownItems = dropdownOptions.map((item) => (
    <Menu.Item key={item.key}> {item.text}</Menu.Item>
  ));

  return (
    <div
      key={`challenges-list-${
        omit(Array.from(query.keys()), ['companies']).length
      }`}
    >
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('nav.category.wellness')]: '/quizzes',
          [t('nav.item.quizzes')]: '',
        }}
        title={t('nav.item.quizzes')}
      />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>{t('quizzes.toolBarTitle')}</div>
        <div className={styles.buttonContainer}>
          <div className={styles.companiesFilterLabel}>
            {t('challenges.assignedTo')}
          </div>
          <Select
            className={styles.companiesField}
            value={t(
              `challenges.assignments.${
                query.has('companies') ? 'companies' : 'everyone'
              }`,
            )}
            dropdownRender={() => (
              <Tree
                checkable
                defaultExpandAll
                selectable={false}
                checkedKeys={
                  query.has('companies')
                    ? query
                        .get('companies')
                        ?.split(',')
                        .map((key: string) => +key)
                    : [EChallengeAssignmentTypes.ALL]
                }
                treeData={[
                  {
                    title: t('challenges.assignments.everyone'),
                    key: EChallengeAssignmentTypes.ALL,
                  },
                  {
                    title: t('challenges.assignments.companies'),
                    key: EChallengeAssignmentTypes.COMPANIES_ONLY,
                    children:
                      adminCompanies?.list?.map((company) => ({
                        title: company.companyName,
                        key: company.id,
                      })) || [],
                  },
                ]}
                onCheck={(checkedKeys: any, e: any) => {
                  const isEveryoneOptionSelected =
                    e.checked && e.node.key === EChallengeAssignmentTypes.ALL;

                  if (!checkedKeys.length || isEveryoneOptionSelected) {
                    query.delete('companies');
                  } else {
                    query.set(
                      'companies',
                      checkedKeys
                        .filter(
                          (key: string | number) => typeof key === 'number',
                        )
                        .join(','),
                    );
                  }

                  navigate({
                    search: `?${query.toString()}`,
                  });
                }}
              />
            )}
          />
          <Search
            allowClear
            defaultValue={query.get('search') || ''}
            className={styles.searchField}
            placeholder={t('quizzes.placeholder.searchChallenge') || ''}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                quizzesData.listParams?.pagination?.perPage
                  ? quizzesData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
          />
          <Button type="primary" onClick={() => navigate('/quizzes/new')}>
            {t('quizzes.createQuiz')}
          </Button>
        </div>
      </div>
      {!!selectedIds.length && (
        <div className={styles.bulkMenu}>
          <div className={styles.bulkMenuCounter}>
            {t('generic.bulk.selected', { length: selectedIds.length })}
          </div>
          <Dropdown
            className={styles.bulkMenuDropdown}
            overlay={
              <Menu onClick={(e) => setBulkOption(e.key)}>{dropdownItems}</Menu>
            }
          >
            <Button>
              {dropdownOptions.find((o) => o.key === bulkOption)?.text}{' '}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button type="primary" onClick={onBulkOperation}>
            {t('generic.confirm')}
          </Button>
        </div>
      )}
      <Table
        actions={quizzesActions}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: quizzesData.listParams?.pagination?.total,
          current: quizzesData.listParams?.pagination?.page,
        }}
      />
    </div>
  );
}

export default QuizzesList;
