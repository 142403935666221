import { Button, Form, Input, Layout, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import FormCard from '../../../../../../components/FormCard/FormCard';
import ActivitySelect from '../../../../../../components/Forms/Company/CompanyActivitySelector/CompanyActivitySelector';
import CategorySelect from '../../../../../../components/Forms/Company/CompanyCategorySelector/CompanyCategorySelector';
import PContact from '../../../../../../components/Forms/Company/CompanyMainPersonOfContact/CompanyMainPersonOfContact';
import PostalCodeInput from '../../../../../../components/Forms/Company/CompanyPostalCodeInput/CompanyPostalCodeInput';
import RequestStatuses from '../../../../../../constants/requestStatuses';
import nullOrAlt from '../../../../../../helpers/nullOrAlt';
import { actions, asyncActions, selectors } from '../../../CompaniesSlice';
import styles from './CompanyInformationTab.module.scss';

const { Content } = Layout;
const { Option } = Select;

type CompanyInformationnTabProps = {
  onSave: (data: typeof CreateCompanyInitialState) => void;
};

export const CreateCompanyInitialState = {
  companyName: null as string | null,
  companyDomain: null as string | null,
  nationalId: null as string | null,
  riskGroup: null as string | null,
  address: null as string | null,
  postalCode: null as string | null,
  city: null as string | null,
  country: null as string | null,
  province: null as string | null,
  legalRepresentativeName: null as string | null,
  legalRepresentativeSurname: null as string | null,
  legalRepresentativeTypeOfDoc: null as string | null,
  legalRepresentativeDocNumber: null as string | null,
  category: null as string | null,
  activity: null as string | null,
  email: null as string | null,
  firstName: null as string | null,
  lastName: null as string | null,
  phoneNumber: null as string | null,
  searchMainContact: '',
};

const onlyDigitsRegex = /^[0-9]*$/g;

const companySchema = yup.object().shape({
  companyName: yup.string().required().nullable(),
  companyDomain: yup.string().required().nullable(),
  nationalId: yup.string().min(1).max(255).required().nullable(),
  riskGroup: yup.string().required().nullable(),
  address: yup.string().required().nullable(),
  postalCode: yup
    .string()
    .length(5)
    .matches(onlyDigitsRegex)
    .required()
    .nullable(),
  city: yup.string().required().nullable(),
  country: yup.string().required().nullable(),
  category: yup.string().required().nullable(),
  activity: yup.string().required().nullable(),
  province: yup.string().required().nullable(),
  legalRepresentativeName: yup.string().required().nullable(),
  legalRepresentativeSurname: yup.string().required().nullable(),
  legalRepresentativeTypeOfDoc: yup.string().required().nullable(),
  legalRepresentativeDocNumber: yup.string().required().nullable(),
  admins: yup.object().shape({
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    country: yup.string().required(),
    phoneNumber: yup.string().required(),
  }),
});

function CompanyInformationTab({ onSave }: CompanyInformationnTabProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // selectors
  const statuses = useSelector(selectors.statuses);

  // validations
  const companyNameValidation = useSelector(
    selectors.validationError('companyName'),
  );

  const companyLegalNameValidation = useSelector(
    selectors.validationError('companyDomain'),
  );

  // company fields
  const [state, setState] = useState(CreateCompanyInitialState);
  const [countryCode, setCountryCode] = useState('');
  const [someThingChange, setChangeFlag] = useState(false);

  useEffect(() => {
    dispatch(actions.clearStatuses());
    dispatch(actions.resetValidation());
  }, []);

  const {
    companyName,
    companyDomain,
    nationalId,
    riskGroup,
    address,
    postalCode,
    city,
    country,
    province,
    legalRepresentativeName,
    legalRepresentativeSurname,
    legalRepresentativeTypeOfDoc,
    legalRepresentativeDocNumber,
    category,
    activity,
    email,
    firstName,
    lastName,
    phoneNumber,
    searchMainContact,
  } = state;

  const onChange = (
    field: keyof typeof CreateCompanyInitialState,
    value: any,
  ) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
    if (field === 'category') {
      setState((prevState) => ({ ...prevState, activity: '' }));
    }
    !someThingChange && setChangeFlag(true);
  };

  const validateFields = () => {
    const validations = companySchema.isValidSync({
      companyName,
      companyDomain,
      nationalId,
      riskGroup,
      address,
      postalCode,
      city,
      country,
      category,
      activity,
      province,
      legalRepresentativeName,
      legalRepresentativeSurname,
      legalRepresentativeTypeOfDoc,
      legalRepresentativeDocNumber,
      countryCode,
      admins: {
        email,
        firstName,
        lastName,
        country: countryCode,
        phoneNumber,
      },
    });

    return validations && !companyNameValidation;
  };

  const sendCompanyData = () => {
    onSave({
      companyName: nullOrAlt(companyName, ''),
      companyDomain: nullOrAlt(companyDomain, ''),
      nationalId: nullOrAlt(nationalId, ''),
      riskGroup: nullOrAlt(riskGroup, ''),
      address: nullOrAlt(address, ''),
      postalCode: nullOrAlt(postalCode, ''),
      city: nullOrAlt(city, ''),
      country: nullOrAlt(country, ''),
      province: nullOrAlt(province, ''),
      category: nullOrAlt(category, ''),
      activity: nullOrAlt(activity, ''),
      legalRepresentativeName: nullOrAlt(legalRepresentativeName, ''),
      legalRepresentativeSurname: nullOrAlt(legalRepresentativeSurname, ''),
      legalRepresentativeTypeOfDoc: nullOrAlt(legalRepresentativeTypeOfDoc, ''),
      legalRepresentativeDocNumber: nullOrAlt(legalRepresentativeDocNumber, ''),
      email: nullOrAlt(email, ''),
      firstName: nullOrAlt(firstName, ''),
      lastName: nullOrAlt(lastName, ''),
      phoneNumber: nullOrAlt(`${countryCode} - ${phoneNumber}`, ''),
      searchMainContact,
    });
  };

  const isCIFValid = () => !nationalId || yup.string().min(1).max(255);

  const serverValidation = () => {
    dispatch(actions.resetValidation());

    const values = Object.entries({
      companyName,
    }).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [key: string]: any });

    if (!Object.keys(values).length) {
      return;
    }

    dispatch<any>(
      asyncActions.validate({
        dataJson: {
          ...values,
        },
      }),
    );
  };

  return (
    <div style={{ width: '80%' }}>
      <div className={styles.title}>
        {t('collective.company..form.companyInformation.addLabel')}
      </div>
      <Layout className={styles.layout}>
        <Content className={styles.content}>
          <FormCard className={styles.card}>
            <>
              <>
                <div className={styles.title}>
                  {t('collective.company.form.companyInformation.title')}
                </div>
                <div className={styles.sectionContainer}>
                  <div className={styles.basicInfo}>
                    <Form className={styles.form} layout="vertical">
                      <div className={styles.basicRowContainer}>
                        <Form.Item
                          label={t(
                            'collective.company.form.companyInformation.companyName',
                          )}
                          className={styles.itemLeftContainer}
                          validateStatus={(() => {
                            if (statuses.validate === RequestStatuses.LOADING) {
                              return 'validating';
                            }
                            if (companyNameValidation) {
                              return 'error';
                            }
                            return '';
                          })()}
                          help={
                            companyNameValidation
                              ? t('collective.company.errors.nameAlreadyExists')
                              : null
                          }
                        >
                          <Input
                            onChange={(e) =>
                              onChange('companyName', e.target.value)
                            }
                            placeholder={t('generic.example') || ''}
                            value={nullOrAlt(companyName, '')}
                            onBlur={serverValidation}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t(
                            'collective.company.form.companyInformation.companyLegalName',
                          )}
                          className={styles.itemRightContainer}
                          validateStatus={(() => {
                            if (statuses.validate === RequestStatuses.LOADING) {
                              return 'validating';
                            }

                            if (companyLegalNameValidation) {
                              return 'error';
                            }

                            return '';
                          })()}
                          help={
                            companyLegalNameValidation
                              ? t(
                                  'collective.company.errors.legalNameAlreadyExists',
                                )
                              : null
                          }
                        >
                          <Input
                            onChange={(e) =>
                              onChange('companyDomain', e.target.value)
                            }
                            placeholder={`${t(
                              'collective.company.form.companyInformation.companyLegalName',
                            )}`}
                            value={nullOrAlt(companyDomain, '')}
                            onBlur={serverValidation}
                          />
                        </Form.Item>
                      </div>
                      <div className={styles.basicRowContainer}>
                        <Form.Item
                          label={t(
                            'collective.company.form.companyInformation.cif',
                          )}
                          className={styles.itemLeftContainer}
                        >
                          <Input
                            className={isCIFValid() ? '' : styles.inputError}
                            min={0}
                            onChange={(e) =>
                              onChange('nationalId', e.target.value)
                            }
                            placeholder={
                              t(
                                'collective.company.form.companyInformation.cif',
                              ) || ''
                            }
                            value={nullOrAlt(nationalId, '')}
                          />
                        </Form.Item>
                        <CategorySelect
                          onChange={(value) => {
                            onChange('category', value.trim());
                          }}
                          category={category}
                        />
                      </div>
                      <div className={styles.basicRowContainer}>
                        <ActivitySelect
                          category={category}
                          activity={activity}
                          onChange={(val) => {
                            onChange('activity', val.trim());
                          }}
                        />
                        <Form.Item
                          label={t(
                            'collective.company.form.companyInformation.riskGroup',
                          )}
                          className={styles.itemRightContainer}
                        >
                          <Input
                            onChange={(e) =>
                              onChange('riskGroup', e.target.value)
                            }
                            placeholder={
                              t(
                                'collective.company.form.companyInformation.riskGroup',
                              ) || ''
                            }
                            value={nullOrAlt(riskGroup, '')}
                          />
                        </Form.Item>
                      </div>
                      <div
                        style={{ width: '100%' }}
                        className={styles.basicRowContainer}
                      >
                        <div
                          style={{ width: '50.7%' }}
                          className={styles.basicRowContainer}
                        >
                          <Form.Item
                            label={t(
                              'collective.company.form.companyInformation.address',
                            )}
                            className={styles.itemLeftContainer}
                          >
                            <Input
                              onChange={(e) =>
                                onChange('address', e.target.value)
                              }
                              placeholder={
                                t(
                                  'collective.company.form.companyInformation.address',
                                ) || ''
                              }
                              value={nullOrAlt(address, '')}
                            />
                          </Form.Item>
                        </div>
                        <div
                          style={{ width: '49.3%' }}
                          className={styles.basicRowContainer}
                        >
                          <PostalCodeInput
                            onChange={(val) => {
                              onChange('postalCode', val);
                            }}
                            postalCode={nullOrAlt(postalCode, '')}
                          />
                          <Form.Item
                            label={t(
                              'collective.company.form.companyInformation.city',
                            )}
                            className={styles.itemRightContainer}
                          >
                            <Input
                              onChange={(e) => onChange('city', e.target.value)}
                              placeholder={
                                t(
                                  'collective.company.form.companyInformation.city',
                                ) || ''
                              }
                              value={nullOrAlt(city, '')}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={styles.basicRowContainer}>
                        <Form.Item
                          label={t(
                            'collective.company.form.companyInformation.region',
                          )}
                          className={styles.itemLeftContainer}
                        >
                          <Input
                            onChange={(e) =>
                              onChange('province', e.target.value)
                            }
                            placeholder={
                              t(
                                'collective.company.form.companyInformation.region',
                              ) || ''
                            }
                            value={nullOrAlt(province, '')}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t(
                            'collective.company.form.companyInformation.country',
                          )}
                          className={styles.itemRightContainer}
                        >
                          <Input
                            onChange={(e) =>
                              onChange('country', e.target.value)
                            }
                            placeholder={
                              t(
                                'collective.company.form.companyInformation.country',
                              ) || ''
                            }
                            value={nullOrAlt(country, '')}
                          />
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
              <>
                <div className={styles.title}>
                  {t('collective.company.form.legalRepresentative.title')}
                </div>
                <div className={styles.sectionContainer}>
                  <div className={styles.basicInfo}>
                    <Form className={styles.form} layout="vertical">
                      <div className={styles.basicRowContainer}>
                        <Form.Item
                          label={t(
                            'collective.company.form.legalRepresentative.name',
                          )}
                          className={styles.itemLeftContainer}
                        >
                          <Input
                            onChange={(e) =>
                              onChange(
                                'legalRepresentativeName',
                                e.target.value,
                              )
                            }
                            placeholder={
                              t(
                                'collective.company.form.legalRepresentative.name',
                              ) || ''
                            }
                            value={nullOrAlt(legalRepresentativeName, '')}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t(
                            'collective.company.form.legalRepresentative.surname',
                          )}
                          className={styles.itemRightContainer}
                        >
                          <Input
                            onChange={(e) =>
                              onChange(
                                'legalRepresentativeSurname',
                                e.target.value,
                              )
                            }
                            placeholder={
                              t(
                                'collective.company.form.legalRepresentative.surname',
                              ) || ''
                            }
                            value={nullOrAlt(legalRepresentativeSurname, '')}
                          />
                        </Form.Item>
                      </div>
                      <div
                        style={{ width: '50.7%' }}
                        className={styles.basicRowContainer}
                      >
                        <Form.Item
                          label={t(
                            'collective.company.form.legalRepresentative.documentType',
                          )}
                          className={styles.itemLeftContainer}
                        >
                          <Select
                            onChange={(e: string) =>
                              onChange('legalRepresentativeTypeOfDoc', e)
                            }
                            placeholder={t('generic.pleaseSelect')}
                            value={nullOrAlt(legalRepresentativeTypeOfDoc, '')}
                          >
                            <Option value="NIE">NIE</Option>
                            <Option value="NIF">NIF</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={t(
                            'collective.company.form.legalRepresentative.document',
                          )}
                          className={styles.itemLeftContainer}
                        >
                          <Input
                            onChange={(e) =>
                              onChange(
                                'legalRepresentativeDocNumber',
                                e.target.value,
                              )
                            }
                            placeholder={t('generic.example') || ''}
                            value={nullOrAlt(legalRepresentativeDocNumber, '')}
                          />
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            </>
          </FormCard>
        </Content>
      </Layout>
      {/* Main Person of Contact */}
      <PContact
        email={email}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        searchMainContact={searchMainContact}
        onChange={(field, val) => {
          if (field === 'countryCode') {
            setCountryCode(val);
          } else onChange(field, val);
        }}
      />
      <div className={styles.buttons}>
        <Button
          disabled={!validateFields()}
          className={styles.button}
          type="primary"
          onClick={sendCompanyData}
        >
          <span>{t('collective.company.form.create')}</span>
        </Button>
      </div>
    </div>
  );
}

export default CompanyInformationTab;
