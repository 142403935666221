import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userTypes } from '../../../UsersConstants';
import styles from './PersonalInfo.module.scss';
import FormTitle from '../../../../../components/Forms/FormTitle/FormTitle';
import FormItemTwoColumn from '../../../../../components/Forms/FormItemTwoColumn/FormItemTwoColumn';
import ContinueEditingModal from '../../../../../components/Modals/ContinueEditingModal/ContinueEditingModal';
import { selectors } from '../../../UsersSlice';
import RequestStatuses from '../../../../../constants/requestStatuses';
import RoleWrapper from '../../../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../../../constants/userRoles';

const { Option } = Select;

type TPersonalInfo = {
  onChange: (field: any, value: any) => void;
  userData: {
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    dateOfBirth: string;
    type: string;
    professionalEmail?: string;
    companyName?: string;
  };
  onSave: () => void;
  onClose: () => void;
  allowToSave: boolean;
  showButtons?: boolean;
  editFlag?: boolean;
  isValid: (val: boolean) => void;
};

function PersonalInfo({
  onChange,
  userData,
  onSave,
  onClose,
  allowToSave,
  showButtons,
  editFlag,
  isValid,
}: TPersonalInfo) {
  const { t } = useTranslation();

  const { firstName, lastName, email } = userData;

  const [error, setError] = useState('');
  const [nameInputError, setNameInputError] = useState('');
  const [lastNameInputError, setLastNameInputError] = useState('');
  const [emailInputError, setEmailInputError] = useState('');

  const currentUserData = useSelector(selectors.currentItem);
  const statuses = useSelector(selectors.statuses);

  useEffect(() => {
    clearErrors();
  }, [currentUserData]);

  useEffect(() => {
    if (
      statuses.create === RequestStatuses.FAILED ||
      statuses.update === RequestStatuses.FAILED
    ) {
      setError(String(t('generic.emailAlreadyExist')));
      setEmailInputError('');
      isValid(false);
    } else if (
      statuses.create === RequestStatuses.SUCCESS ||
      statuses.update === RequestStatuses.SUCCESS ||
      email === ''
    ) {
      setError('');
      if (
        nameInputError === '' &&
        lastNameInputError === '' &&
        emailInputError === ''
      ) {
        isValid(true);
      }
    }
  }, [statuses]);

  const nameValidation = () => {
    const characters = "0123456789ª!|@·#$~%&¬/()=?¿'¡`+´ç^*{},.-;:_";

    const onlyLetters = firstName
      .split('')
      .find((character) => characters.indexOf(character) !== -1);

    if (onlyLetters) {
      setNameInputError(String(t('generic.lettersValidation')));
      isValid(false);
    } else {
      setNameInputError('');
      if (error === '' && lastNameInputError === '' && emailInputError === '') {
        isValid(true);
      }
    }
  };

  const lastNameValidation = () => {
    const characters = "0123456789ª!|@·#$~%&¬/()=?¿'¡`+´ç^*{},.-;:_";

    const onlyLetters = lastName
      .split('')
      .find((character) => characters.indexOf(character) !== -1);

    if (onlyLetters) {
      setLastNameInputError(String(t('generic.lettersValidation')));
      isValid(false);
    } else {
      setLastNameInputError('');
      if (error === '' && nameInputError === '' && emailInputError === '') {
        isValid(true);
      }
    }
  };

  const emailValidation = () => {
    if (email.split('').filter((i) => i === '@').length === 1) {
      setEmailInputError('');
      if (nameInputError === '' && lastNameInputError === '' && error === '') {
        isValid(true);
      }
    } else {
      setEmailInputError(String(t('generic.invalidEmail')));
      setError('');
      isValid(false);
    }
  };

  const createValidation = () => {
    if (
      allowToSave &&
      nameInputError === '' &&
      lastNameInputError === '' &&
      emailInputError === ''
    ) {
      return false;
    }
    return true;
  };

  const clearErrors = () => {
    setError('');
    setEmailInputError('');
    setNameInputError('');
    setLastNameInputError('');
  };

  return (
    <div className={styles.personalInfo}>
      <Form layout="vertical">
        <FormTitle className={styles.formTitle}>
          {String(t('users.form.personalInformation'))}
        </FormTitle>
        <FormItemTwoColumn>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('users.form.firstName')}
            >
              <RoleWrapper
                roles={[UserRoles.ADMIN]}
                alternative={
                  <div className={styles.userDataValue}>{firstName}</div>
                }
              >
                <>
                  <Input
                    onChange={(e) => {
                      onChange('firstName', e.target.value);
                    }}
                    placeholder={String(t('generic.example'))}
                    value={firstName}
                    onBlur={nameValidation}
                  />
                  {nameInputError && (
                    <span style={{ color: 'red' }}>{nameInputError}</span>
                  )}
                </>
              </RoleWrapper>
            </Form.Item>
          </FormItemTwoColumn.Column>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('users.form.surnames')}
            >
              <RoleWrapper
                roles={[UserRoles.ADMIN]}
                alternative={
                  <div className={styles.userDataValue}>{lastName}</div>
                }
              >
                <>
                  <Input
                    onChange={(e) => onChange('lastName', e.target.value)}
                    placeholder={String(t('generic.example'))}
                    value={lastName}
                    onBlur={lastNameValidation}
                  />
                  {lastNameInputError && (
                    <span style={{ color: 'red' }}>{lastNameInputError}</span>
                  )}
                </>
              </RoleWrapper>
            </Form.Item>
          </FormItemTwoColumn.Column>
        </FormItemTwoColumn>
        <FormItemTwoColumn>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('users.form.typeOfUser')}
            >
              <RoleWrapper
                roles={[UserRoles.ADMIN]}
                alternative={
                  <div className={styles.userDataValue}>
                    {userData.type
                      ? userData.type.charAt(0).toUpperCase() +
                        userData.type.slice(1)
                      : ''}
                  </div>
                }
              >
                <Select
                  // onChange={(val) => onChange('firstName', val)}
                  placeholder={t('generic.example')}
                  value={userData.type}
                  defaultValue={userData.type}
                >
                  <Option value={userTypes.EMPLOYEE}>
                    {t('users.form.employee')}
                  </Option>
                  <Option value={userTypes.APP_USER}>
                    {t('users.form.appUser')}
                  </Option>
                </Select>
              </RoleWrapper>
            </Form.Item>
          </FormItemTwoColumn.Column>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('users.form.personalEmail')}
            >
              <RoleWrapper
                roles={[UserRoles.ADMIN]}
                alternative={
                  <div className={styles.userDataValue}>{email}</div>
                }
              >
                <>
                  <Input
                    onChange={(e) => {
                      onChange('email', e.target.value);
                      setError('');
                    }}
                    placeholder="email@email.com"
                    type="email"
                    value={email}
                    onBlur={emailValidation}
                  />
                  {error && <span style={{ color: 'red' }}>{error}</span>}
                  {emailInputError && (
                    <span style={{ color: 'red' }}>{emailInputError}</span>
                  )}
                </>
              </RoleWrapper>
            </Form.Item>
          </FormItemTwoColumn.Column>
        </FormItemTwoColumn>
        <FormTitle className={styles.formTitle}>
          {String(t('users.form.company'))}
        </FormTitle>
        <FormItemTwoColumn>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('users.form.company')}
            >
              <RoleWrapper
                roles={[UserRoles.ADMIN]}
                alternative={
                  <div className={styles.userDataValue}>
                    {userData.companyName}
                  </div>
                }
              >
                <Select
                  placeholder={t('generic.placeholder.placeSelect')}
                  disabled={!userData.companyName}
                  value={userData.companyName}
                >
                  <Option value={userData.companyName}>
                    {userData.companyName}
                  </Option>
                </Select>
              </RoleWrapper>
            </Form.Item>
          </FormItemTwoColumn.Column>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('users.form.department')}
            >
              <Select
                // onChange={(val) => onChange('firstName', val)}
                placeholder={t('generic.placeholder.placeSelect')}
                disabled
              >
                <Option value="employee">-</Option>
              </Select>
            </Form.Item>
          </FormItemTwoColumn.Column>
        </FormItemTwoColumn>
        <FormItemTwoColumn>
          <FormItemTwoColumn.Column>
            <Form.Item
              className={styles.formItem}
              label={t('users.form.professionalEmail')}
            >
              <RoleWrapper
                roles={[UserRoles.ADMIN]}
                alternative={
                  <div className={styles.userDataValue}>
                    {userData.professionalEmail}
                  </div>
                }
              >
                <Input
                  onChange={(e) =>
                    onChange('professionalEmail', e.target.value)
                  }
                  placeholder="email@email.com"
                  value={userData.professionalEmail}
                  disabled={userData.professionalEmail === undefined}
                />
              </RoleWrapper>
            </Form.Item>
          </FormItemTwoColumn.Column>
          <FormItemTwoColumn.Column>
            <div />
          </FormItemTwoColumn.Column>
        </FormItemTwoColumn>
      </Form>
      {showButtons && (
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            type="primary"
            onClick={onSave}
            disabled={createValidation()}
          >
            {t('users.buttons.createUserProfile')}
          </Button>
          {editFlag ? (
            <ContinueEditingModal
              onClick={() => {
                clearErrors();
                onClose();
              }}
            >
              <Button className={styles.button} onClick={() => {}}>
                {t('generic.modals.buttons.cancel')}
              </Button>
            </ContinueEditingModal>
          ) : (
            <Button
              className={styles.button}
              onClick={() => {
                clearErrors();
                onClose();
              }}
            >
              {t('generic.modals.buttons.cancel')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

PersonalInfo.defaultProps = {
  showButtons: false,
  editFlag: false,
};

export default PersonalInfo;
