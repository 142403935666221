import {
  apiRequest,
  apiRequestFormData,
  apiRequestGet,
  TListParams,
} from '../../../helpers/apiRequest';
import {
  TClaimedReward,
  TClaimedRewardRewardsResponse,
  TSingleClaimedRewardRewardResponse,
} from './ClaimedTypes';

export const fetchClaimedRewards = async (
  listParams?: TListParams,
): Promise<TSingleClaimedRewardRewardResponse> =>
  apiRequestGet<TSingleClaimedRewardRewardResponse>(
    '/rewards/claimed',
    {...listParams, version: 'v2'},
  );

export const publishClaimedReward = async (
  id: TClaimedReward['id'],
): Promise<TClaimedRewardRewardsResponse> =>
  apiRequest<TClaimedRewardRewardsResponse>(
    `/rewards/claimed/${id}/grant`,
    'post',
    null,
    { version: 'v2' }    
  );

export const unpublishClaimedReward = async (
  id: TClaimedReward['id'],
): Promise<TClaimedRewardRewardsResponse> =>
  apiRequest<TClaimedRewardRewardsResponse>(
    `/rewards/claimed/${id}/cancel`,
    'post',
    null,
    { version: 'v2' }
  );
