import { fetchClaimedrewards } from './UsersApi';
import {
  TClaimedReward,
  TClaimedRewardListResponse,
  TClaimedRewardResponse,
} from './UsersTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

const claimedRewardsSlice = buildSlice<
  TClaimedReward,
  TClaimedRewardResponse,
  TClaimedRewardListResponse
>('claimedRewards', {
  fetchList: fetchClaimedrewards,
});

export const { selectors, asyncActions, actions } = claimedRewardsSlice;

export default claimedRewardsSlice.reducer;
