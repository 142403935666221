// form validator
import * as yup from 'yup';
import { EChallengeTypes } from '../../ChallengesTypes';

const ChallengeSingleFormSchema = yup
  .object({
    // required
    title: yup.string().trim().required(),
    goalValue: yup.string().required(),
    imageBase64: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup
      .date()
      .when('startDate', (startDate: Date, yupP) =>
        startDate ? yupP.min(startDate) : yupP.min(new Date()),
      )
      .required(),
    description: yup.string().nullable().required(),
    // optional
    goalType: yup.string().default('STEPS'),
    type: yup.string().default(EChallengeTypes.PERSONAL),
    repetition: yup.string().default('TOTAL'),
    calculationMethod: yup.string().default('SUM'),
    steakType: yup.string().default('NONE'),
    goalCap: yup.boolean().default(false),
    enrollmentMethod: yup.string().default('AUTOMATIC'),
    visibility: yup.string().oneOf(['PUBLIC', 'PRIVATE']).default('PUBLIC'),
    companies: yup.array().nullable().optional(),
    assignmentType: yup.string().nullable().optional(),
    activityType: yup.array().nullable().optional(),
    isSpecificLeaderboard: yup.string().nullable().optional(),
  })
  .required();

export default ChallengeSingleFormSchema;
