import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  AutoComplete,
  Tag,
} from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uniqWith } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { actions, asyncActions, selectors } from '../CategoriesSlice';
import {
  asyncActions as activityAsyncActions,
  selectors as activitySelectors,
  actions as activityActions,
} from '../../activityTags/ActivityTagsSlice';
import ContinueEditingModal from '../../../components/Modals/ContinueEditingModal/ContinueEditingModal';
import nullOrAlt from '../../../helpers/nullOrAlt';
import FormCard from '../../../components/FormCard/FormCard';
import styles from './CategoryForm.module.scss';
import FormItemTwoColumn from '../../../components/Forms/FormItemTwoColumn/FormItemTwoColumn';
import ColorPicker from '../../../components/ColorPicker/ColorPicker';
import FormTitle from '../../../components/Forms/FormTitle/FormTitle';
import { TActivityTag } from '../../activityTags/ActivityTagsTypes';
import DeleteCategoryModal from '../../../components/Modals/CategoriesModal/DeleteCategoriesModal/DeleteChallengeModal';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';

type CategoryFormParams = {
  id: string;
  section: string;
};

const initialState = {
  title: null as string | null,
  color: null as string | null,
  activities: null as TActivityTag[] | null,
};

function CategoryForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: categoryId, section }= useParams();

  const isExistingCategory = useMemo(() => categoryId !== 'new', [categoryId]);

  // selectors
  const currentCategory = useSelector(selectors.currentItem);
  const availableActivities = useSelector(activitySelectors.listData);
  const currentActivity = useSelector(activitySelectors.currentItem);

  // state
  const [state, setState] = useState(initialState);
  const [editFlag, setEditFlag] = useState(false);
  const [enteredActivity, setEnteredActivity] = useState(null as string | null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [activitiesForAttach, setActivitiesForAttach] = useState(
    [] as TActivityTag[],
  );
  const [activitiesForDetach, setActivitiesForDetach] = useState(
    [] as TActivityTag[],
  );

  const [autocompleteKey, setAutocompleteKey] = useState(null as string | null);

  const { title, color, activities } = state;

  const attachedActivities =
    categoryId && isExistingCategory
      ? (currentCategory?.activityTagToCategory || [])
          .map((actToCat) => actToCat.activityTag)
          .concat(activitiesForAttach)
          .filter(
            (a) => activitiesForDetach.map((ad) => ad.id).indexOf(a.id) === -1,
          )
      : activities || [];

  useEffect(() => {
    if (categoryId && isExistingCategory) {
      dispatch<any>(asyncActions.get({ id: +categoryId }));
    } else {
      dispatch(actions.clearCurrentItem());
    }
  }, [dispatch, categoryId]);

  useEffect(() => {
    dispatch<any>(activityAsyncActions.fetchList());

    return () => {
      dispatch(actions.clearCurrentItem());
      dispatch(activityActions.clearCurrentItem());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(activityActions.setSearch({ query: enteredActivity || '' }));
    dispatch<any>(activityAsyncActions.fetchList());
  }, [enteredActivity, dispatch]);

  useEffect(() => {
    if (
      !currentActivity ||
      attachedActivities.map((a) => a.id)?.indexOf(currentActivity.id) !== -1
    ) {
      return;
    }
    if (categoryId && isExistingCategory) {
      setActivitiesForAttach([...activitiesForAttach, currentActivity]);
      dispatch(activityActions.clearCurrentItem());
    } else {
      onChange('activities', [...(activities || []), currentActivity]);
    }
  }, [currentActivity, dispatch]);

  const onClose = (locationState: string = '') =>
    navigate(`/categories/${section}`,
      { state: {
        search: location.state as string,
        state: locationState,
      }}
    );

  const validateFields = () => {
    let allFilled = true;
    [title, color, !!activities?.length].forEach((f) => {
      allFilled = allFilled && !!f;
    });

    return allFilled;
  };

  const validateFieldsForUpdate = () => {
    let allFilled = true;
    [!!attachedActivities.length].forEach((f) => {
      allFilled = allFilled && f;
    });

    return allFilled;
  };

  const onChange = (key: keyof typeof initialState, value: any) => {
    setState({ ...state, [key]: value });
    setEditFlag(true);
  };

  const onCancel = (locationState: string = '') => {
    setEditFlag(false);
    onClose(locationState);
  };

  const onCancelPress = () => {
    if (editFlag) {
      setShowConfirmModal(true)
    } else {
      onCancel()
    }
  }

  const onSave = () => {
    setEditFlag(false);

    const categoryData = {
      name: title || undefined,
      color: color || undefined,
      activityTags: activities?.map((a) => a.id) || undefined,
      icon: '🧠',
    };

    if (categoryId && isExistingCategory) {
      dispatch<any>(
        asyncActions.update({ id: +categoryId, dataJson: categoryData }),
      );
    } else {
      dispatch<any>(asyncActions.create({ dataJson: categoryData }));
    }

    if (activitiesForAttach.length) {
      dispatch(
        asyncActions.singleRequests?.attachActivityBulk?.({
          id: categoryId,
          dataJson: {
            activityTagIds: activitiesForAttach.map((a) => a.id),
          },
        }),
      );
    }
    if (activitiesForDetach.length) {
      dispatch(
        asyncActions.singleRequests?.detachActivityBulk?.({
          id: categoryId,
          dataJson: { activityTagIds: activitiesForDetach.map((a) => a.id) },
        }),
      );
    }

    onChange('activities', null);
    setActivitiesForAttach([]);
    setActivitiesForDetach([]);
    onCancel('published');
  };

  const onAddActivity = () => {
    const existingActivity = availableActivities.list?.find(
      (a) => a.name.toLowerCase() === (enteredActivity || '').toLowerCase(),
    );

    if (existingActivity) {
      if (categoryId && isExistingCategory) {
        setActivitiesForDetach(
          activitiesForDetach.filter((a) => a.id !== existingActivity.id),
        );

        const alreadyAttachedActivity = attachedActivities.find(
          (activity) =>
            activity.name.toLowerCase() === existingActivity.name.toLowerCase(),
        );

        if (!alreadyAttachedActivity) {
          setActivitiesForAttach(
            uniqWith(
              [...activitiesForAttach, existingActivity],
              (prevValue, nextValue) =>
                prevValue.name.toLowerCase() === nextValue.name.toLowerCase(),
            ).filter(
              (a) =>
                currentCategory?.activityTagToCategory
                  ?.map((at) => at.id)
                  .indexOf(a.id) === -1,
            ),
          );
        }
      } else {
        onChange(
          'activities',
          uniqWith(
            [...(activities || []), existingActivity],
            (prevValue, nextValue) =>
              prevValue.name.toLowerCase() === nextValue.name.toLowerCase(),
          ),
        );
      }
    } else if (enteredActivity) {
      dispatch<any>(
        activityAsyncActions.create({ dataJson: { name: enteredActivity } }),
      );
    }
    setEnteredActivity(null);
    setEditFlag(true);
    setAutocompleteKey((+new Date()).toString());
  };

  const removeActivity = (activity: TActivityTag) => {
    if (categoryId && isExistingCategory) {
      setActivitiesForAttach(
        activitiesForAttach.filter((a) => a.id !== activity.id),
      );
      setActivitiesForDetach([...activitiesForDetach, activity]);
    } else {
      onChange(
        'activities',
        activities?.filter((a) => a.id !== activity.id) || [],
      );
    }
    setEditFlag(true);
  };

  const activityOptions = availableActivities.list
    ?.filter(
      (activity) => !attachedActivities.find((item) => item.id === activity.id),
    )
    ?.map((a) => ({
      value: a.name,
    }));

  const headerMenu = (
    <Menu className={styles.dropdown}>
      <DeleteCategoryModal
        categoryTitle={nullOrAlt(title, currentCategory?.name || '')}
        onClick={() => {
          if (currentCategory) {
            dispatch<any>(asyncActions.delete({ item: currentCategory }));
            onCancel();
          }
        }}
      >
        <Menu.Item
          className={styles.deleteButton}
          key={Math.random()}
          disabled={!currentCategory}
        >
          {t('generic.buttons.delete')}
        </Menu.Item>
      </DeleteCategoryModal>
    </Menu>
  );

  return (
    <div>
      <ProPageHeader
        prefixedClassName={styles.pageHeader}
        title={t('categories.forms.title')}
        extra={[
          <Button className={styles.defaultButton} onClick={() => onCancelPress()}>
            {t('generic.modals.buttons.cancel')}
          </Button>,
          categoryId && isExistingCategory ? (
            <Button
              type="primary"
              onClick={onSave}
              disabled={!validateFieldsForUpdate()}
            >
              {t('generic.modals.save')}
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={!validateFields()}
              onClick={onSave}
            >
              {t('generic.create')}
            </Button>
          ),
          <Dropdown overlay={headerMenu} placement="bottomRight">
            <Button style={{ width: '32px', padding: 0 }}>...</Button>
          </Dropdown>,
        ]}
        onBack={() => {}}
        backIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ margin: 0, padding: 0 }}
            onClick={() => onClose()}
          />
        }
      />
      <div className={styles.formContainer}>
        <FormCard className={styles.formCard}>
          <Form layout="vertical">
            <FormItemTwoColumn>
              <FormItemTwoColumn.Column>
                <Form.Item
                  className={styles.formItem}
                  label={t('categories.forms.categoryTitle')}
                >
                  <Input
                    onChange={(e) => onChange('title', e.target.value)}
                    placeholder={t('generic.example') || ''}
                    value={nullOrAlt(title, currentCategory?.name || '')}
                  />
                </Form.Item>
              </FormItemTwoColumn.Column>
              <FormItemTwoColumn.Column>
                <div />
              </FormItemTwoColumn.Column>
            </FormItemTwoColumn>
            <FormItemTwoColumn>
              <FormItemTwoColumn.Column>
                <Form.Item
                  className={styles.formItem}
                  label={t('categories.forms.categoryColor')}
                >
                  <ColorPicker
                    currentColor={nullOrAlt(
                      color,
                      currentCategory?.color || '#FFFFFF',
                    )}
                    onColorSelect={(val) => onChange('color', val)}
                  />
                </Form.Item>
              </FormItemTwoColumn.Column>
              <FormItemTwoColumn.Column>
                <div />
              </FormItemTwoColumn.Column>
            </FormItemTwoColumn>
            <FormTitle className={styles.formTitle}>
              {t('categories.form.addActivities') || ''}
            </FormTitle>
            <FormItemTwoColumn>
              <FormItemTwoColumn.Column>
                <Form.Item
                  className={styles.formItem}
                  label={t('categories.forms.activityName')}
                >
                  <div className={styles.activityInputWrap}>
                    <AutoComplete
                      key={`autocomplete-${autocompleteKey}`}
                      placeholder={t('categories.placeholder.walking')}
                      value={enteredActivity || ''}
                      options={activityOptions}
                      filterOption
                      onChange={(val) => setEnteredActivity(val.trim())}
                    />
                    <Button
                      className={styles.activityAddButton}
                      onClick={onAddActivity}
                    >
                      {t('categories.buttons.addActivity')}
                    </Button>
                  </div>
                </Form.Item>
              </FormItemTwoColumn.Column>
              <FormItemTwoColumn.Column>
                <div />
              </FormItemTwoColumn.Column>
            </FormItemTwoColumn>
            <FormTitle className={styles.formTitle}>
              {t('categories.forms.addedActivities') || ''}
            </FormTitle>
            <FormItemTwoColumn>
              <FormItemTwoColumn.Column>
                <div className={styles.activityTagsWrap}>
                  {attachedActivities.map((a) => (
                    <Tag
                      className={styles.tag}
                      color="#69AA9E"
                      closable
                      onClose={(e) => {
                        removeActivity(a);
                        e.preventDefault();
                      }}
                    >
                      {a.name}
                    </Tag>
                  ))}
                </div>
              </FormItemTwoColumn.Column>
              <FormItemTwoColumn.Column>
                <div />
              </FormItemTwoColumn.Column>
            </FormItemTwoColumn>
          </Form>
        </FormCard>
      </div>
      <RouteLeavingGuard
        lastLocation={'/categories/challenges'}
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </div>
  );
}

export default CategoryForm;
