import { getCompanyCategories } from './CompaniesApi';

import buildSlice from '../../general/helpers/genericSliceBuilder';

const companiesCategoriesSliece = buildSlice<any, any, any>(
  'companiesCategories',
  {
    fetchList: getCompanyCategories,
  },
);

export const { selectors, asyncActions, actions } = companiesCategoriesSliece;

export default companiesCategoriesSliece.reducer;
