import React from 'react';
import styles from './CentralCard.module.scss';

type TCentralCard = {
  children: JSX.Element;
  className?: string;
};

function CentralCard({ children, className }: TCentralCard) {
  return <div className={`${styles.card} ${className}`}>{children}</div>;
}

export default CentralCard;
