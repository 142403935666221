import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteRewardModalProps = {
  children: JSX.Element;
  onClick: () => void;
};

function SaveProductModal({ children, onClick }: TDeleteRewardModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('products.modals.save.title'),
    okText: t('generic.modals.save'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('products.modals.save.content'),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM}
    />
  );
}

export default SaveProductModal;
