import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteRewardModalProps = {
  productName: string;
  children: JSX.Element;
  onClick: () => void;
};

function DeleteProductModal({
  productName,
  children,
  onClick,
}: TDeleteRewardModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('products.modals.delete.title'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('products.modals.delete.content', { productName }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeleteProductModal;
