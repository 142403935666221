import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Dropdown, Input, Menu, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'antd/es/table/interface';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { uniqBy } from 'lodash';
import { selectors, asyncActions, actions } from '../ArticlesSlice';
import { EArticleStatuses, TArticle, TArticleFilled } from '../ArticlesTypes';
import { getArticleStatus, getArticleStatusRendered } from '../ArticlesHelpers';
import DeleteArticleModal from '../../../components/Modals/ArticlesModals/DeleteArticleModal/DeleteArticleModal';
import styles from './ArticleList.module.scss';
import PageHeaderDefault from '../../../components/PageHeaderDefault/PageHeaderDefault';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../constants/requestStatuses';
import useQuery from '../../../helpers/useQuery';
import Table from '../../../components/Table/Table';
import { TCategory } from '../../categories/CategoriesTypes';

const { Search } = Input;

type TTableData = {
  key: Key;
  image: TArticleFilled['titleImage'];
  title: TArticleFilled['title'];
  category: TArticleFilled['category'];
  createdAt: string;
  updatedAt: string;
  status: string;
  actions: null;
};

function ArticleList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useQuery();

  // selectors
  const articlesData = useSelector(selectors.listData);
  const filterData = useSelector(selectors.filterData) as
    | { categories: TCategory[] }
    | undefined;
  const statuses = useSelector(selectors.statuses);
  const bulkArticles = useSelector(selectors.bulkItems);
  const actionArticles = useSelector(selectors.actionItems);

  // effects
  useLayoutEffect(() => {
    dispatch(actions.clearFilters());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';

      dispatch(
        actions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    if (query.has('category')) {
      const filterCategories = (query.get('category') || '').split(',');

      for (const category of filterCategories) {
        dispatch(
          actions.setFilter({
            field: 'category',
            value: category,
          }),
        );
      }
    }

    if (query.has('status')) {
      const filterStatus = (query.get('status') || '').split(',');

      for (const status of filterStatus) {
        dispatch(
          actions.setFilter({
            field: 'status',
            value: status,
          }),
        );
      }
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (articlesData.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, articlesData]);

  const bulkPopup = (
    status: RequestStatuses | null | undefined,
    articles: TArticleFilled[],
    operation: string,
    isDeleted?: boolean,
  ) => {
    const { length } = articles;
    if (status === RequestStatuses.SUCCESS) {
      if (isDeleted) {
        ActionAlert.info(
          length > 1
            ? t('articles.alerts.bulkPopupPrural', { length, operation })
            : t('articles.alerts.bulkPopupSingular', { length, operation }),
        );
      } else {
        ActionAlert.success(
          length > 1
            ? t('articles.alerts.bulkPopupPrural', { length, operation })
            : t('articles.alerts.bulkPopupSingular', { length, operation }),
        );
      }
      dispatch(actions.clearStatuses());
    }
  };
  const singlePopup = (
    status: RequestStatuses | null | undefined,
    article: TArticleFilled | null,
    operation: string,
    isDeleted?: boolean,
  ) => {
    if (status === RequestStatuses.SUCCESS) {
      if (isDeleted) {
        ActionAlert.info(
          t('articles.alerts.singualarPopup', {
            title: article?.title,
            operation,
          }),
        );
      } else {
        ActionAlert.success(
          t('articles.alerts.singualarPopup', {
            title: article?.title,
            operation,
          }),
        );
      }
      dispatch(actions.clearStatuses());
    }
  };
  useEffect(
    () =>
      bulkPopup(
        statuses.deleteBulk,
        bulkArticles.delete,
        t('generic.operations.deleted'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      bulkPopup(
        statuses.publishBulk,
        bulkArticles.publish,
        t('generic.operations.activated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      bulkPopup(
        statuses.unpublishBulk,
        bulkArticles.unpublish,
        t('generic.operations.deactivated'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.delete,
        actionArticles.delete,
        t('generic.operations.deleted'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.publish,
        actionArticles.publish,
        t('generic.operations.activated'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.unpublish,
        actionArticles.unpublish,
        t('generic.operations.deactivated'),
        true,
      ),
    [statuses],
  );
  useEffect(() => setSelectedIds([]), [statuses]);

  // state
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [bulkOption, setBulkOption] = useState('publish');

  // actions
  const onCopy = (id: TArticle['id']) => () => {
    // TODO: implement copy
  };
  const onDelete = (id: TArticle['id']) => () => {
    const article = articlesData.list?.find((a) => a.id === id);
    if (article) {
      dispatch<any>(asyncActions.delete({ item: article }));
    }
  };

  // table data
  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };
  const actionsBlock = (id: TArticle['id'], title: TArticle['title']) => (
    <div className={styles.tableActions}>
      <Link
        to={{
          pathname: `/articles/${id}`,
          search: `?${query.toString()}`
        }}
        className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
      >
        <EditOutlined />
      </Link>
      {[{ icon: <CopyOutlined />, action: onCopy(id) }].map((b) => (
        <div className={styles.actionButton} onClick={b.action}>
          {b.icon}
        </div>
      ))}
      <DeleteArticleModal articleName={title} onClick={onDelete(id)}>
        <div className={styles.actionButton}>
          <DeleteOutlined />
        </div>
      </DeleteArticleModal>
    </div>
  );
  const tableData: TTableData[] =
    articlesData.list?.map((item) => ({
      key: item.id,
      image: item.titleImage,
      title: item.title,
      createdAt: new Date(item.createdAt).toLocaleDateString(),
      updatedAt: new Date(item.updatedAt).toLocaleDateString(),
      status: getArticleStatus(item), // getArticleStatusRendered(item, t)
      actions: null,
      category: item.category,
      categoryId: item.category.id,
    })) || [];
  const columns = [
    {
      title: t('articles.edit.articleName'),
      dataIndex: 'title',
      render: (_: string, record: TTableData) => (
        <Link
          to={{
            pathname: `/articles/${record.key}`,
            search: `?${query.toString()}`
          }}
          className={styles.articleTitle}
        >
          <img
            className={styles.titleImage}
            src={record.image || '/assets/articles/title-photo-placeholder.png'}
            alt={record.title}
          />
          {record.title.length > 30 ? (
            <Tooltip
              placement="top"
              title={record.title}
            >{`${record.title.slice(0, 30)}...`}</Tooltip>
          ) : (
            record.title
          )}
        </Link>
      ),
      sorter: true,
      ...(query.has('sort') &&
      ['title', '-title'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-title' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('articles.category'),
      dataIndex: 'category',
      render: (_: string, record: TTableData) => record.category?.name,
      filters: uniqBy(
        filterData?.categories
          ?.filter((c) => c.id)
          .map((category) => ({ value: category?.id, text: category?.name })),
        'value',
      ),
      ...(query.has('category')
        ? {
            defaultFilteredValue: query.get('category')?.split(','),
          }
        : {}),
    },
    {
      title: t('articles.creationDate'),
      dataIndex: 'createdAt',
      sorter: true,
      ...(query.has('sort') &&
      ['createdAt', '-createdAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-createdAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('articles.lastUpdate'),
      dataIndex: 'updatedAt',
      sorter: true,
      ...(query.has('sort') &&
      ['updatedAt', '-updatedAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-updatedAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('articles.status'),
      dataIndex: 'status',
      render: (_: string, record: TTableData) => {
        const article = articlesData.list?.find((it) => it.id === record.key);
        return article ? getArticleStatusRendered(article, t) : <div />;
      },
      filters: [
        { value: EArticleStatuses.PUBLISHED, text: t('generic.published') },
        { value: EArticleStatuses.DRAFT, text: t('generic.draft') },
        { value: EArticleStatuses.ARCHIVED, text: t('generic.archived') },
      ],
      ...(query.has('status')
        ? {
            defaultFilteredValue: query.get('status')?.split(','),
          }
        : {}),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      render: (_: null, record: TTableData) =>
        actionsBlock(parseInt(record.key.toString(), 10), record.title),
    },
  ];

  // bulk menu dropdown
  const dropdownOptions = [
    { key: 'publish', text: t('generic.publish') },
    { key: 'archive', text: t('generic.archive') },
    { key: 'delete', text: t('generic.delete') },
  ];
  const dropdownItems = dropdownOptions.map((item) => (
    <Menu.Item key={item.key}> {item.text}</Menu.Item>
  ));
  const onBulkOperation = () => {
    let selectedArticles =
      articlesData.list?.filter(({ id }) => selectedIds.indexOf(id) !== -1) ||
      [];
    switch (bulkOption) {
      case 'delete': {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedArticles }));
        setSelectedIds([]);
        break;
      }
      case 'publish': {
        selectedArticles = selectedArticles.filter((article) => {
          if (
            (article.publishDate !== null && article.unpublishDate !== null) ||
            article.publishDate === null
          ) {
            return article;
          }
          return false;
        });
        dispatch<any>(asyncActions.publishBulk({ items: selectedArticles }));
        break;
      }
      case 'archive': {
        selectedArticles = selectedArticles.filter(
          (article) => article.createdAt && article.unpublishDate === null,
        );
        dispatch<any>(asyncActions.unpublishBulk({ items: selectedArticles }));
        break;
      }
      default: {
        // do nothing
      }
    }
    setSelectedIds([]);
  };
  return (
    <div
      key={`articles-list-${Array.from(query.keys()).length}-${
        filterData?.categories?.length
      }`}
    >
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('nav.category.wellness')]: '/articles',
          [t('nav.item.articles')]: '',
        }}
        title={t('nav.item.articles')}
      />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>{t('articles.toolBarTitle')}</div>
        <div className={styles.buttonContainer}>
          <Search
            allowClear
            defaultValue={query.get('search') || ''}
            className={styles.searchField}
            placeholder={t('articles.search.placeholder') || ''}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                articlesData.listParams?.pagination?.perPage
                  ? articlesData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
          />
          <Button
            className={styles.manageButton}
            onClick={() =>
              navigate({
                pathname: `/categories/articles?${query.toString()}`,
              })
            }
          >
            {t('generic.manageCategories')}
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/articles/new')}
          >
            {t('articles.createArticle')}
          </Button>
        </div>
      </div>
      {!!selectedIds.length && (
        <div className={styles.bulkMenu}>
          <div className={styles.bulkMenuCounter}>
            {t('generic.bulk.selected', { length: selectedIds.length })}
          </div>
          <Dropdown
            className={styles.bulkMenuDropdown}
            overlay={
              <Menu onClick={(e) => setBulkOption(e.key)}>{dropdownItems}</Menu>
            }
          >
            <Button>
              {dropdownOptions.find((o) => o.key === bulkOption)?.text}{' '}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button type="primary" onClick={onBulkOperation}>
            {t('generic.confirm')}
          </Button>
        </div>
      )}
      <Table
        actions={actions}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: articlesData.listParams?.pagination?.total,
          current: articlesData.listParams?.pagination?.page,
        }}
      />
    </div>
  );
}

export default withTranslation()(ArticleList);
