import { useTranslation } from 'react-i18next';
import styles from './ContactLink.module.scss';
import { Config } from '../../config/config';

type TContactLinkProps = {
  message: string;
};

function ContactLink({ message }: TContactLinkProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <span className={styles.text}>
        {`${message} `}
        <a
          className={styles.form}
          href={Config.CONTACT_FORM}
          target="_blank"
          rel="noreferrer"
        >
          {t('auth.confirmData.confirmData.contactForm')}
        </a>
      </span>
    </div>
  );
}

export default ContactLink;
