import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../../../../../helpers/apiRequest';
import {
  TUserCompanyProfile,
  TUserCompanyProfileListResponse,
  TUserCompanyProfileRequest,
  TUserCompanyProfileResponse,
} from './EmployeesType';

export const getEmployeesByCompany = async (
  listParams?: TListParams,
  companyId?: number,
): Promise<TUserCompanyProfileListResponse> =>
  apiRequestGet<TUserCompanyProfileListResponse>(
    `/user-company-profiles/companies/${companyId}`,
    {...listParams, version: 'v2' }
  );

export const getEmployee = async (
  id: TUserCompanyProfile['id'],
): Promise<TUserCompanyProfileResponse> =>
  apiRequestGet<TUserCompanyProfileResponse>(`/user-company-profiles/${id}`, { version: 'v2' });

export const createEmployee = async (
  data: TUserCompanyProfileRequest,
): Promise<TUserCompanyProfileResponse> =>
  apiRequest<TUserCompanyProfileResponse>(
    '/user-company-profiles',
    'post',
    data,
    { version: 'v2' }
  );

export const updateEmployee = async (
  id: TUserCompanyProfile['id'],
  data: TUserCompanyProfileRequest,
): Promise<TUserCompanyProfileResponse> =>
  apiRequest<TUserCompanyProfileResponse>(
    `/user-company-profiles/${id}`,
    'patch',
    data,
    { version: 'v2' }
  );

export const deleteEmployee = async (
  id: TUserCompanyProfile['id'],
): Promise<any> =>
  apiRequest<any>(`/user-company-profiles/${id}`, 'delete', null, { version: 'v2' });
