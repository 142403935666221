import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { EChallengeStatuses } from '../../../features/challenges/ChallengeList/ChallengeListTypes';
import styles from './ChallengeStatusBadge.module.scss';

function ChallengeStatusBadge({ status }: { status: EChallengeStatuses }) {
  const { t } = useTranslation();

  const statusBadgeMap = {
    [EChallengeStatuses.DRAFT]: (
      <Badge color="yellow" text={t('generic.draft')} />
    ),
    [EChallengeStatuses.ONGOING]: (
      <Badge color="green" text={t('generic.ongoing')} />
    ),
    [EChallengeStatuses.UPCOMING]: (
      <Badge color="blue" text={t('generic.upcoming')} />
    ),
    [EChallengeStatuses.INACTIVE]: (
      <Badge
        className={styles.inactiveBadge}
        status="default"
        text={t('generic.inactive')}
      />
    ),
    [EChallengeStatuses.FINISHED]: (
      <Badge status="default" text={t('generic.finished')} />
    ),
  };

  return statusBadgeMap[status] || <div />;
}

export default ChallengeStatusBadge;
