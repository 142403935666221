import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteRewardModalProps = {
  userName: string;
  children: JSX.Element;
  onClick: () => void;
};

function DeleteUserModal({
  userName,
  children,
  onClick,
}: TDeleteRewardModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('users.modals.delete.title'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('users.modals.delete.content', { userName }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeleteUserModal;
