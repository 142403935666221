import { apiRequestGet, TListParams } from '../../helpers/apiRequest';
import {
  TCompaniesListResponse,
  TCompanyAnalyticsResponse,
  TDailyActivityResponse,
  TEngagementStatisticsResponse,
} from './WellnessAnalyticsType';

export const getCompanyAnalyticsRequest = async (
  params?: TListParams,
): Promise<TCompanyAnalyticsResponse> =>
  apiRequestGet<TCompanyAnalyticsResponse>(
    '/wellness/company-statistics',
    params,
  );

export const getCompaniesListRequest =
  async (): Promise<TCompaniesListResponse> =>
    apiRequestGet<TCompaniesListResponse>('/wellness/companies', {});

export const getDailyActivityRequest = async (
  params?: TListParams,
): Promise<TDailyActivityResponse> =>
  apiRequestGet<TDailyActivityResponse>('/wellness/daily-activity', params);

export const getEngagementStatisticsRequest = async (
  params?: TListParams,
): Promise<TEngagementStatisticsResponse> =>
  apiRequestGet<TEngagementStatisticsResponse>(
    '/wellness/engagement-statistics',
    params,
  );
