import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';
import { EChallengeStatuses } from '../../../../features/challenges/ChallengeList/ChallengeListTypes';

type TPublishArticleModalProps = {
  children: JSX.Element;
  onClick: () => void;
  status: EChallengeStatuses;
  type: string,
};

function DeactivateOngoingChallengeModal({
  children,
  onClick,
  status,
  type,
}: TPublishArticleModalProps) {
  const { t } = useTranslation();

  const config = {
    title: type === 'quiz' ? t('quizzes.modal.deactivateOngoing.title') : t('challenges.modal.deactivateOngoing.title'),
    okText: t('generic.buttons.update'),
    cancelText: t('generic.modals.buttons.cancel'),
    content:
      status === EChallengeStatuses.UPCOMING
        ? (type === 'quiz' ?t('quizzes.modal.deactivateUpcoming.content'):t('challenges.modal.deactivateUpcoming.content'))
        : (type === 'quiz' ?t('quizzes.modal.deactivateOngoing.content'):t('challenges.modal.deactivateOngoing.content')),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeactivateOngoingChallengeModal;
