import React from 'react';
import styles from './BackgroundWave.module.scss';

type TBackgroundWaveProps = {
  children: JSX.Element;
};

function BackgroundWave({ children }: TBackgroundWaveProps) {
  const isMobile = window.screen.width < 600;

  return (
    <div className={styles.wrap}>
      <div
        className={isMobile ? styles.waveMobile : styles.wave}
        style={{ backgroundImage: 'url(/assets/auth/wave.svg)' }}
      />
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default BackgroundWave;
