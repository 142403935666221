import { Form, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../../constants/userRoles';
import { TActivity } from '../../../../features/collective/companies/CompanyType';
import nullOrAlt from '../../../../helpers/nullOrAlt';
import usePrevious from '../../../../hooks/usePrevious';

import { selectors as categoriesSelectors } from '../../../../features/collective/companies/CompaniesCategoriesSliece';

import RoleWrapper from '../../../RoleWrapper/RoleWrapper';

import styles from './CompanyActivitySelector.module.scss';

type TCompanyActivitySelectorProps = {
  alternative?: string;
  onChange: (value: string) => void;
  category: string | null;
  activity: string | null;
};

function CompanyActivitySelector({
  alternative,
  onChange: onChangeCallback,
  category,
  activity,
}: TCompanyActivitySelectorProps) {
  const { t } = useTranslation();
  const categoriesData = useSelector(categoriesSelectors.listData);
  const [activities, setActivities] = useState([]);
  const [activitiesList, setActivitiesList] = useState([{ value: '' }]);
  const previousCategory = usePrevious(category);

  useEffect(() => {
    if (category && category !== '') {
      const val = category.split(']')[1].trim();
      categoriesData.list?.forEach(
        (item) => item.title === val && setActivities(item.activities),
      );
    } else {
      setActivities([]);
    }

    if (category !== previousCategory && previousCategory !== undefined) {
      onChangeValue('');
    }
  }, [categoriesData.list, category]);

  useEffect(() => {
    setActivitiesList(
      activities.map((avtivity: TActivity) => ({
        value: `[${avtivity.code}] ${avtivity.title}`,
      })),
    );
  }, [activities]);

  const searchActivityResult = (query: string) => {
    const newlist =
      activities.length > 0
        ? activities.filter(
            (item: TActivity) =>
              item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1,
          )
        : [];

    if (newlist.length > 0) {
      setActivitiesList(
        newlist.map((avtivity: TActivity) => ({
          value: `[${avtivity.code}] ${avtivity.title}`,
        })),
      );
    }
  };

  const onChangeValue = (value: string) => {
    onChangeCallback(value);
  };

  return (
    <Form.Item
      label={t('collective.company.form.companyInformation.activity')}
      className={`${styles.itemLeftContainer} ${styles.companyActivity}`}
    >
      <RoleWrapper
        roles={[UserRoles.ADMIN]}
        alternative={
          <div className={styles.companyDetailsValue}>{alternative}</div>
        }
      >
        <Select
          showSearch
          placeholder={t('generic.startTyping')}
          disabled={activities.length === 0}
          options={activitiesList}
          value={nullOrAlt(activity, '')}
          onSearch={searchActivityResult}
          onChange={(val: string) => onChangeValue(val)}
          allowClear
          onClear={() => {
            onChangeValue('');
            searchActivityResult('');
          }}
        />
      </RoleWrapper>
    </Form.Item>
  );
}

CompanyActivitySelector.defaultProps = {
  alternative: '',
};

export default CompanyActivitySelector;
