import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const container = document.getElementById('content-container');

    if (container) {
      container.scrollTo({ top: 0 });
    }
  }, [pathname]);

  return null;
}

export default ScrollToTop;
