import React, { useEffect, useState } from 'react';
import { Button, Modal, Tabs } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userTypes } from '../UsersConstants';
import styles from './UserForm.module.scss';
import ModalSide from '../../../components/ModalSide/ModalSide';
import PersonalInfo from './screens/Personalnfo/PersonalInfo';
import { TUser } from '../UsersTypes';
import { actions, asyncActions, selectors } from '../UsersSlice';
import {
  asyncActions as aChAsyncActions,
  selectors as aChSelectors,
} from '../ActiveChallengesSlice';
import {
  asyncActions as clRAsyncActions,
  selectors as clRSelectors,
} from '../ClaimedRewardsSlice';
import nullOrAlt from '../../../helpers/nullOrAlt';
import DeleteUserModal from '../../../components/Modals/UsersModals/DeleteUserModal/DeleteUserModal';
import ContinueEditingModal from '../../../components/Modals/ContinueEditingModal/ContinueEditingModal';
import RequestStatuses from '../../../constants/requestStatuses';
import RoleWrapper from '../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../constants/userRoles';
import { selectAuthenticatedUser } from '../../auth/AuthSlice';

const { TabPane } = Tabs;

type TUserFormProps = {
  show: boolean;
  currentUser?: TUser['id'] | null;
  onClose: () => void;
  onDelete: (id: TUser['id']) => () => void;
};

const initialState = {
  firstName: null as string | null,
  lastName: null as string | null,
  email: null as string | null,
  gender: null as string | null,
  dateOfBirth: null as string | null,
  professionalEmail: null as string | null,
};

function UserForm({ show, currentUser, onClose, onDelete }: TUserFormProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [userData, setUserData] = useState(initialState);
  const [currentTab, setCurrentTab] = useState('1');
  const [editFlag, setEditFlag] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const currentUserData = useSelector(selectors.currentItem);
  const activeChallenges = useSelector(aChSelectors.listData);
  const claimedRewards = useSelector(clRSelectors.listData);
  const statuses = useSelector(selectors.statuses);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  useEffect(() => {
    if (currentUser) {
      dispatch<any>(asyncActions.get({ id: currentUser }));
      dispatch<any>(aChAsyncActions.fetchList({ id: currentUser }));
      dispatch<any>(clRAsyncActions.fetchList({ id: currentUser }));
    } else {
      dispatch(actions.clearCurrentItem());
      setCurrentTab('1');
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (
      statuses.create === RequestStatuses.SUCCESS ||
      statuses.update === RequestStatuses.SUCCESS
    ) {
      onCancel();
    }
  }, [statuses]);

  const userDataSelected = {
    firstName: nullOrAlt(userData.firstName, currentUserData?.firstName || ''),
    lastName: nullOrAlt(userData.lastName, currentUserData?.lastName || ''),
    email: nullOrAlt(userData.email, currentUserData?.email || ''),
    gender: userData.gender || '',
    dateOfBirth: userData.dateOfBirth || '',
    type: currentUserData?.companyProfile
      ? userTypes.EMPLOYEE
      : userTypes.APP_USER,
    professionalEmail: nullOrAlt(
      userData.professionalEmail,
      currentUserData?.companyProfile?.companyEmail || '',
    ),
    companyName: currentUserData?.company?.companyName,
  };

  const { firstName, lastName, email } = userDataSelected;

  const onChange = (field: keyof typeof initialState, value: any) => {
    setUserData((prevState) => ({ ...prevState, [field]: value }));
    setEditFlag(true);
  };

  const validateFields = () => {
    let allFilled = true;
    [firstName, lastName, email].forEach((f) => {
      allFilled = allFilled && !!f;
    });

    return allFilled && isValid;
  };

  const onSave = () => {
    const userValues = {
      firstName,
      lastName,
      email,
    };

    if (currentUser) {
      dispatch<any>(
        asyncActions.update({
          id: currentUser,
          dataJson: { user: userValues },
        }),
      );
    } else {
      dispatch<any>(asyncActions.create({ dataJson: userValues }));
    }
    setEditFlag(false);
  };

  const onCancel = () => {
    setUserData(initialState);
    setEditFlag(false);
    setCurrentTab('1');
    dispatch(actions.clearStatuses());
    onClose();
  };

  const onBackdropClick = () => {
    Modal.confirm({
      title: t('generic.modals.continueEditing.title'),
      okText: t('generic.modals.continueEditing.buttons.continue'),
      cancelText: t('generic.modals.continueEditing.buttons.discard'),
      content: t('generic.modals.continueEditing.content'),
      icon: <ExclamationCircleOutlined className={styles.confirmIcon} />,
      onCancel,
    });
  };

  return (
    <ModalSide
      position="right"
      show={show}
      onClose={editFlag ? onBackdropClick : onCancel}
    >
      <>
        <RoleWrapper roles={[UserRoles.ADMIN]}>
          <ProPageHeader
            prefixedClassName={styles.pageHeader}
            className={styles.header}
            title={t('users.userProfile')}
            extra={
              currentUserData
                ? [
                    <DeleteUserModal
                      userName={`${currentUserData?.firstName} ${currentUserData?.lastName}`}
                      onClick={() => {
                        if (currentUserData?.id) {
                          onDelete(currentUserData?.id)();
                          onCancel();
                        }
                      }}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        type="text"
                        disabled={!currentUserData}
                      >
                        {t('users.buttons.deleteUser')}
                      </Button>
                    </DeleteUserModal>,
                    editFlag ? (
                      <ContinueEditingModal onClick={onCancel}>
                        <Button
                          className={styles.cancelButton}
                          onClick={() => {}}
                        >
                          {t('generic.cancel')}
                        </Button>
                      </ContinueEditingModal>
                    ) : (
                      <Button
                        className={styles.cancelButton}
                        onClick={onCancel}
                      >
                        {t('generic.cancel')}
                      </Button>
                    ),
                    <Button
                      type="primary"
                      disabled={!validateFields()}
                      onClick={onSave}
                    >
                      {t('users.buttons.saveUser')}
                    </Button>,
                  ]
                : []
            }
          />
        </RoleWrapper>
        <RoleWrapper roles={[UserRoles.COMPANY_MANAGER]}>
          <ProPageHeader
            prefixedClassName={styles.pageHeader}
            className={styles.header}
            title="User profile"
            extra={
              currentUserData
                ? [
                    <Button className={styles.cancelButton} onClick={onCancel}>
                      Cancel
                    </Button>,
                  ]
                : []
            }
          />
        </RoleWrapper>
        <Tabs onChange={setCurrentTab} activeKey={currentTab}>
          <TabPane tab={t('generic.information')} key={1}>
            <PersonalInfo
              onChange={onChange}
              userData={userDataSelected}
              onSave={onSave}
              onClose={onCancel}
              allowToSave={validateFields() && !currentUser}
              showButtons={!currentUserData}
              editFlag={editFlag}
              isValid={(val) => setIsValid(val)}
            />
          </TabPane>
        </Tabs>
      </>
    </ModalSide>
  );
}

UserForm.defaultProps = {
  currentUser: null,
};

export default UserForm;
