import { put, takeLatest } from 'redux-saga/effects';
import { authActions } from './AuthSlice';

interface ILoginAction {
  payload: {
    email: string;
    password: string;
  };
}
function* loginSaga(action: ILoginAction): Generator {
  const { email, password } = action.payload;
  try {
    const user = {};
    yield put({ type: authActions.LOGIN, user });
  } catch (e: any) {
    yield put({ type: authActions.LOGIN, message: e.message });
  }
}

const takeLatestTS: any = takeLatest;
function* authSagas() {
  // yield all([
  //   takeLatestTS(authActions.LOGIN, loginSaga),
  // ])
}

export default authSagas;
