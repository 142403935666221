import { UserRoles } from '../../constants/userRoles';

const categoryActivityTypes: { [key: string]: { [key: string]: string } } = {
  SPORT: {
    RUNNING: 'Running',
    CROSSFIT: 'Crossfit',
    CYCLING: 'Cycling',
    SWIMMING: 'Swimming',
    ROWING: 'Rowing',
    WALKING: 'Walking',
  },
  MINDFULLNESS: {
    MINDFULL: 'Mindfull',
    YOGA: 'Yoga',
  },
};

const categoryNames = {
  SPORT: 'Sport',
  MINDFULLNESS: 'Mindfullness',
};

export const getCategoryByActivityType = (activityType: string) =>
  Object.keys(categoryActivityTypes).find(
    (k) => !!categoryActivityTypes[k][activityType],
  );

export const categoryMap = (category: string | null) =>
  category && categoryNames[category as keyof typeof categoryNames];

const consistsAllIds = (companyIds: number[], challengeIds: number[]) =>
  !challengeIds.find((cId) => !companyIds.includes(cId));

export const isActionAvailable = (
  role: UserRoles | null,
  userCompaniesIds: number[] = [],
  challengeCompaniesIds: number[] = [],
): boolean =>
  role === UserRoles.ADMIN ||
  !challengeCompaniesIds.length ||
  consistsAllIds(userCompaniesIds, challengeCompaniesIds);
