import { TEnrolledUser, TEnrolledUserListResponse } from './ChallengesTypes';
import { fetchEnrolledUsers } from './ChallengesApi';
import buildSlice from '../general/helpers/genericSliceBuilder';

const challengeEnrolledUsersSlice = buildSlice<
  TEnrolledUser,
  any,
  TEnrolledUserListResponse
>('challengeEnrolledUsers', {
  fetchList: fetchEnrolledUsers as (
    params: any,
    id?: TEnrolledUser['id'],
  ) => Promise<TEnrolledUserListResponse>,
});

export const { selectors, asyncActions, actions } = challengeEnrolledUsersSlice;

export default challengeEnrolledUsersSlice.reducer;
