import {
  createGuarantee,
  deleteGuarantee,
  fetchGuarantees,
  getGuarantee,
  updateGuarantee,
} from './GuaranteesApi';
import { TGuarantee } from './GuaranteesTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

export const guaranteesSlice = buildSlice<TGuarantee, any, any>('guarantees', {
  createJson: createGuarantee as (data: { [key: string]: any }) => Promise<any>,
  delete: deleteGuarantee,
  fetchList: fetchGuarantees,
  getOne: getGuarantee,
  updateJson: updateGuarantee as (
    id: number,
    data: { [key: string]: any },
  ) => Promise<any>,
});

export const { selectors, asyncActions, actions } = guaranteesSlice;

export default guaranteesSlice.reducer;
