import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavMenu from '../../components/NavMenu/NavMenu';
import CurrentRoutes from '../../Router/Router';
import {
  collapseSideMenu,
  selectCollapsed,
} from '../navigation/NavigationSlice';
import styles from './Layout.module.scss';
import MainHeader from '../../components/Header/Header';
import { selectCurrentLang, setCurrentLanguage } from '../i18n/i18nSlice';
import GuaranteesList from '../guarantees/GuaranteesList/GuaranteesList';
import HomePage from '../home/HomePage';
import EditReward from '../rewards/EditReward/EditReward';
import ClaimedRewards from '../rewards/ClaimedRewards/ClaimedRewards';
import RewardsList from '../rewards/RewardList/RewardsList';
import UserList from '../users/UserList/UserList';
import EditGuarantee from '../guarantees/EditGuarantee/EditGuarantees';
import EditQuiz from '../quizzes/EditQuiz/EditQuiz';
import EditChallenge from '../challenges/EditChallenge/EditChallenge';
import ChallengeList from '../challenges/ChallengeList/ChallengeList';
import AdminUsersList from '../adminUsers/AdminUsersList/AdminUsersList';
import EditCompany from '../collective/companies/EditCompany/EditCompany';
import CollectiveScreen from '../collective/CollectiveScreen';
import EditInsight from '../insights/EditInsight/EditInsight';
import InsightList from '../insights/InsightList/InsightList';
import EditArticle from '../articles/EditArticle/EditArticle';
import ArticleList from '../articles/ArticleList/ArticleList';
import WellnessAnalytics from '../analytics/WellnessAnalytics';
import MembersList from '../collective/members/MembersList/MembersList';
import CategoryForm from '../categories/CategoryForm/CategoryForm';
import CategoryList from '../categories/CategoryList/CategoryList';
import { selectAuthenticatedUser } from '../auth/AuthSlice';
import { UserRoles } from '../../constants/userRoles';
import QuizzesList from '../quizzes/QuizzesList/QuizzesList';
import CreateCompany from '../collective/companies/CreateCompany/CreateCompany';

const { Content, Sider, Footer } = Layout;

function PageLayout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const collapsed = useSelector(selectCollapsed);
  const lang = useSelector(selectCurrentLang);

  const [path, setPath] = useState('');
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  useEffect(() => {
    setPath(location.pathname);
    elementShow();
  }, [location]);

  const elementShow = () => {
    if (path.includes('guarantees') && path !== '/guarantees') {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN].includes(authenticatedUser.role)
      )
        return <EditGuarantee />;
      else return null;
    } else if (
      path.includes('rewards') &&
      path !== '/rewards' &&
      path !== '/rewards/claimed'
    ) {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
          authenticatedUser.role,
        )
      )
        return <EditReward />;
      else return null;
    } else if (path.includes('admin-users') && path !== '/admin-users') {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN].includes(authenticatedUser.role)
      )
        return <AdminUsersList />;
      else return null;
    } else if (path.includes('users/') && path !== '/users') {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN].includes(authenticatedUser.role)
      )
        return <UserList />;
      else return null;
    } else if (path.includes('categories/challenges') && path.length > 24) {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN].includes(authenticatedUser.role)
      )
        return <CategoryForm />;
      else return null;
    } else if (path.includes('categories/challenges')) {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN].includes(authenticatedUser.role)
      )
        return <CategoryList />;
      else return null;
    } else if (path.includes('quizzes') && path !== '/quizzes') {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
          authenticatedUser.role,
        )
      )
        return <EditQuiz />;
      else return null;
    } else if (path.includes('challenges/biometrics')) {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
          authenticatedUser.role,
        )
      )
        return <EditChallenge />;
      else return null;
    } else if (path.includes('collective/new')) {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN].includes(authenticatedUser.role)
      )
        return <CreateCompany />;
      else return null;
    } else if (path.includes('collective') && path !== '/collective') {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
          authenticatedUser.role,
        )
      )
        return <EditCompany />;
      else return null;
    } else if (path.includes('articles') && path !== '/articles') {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN].includes(authenticatedUser.role)
      )
        return <EditArticle />;
      else return null;
    } else if (path.includes('insights') && path !== '/insights') {
      if (
        authenticatedUser.role &&
        [UserRoles.ADMIN].includes(authenticatedUser.role)
      )
        return <EditInsight />;
      else return null;
    }

    switch (path) {
      case '/': {
        return <HomePage />;
      }
      case '/guarantees': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN].includes(authenticatedUser.role)
        )
          return <GuaranteesList />;
        else return null;
      }
      case '/rewards/claimed': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
            authenticatedUser.role,
          )
        )
          return <ClaimedRewards />;
        else return null;
      }
      case '/rewards': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
            authenticatedUser.role,
          )
        )
          return <RewardsList />;
        else return null;
      }
      case '/admin-users': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN].includes(authenticatedUser.role)
        )
          return <AdminUsersList />;
        else return null;
      }
      case '/users': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN].includes(authenticatedUser.role)
        )
          return <UserList />;
        else return null;
      }
      case '/challenges': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
            authenticatedUser.role,
          )
        )
          return <ChallengeList />;
        else return null;
      }
      case '/quizzes': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
            authenticatedUser.role,
          )
        )
          return <QuizzesList />;
        else return null;
      }
      case '/collective': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
            authenticatedUser.role,
          )
        )
          return <CollectiveScreen />;
        else return null;
      }
      case '/articles': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN].includes(authenticatedUser.role)
        )
          return <ArticleList />;
        else return null;
      }
      case '/insights': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN].includes(authenticatedUser.role)
        )
          return <InsightList />;
        else return null;
      }
      case '/wellness/analytics': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
            authenticatedUser.role,
          )
        )
          return <WellnessAnalytics />;
        else return null;
      }
      case '/members': {
        return <MembersList />;
      }
      case '/categories/:section/:id(new|[0-9]+)': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER].includes(
            authenticatedUser.role,
          )
        )
          return <CategoryForm />;
        else return null;
      }
      case '/categories/:section': {
        if (
          authenticatedUser.role &&
          [UserRoles.ADMIN].includes(authenticatedUser.role)
        )
          return <CategoryList />;
        else return null;
      }
      default: {
        return <HomePage />;
      }
    }
  };

  useEffect(() => {
    elementShow();
  }, [path]);
  return (
    <Layout className={styles.rootLayout}>
      <div className={styles.headerContainer}>
        <MainHeader
          onSelectLanguage={(language) => {
            dispatch(setCurrentLanguage({ language }));
          }}
          selectedLocale={lang || undefined}
        />
      </div>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth={40}
          className={styles.sider}
          collapsed={collapsed}
        >
          <NavMenu
            location={path}
            collapsed={collapsed}
            onSwitchMode={(collapse) => {
              dispatch(collapseSideMenu({ collapse }));
            }}
          />
        </Sider>
        <Layout className={styles.contentContainer}>
          <Content
            id="content-container"
            className={`${styles.content} ${
              collapsed ? styles.contentCollapsed : ''
            }`}
          >
            {/* <CurrentRoutes /> */}
            {elementShow()}
            <Footer className={styles.footer}>
              {t('generic.copyright')}
              <div>
                <a
                  href="https://www.vitaance.com/aviso-legal"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('generic.legalDocs')}
                </a>{' '}
                |{' '}
                <a
                  href="https://www.vitaance.com/politica-de-privacidad-vitaance"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('generic.privacyPolicy')}
                </a>
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default PageLayout;
