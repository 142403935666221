import {
  createUser,
  deleteUser,
  fetchUsers,
  getUser,
  publishUser,
  resendInvitation,
  unpublishUser,
  updateUser,
} from './UsersApi';
import {
  TUser,
  TUserCompany,
  TUserListResponse,
  TUserResponse,
} from './UsersTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

const usersSlice = buildSlice<TUserCompany, TUserResponse, TUserListResponse>(
  'users',
  {
    createJson: createUser as (data: {
      [key: string]: any;
    }) => Promise<TUserResponse>,
    delete: deleteUser,
    fetchList: fetchUsers,
    getOne: getUser,
    publish: publishUser,
    unpublish: unpublishUser,
    updateJson: updateUser,
    singleRequests: [
      {
        request: resendInvitation as (
          id: TUser['id'],
          data?: { [key: string]: any },
        ) => Promise<TUserResponse>,
        name: 'resendInvitation',
      },
    ],
  },
);

export const { selectors, asyncActions, actions } = usersSlice;

export default usersSlice.reducer;
