import buildSlice from '../general/helpers/genericSliceBuilder';
import {
  TActivityTag,
  TActivityTagListResponse,
  TActivityTagResponse,
} from './ActivityTagsTypes';
import { createActivityTag, fetchActivityTags } from './ActivityTagsApi';

const activityTagsSlice = buildSlice<
  TActivityTag,
  TActivityTagResponse,
  TActivityTagListResponse
>('activityTags', {
  fetchList: fetchActivityTags,
  createJson: createActivityTag as (data: {
    [key: string]: any;
  }) => Promise<TActivityTagResponse>,
});

export const { actions, asyncActions, selectors } = activityTagsSlice;

export default activityTagsSlice.reducer;
