import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteArticleModalProps = {
  articleName: string;
  children: JSX.Element;
  onClick: () => void;
};

function DeleteArticleModal({
  articleName,
  children,
  onClick,
}: TDeleteArticleModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('articles.modal.delete.title'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('articles.modal.delete.content', { articleName }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeleteArticleModal;
