import React from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { VIDEO_COVER } from '../../helpers/constants';

type TCkEditorProps = {
  currentData?: string;
  onChange: (event: any, editor: any) => void;
};

function CkEditor({ currentData, onChange }: TCkEditorProps) {

  return (
    <CKEditor
      config={{
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
        mediaEmbed: {
          previewsInData: true,
          extraProviders: [
            {
              name: 'mp4Provider',
              url: /^https?:\/\/.*\.mp4/i,
              html: (match) =>
                // You can return the HTML code to embed the MP4 video here
                `<video controls src="${match.input}" controlsList="nodownload" width="100%" id="${match.input ? match.input.split('/')[match.input.split('/').length - 1] : 0}" poster="${VIDEO_COVER}"></video>`,
            },
            {
              name: 'audioProvider',
              url: /^https?:\/\/.*\.(mp3|ogg)/i,
              html: (match) =>
                // HTML code for embedding audio (MP3, OGG, etc.)
                `<audio controls src="${match.input}" controlsList="nodownload" id="${match.input ? match.input.split('/')[match.input.split('/').length - 1] : 0}" width="100%" style="display: block; margin: 0 auto;"></audio>`,
            },
            // Add other extra providers as needed
          ],
        },
      }}
      // @ts-ignore
      editor={Editor}
      data={currentData}
      onChange={onChange}
    />
  );
}

CkEditor.defaultProps = { currentData: '' };

export default CkEditor;
