import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../helpers/apiRequest';
import {
  TTagsResponse,
  TSingleTagResponse,
  TTagCreateRequest,
} from './TagsTypes';

export const createTag = async (
  tagData: TTagCreateRequest,
): Promise<TSingleTagResponse> =>
  apiRequest<TSingleTagResponse>('/tags', 'post', tagData, { version: 'v2' });

export const fetchTags = async (
  listParams?: TListParams,
): Promise<TTagsResponse> =>
  apiRequestGet<TTagsResponse>('/tags', { ...listParams, version: 'v2' });
