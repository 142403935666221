import {
  activateInsight,
  createInsight,
  deactivateInsight,
  deleteInsight,
  fetchInsights,
  getInsight,
  updateInsight,
} from './InsightsApi';
import {
  TInsightFilled,
  TInsightsResponse,
  TSingleInsightResponse,
} from './InsightsTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

export const insightSlice = buildSlice<
  TInsightFilled,
  TSingleInsightResponse,
  TInsightsResponse
>('insights', {
  create: createInsight,
  delete: deleteInsight,
  fetchList: fetchInsights,
  getOne: getInsight,
  update: updateInsight,
  publish: activateInsight,
  unpublish: deactivateInsight,
});

export const { selectors, asyncActions, actions } = insightSlice;

export default insightSlice.reducer;
