import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TTeamUserListResponse,
  TTopTeam,
  TTopTeamListResponse,
} from '../../ChallengesTypes';
import { fetchTeamUsers, fetchTopTeams } from '../../ChallengesApi';
import RequestStatuses from '../../../../constants/requestStatuses';
import { extraReducerBuilderV2 } from '../../../../helpers/extraReducerBuilder';
import { TUser } from '../../../users/UsersTypes';
import { TListParams } from '../../../../helpers/apiRequest';

const leaderboardActions = {
  FETCH_TEAMS: 'challengeLeaderboard/fetchTeams',
  FETCH_TEAM_USERS: 'challengeLeaderboard/fetchTeamUsers',
};

type TState = {
  statuses: {
    fetch?: RequestStatuses | null;
    fetchTeamUsers?: RequestStatuses | null;
  };
  teams: TTopTeam[];
  teamUsers: {
    list: TUser[] | null;
    listParams?: TListParams;
    outdated?: boolean;
  };
};

const initialState: TState = {
  statuses: {},
  teams: [],
  teamUsers: {
    list: null,
    outdated: false,
  },
};

export const fetchTeamsAsync = createAsyncThunk(
  leaderboardActions.FETCH_TEAMS,
  async ({ id }: { id: number }) => fetchTopTeams(id),
);

export const fetchTeamUsersAsync = createAsyncThunk<
  any,
  any,
  { state: { challengeLeaderboard: TState } }
>(
  leaderboardActions.FETCH_TEAM_USERS,
  async (
    { challengeId, teamId }: { challengeId: number; teamId: number },
    { getState },
  ) => {
    const {
      challengeLeaderboard: {
        teamUsers: { listParams },
      },
    } = getState();

    return fetchTeamUsers(challengeId, teamId, listParams || {});
  },
);

export const leaderboardSlice = createSlice({
  name: 'challengeLeaderboard',
  initialState,
  reducers: {
    setTeamUsersPagination: (
      state,
      action: PayloadAction<{ page: number; perPage: number }>,
    ) => {
      const { page, perPage } = action.payload;
      const { listParams } = state.teamUsers;

      state.teamUsers.listParams = {
        ...listParams,
        pagination: {
          page,
          perPage,
          total: listParams?.pagination?.total || 0,
        },
      };
    },
    setTeamUsersSearch: (state, action: PayloadAction<{ query: string }>) => {
      const { listParams } = state.teamUsers;
      const { query } = action.payload;

      state.teamUsers.listParams = {
        ...listParams,
        search: query,
      };
    },
  },
  extraReducers: (builder) => {
    extraReducerBuilderV2<TState, TTopTeamListResponse>(
      builder,
      fetchTeamsAsync,
      {
        statusChanger: (state: TState, status: RequestStatuses) => {
          state.statuses.fetch = status;
        },
        events: {
          onSuccess: (state: TState, payload: TTopTeamListResponse) => {
            state.teams = payload.data;
          },
        },
        skipSuccessInResponse: true,
      },
    );
    extraReducerBuilderV2<TState, TTeamUserListResponse>(
      builder,
      fetchTeamUsersAsync,
      {
        statusChanger: (state: TState, status: RequestStatuses) => {
          state.statuses.fetch = status;
        },
        events: {
          onSuccess: (state: TState, payload: TTeamUserListResponse) => {
            const { listParams } = state.teamUsers;
            const { offset, limit, total } = payload.pagination;

            state.teamUsers.list = payload.data;
            state.teamUsers.listParams = {
              ...listParams,
              pagination: { page: offset / limit + 1, perPage: limit, total },
            };
          },
        },
        skipSuccessInResponse: true,
      },
    );
  },
});

export const selectors = {
  teams: (state: { challengeLeaderboard: TState }) =>
    state.challengeLeaderboard.teams,
  teamUsers: (state: { challengeLeaderboard: TState }) =>
    state.challengeLeaderboard.teamUsers,
};

export const asyncActions = {
  fetch: fetchTeamsAsync,
  fetchTeamMembers: fetchTeamUsersAsync,
};

export const { actions } = leaderboardSlice;

export default leaderboardSlice.reducer;
