import React from 'react';
import styles from './ModalSide.module.scss';

type TModalSideProps = {
  position: string;
  children: JSX.Element;
  show: boolean;
  onClose?: () => void;
};

function ModalSide({ position, children, show, onClose }: TModalSideProps) {
  if (!show) {
    return <></>;
  }

  return (
    <div
      className={styles.back}
      style={{ display: show ? 'initial' : 'none' }}
      onClick={onClose}
    >
      <div
        className={styles.modal}
        style={{ [position]: 0 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default ModalSide;
