import { ProPageHeader } from '@ant-design/pro-layout';
import { Tabs } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ActionAlert from '../../../../components/ActionAlert/ActionAlert';
import RoleWrapper from '../../../../components/RoleWrapper/RoleWrapper';
import RouteLeavingGuard from '../../../../components/RouteLeavingGuard/RouteLeavingGuard';
import { UserRoles } from '../../../../constants/userRoles';
import { actions, asyncActions } from '../CompaniesSlice';
import {
  asyncActions as asyncCompanyAdminsActions,
  selectors as companyAdminsSelectors,
} from '../EditCompany/screens/Admins/CompanyAdminsSlice';
import styles from './CreateCompany.module.scss';
import CompanyInformationTab, {
  CreateCompanyInitialState,
} from './screens/Information/CompanyInformationTab';

const { TabPane } = Tabs;

function CreateCompany() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const companyPageRef = useRef<HTMLDivElement>(null);
  // selectors
  const adminsData = useSelector(companyAdminsSelectors.listData);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const createCompany = async (company: typeof CreateCompanyInitialState, adminId: number | null) => {
    const creationValues = assignCreationValues(company, adminId);
    const result = await dispatch<any>(
      asyncActions.create({ dataJson: creationValues }),
    );
    if (
      result.type === 'companies/create/fulfilled' &&
      result.payload &&
      result.payload.id
    ) {
      onSuccess(creationValues.companyName!);
      navigate(`/collective/${result.payload.id}`);
    }
  };

  const onSave = async (company: typeof CreateCompanyInitialState) => {
    dispatch(actions.clearStatuses());
    if (company.searchMainContact === '') {
      const newMainContactValues = {
        email: company.email,
        firstName: company.firstName,
        lastName: company.lastName,
        phoneNumber: company.phoneNumber,
        roleEnum: UserRoles.COMPANY_MANAGER,
        status: 'invited',
      };
      const response = await dispatch<any>(
        asyncCompanyAdminsActions.create({ dataJson: newMainContactValues }),
      );
      const adminId = response?.payload?.data?.id;
      if (adminId) {
        createCompany(company, adminId);
      }

      await dispatch<any>(asyncCompanyAdminsActions.fetchList());
    } else {
      createCompany(company, null);
    }
  };

  const assignCreationValues = (company: typeof CreateCompanyInitialState, adminId: number | null) => {
    const {
      companyName,
      companyDomain,
      nationalId,
      riskGroup,
      address,
      postalCode,
      city,
      country,
      province,
      category,
      activity,
      legalRepresentativeName,
      legalRepresentativeSurname,
      legalRepresentativeTypeOfDoc,
      legalRepresentativeDocNumber,
    } = company;

    const main = adminId || (company.searchMainContact !== ''
      ? adminsData.list?.find(
        (admin) =>
          admin.email
          === company.searchMainContact
            .split(' ')
            .find((item) => item.includes('@')),
      )?.id || null
      : adminsData.list?.[0]?.id);

    return {
      adminsIds: [main],
      companyName,
      companyDomain,
      nationalId,
      riskGroup,
      address,
      postalCode,
      city,
      country,
      province,
      category,
      activity,
      legalRepresentativeName,
      legalRepresentativeSurname,
      legalRepresentativeTypeOfDoc,
      legalRepresentativeDocNumber,
    };
  };

  const onSuccess = (title: string) => {
    ActionAlert.success(
      t('collective.company.alerts.singualarPopup', {
        title,
        operation: t('generic.operations.created'),
      }),
    );
  };

  return (
    <div className={styles.companyPage} ref={companyPageRef}>
      <RoleWrapper roles={[UserRoles.ADMIN]}>
        <ProPageHeader
          prefixedClassName={styles.pageHeader}
          title={t('collective.company.form.companyInformation.createCompany')}
          onBack={() => {
            navigate('/collective');
          }}
        />
      </RoleWrapper>
      <div className={styles.container}>
        <Tabs className={styles.tabs}>
          <TabPane
            tab={t('collective.company.tabs.information')}
            key="1"
            className={styles.informationTabContainer}
          >
            <CompanyInformationTab onSave={(data) => onSave(data)} />
          </TabPane>
          <TabPane tab={t('collective.company.tabs.admins')} key="2" disabled />
          <TabPane
            tab={t('collective.company.tabs.employees')}
            key="3"
            disabled
          />
          <TabPane
            tab={t('collective.company.tabs.products')}
            key="4"
            disabled
          />
        </Tabs>
      </div>
      <RouteLeavingGuard
        lastLocation="/collective"
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </div>
  );
}

export default CreateCompany;
