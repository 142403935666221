import React, { useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProPageHeader } from '@ant-design/pro-layout';
import styles from './EditProduct.module.scss';
import { actions, asyncActions, selectors } from '../ProductsSlice';
import { asyncActions as assignEmlAsyncActions } from './screens/AssignEmployees/AssignEmployeesSlice';
import { TProduct } from '../ProductsTypes';
import RequestStatuses from '../../../../../../../constants/requestStatuses';
import { EProductPlans, EProductStatuses } from '../ProductsConstants';
import nullOrAlt from '../../../../../../../helpers/nullOrAlt';
import ModalSide from '../../../../../../../components/ModalSide/ModalSide';
import ContinueEditingModal from '../../../../../../../components/Modals/ContinueEditingModal/ContinueEditingModal';
import ProductInfo from './screens/ProductInfo/ProductInfo';
import SaveProductModal from '../../../../../../../components/Modals/ProductsModals/SaveProductModal/SaveProductModal';
import AssignEmployees from './screens/AssignEmployees/AssignEmployees';
import { UserRoles } from '../../../../../../../constants/userRoles';
import { selectAuthenticatedUser } from '../../../../../../auth/AuthSlice';
import { enableForRoles } from '../../../../../../../helpers/role';

const { TabPane } = Tabs;

type TEditProduct = {
  show: boolean;
  id?: TProduct['id'] | null;
  onClose: () => void;
  companyId?: number;
};

const initialState = {
  productName: null as string | null,
  plan: null as EProductPlans | null,
  productCategoryId: null as number | null,
  policyNumber: null as string | null,
  ctaLink: null as string | null,
  description: null as string | null,
  activationDate: null as string | null,
  guarantees: null as number[] | null,
  headImage: null as string | null,
  imageBase64: null as string | null,
  isVisible: null as boolean | null,
};

function EditProduct({
  show, id, onClose, companyId,
}: TEditProduct) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [productData, setProductData] = useState(initialState);
  const [currentTab, setCurrentTab] = useState('1');
  const [editFlag, setEditFlag] = useState(false);
  const [assignEmployees, setAssignEmployees] = useState(
    null as number[] | null,
  );
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const currentProduct = useSelector(selectors.currentItem);
  const statuses = useSelector(selectors.statuses);

  useEffect(() => {
    if (
      (statuses.create === RequestStatuses.SUCCESS
        || statuses.update === RequestStatuses.SUCCESS)
      && currentProduct?.id
    ) {
      if (assignEmployees) {
        dispatch(
          assignEmlAsyncActions.singleRequests?.assignEmployees?.({
            id: currentProduct.id,
            dataJson: { employeeIds: assignEmployees },
          }),
        );
        setAssignEmployees(null);
      }
      dispatch<any>(asyncActions.get({ id: currentProduct.id }));
      dispatch(actions.clearStatuses());
    } else if (!statuses.update && !statuses.create && editFlag) {
      setEditFlag(false);
      dispatch(actions.clearCurrentItem());
      onCancel();
    }
  }, [statuses, dispatch]);

  useEffect(() => {
    if (currentProduct?.id && assignEmployees && assignEmployees.length) {
      dispatch(
        assignEmlAsyncActions.singleRequests?.assignEmployees?.({
          id: currentProduct.id,
          dataJson: { employeeIds: assignEmployees },
        }),
      );
      setAssignEmployees(null);
    }
  }, [assignEmployees, currentProduct?.id, dispatch]);

  const deleteAssignEmployees = (ids: number) => {
    if (currentProduct) {
      dispatch(
        assignEmlAsyncActions.singleRequests?.removeEmployee?.({
          id: currentProduct.id,
          dataJson: { employeeId: ids },
        }),
      );
      setAssignEmployees(null);
      dispatch<any>(asyncActions.get({ id: currentProduct.id }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch<any>(asyncActions.get({ id }));
    } else {
      dispatch(actions.clearCurrentItem());
      setCurrentTab('1');
    }
  }, [dispatch, id]);

  const productDataSelected = {
    productName: nullOrAlt(
      productData.productName,
      currentProduct?.name || null,
    ),
    plan: nullOrAlt(productData.plan, currentProduct?.plan || null),
    activationDate: nullOrAlt(
      productData.activationDate,
      currentProduct?.activatedAt || null,
    ),
    productCategoryId: nullOrAlt(productData.productCategoryId, currentProduct?.productCategory?.id || null),
    policyNumber: nullOrAlt(productData.policyNumber, currentProduct?.policyNumber || null),
    ctaLink: nullOrAlt(productData.ctaLink, currentProduct?.ctaLink || null),
    description: nullOrAlt(productData.description, currentProduct?.description || null),
    headImage: nullOrAlt(productData.headImage, currentProduct?.headerImage || null),
    imageBase64: nullOrAlt(productData.imageBase64, currentProduct?.headerImage || null),
    guarantees: nullOrAlt(
      productData.guarantees,
      currentProduct?.guarantees
        ?.map((g) => ({
          id: g.id,
          products: g.products?.map((p) => p.id) || [],
        }))
        ?.filter((g) => g.products.indexOf(id || -1) !== -1)
        ?.map((g) => g.id) || [],
    ),
    statusText: currentProduct?.statusText,
    id: currentProduct?.id,
    createdAt:
      currentProduct?.createdAt
      && new Date(currentProduct?.createdAt).toLocaleString(),
    updatedAt:
      currentProduct?.updatedAt
      && new Date(currentProduct?.updatedAt).toLocaleString(),
    isVisible: nullOrAlt(productData.isVisible, currentProduct?.isVisible || false),
  };

  const {
    productName,
    plan,
    activationDate, guarantees, productCategoryId, policyNumber, ctaLink, description, headImage, isVisible,
  } = productDataSelected;

  const onChange = (field: keyof typeof initialState, value: any) => {
    setProductData((prevState) => ({ ...prevState, [field]: value }));
    setEditFlag(true);
  };

  const validateFields = (draft = false) => {
    let allFilled = true;
    [productName, plan, draft || activationDate, guarantees.length,
      productCategoryId, policyNumber, ctaLink, description].forEach(
      (f) => {
        allFilled = allFilled && !!f;
      },
    );

    return allFilled;
  };

  const onSave = (draft = false) =>
    () => {
      const formData = new FormData();
      formData.append('name', productName);
      formData.append('plan', plan);
      formData.append('activatedAt', draft ? null : activationDate);
      formData.append('guaranteeIds', JSON.stringify(guarantees));
      formData.append('productCategoryId', productCategoryId);
      formData.append('policyNumber', policyNumber);
      formData.append('ctaLink', ctaLink);
      formData.append('description', description);
      formData.append('isVisible', isVisible);
      formData.append('uploadHeaderImage', headImage as Blob);
      if (companyId) {
        formData.append('companyId', companyId.toString());
      }

      if (currentProduct) {
        dispatch<any>(
          asyncActions.update({
            id: currentProduct.id,
            data: formData,
          }),
        );

        if (
          draft
          && currentProduct
          && [EProductStatuses.DRAFT, EProductStatuses.INACTIVE].indexOf(
            currentProduct.statusText,
          ) === -1
        ) {
          dispatch(asyncActions.singleRequests?.deactivateProduct?.({ id }));
        }
      } else {
        dispatch<any>(
          asyncActions.create({ data: formData }),
        );
      }

      // setEditFlag(false);

      // if (!draft) {
      //   dispatch(actions.clearCurrentItem());
      //   onCancel();
      // }
    };

  const onCancel = () => {
    setProductData(initialState);
    setEditFlag(false);
    setCurrentTab('1');
    onClose();
  };

  return (
    <ModalSide position="right" show={show} onClose={onClose}>
      <div className={styles.wrap}>
        <ProPageHeader
          prefixedClassName={styles.pageHeader}
          className={styles.header}
          title={t('products.forms.productInfo')}
          extra={
            enableForRoles(authenticatedUser.role, [UserRoles.COMPANY_MANAGER])
              ? []
              : [
                editFlag || assignEmployees ? (
                  <ContinueEditingModal onClick={onCancel}>
                    <Button
                      className={styles.cancelButton}
                      onClick={() => {}}
                    >
                      {t('generic.cancel')}
                    </Button>
                  </ContinueEditingModal>
                ) : (
                  <Button className={styles.cancelButton} onClick={onCancel}>
                    {t('generic.cancel')}
                  </Button>
                ),
                <Button
                  disabled={!validateFields(true) || !editFlag}
                  onClick={onSave(true)}
                >
                  {t('generic.forms.saveAsDraft')}
                </Button>,
                <SaveProductModal onClick={onSave()}>
                  <Button
                    type="primary"
                    disabled={!validateFields() || !editFlag}
                    onClick={() => {}}
                  >
                    {t('products.forms.save')}
                  </Button>
                </SaveProductModal>,
              ]
          }
        />
        <Tabs
          className={styles.tabs}
          onChange={setCurrentTab}
          activeKey={currentTab}
        >
          <TabPane tab={t('products.productInfo')} key={1}>
            <ProductInfo
              productData={productDataSelected}
              onChange={onChange}
              disabled={enableForRoles(authenticatedUser.role, [
                UserRoles.COMPANY_MANAGER,
              ])}
            />
          </TabPane>
          <TabPane tab={t('products.assignedEmployees')} disabled={!id} key={2}>
            <AssignEmployees
              productId={currentProduct?.id || -1}
              companyId={companyId || -1}
              onSelectEmployees={setAssignEmployees}
              onDeleteEmployees={deleteAssignEmployees}
              disabled={enableForRoles(authenticatedUser.role, [
                UserRoles.COMPANY_MANAGER,
              ])}
            />
          </TabPane>
        </Tabs>
      </div>
    </ModalSide>
  );
}

EditProduct.defaultProps = {
  id: null,
  companyId: undefined,
};

export default EditProduct;
