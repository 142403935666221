import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteQuizModalProps = {
  quizTitle: string;
  children: JSX.Element;
  onClick: () => void;
};

function DeleteQuizModal({
  quizTitle,
  children,
  onClick,
}: TDeleteQuizModalProps) {
  const { t } = useTranslation();

  const config = {
    title: t('quizzes.modal.delete.title'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: t('quizzes.modal.delete.content', { quizTitle }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeleteQuizModal;
