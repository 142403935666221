import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Menu, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProPageHeader } from '@ant-design/pro-layout';

import styles from './EditCompany.module.scss';
import { actions, asyncActions, selectors } from '../CompaniesSlice';
import { selectors as companyAdminsSelectors } from './screens/Admins/CompanyAdminsSlice';
import CompanyInformationTab from './screens/Information/CompanyInformationTab';
import DeleteCompanyModal from '../../../../components/Modals/CollectiveModals/DeleteCompanyModal/DeleteCompanyModal';
import RoleWrapper from '../../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../../constants/userRoles';
import EmployeeList from './screens/Employees/EmployeeList';
import ProductsList from './screens/Products/ProductsList/ProductsList';
import CompanyAdmins from './screens/Admins/CompanyAdmins';
import RequestStatuses from '../../../../constants/requestStatuses';
import ActionAlert from '../../../../components/ActionAlert/ActionAlert';
import { TCompanyStatus } from '../CompanyType';
import RouteLeavingGuard from '../../../../components/RouteLeavingGuard/RouteLeavingGuard';

const { TabPane } = Tabs;

const initialCompanyInformation = {
  companyName: null as string | null,
  companyDomain: null as string | null,
  nationalId: null as string | null,
  riskGroup: null as string | null,
  address: null as string | null,
  postalCode: null as string | null,
  city: null as string | null,
  country: null as string | null,
  province: null as string | null,
  category: null as string | null,
  activity: null as string | null,
  legalRepresentativeName: null as string | null,
  legalRepresentativeSurname: null as string | null,
  legalRepresentativeTypeOfDoc: null as string | null,
  legalRepresentativeDocNumber: null as string | null,
  email: null as string | null,
  firstName: null as string | null,
  lastName: null as string | null,
  phoneNumber: null as string | null,
  searchMainContact: '',
};

function EditCompany() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: companyId } = useParams();

  const isExistingCompany = useMemo(() => companyId !== 'new', [companyId]);

  const companyPageRef = useRef<HTMLDivElement>(null);
  const initialLength = 0;
  // selectors
  const currentCompany = useSelector(selectors.currentItem);
  const companiesData = useSelector(selectors.listData);
  const companyStatuses = useSelector(selectors.statuses);
  const adminsData = useSelector(companyAdminsSelectors.listData);
  const companyNameValidation = useSelector(
    selectors.validationError('companyName'),
  );

  const [companyInformation, setCompanyInformation] = useState(
    initialCompanyInformation,
  );
  const [informationShow, setInformationShow] = useState(false);
  const [somethingChanged, setChangedFlag] = useState(false);
  const [inicialCompaniesLength, setLength] = useState(initialLength);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  useEffect(() => {
    setLength(companiesData.list?.length || 0);
  }, []);

  useEffect(() => {
    setLength(companiesData.list?.length || 0);
  }, [companyId]);

  useEffect(() => {
    if (companyId && isExistingCompany) {
      dispatch<any>(asyncActions.get({ id: +companyId }));
    } else {
      dispatch(actions.clearCurrentItem());
      setCompanyInformation(initialCompanyInformation);
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch<any>(asyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    setInformationShow(
      (companyId && currentCompany != null) || !companyId || !isExistingCompany,
    );
    if (currentCompany) {
      setCompanyInformation({
        ...initialCompanyInformation,
        companyName: currentCompany.companyName,
        ...currentCompany.companyInfo,
      });
    }
  }, [currentCompany]);

  useEffect(() => {
    if (
      companyStatuses.create === RequestStatuses.SUCCESS ||
      companyStatuses.update === RequestStatuses.SUCCESS ||
      companyStatuses.publish === RequestStatuses.SUCCESS ||
      companyStatuses.delete === RequestStatuses.SUCCESS
    ) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [companyStatuses]);

  useEffect(() => {
    if (inicialCompaniesLength === 0) {
      setLength(companiesData.listParams?.pagination?.total || 0);
    }
    if (
      companyStatuses.create !== RequestStatuses.SUCCESS &&
      (companyStatuses.update ||
        companyStatuses.publish ||
        companyStatuses.delete)
    ) {
      companyStatuses.update === RequestStatuses.SUCCESS &&
        onSuccess(t('generic.operations.updated'));
      dispatch(actions.clearStatuses());
    }
  }, [companiesData]);

  const onBack = (locationState: string = '') =>
    navigate('/collective', {
      state: {
        search: location.state as string,
        state: locationState,
      },
    });

  const onCancelPress = () => {
    if (somethingChanged) {
      setShowConfirmModal(true);
    } else {
      onBack();
    }
  };

  const onSave = async (isGoBack = true) => {
    dispatch(actions.clearStatuses());
    if (currentCompany?.id) {
      dispatch<any>(
        asyncActions.update({
          id: currentCompany.id,
          dataJson: assignCreationValues(),
        }),
      );
      isGoBack && onBack('published');
      setChangedFlag(false);
    }
  };

  const assignCreationValues = () => {
    const {
      companyName,
      companyDomain,
      nationalId,
      riskGroup,
      address,
      postalCode,
      city,
      country,
      province,
      category,
      activity,
      legalRepresentativeName,
      legalRepresentativeSurname,
      legalRepresentativeTypeOfDoc,
      legalRepresentativeDocNumber,
    } = companyInformation;

    const main =
      companyInformation.searchMainContact !== ''
        ? adminsData.list?.find(
            (admin) =>
              admin.email ===
              companyInformation.searchMainContact
                .split(' ')
                .find((item) => item.includes('@')),
          )?.id || null
        : adminsData.list?.[0]?.id;

    return {
      adminsIds: currentCompany?.id
        ? currentCompany?.admins?.map((admin) => admin.id)
        : [main],
      companyName,
      companyDomain,
      nationalId,
      riskGroup,
      address,
      postalCode,
      city,
      country,
      province,
      category,
      activity,
      legalRepresentativeName,
      legalRepresentativeSurname,
      legalRepresentativeTypeOfDoc,
      legalRepresentativeDocNumber,
    };
  };

  const onSuccess = (operation: string) => {
    setChangedFlag(false);
    companyPageRef?.current?.scrollTo({ top: 0 });
    ActionAlert.success(
      t('collective.company.alerts.singualarPopup', {
        title: currentCompany?.companyName,
        operation,
      }),
    );
  };

  const onActive = () => {
    if (currentCompany) {
      dispatch<any>(asyncActions.publish({ item: currentCompany }));

      onBack('activated');
    }
  };

  const onDescative = () => {
    if (currentCompany) {
      dispatch<any>(asyncActions.unpublish({ item: currentCompany }));

      onBack('deactivated');
    }
  };

  const onChange = (val: any) => {
    setCompanyInformation(val);
  };

  const validateFields = () => {
    let allFilled = true;
    [
      companyInformation?.companyName,
      companyInformation?.companyDomain,
      companyInformation?.nationalId,
      companyInformation?.riskGroup,
      companyInformation?.address,
      companyInformation?.postalCode,
      companyInformation?.city,
      companyInformation?.country,
      companyInformation?.category,
      companyInformation?.activity,
      companyInformation?.province,
      companyInformation?.legalRepresentativeName,
      companyInformation?.legalRepresentativeSurname,
      companyInformation?.legalRepresentativeTypeOfDoc,
      companyInformation?.legalRepresentativeDocNumber,
    ].forEach((f) => {
      allFilled = allFilled && !!f;
    });

    return allFilled && !companyNameValidation;
  };

  // compoonent to dopdown
  const headerMenu = (
    <Menu className={styles.dropdown}>
      <Menu.Item
        key={Math.random()}
        onClick={onDescative}
        disabled={
          !currentCompany ||
          (currentCompany &&
            !currentCompany.activatedAt &&
            !currentCompany.deactivatedAt)
        }
      >
        {t('generic.inactive')}
      </Menu.Item>
      <DeleteCompanyModal
        companyName={companyInformation.companyName ?? ''}
        onClick={() => {
          if (currentCompany) {
            dispatch<any>(asyncActions.delete({ item: currentCompany }));
            onBack();
          }
        }}
      >
        <Menu.Item
          className={styles.deleteButton}
          key={Math.random()}
          disabled={!currentCompany}
        >
          {t('generic.buttons.delete')}
        </Menu.Item>
      </DeleteCompanyModal>
    </Menu>
  );

  return (
    <div className={styles.companyPage} ref={companyPageRef}>
      <RoleWrapper roles={[UserRoles.ADMIN]}>
        <ProPageHeader
          prefixedClassName={styles.pageHeader}
          title={
            currentCompany &&
            t('collective.company..form.companyInformation.companyName', {
              name: currentCompany.companyName,
            })
          }
          extra={[
            ...(() => {
              if (!currentCompany) return [];

              if (
                currentCompany.status.companyStatusTitle ===
                TCompanyStatus.PENDING
              ) {
                return [
                  <Button
                    className={styles.defaultButton}
                    onClick={() => onCancelPress()}
                  >
                    {t('generic.modals.buttons.cancel')}
                  </Button>,
                  <Button
                    disabled={!validateFields()}
                    onClick={() => onSave(false)}
                  >
                    {t('generic.forms.saveAsProject')}
                  </Button>,
                  <Button
                    type="primary"
                    disabled={!currentCompany || !!companyNameValidation}
                    onClick={onActive}
                  >
                    {t('collective.company.form.buttons.active')}
                  </Button>,
                  <Dropdown overlay={headerMenu} placement="bottomRight">
                    <Button style={{ width: '32px', padding: 0 }}>...</Button>
                  </Dropdown>,
                ];
              }

              if (
                currentCompany.status.companyStatusTitle ===
                TCompanyStatus.ACTIVE
              ) {
                return [
                  <Button
                    className={styles.defaultButton}
                    onClick={() => onBack()}
                  >
                    {t('generic.modals.buttons.cancel')}
                  </Button>,
                  <Button
                    type="primary"
                    disabled={!!companyNameValidation}
                    onClick={() => onSave()}
                  >
                    {t('collective.company.form.buttons.upload')}
                  </Button>,
                  <Dropdown overlay={headerMenu} placement="bottomRight">
                    <Button style={{ width: '32px', padding: 0 }}>...</Button>
                  </Dropdown>,
                ];
              }

              return [
                <Button disabled={!currentCompany} onClick={onActive}>
                  {t('collective.company.form.buttons.reactive')}
                </Button>,
              ];
            })(),
          ]}
          onBack={() => {}}
          backIcon={
            <Button
              icon={<ArrowLeftOutlined />}
              type="text"
              style={{ margin: 0, padding: 0 }}
              onClick={() => onBack()}
            />
          }
        />
      </RoleWrapper>
      <RoleWrapper roles={[UserRoles.COMPANY_MANAGER]}>
        <ProPageHeader
          prefixedClassName={styles.pageHeader}
          title={t('collective.company..form.companyInformation.companyName', {
            name: currentCompany?.companyName || '',
          })}
          extra={[
            <Button
              href={`https://51mfpv9wviz.typeform.com/policychanges`}
              className={styles.requestUpdateButton}
              target="_blank"
            >
              {t('collective.company.form.buttons.manageEmployees')}
            </Button>,
          ]}
          onBack={() => {}}
          backIcon={
            <Button
              icon={<ArrowLeftOutlined />}
              type="text"
              style={{ margin: 0, padding: 0 }}
              onClick={() => onBack()}
            />
          }
        />
      </RoleWrapper>
      <div className={styles.container}>
        <Tabs className={styles.tabs}>
          <TabPane
            tab={t('collective.company.tabs.information')}
            key="1"
            className={styles.informationTabContainer}
          >
            {informationShow && (
              <CompanyInformationTab
                companyId={isExistingCompany && companyId ? +companyId : null}
                handleCompanyData={(val) => onChange(val)}
                onSomethingChange={() => setChangedFlag(true)}
              />
            )}
          </TabPane>
          <TabPane
            tab={t('collective.company.tabs.admins')}
            key="2"
            disabled={!currentCompany}
          >
            <CompanyAdmins
              companyId={currentCompany?.id || -1}
              onSomethingChange={() => setChangedFlag(true)}
              onAdminsSave={() => setChangedFlag(false)}
            />
          </TabPane>
          <TabPane
            tab={t('collective.company.tabs.employees')}
            key="3"
            disabled={!currentCompany}
          >
            <EmployeeList companyId={currentCompany?.id || -1} />
          </TabPane>
          <TabPane
            tab={t('collective.company.tabs.products')}
            key="4"
            disabled={!currentCompany}
          >
            <ProductsList companyId={currentCompany?.id || -1} />
          </TabPane>
          {/* <TabPane tab={t('collective.company.tabs.payment')} key="5" disabled={!currentCompany}>
              <></>
            </TabPane> */}
        </Tabs>
        <ActionAlert />
      </div>
      <RouteLeavingGuard
        lastLocation="/collective"
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </div>
  );
}

export default EditCompany;
