import { createTag, fetchTags } from './TagsApi';
import { TTag, TTagsResponse, TSingleTagResponse } from './TagsTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

export const tagsSlice = buildSlice<TTag, TSingleTagResponse, TTagsResponse>(
  'tags',
  {
    fetchList: fetchTags,
    createJson: createTag as (data: {
      [key: string]: any;
    }) => Promise<TSingleTagResponse>,
  },
);

export const { selectors, asyncActions, actions } = tagsSlice;

export default tagsSlice.reducer;
