import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../../../../../../helpers/apiRequest';
import { TProductsListResponse, TUpdateEmployeeCertificate, TUpdatePolicies } from './ProductsType';

export const getProductsByEmployees = async (
  id: number,
  listParams?: TListParams,
): Promise<TProductsListResponse> =>
  apiRequestGet<TProductsListResponse>(
    `/companies/${id}/products/by-employees`,
    { ...listParams, version: 'v2' }
  );

export const activatePoliciesRequest = async (
  body: TUpdatePolicies,
): Promise<any> =>
  apiRequest<any>(
    `/companies/${body.companyId}/products/activate-policies`,
    'patch',
    body,
    { version: 'v2' },
  );

export const deactivatePoliciesRequest = async (
  body: TUpdatePolicies,
): Promise<any> =>
  apiRequest<any>(
    `/companies/${body.companyId}/products/deactivate-policies`,
    'patch',
    body,
    { version: 'v2' },
  );

export const deleteEmployeeCertificateRequest = async (
  params: TUpdateEmployeeCertificate,
): Promise<any> => apiRequest<any>(
  `/users/${params.userId}/products/${params.companyId}`,
  'patch',
  { pathToFile: null },
);
