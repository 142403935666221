import buildSlice, {
  TDefaultCreateJsonRequest,
  TDefaultUpdateJsonRequest,
} from '../../../../../general/helpers/genericSliceBuilder';
import {
  TUserCompanyProfile,
  TUserCompanyProfileListResponse,
  TUserCompanyProfileResponse,
} from './EmployeesType';
import {
  createEmployee,
  deleteEmployee,
  getEmployee,
  getEmployeesByCompany,
  updateEmployee,
} from './EmployeesApi';

const employeesSlice = buildSlice<
  TUserCompanyProfile,
  TUserCompanyProfileResponse,
  TUserCompanyProfileListResponse
>('employees', {
  fetchList: getEmployeesByCompany,
  getOne: getEmployee,
  createJson:
    createEmployee as TDefaultCreateJsonRequest<TUserCompanyProfileResponse>,
  updateJson:
    updateEmployee as TDefaultUpdateJsonRequest<TUserCompanyProfileResponse>,
  delete: deleteEmployee,
});

export const { selectors, actions, asyncActions } = employeesSlice;

export default employeesSlice.reducer;
