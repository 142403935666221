import { TCompany } from '../collective/companies/CompanyType';
import { IPagination } from '../general/GeneralTypes';

export type TReward = {
  id: number;
  name: string;
  description: string;
  price: number;
  datetime: string;
  totalPointsNeeded: number;
  addressFormatted?: string;
  addressComponents?: string;
  image: string;
  publishDate: string;
  unpublishDate: string;
  visibleFrom: string;
  visibleUntil?: string;
  startDate?: string;
  endDate?: string;
  createdAt: string;
  updatedAt: string;
  companies?: TCompany[];
  assignmentType: {
    title: EAssigmentType;
  };
};

export type TSingleRewardResponse = {
  statusCode: number;
  message?: string[];
  error?: string;
  success?: boolean;
  data: TReward;
};

export type TRewardsResponse = {
  data: TReward[];
  pagination: IPagination;
};

export enum ERewardStatuses {
  DRAFT = 'draft',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum EAssigmentType {
  ALL = 'all',
  COMPANIES_ONLY = 'companies_only',
  SELECTED_COMPANIES = 'selected_companies',
}

export type TRewardForm = {
  name?: string;
  description?: string;
  price?: number;
  totalPointsNeeded?: number;
  visibleFrom?: Date;
  visibleUntil?: Date;
  image?: File;
  imageBase64?: string;
  assignmentTypeTitle?: EAssigmentType;
  companiesIds?: number[];
  addressFormatted?: string;
  addressComponents?: string;
  startDate?: Date;
  endDate?: Date;
};
