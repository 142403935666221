import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import BackgroundWave from '../../../components/BackgoundWave/BackgroundWave';
import SideBackgroundImage from '../../../components/SideBackgroudImage/SideBackgroundImage';
import CentralCard from '../../../components/CentralCard/CentralCard';
import styles from './ForgotPassword.module.scss';
import { forgotPasswordAsync, selectForgotPasswordStatus } from '../AuthSlice';
import requestStatuses from '../../../constants/requestStatuses';
import { ROUTES } from '../../../constants/routes';
import { i18nInsertElement } from '../../../helpers/i18nInsertElement';

const { Item } = Form;

function ForgotPassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const status = useSelector(selectForgotPasswordStatus);

  const [email, setEmail] = useState(null as string | null);

  const onSend = () => {
    if (email) {
      dispatch<any>(forgotPasswordAsync({ email }));
    }
  };

  return (
    <BackgroundWave>
      <SideBackgroundImage
        leftImage="/assets/auth/fox.svg"
        rightImage="/assets/auth/payment.svg"
      >
        <div className={styles.wrap}>
          <Link to="/" className={styles.logoContainer}>
            <ReactSVG className={styles.logo} src="/assets/general/logo.svg" />
            <div className={styles.logoText}>Vitaance app</div>
          </Link>
          <CentralCard className={styles.centralCard}>
            <>
              <div className={styles.title}>{t('forgotPassword.title')}</div>
              <div className={styles.helpText}>
                {t('forgotPassword.helpText')}
              </div>
              <Form className={styles.form} layout="vertical">
                <Item label={t('generic.email')}>
                  <Input
                    className={styles.input}
                    type="email"
                    placeholder="example@vitaance.com"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Item>
                {status === requestStatuses.FAILED && (
                  <div className={styles.errorMessage}>
                    {t('auth.userNotExists')}
                  </div>
                )}
                {status === requestStatuses.SUCCESS && (
                  <div className={styles.successMessage}>
                    {t('auth.emailSent')}
                  </div>
                )}
                <Item>
                  <Button
                    onClick={onSend}
                    htmlType="submit"
                    className={styles.signInButton}
                    type="primary"
                  >
                    {t('generic.send')}
                  </Button>
                </Item>
                <Item>
                  {i18nInsertElement(t('auth.goBackToLogin'), 'btn', (text) => (
                    <Link to={ROUTES.login}>
                      <Button className={styles.logInLink} type="link">
                        {text}
                      </Button>
                    </Link>
                  ))}
                </Item>
              </Form>
            </>
          </CentralCard>
          <div className={styles.legalBlock}>
            <div>{t('generic.copyright')}</div>
            <div>
              <a href="https://www.vitaance.com/aviso-legal" target="_blank" rel="noreferrer">
                {t('generic.legalDocs')}
              </a>
              {' '}
              |
              {' '}
              <a
                href="https://www.vitaance.com/politica-de-privacidad-vitaance"
                target="_blank"
                rel="noreferrer"
              >
                {t('generic.privacyPolicy')}
              </a>
            </div>
          </div>
        </div>
      </SideBackgroundImage>
    </BackgroundWave>
  );
}

export default ForgotPassword;
