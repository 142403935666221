import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import store from './app/store';
import './index.css';
import App from './App';
import initLocalization from './features/i18n/i18n';
import { Config } from './config/config';
import './styles/antd.css';

const { I18N } = Config;

initLocalization(localStorage.getItem('lang') || I18N.DEFAULT_LANGUAGE);

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>
        Vitaance Admin Panel
        {process.env.NODE_ENV === 'production' ? '' : ' Staging'}
      </title>
    </Helmet>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  rootElement,
);
