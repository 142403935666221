import { Button, Divider, Form, Input, InputRef, Modal, Select, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from './TagsInput.module.scss';
import {
  selectors as tagsSelectors,
  asyncActions as tagsAsyncActions,
  actions as tagsActions,
} from '../../features/tags/TagsSlice';
import { TTag } from "../../features/tags/TagsTypes";
import { Config } from "../../config/config";
import { Languages } from "../../constants/languages";

function TagsInput({
  selectedInsightLanguage,
  tags,
  tagList,
  emptyTranslations,
  setTags,
  setTagList,
}:{
  selectedInsightLanguage: string,
  tags: number[],
  tagList: TTag[],
  emptyTranslations: () => {[key: string]: string}
  setTags: (value: number[]) => void,
  setTagList: (value: TTag[]) => void,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef<InputRef>(null);

  const [newTagTranslations, setNewTagTranslations] = useState(
    emptyTranslations(),
  );

  const tagListSelector = useSelector(tagsSelectors.listData);

  useEffect(() => {
    dispatch(tagsActions.setPagination({ page: 1, perPage: 5000 }));
    dispatch<any>(tagsAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    setTagList(tagListSelector.list || []);
  }, [tagListSelector]);

  const MAX_TAGS_VALUE = 5;

  const languages = Config.I18N.LOCALES;

  const openModal = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    setOpen(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleOk = async () => {
    setLoading(true);
    const newTagApi = Object.keys(newTagTranslations).reduce(
      (result, aLanguage) => {
        result[t(`languages.${aLanguage}`, { lng: Languages.EN }).toLowerCase()] =
          newTagTranslations[aLanguage];
        return result;
      },
      {} as { [key: string]: string },
    );
    const result = await dispatch<any>(
      tagsAsyncActions.create({ dataJson: newTagApi }),
    );
    if (
      result.type === 'tags/create/fulfilled' &&
      result.payload &&
      result.payload.id
    ) {
      setNewTagTranslations(emptyTranslations());
      setOpen(false);
      if (tagList) {
        setTagList([...tagList, result.payload]);
      }
      setTags([...tags, result.payload.id]);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title={
          <div className={styles.modalTitle}>
            {t('insights.tags.modal.title')}
          </div>
        }
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('generic.cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {t('generic.submit')}
          </Button>,
        ]}
      >
        <Form layout="vertical">
          {languages.map((aLang) => (
            <Form.Item
              className={styles.formItem}
              label={t(`languages.${aLang}`)}
            >
              <Input
                maxLength={15}
                showCount
                value={newTagTranslations[aLang]}
                onChange={(e) => {
                  setNewTagTranslations({
                    ...newTagTranslations,
                    [aLang]: e.target.value,
                  });
                }}
              />
            </Form.Item>
          ))}
        </Form>
      </Modal>
      <Select
        mode="multiple"
        placeholder={t('insights.edit.tags')}
        dropdownStyle={{
          background:
            tags.length >= MAX_TAGS_VALUE ? 'lightgrey' : undefined,
        }}
        filterOption={(input, option) => {
          const exists = option?.label?.toString()
            .replace(/\s/g, '')
            .toLowerCase()
            .includes(input.toLowerCase());
          return exists || false;
        }}
        dropdownRender={(menu) => (
          <div
            className={
              tags.length >= MAX_TAGS_VALUE
                ? styles.disabledSelect
                : undefined
            }
          >
            <Space style={{ padding: '0 8px 0' }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={openModal}
                disabled={tags.length >= MAX_TAGS_VALUE}
              >
                {t('insights.tag.newTitle')}
              </Button>
            </Space>
            <Divider style={{ margin: '4px 0' }} />
            {menu}
          </div>
        )}
        onChange={(values) => {
          setTags(values);
        }}
        // open={tags.length < MAX_TAGS_VALUE}
        value={tags}
        options={tagList?.map((tag) => ({
          label:
            tag.translation[
              `name${_.startCase(_.toLower(selectedInsightLanguage))}`
            ],
          value: tag.id,
        }))}
        tagRender={(props) => {
          // eslint-disable-next-line react/prop-types
          const { closable, value, onClose } = props;
          const onPreventMouseDown = (
            event: React.MouseEvent<HTMLSpanElement>,
          ) => {
            event.preventDefault();
            event.stopPropagation();
          };
          return (
            <Tag
              onMouseDown={onPreventMouseDown}
              closable={closable}
              onClose={onClose}
              style={{
                marginRight: 3,
                color: 'black',
                border: 0,
                backgroundColor: '#F4F3F3',
              }}
            >
              {
                tagList?.find((tag) => tag.id === value)?.translation[
                  `name${_.startCase(
                    _.toLower(selectedInsightLanguage),
                  )}`
                ]
              }
            </Tag>
          );
        }}
      />
    </>
   
  )
}

export default TagsInput;