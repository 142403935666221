import {
  apiRequestGet,
  apiRequest,
  apiRequestFormData,
  TListParams,
} from '../../helpers/apiRequest';
import {
  IFetchGuaranteesResponse,
  TGuarantee,
  TGuaranteesResponse,
} from './GuaranteesTypes';

export const fetchGuarantees = async (
  listParams?: TListParams,
): Promise<IFetchGuaranteesResponse> =>
  apiRequestGet<any>('/insurance/guarantee/', { ...listParams });

export const createGuarantee = async (
  CompanyData: TGuarantee,
): Promise<TGuaranteesResponse> =>
  apiRequest<TGuaranteesResponse>(
    '/insurance/guarantee/',
    'post',
    CompanyData,
    null,
  );

export const getGuarantee = async (id: number): Promise<any> =>
  apiRequestGet<any>(`/insurance/guarantee/${id}`, null);

export const updateGuarantee = async (
  id: TGuarantee['id'],
  companyData: TGuarantee,
): Promise<TGuaranteesResponse> =>
  apiRequest<TGuaranteesResponse>(
    `/insurance/guarantee/${id}`,
    'patch',
    companyData,
    null,
  );

export const deleteGuarantee = async (id: number): Promise<any> =>
  apiRequest<any>(`/insurance/guarantee/${id}`, 'delete', null, null);
