import { useEffect, useState, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'antd';
import VisitWebsite from '../../../components/VisitWebsite/VisitWebsite';
import CarouselAntd from '../../../components/CarouselAntd/CarouselAntd';
import styles from './OnBoarding.module.scss';
import { ROUTES } from '../../../constants/routes';
import { Config } from '../../../config/config';
import { selectCurrentLang, setCurrentLanguage } from '../../i18n/i18nSlice';
const { Option } = Select;
import logo from './images/logo.svg';
import responsiveEnglish from '../../../assets/svg/third-en.svg';
import responsiveSpanish from '../../../assets/svg/third-es.svg';

import { apiMobRequestGet } from '../../../helpers/apiRequest';
import { TGuarantee } from '../../guarantees/GuaranteesTypes';
import { useDispatch, useSelector } from 'react-redux';

import SpainFlag from '../../../../src/assets/svg/spain.svg';
import UKFlag from '../../../assets/svg/united-kingdom.svg';

function OnBoarding(props: any) {
  const { t, i18n } = useTranslation();
  const { token } = useParams() as { token: string };
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState([]);
  const [activatedAt, setActivatedAt] = useState(null as Date | null);
  const [guarantees, setGuarantees] = useState<TGuarantee[]>([]);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('');
  const dispatch = useDispatch();
  const lang = useSelector(selectCurrentLang);

  type TUserResponse = {
    firstName: string;
    lastName: string;
    birthDate?: any;
    email: string;
    productToUser: {
      activatedAt: Date;
      product: {
        guarantees: TGuarantee[];
      };
    }[];
  };
  const getUserData = async (): Promise<TUserResponse> =>
    apiMobRequestGet<TUserResponse>(`/users/products/welcome/${token}`, {
      version: 'v2',
    });

  useEffect(() => {
    getUserData()
      .then((res) => {
        setFirstName(res.firstName || '');
        setLastName(res.lastName);
        setDob(res.birthDate || null);
        setEmail(res.email || '');
        setActivatedAt(res.productToUser[0].activatedAt);
        setGuarantees(res.productToUser[0].product.guarantees);
      })
      .catch((error: Error) => {
        if (error.message) {
          if (error.message.includes('404')) {
            // 404 - invalid link or link already used
            navigate(ROUTES.alreadyRegistered);
          }
          if (error.message.includes('409')) {
            // 409 - invitation link has expired
            navigate(ROUTES.invitationExpired);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLanguage(lang || '');
  }, [lang]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return <Suspense fallback="loading" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.rowLeftContainer}>
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            <Select
              className={styles.langSelector}
              defaultValue={Config.I18N.DEFAULT_LANGUAGE}
              value={
                lang || Config.I18N.DEFAULT_LANGUAGE || Config.I18N.LOCALES[0]
              }
              onSelect={(language) => {
                dispatch(setCurrentLanguage({ language }));
              }}
            >
              {Config.I18N.LOCALES.map((loc) => (
                <Option value={loc} key={loc}>
                  {
                    <div
                      style={{
                        width: windowSize.width < 876 ? 40 : 80,
                        flex: 1,
                        flexDirection: 'row',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          width: windowSize.width < 876 ? '60%' : '30%',
                        }}
                      >
                        <img
                          src={loc === 'es' ? SpainFlag : UKFlag}
                          alt=""
                          height={20}
                          width={20}
                        />
                      </div>
                      <div
                        style={{
                          width: windowSize.width < 876 ? '40%' : '50%',
                        }}
                      >
                        <a className={styles.langText}>
                          {windowSize.width < 876
                            ? loc.toUpperCase()
                            : loc === 'es'
                            ? 'Español'
                            : 'English'}
                        </a>
                      </div>
                    </div>
                  }
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div className={styles.vitaanceLogoRes}>
          <div className={styles.img}>
            <img
              src={lang?.includes('es') ? responsiveSpanish : responsiveEnglish}
              className={styles.resonsive}
            />
          </div>
          <div className={styles.vitaanceTextContainer}>
            <img src={logo} className={styles.logo} />
            <span className={styles.vitaanceText}>Vitaance</span>
          </div>
        </div>

        <div className={styles.welcomeContainer}>
          <div className={styles.welcomeTextContainer}>
            <span className={styles.welcomeText}>
              {t('auth.onboarding.onboarding.title', {
                name: firstName,
              })}
            </span>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            type="link"
            onClick={() => {
              navigate(ROUTES.confirmData, {
                state: {
                  firstName,
                  lastName,
                  dob,
                  email,
                  guarantees,
                  activatedAt,
                  token,
                },
              });
            }}
          >
            {t('auth.onboarding.onboarding.button')}
          </Button>
        </div>

        <div className={styles.footer}>
          <VisitWebsite />
          <div className={styles.footerContainer}>
            <p className={styles.policy}>{t('generic.copyright')}</p>
            <p className={styles.copyrightUnderline}>
              <a href={Config.LEGAL} target="_blank" rel="noreferrer">
                <span>{t('generic.legalDocs')}</span>
              </a>{' '}
              |{' '}
              <a href={Config.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                <span>{t('generic.privacyPolicy')}</span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.carousel}>
        <CarouselAntd />
      </div>
    </div>
  );
}

export default OnBoarding;
