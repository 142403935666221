import React, { useEffect, useMemo, useState } from 'react';
import {
  AutoComplete,
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import {
  ArrowLeftOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { intersectionBy } from 'lodash';
import { actions, asyncActions, selectors } from '../RewardsSlice';
import styles from './EditReward.module.scss';
import FormCard from '../../../components/FormCard/FormCard';
import FormTitle from '../../../components/Forms/FormTitle/FormTitle';
import DeleteRewardModal from '../../../components/Modals/RewardsModals/DeleteRewardModal/DeleteRewardModal';
import PublishRewardModal from '../../../components/Modals/RewardsModals/PublishRewardModal/PublishRewardModal';
import ImageUploadForm from '../../../components/ImageUploadForm/ImageUploadForm';
import { EAssigmentType, ERewardStatuses, TRewardForm } from '../RewardsTypes';
import {
  getRewardStatus,
  getRewardStatusRendered,
  isActionAvailable,
} from '../RewardsHelpers';
import FormItemTwoColumn from '../../../components/Forms/FormItemTwoColumn/FormItemTwoColumn';
import {
  asyncActions as placeAsyncActions,
  selectors as placeSelectors,
} from '../../places/PlacesSlice';
import { enableForRoles } from '../../../helpers/role';
import { selectAuthenticatedUser } from '../../auth/AuthSlice';
import {
  asyncActions as adminUserAdminAsyncActions,
  selectors as adminUserAdminSelectors,
} from '../../adminUsers/AdminUsersAdminSlice';
import { UserRoles } from '../../../constants/userRoles';
import { TCompany } from '../../collective/companies/CompanyType';
import RequestStatuses from '../../../constants/requestStatuses';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';

const RewardFormSchema = yup
  .object({
    name: yup.string().trim().required(),
    description: yup.string().trim().max(400).required(),
    price: yup
      .number()
      .typeError('generic.minValue')
      .min(0, 'generic.minValue')
      .required(),
    totalPointsNeeded: yup
      .number()
      .typeError('generic.minValue')
      .min(0, 'generic.minValue')
      .required(),
    visibleFrom: yup.string().trim().required(),
    visibleUntil: yup.string().trim().nullable().optional(),
    imageBase64: yup.string().required(),
    assignmentTypeTitle: yup
      .string()
      .trim()
      .oneOf([
        EAssigmentType.ALL,
        EAssigmentType.COMPANIES_ONLY,
        EAssigmentType.SELECTED_COMPANIES,
      ])
      .required(),
    companiesIds: yup
      .array()
      .when('assignmentTypeTitle', (assignmentType: EAssigmentType, yupP) =>
        assignmentType === EAssigmentType.SELECTED_COMPANIES
          ? yupP.min(1).required()
          : yupP.nullable().optional(),
      ),
    addressFormatted: yup.string().trim().nullable().optional(),
    addressComponents: yup.string().trim().nullable().optional(),
    startDate: yup.string().trim().nullable().optional(),
    endDate: yup.string().trim().nullable().optional(),
  })
  .required();

function EditReward() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: rewardId } = useParams();

  const isExistingReward = useMemo(() => rewardId !== 'new', [rewardId]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [somethingChanged, setChangedFlag] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(RewardFormSchema),
  });

  useEffect(() => {}, [])
  const currentReward = useSelector(selectors.currentItem);
  const statuses = useSelector(selectors.statuses);
  const places = useSelector(placeSelectors.placeList);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const adminCompaniesData = useSelector(adminUserAdminSelectors.listData);

  const defaultAssignmentType = enableForRoles(authenticatedUser.role, [
    UserRoles.COMPANY_MANAGER,
  ])
    ? EAssigmentType.SELECTED_COMPANIES
    : EAssigmentType.ALL;

  const addressFormatted = watch('addressFormatted');

  useEffect(() => {
    setValue(
      'addressComponents',
      JSON.stringify(places.find((p) => p.description === addressFormatted)),
    );

    if (addressFormatted) {
      dispatch<any>(placeAsyncActions.fetch({ query: addressFormatted }));
    }
  }, [addressFormatted]);

  const assignmentTypeTitle = watch('assignmentTypeTitle');

  useEffect(() => {
    if (
      [EAssigmentType.ALL, EAssigmentType.COMPANIES_ONLY].includes(
        assignmentTypeTitle,
      ) &&
      enableForRoles(authenticatedUser.role, [UserRoles.ADMIN])
    ) {
      setValue('companiesIds', undefined);
    }
  }, [assignmentTypeTitle]);

  useEffect(() => {
    dispatch(actions.clearCurrentItem());

    dispatch<any>(adminUserAdminAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    if (rewardId && isExistingReward) {
      dispatch<any>(asyncActions.get({ id: +rewardId }));
    }
  }, [dispatch, rewardId]);

  useEffect(() => {
    let defaultSelectedCompanies = currentReward?.companies || [];

    if (
      !defaultSelectedCompanies.length &&
      enableForRoles(authenticatedUser.role, [UserRoles.COMPANY_MANAGER])
    ) {
      defaultSelectedCompanies = adminCompaniesData?.list || [];
    }

    let selectedAssignmentType =
      currentReward?.assignmentType?.title || defaultAssignmentType;

    if (
      enableForRoles(authenticatedUser.role, [UserRoles.COMPANY_MANAGER]) &&
      selectedAssignmentType === EAssigmentType.COMPANIES_ONLY
    ) {
      selectedAssignmentType = EAssigmentType.SELECTED_COMPANIES;
    }

    reset({
      name: currentReward?.name,
      description: currentReward?.description,
      price: currentReward?.price,
      totalPointsNeeded: currentReward?.totalPointsNeeded,
      image: currentReward?.image,
      imageBase64: currentReward?.image,
      visibleFrom: currentReward?.visibleFrom
        ? dayjs(currentReward?.visibleFrom)
        : null,
      visibleUntil: currentReward?.visibleUntil
        ? dayjs(currentReward?.visibleUntil)
        : null,
      startDate: currentReward?.startDate
        ? dayjs(currentReward?.startDate)
        : null,
      endDate: currentReward?.endDate ? dayjs(currentReward?.endDate) : null,
      assignmentTypeTitle: selectedAssignmentType,
      companiesIds: intersectionBy(
        adminCompaniesData?.list || [],
        defaultSelectedCompanies,
        'id',
      )?.map((company: TCompany) => company.id),
      addressFormatted: currentReward?.addressFormatted,
      addressComponents: currentReward?.addressComponents,
    });
  }, [currentReward]);

  useEffect(() => {
    if (statuses.create === RequestStatuses.SUCCESS && currentReward?.id) {
      navigate(`/rewards/${currentReward.id}`);

      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  const onBack = (locationState: string = '') =>
    navigate('/rewards',{
      state: {
        search: location.state as string,
        state: locationState,
      }
    });

  const onSave = (data: TRewardForm, publish: boolean = false) => {
    const formData = new FormData();

    Object.keys(data).forEach((k: string) => {
      const key = k as keyof TRewardForm;

      const skipFields = ['imageBase64'];

      if (!data[key] || skipFields.includes(key)) {
        return;
      }

      switch (key) {
        case 'image': {
          formData.append('image', data[key] as Blob);
          break;
        }
        case 'companiesIds': {
          if (data.assignmentTypeTitle !== EAssigmentType.SELECTED_COMPANIES) {
            return;
          }

          (data[key] as TRewardForm['companiesIds'])?.forEach((c) => {
            formData.append('companiesIds[]', c.toString());
          });
          break;
        }
        case 'visibleFrom': {
          formData.append(
            key,
            data[key] ? dayjs(data[key]).toISOString() : '',
          );
          break;
        }
        case 'visibleUntil': {
          formData.append(
            key,
            data[key] ? dayjs(data[key]).toISOString() : '',
          );
          break;
        }
        case 'startDate': {
          formData.append(
            key,
            data[key] ? dayjs(data[key]).toISOString() : '',
          );
          break;
        }
        case 'endDate': {
          formData.append(
            key,
            data[key] ? dayjs(data[key]).toISOString() : '',
          );
          break;
        }
        default:
          formData.append(key, data[key]?.toString() || '');
      }
    });

    if (currentReward?.id) {
      const { publishDate, unpublishDate, id } = currentReward;

      if (publish && (!publishDate || (publishDate && unpublishDate))) {
        dispatch<any>(asyncActions.updateAndPublish({ id, data: formData }));
      } else if (!publish && !currentReward?.unpublishDate) {
        dispatch<any>(asyncActions.updateAndDraft({ id, data: formData }));
      } else {
        dispatch<any>(asyncActions.update({ id, data: formData }));
      }
    } else if (publish) {
      dispatch<any>(asyncActions.createAndPublish({ data: formData }));
    } else {
      dispatch<any>(asyncActions.create({ data: formData }));
    }

    if (publish) {
      onBack('published');
    }
  };

  const onUnPublish = () => {
    if (currentReward) {
      dispatch<any>(asyncActions.unpublish({ item: currentReward }));
    }
  };

  const onCancel = () => {
    onBack();
  };

  const onCancelPress = () => {
    if (somethingChanged) {
      setShowConfirmModal(true)
    } else {
      onCancel()
    }
  }
  const isEditable = isActionAvailable(
    authenticatedUser?.role,
    adminCompaniesData?.list?.map((company: TCompany) => company.id),
    currentReward?.companies?.map((company) => company.id),
    currentReward?.assignmentType?.title || defaultAssignmentType,
  );

  const disablePublishButton = !!(
    currentReward &&
    [ERewardStatuses.DRAFT, ERewardStatuses.INACTIVE].indexOf(
      getRewardStatus(currentReward),
    ) === -1
  );

  const headerMenu = (
    <Menu className={styles.dropdown}>
      <Menu.Item
        key={Math.random()}
        onClick={onUnPublish}
        disabled={!currentReward || !disablePublishButton}
      >
        {t('generic.deactivate')}
      </Menu.Item>
      <Menu.Item key={Math.random()}>
        {t('generic.buttons.duplicate')}
      </Menu.Item>
      <DeleteRewardModal
        rewardName={currentReward?.name ?? ''}
        onClick={() => {
          if (currentReward) {
            dispatch<any>(asyncActions.delete({ item: currentReward }));
            onCancel();
          }
        }}
      >
        <Menu.Item
          className={styles.deleteButton}
          key={Math.random()}
          disabled={!currentReward}
        >
          {t('generic.buttons.delete')}
        </Menu.Item>
      </DeleteRewardModal>
    </Menu>
  );

  return (
    <>
      <div>
        <ProPageHeader
          prefixedClassName={styles.pageHeader}
          title={t('rewards.forms.createOrEdit')}
          extra={[
            isEditable && (
              <Button className={styles.defaultButton} onClick={onCancelPress}>
                {t('generic.modals.buttons.cancel')}
              </Button>
            ),
            isEditable && (
              <>
                <Tooltip
                  placement="bottom"
                  title={
                    currentReward &&
                    getRewardStatus(currentReward) !== ERewardStatuses.DRAFT
                      ? t('rewards.tooltips.removeFromSite')
                      : ''
                  }
                >
                  <Button
                    className={styles.defaultButton}
                    disabled={!isValid}
                    onClick={handleSubmit((data: TRewardForm) => onSave(data))}
                  >
                    {t('generic.forms.saveAsDraft')}
                  </Button>
                </Tooltip>
                <PublishRewardModal
                  rewardName={watch('name', '')}
                  onClick={handleSubmit((data: TRewardForm) =>
                    onSave(data, true),
                  )}
                >
                  <Button type="primary" disabled={!isValid}>
                    {t('rewards.button.publishReward')}
                  </Button>
                </PublishRewardModal>
                {isExistingReward && (
                  <Dropdown
                    overlay={headerMenu}
                    placement="bottomRight"
                    disabled={!isValid}
                  >
                    <Button style={{ width: '32px', padding: 0 }}>...</Button>
                  </Dropdown>
                )}
              </>
            ),
          ]}
          onBack={() => {}}
          backIcon={
            <Button
              icon={<ArrowLeftOutlined />}
              type="text"
              style={{ margin: 0, padding: 0 }}
              onClick={onCancel}
            />
          }
        />
      </div>
      <div className={styles.formContainer}>
        <FormCard className={styles.formCard}>
          <Form layout="vertical">
            <FormTitle>{t('rewards.forms.rewardInformation') || ''}</FormTitle>
            <FormItemTwoColumn>
              <FormItemTwoColumn.Column>
                <>
                  <Form.Item
                    className={styles.formItem}
                    label={t('rewards.forms.name')}
                  >
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t('generic.example') || ''}
                          disabled={!isEditable}
                        />
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    className={styles.formItem}
                    label={t('generic.description')}
                  >
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <Input.TextArea
                          {...field}
                          autoSize={{ minRows: 4, maxRows: 10 }}
                          showCount
                          maxLength={400}
                          placeholder={String(t(
                            'rewards.forms.placeholder.addDescription',
                          ))}
                          disabled={!isEditable}
                        />
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    className={styles.formItem}
                    label={t('generic.price')}
                  >
                    <Controller
                      name="price"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="15"
                          type="number"
                          min={0}
                          disabled={!isEditable}
                        />
                      )}
                    />
                    {errors.price && (
                      <span className={styles.validationError}>
                        {t(errors.price.message as string, { value: 0 })}
                      </span>
                    )}
                  </Form.Item>
                  <Form.Item
                    className={styles.formItem}
                    label={t('rewards.forms.priceUnlock')}
                  >
                    <Controller
                      name="totalPointsNeeded"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="15"
                          type="number"
                          min={0}
                          disabled={!isEditable}
                        />
                      )}
                    />
                    {errors.totalPointsNeeded && (
                      <span className={styles.validationError}>
                        {t(errors.totalPointsNeeded.message as string, {
                          value: 0,
                        })}
                      </span>
                    )}
                  </Form.Item>
                  <FormItemTwoColumn>
                    <FormItemTwoColumn.Column>
                      <Form.Item
                        className={styles.formItem}
                        label={t('rewards.forms.visibleFrom')}
                      >
                        <Controller
                          name="visibleFrom"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              disabled={!isEditable}
                              className={styles.dateInput}
                            />
                          )}
                        />
                      </Form.Item>
                    </FormItemTwoColumn.Column>
                    <FormItemTwoColumn.Column>
                      <Form.Item
                        className={styles.formItem}
                        label={t('rewards.forms.visibleUntil')}
                      >
                        <Controller
                          name="visibleUntil"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              disabled={!isEditable}
                              className={styles.dateInput}
                            />
                          )}
                        />
                      </Form.Item>
                    </FormItemTwoColumn.Column>
                  </FormItemTwoColumn>
                </>
              </FormItemTwoColumn.Column>
              <FormItemTwoColumn.Column>
                <div className={styles.formItem}>
                  <ImageUploadForm
                    onFileChanged={(img, base64) => {
                      setValue('image', img || undefined);
                      setValue('imageBase64', base64 || undefined, {
                        shouldValidate: true,
                      });
                    }}
                    tooltip={t('articles.image.tooltip')}
                    currentImage={watch('imageBase64')}
                    disabled={!isEditable}
                  />
                </div>
              </FormItemTwoColumn.Column>
            </FormItemTwoColumn>
            <Form layout="vertical">
              <FormTitle className={styles.formTitle}>
                {t('rewards.forms.rerawdsAssignment') || ''}
              </FormTitle>
              <div className={styles.formItemRadios}>
                <Form.Item>
                  <Controller
                    name="assignmentTypeTitle"
                    control={control}
                    render={({ field }) => (
                      <Radio.Group
                        {...field}
                        size="small"
                        className={styles.radiosContainer}
                        disabled={!isEditable}
                      >
                        {enableForRoles(authenticatedUser.role as UserRoles, [
                          UserRoles.ADMIN,
                        ]) && (
                          <>
                            <Radio
                              key={1}
                              value={EAssigmentType.ALL}
                              className={styles.radioOption}
                            >
                              {t('challenges.form.challengeAssignment.all')}
                            </Radio>
                            <Radio
                              key={2}
                              value={EAssigmentType.COMPANIES_ONLY}
                              className={styles.radioOption}
                            >
                              {t(
                                'challenges.form.challengeAssignment.companiesOnly',
                              )}
                            </Radio>
                          </>
                        )}
                        <Radio
                          key={3}
                          value={EAssigmentType.SELECTED_COMPANIES}
                        >
                          {t(
                            'challenges.form.challengeAssignment.selectedCompanies',
                          )}
                        </Radio>
                      </Radio.Group>
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={t(
                    'challenges.form.challengeAssignment.selectCompanies',
                  )}
                  tooltip={{
                    title: t(
                      'challenges.form.challengeAssignment.selectCompanies',
                    ),
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Controller
                    name="companiesIds"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        mode="multiple"
                        optionFilterProp="label"
                        disabled={
                          watch('assignmentTypeTitle') !==
                            EAssigmentType.SELECTED_COMPANIES || !isEditable
                        }
                        className={styles.selectOption}
                        options={adminCompaniesData?.list?.map(
                          (company: TCompany) => ({
                            value: company.id,
                            label: company.companyName,
                          }),
                        )}
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </Form>
            <FormItemTwoColumn>
              <FormItemTwoColumn.Column>
                <>
                  <FormTitle className={styles.formTitle}>
                    {String(t('rewards.forms.rewardsDetails'))}
                  </FormTitle>
                  <Form.Item
                    className={styles.formItem}
                    label={t('generic.location')}
                  >
                    <Controller
                      name="addressFormatted"
                      control={control}
                      render={({ field }) => (
                        <AutoComplete
                          {...field}
                          placeholder={t('generic.locationLower')}
                          options={places.map((p) => ({
                            value: p.description,
                          }))}
                          disabled={!isEditable}
                        >
                          <Input
                            addonAfter={
                              <EnvironmentOutlined
                                className={styles.positionIcon}
                              />
                            }
                          />
                        </AutoComplete>
                      )}
                    />
                  </Form.Item>
                  <FormItemTwoColumn>
                    <FormItemTwoColumn.Column>
                      <Form.Item
                        className={styles.formItem}
                        label={t('rewards.forms.starts')}
                      >
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              disabled={!isEditable}
                              placeholder={String(t('generic.selectDate'))}
                              className={styles.dateInput}
                            />
                          )}
                        />
                      </Form.Item>
                    </FormItemTwoColumn.Column>
                    <FormItemTwoColumn.Column>
                      <Form.Item
                        className={styles.formItem}
                        label={t('rewards.forms.ends')}
                      >
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              disabled={!isEditable}
                              placeholder={String(t('generic.selectDate'))}
                              className={styles.dateInput}
                            />
                          )}
                        />
                      </Form.Item>
                    </FormItemTwoColumn.Column>
                  </FormItemTwoColumn>
                </>
              </FormItemTwoColumn.Column>
              <FormItemTwoColumn.Column>
                <span> </span>
              </FormItemTwoColumn.Column>
            </FormItemTwoColumn>
          </Form>
        </FormCard>
        {currentReward && (
          <div className={styles.rewardDataColumn}>
            <div>{t('rewards.forms.rewardInformation')}</div>
            <div className={styles.dataBlock}>
              <div>{t('generic.currentStatus')}</div>
              {getRewardStatusRendered(currentReward, t)}
            </div>
            <div className={styles.dataBlock}>
              <div>{t('generic.idNumber')}</div>
              <div className={styles.dataBlockValue}>{currentReward?.id}</div>
            </div>
            <div className={styles.dataBlock}>
              <div>{t('generic.created')}</div>
              <div className={styles.dataBlockValue}>
                {dayjs(currentReward.createdAt).toLocaleString()}
              </div>
            </div>
            <div className={styles.dataBlock}>
              <div>{t('generic.lastUpdate')}</div>
              <div className={styles.dataBlockValue}>
                {dayjs(currentReward.updatedAt).toLocaleString()}
              </div>
            </div>
          </div>
        )}
      </div>
      <RouteLeavingGuard
        lastLocation='/rewards'
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </>
  );
}

EditReward.defaultProps = {
  rewardId: null,
};

export default EditReward;
