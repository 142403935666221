import {
  deleteAdminUser,
  fetchAdminUsers,
  getAdminUser,
  sendInvitation,
  updateAdminUser,
} from './AdminUsersApi';
import {
  TAdminUser,
  TAdminUserResponse,
  TAdminUsersListResponse,
} from './AdminUsersTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

const adminUsersSlice = buildSlice<
  TAdminUser,
  TAdminUserResponse,
  TAdminUsersListResponse
>('adminUsers', {
  updateJson: updateAdminUser,
  fetchList: fetchAdminUsers,
  getOne: getAdminUser,
  delete: deleteAdminUser,
  singleRequests: [
    {
      request: sendInvitation as (
        id: TAdminUser['id'],
        data?: { [key: string]: any },
      ) => Promise<TAdminUserResponse>,
      name: 'sendInvitation',
    },
  ],
});

export const { selectors, asyncActions, actions } = adminUsersSlice;

export default adminUsersSlice.reducer;
