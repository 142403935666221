import { UserRoles } from '../constants/userRoles';

export const enableForRoles = (
  currentUserRole: UserRoles | null,
  allowedRoles: UserRoles[],
): boolean => {
  if (!currentUserRole) return false;

  return allowedRoles.includes(currentUserRole);
};
