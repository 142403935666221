import React from 'react';

import styles from './Badge.module.scss';

type TBadgeProps = {
  value: string;
  type: string;
  className?: string;
};

function Badge({ value, type, className }: TBadgeProps) {
  const badgeClass = type === 'negative' ? styles.badgeNegative : styles.badge;

  return (
    <div className={`${badgeClass} ${className}`}>
      <img
        src={`/assets/wellness/${type}.svg`}
        alt={type}
        className={styles.icon}
      />
      <div>{value}</div>
    </div>
  );
}

export default Badge;
