import { TCategory } from '../categories/CategoriesTypes';
import { IPagination } from '../general/GeneralTypes';
import { TCompany } from '../collective/companies/CompanyType';
import { TChallengeStatus } from '../challenges/ChallengesTypes';
import { TActivityTag } from '../activityTags/ActivityTagsTypes';

export interface IQuiz {
  title: string;
  type: string;
  activityTag?: TActivityTag;
  description: string | null;
  titleImage: string | null;
  id: number;
  createdAt: string;
  updatedAt: string;
  categoryId?: number;
  category?: TCategory;
  typeFormId: string;
  rewardInPoints: number;
  publishDate: string | null;
  unpublishDate: string | null;
  bySuperAdmin: boolean;
  companies?: TCompany[];
  isCompanyOnly: boolean;
  status: TChallengeStatus;
}

export interface IFetchQuizzesResponse {
  success: boolean;
  data: IQuiz[];
  pagination: IPagination;
}

export type TNewQuizResponse = {
  statusCode: number;
  message?: string[];
  error?: string;
  success?: boolean;
  data: IQuiz;
};

export enum EQuizStatuses {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}
