import { AutoComplete, Form, Input, Layout, Select } from 'antd';
import codes from 'country-calling-code';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import RequestStatuses from '../../../../constants/requestStatuses';
import { UserRoles } from '../../../../constants/userRoles';
import { selectors } from '../../../../features/collective/companies/CompaniesSlice';
import { asyncActions as asyncCategoriesActions } from '../../../../features/collective/companies/CompaniesCategoriesSliece';
import {
  selectors as companyAdminsSelectors,
  actions as companyAdminsActions,
  asyncActions as asyncCompanyAdminsActions,
} from '../../../../features/collective/companies/EditCompany/screens/Admins/CompanyAdminsSlice';
import nullOrAlt from '../../../../helpers/nullOrAlt';
import FormCard from '../../../FormCard/FormCard';
import RoleWrapper from '../../../RoleWrapper/RoleWrapper';
import styles from './CompanyMainPersonOfContact.module.scss';

const { Content } = Layout;
const { Option } = Select;

type TCompanyMainPersonOfContactProps = {
  onChange: (
    field:
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'phoneNumber'
      | 'countryCode'
      | 'searchMainContact',
    value: any,
  ) => void;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  countryCode: string;
  searchMainContact: string;
};

function CompanyMainPersonOfContact({
  onChange,
  email,
  firstName,
  lastName,
  phoneNumber,
  countryCode,
  searchMainContact,
}: TCompanyMainPersonOfContactProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const adminsData = useSelector(companyAdminsSelectors.listData);
  const statuses = useSelector(selectors.statuses);

  const initalAdmins =
    adminsData && adminsData.list
      ? adminsData.list
          .filter((admin) => admin.status.adminStatusTitle !== 'deleted')
          .map((admin) => ({
            value: `${admin.firstName} ${admin.lastName} ( ${admin.email} )`,
          }))
      : [];

  const [adminsOptions, setAdminsOptions] = useState(initalAdmins);

  useEffect(() => {
    if (
      statuses.create === RequestStatuses.FAILED ||
      statuses.update === RequestStatuses.FAILED
    ) {
      setError(t('generic.emailAlreadyExist') || '');
    }
  }, [statuses]);

  useEffect(() => {
    dispatch<any>(asyncCategoriesActions.fetchList());
    dispatch(
      companyAdminsActions.setFilter({
        field: 'role',
        value: UserRoles.COMPANY_MANAGER,
      }),
    );
    dispatch(
      companyAdminsActions.setPagination({
        page: 1,
        perPage: 1000,
      }),
    );
    dispatch<any>(asyncCompanyAdminsActions.fetchList());
  }, [dispatch]);

  codes.sort((previousCode, nextCode) =>
    previousCode.isoCode2 > nextCode.isoCode2 ? 1 : -1,
  );

  const searchMainContactResult = (query: string) => {
    const newlist =
      adminsData.list !== null
        ? adminsData.list
            .filter((item) => item.status.adminStatusTitle !== 'deleted')
            .filter(
              (item) =>
                item.firstName.toLowerCase().indexOf(query.toLowerCase()) !==
                  -1 ||
                item.lastName.toLowerCase().indexOf(query.toLowerCase()) !==
                  -1 ||
                item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1,
            )
        : [];
    newlist.length > 0 &&
      setAdminsOptions(
        newlist.map((admin) => ({
          value: `${admin.firstName} ${admin.lastName} ( ${admin.email} )`,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {(() => {
                if (
                  admin.firstName.toLowerCase().includes(query.toLowerCase()) &&
                  query !== ''
                ) {
                  return (
                    <span>
                      <a className={styles.searchResult}>{admin.firstName}</a>
                      {` ${admin.lastName} ( ${admin.email} )`}
                    </span>
                  );
                }
                if (
                  admin.lastName.toLowerCase().includes(query.toLowerCase()) &&
                  query !== ''
                ) {
                  return (
                    <span>
                      {`${admin.firstName} `}
                      <a className={styles.searchResult}>{admin.lastName}</a>
                      {` ( ${admin.email} )`}
                    </span>
                  );
                }
                if (
                  admin.email.toLowerCase().includes(query.toLowerCase()) &&
                  query !== ''
                ) {
                  return (
                    <span>
                      {`${admin.firstName} ${admin.lastName} ( `}
                      <a className={styles.searchResult}>{admin.email}</a>
                      {' )'}
                    </span>
                  );
                }
                return (
                  <span>
                    {`${admin.firstName} ${admin.lastName} ( ${admin.email} )`}
                  </span>
                );
              })()}
            </div>
          ),
        })),
      );
  };

  const handleSearchSelected = async (newSearch: string) => {
    adminsOptions.find((admin) => {
      if (admin.value === newSearch) {
        const currentAdmin = adminsData.list?.find((a) =>
          newSearch.includes(a.email),
        );
        onChange('firstName', currentAdmin?.firstName);
        onChange('lastName', currentAdmin?.lastName);
        onChange('email', currentAdmin?.email);
        currentAdmin?.phoneNumber
          ? onChange('phoneNumber', currentAdmin?.phoneNumber.split('-')[1])
          : onChange('phoneNumber', '');
        onChange('searchMainContact', newSearch);
        currentAdmin?.phoneNumber
          ? onChange('countryCode', currentAdmin?.phoneNumber.split('-')[0])
          : onChange('countryCode', '');

        return true;
      }
      return false;
    });
  };

  const isAdminEmailValid = () =>
    !email || yup.string().email().required().isValidSync(email);

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <FormCard className={styles.card}>
          <>
            <div className={styles.title}>
              {t('collective.company.form.mainPerson.title')}
            </div>
            <div className={styles.sectionContainer}>
              <div className={styles.basicInfo}>
                <Form className={styles.form} layout="vertical">
                  <div className={styles.basicRowContainer}>
                    <Form.Item className={styles.itemLeftContainer}>
                      <AutoComplete
                        dropdownMatchSelectWidth={450}
                        options={adminsOptions}
                        onSearch={searchMainContactResult}
                        onSelect={handleSearchSelected}
                        onChange={(val) => {
                          if (!val) {
                            onChange('searchMainContact', '');
                            onChange('firstName', '');
                            onChange('lastName', '');
                            onChange('email', '');
                            onChange('phoneNumber', '');
                            searchMainContactResult('');
                            onChange('countryCode', '');
                          }
                        }}
                      >
                        <Input.Search
                          allowClear
                          onSearch={searchMainContactResult}
                          placeholder={
                            t('collective.company.admin.searchUser') || ''
                          }
                        />
                      </AutoComplete>
                    </Form.Item>
                    <Form.Item className={styles.itemRightContainer} />
                  </div>
                  <div className={styles.basicRowContainer}>
                    <Form.Item
                      label={t('collective.company.form.mainPerson.name')}
                      className={styles.itemLeftContainer}
                    >
                      <RoleWrapper
                        roles={[UserRoles.ADMIN]}
                        alternative={
                          <div className={styles.companyDetailsValue}>
                            {firstName || ''}
                          </div>
                        }
                      >
                        <Input
                          onChange={(e) =>
                            onChange('firstName', e.target.value)
                          }
                          placeholder={
                            t('collective.company.form.mainPerson.name') || ''
                          }
                          value={nullOrAlt(firstName, '')}
                          disabled={searchMainContact !== ''}
                        />
                      </RoleWrapper>
                    </Form.Item>
                    <Form.Item
                      label={t('collective.company.form.mainPerson.surname')}
                      className={styles.itemRightContainer}
                    >
                      <RoleWrapper
                        roles={[UserRoles.ADMIN]}
                        alternative={
                          <div className={styles.companyDetailsValue}>
                            {lastName || ''}
                          </div>
                        }
                      >
                        <Input
                          onChange={(e) => onChange('lastName', e.target.value)}
                          placeholder={
                            t('collective.company.form.mainPerson.surname') ||
                            ''
                          }
                          value={nullOrAlt(lastName, '')}
                          disabled={searchMainContact !== ''}
                        />
                      </RoleWrapper>
                    </Form.Item>
                  </div>
                  <div className={styles.basicRowContainer}>
                    <Form.Item
                      label={t('collective.company.form.mainPerson.email')}
                      className={styles.itemLeftContainer}
                    >
                      <RoleWrapper
                        roles={[UserRoles.ADMIN]}
                        alternative={
                          <div className={styles.companyDetailsValue}>
                            {email || ''}
                          </div>
                        }
                      >
                        <>
                          <Input
                            className={
                              isAdminEmailValid() ? '' : styles.inputError
                            }
                            onChange={(e) => {
                              onChange('email', e.target.value);

                              setError('');
                            }}
                            placeholder={
                              t('collective.company.form.mainPerson.email') ||
                              ''
                            }
                            type="email"
                            value={nullOrAlt(email, '')}
                            disabled={searchMainContact !== ''}
                          />
                          {/* {error && (
                            <span style={{ color: 'red' }}>{error}</span>
                          )} */}
                        </>
                      </RoleWrapper>
                    </Form.Item>
                    <RoleWrapper roles={[UserRoles.ADMIN]}>
                      <div
                        style={{ width: '49%' }}
                        className={styles.basicRowContainer}
                      >
                        <Form.Item
                          label={t(
                            'collective.company.form.mainPerson.country',
                          )}
                          className={styles.itemLeftContainer}
                        >
                          <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            placeholder="Please select"
                            onChange={(e: string) => onChange('countryCode', e)}
                            value={countryCode}
                            disabled={searchMainContact !== ''}
                          >
                            {codes.map((code, index) => (
                              <Option
                                key={`country-${code.isoCode2}`}
                                value={code.countryCodes.toString()}
                              >
                                {`${code.isoCode2} (+${code.countryCodes})`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={t('collective.company.form.mainPerson.phone')}
                          className={styles.itemRightContainer}
                        >
                          <Input
                            onChange={(e) =>
                              onChange('phoneNumber', e.target.value)
                            }
                            placeholder="00000000"
                            value={nullOrAlt(phoneNumber, '')}
                            disabled={searchMainContact !== ''}
                          />
                        </Form.Item>
                      </div>
                    </RoleWrapper>
                  </div>
                </Form>
              </div>
            </div>
          </>
        </FormCard>
      </Content>
    </Layout>
  );
}

export default CompanyMainPersonOfContact;
