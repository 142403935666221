import { TCategory } from '../categories/CategoriesTypes';
import { IPagination } from '../general/GeneralTypes';

export type TArticle = {
  id: number;
  title: string;
  categoryId: number;
  activityTagId: number;
  shortDescription: string;
  titleImage: string;
  featured: boolean;
  content: string;
  createdAt: string;
  updatedAt: string;
  publishDate: string;
  unpublishDate: string;
};

export type TArticleFilled = {
  category: TCategory;
} & TArticle;

export type TSingleArticleResponse = {
  statusCode: number;
  message?: string[];
  error?: string;
  success?: boolean;
  data: TArticle;
};

export type TArticlesResponse = {
  data: TArticleFilled[];
  pagination: IPagination;
};

export enum EArticleStatuses {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}
