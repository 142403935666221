import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Input } from 'antd';
import styles from './EnrolledUsers.module.scss';
import {
  selectors,
  asyncActions,
  actions,
} from '../../../ChallengeEnrolledUsersSlice';
import Table from '../../../../../components/Table/Table';

const { Search } = Input;

type TTableData = {
  key: number;
  id: number;
  name: string;
  email: string;
  enrollmentDate: string;
};

type TEnrolledUsers = {
  challengeId: number;
  enrolledUsersNumber: number;
};

function EnrolledUsers({ challengeId, enrolledUsersNumber }: TEnrolledUsers) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* DATA STORE */
  const enrolledUsers = useSelector(selectors.listData);

  const tableData: TTableData[] =
    enrolledUsers.list?.map((item) => ({
      key: item.id,
      id: item.user.id,
      name: `${item.user.firstName} ${item.user.lastName}`,
      email: item.user.email,
      enrollmentDate: dayjs(item.createdAt).format('DD/MM/YYYY'),
    })) || [];

  const columns = [
    {
      title: t('generic.id'),
      dataIndex: 'id',
    },
    {
      title: t('generic.name'),
      dataIndex: 'name',
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
    },
    {
      title: t('users.enrollmentDate'),
      dataIndex: 'enrollmentDate',
    },
  ];

  return (
    <div className={styles.enrolledUsersWrapper}>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('challenges.form.enrolledUsersCount', {
            count: enrolledUsersNumber,
          })}
        </div>
        <Search
          allowClear
          className={styles.searchField}
          placeholder={t('users.placeholder.searchForUser') || ''}
          onSearch={(val: string) => {
            dispatch(actions.setSearch({ query: val }));
            dispatch(
              actions.setPagination({
                page: 1,
                perPage: enrolledUsers.listParams?.pagination?.perPage || 10,
              }),
            );
            dispatch<any>(asyncActions.fetchList({ id: challengeId }));
          }}
        />
      </div>
      <div className={styles.tableWrapper}>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: enrolledUsers.listParams?.pagination?.total,
            current: enrolledUsers.listParams?.pagination?.page,
          }}
          actions={actions}
          asyncActions={asyncActions}
          fetchListActionParams={{ id: challengeId }}
        />
      </div>
    </div>
  );
}

export default EnrolledUsers;
