import { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Menu, Form, Input, Tooltip, Col } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { selectors, asyncActions, actions } from '../InsightsSlice';
import styles from './EditInsight.module.scss';
import FormCard from '../../../components/FormCard/FormCard';
import FormTitle from '../../../components/Forms/FormTitle/FormTitle';
import ImageUploadForm from '../../../components/ImageUploadForm/ImageUploadForm';
import CkEditor from '../../../components/CkEditor/CkEditor';
import { EInsightStatuses } from '../InsightsTypes';
import {
  getInsightStatusRendered,
  isActionAvailable,
} from '../InsightsHelpers';
import RequestStatuses from '../../../constants/requestStatuses';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import { selectCurrentLang } from '../../i18n/i18nSlice';
import { Config } from '../../../config/config';
import ActivateInsightModal from '../../../components/Modals/InsightsModals/ActivateInsightModal/ActivateInsightModal';
import DeleteInsightModal from '../../../components/Modals/InsightsModals/DeleteInsightModal/DeleteInsightModal';
import { TTag } from '../../tags/TagsTypes';
import TranslationTabs from '../../../components/TranslationTabs/TranslationTabs';
import TagsInput from '../../../components/TagsInput/TagsInput';
import CompanySelector from '../../../components/Forms/CompanySelector/CompanySelector';
import nullOrAlt from '../../../helpers/nullOrAlt';
import { enableForRoles } from '../../../helpers/role';
import { selectAuthenticatedUser } from '../../auth/AuthSlice';
import { UserRoles } from '../../../constants/userRoles';
import { TCompany } from '../../collective/companies/CompanyType';
import {
  asyncActions as adminUserAdminAsyncActions,
  selectors as adminUserSelectors,
} from '../../adminUsers/AdminUsersAdminSlice';
import { asyncActions as companiesAsyncActions } from '../../collective/companies/AllCompaniesSlice';

const { TextArea } = Input;

function emptyTranslations() {
  return Config.I18N.LOCALES.reduce((acc, current) => {
    acc[current] = '';
    return acc;
  }, {} as { [key: string]: string });
}

function EditInsight() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: insightId } = useParams();

  const isExistingInsight = useMemo(() => insightId !== 'new', [insightId]);
  const [tagList, setTagList] = useState<TTag[]>([]);
  // selectors
  const currentInsight = useSelector(selectors.currentItem);
  const statuses = useSelector(selectors.statuses);
  const lang = useSelector(selectCurrentLang);
  const languages = Config.I18N.LOCALES;
  const [selectedInsightLanguage, setSelectedInsightLanguage] = useState(
    lang || Config.I18N.DEFAULT_LANGUAGE,
  );

  // state
  const [name, setName] = useState(emptyTranslations());
  const [shortDescription, setShortDescription] = useState(emptyTranslations());

  const [content, setContent] = useState(emptyTranslations());
  const [editorContent, setEditorContent] = useState('');

  const [titleImage, setTitleImage] = useState(null as string | File | null);
  const [tags, setTags] = useState<number[]>([]);
  const [imageBase64, setImageBase64] = useState(null as string | null);
  const [createdAt, setCreatedAt] = useState(null as string | null);
  const [updatedAt, setUpdatedAt] = useState(null as string | null);
  const [companyIds, setCompanyIds] = useState<number[]>([]);
  const [companyOnly, setCompanyOnly] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [somethingChanged, setChangedFlag] = useState(false);

  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const adminCompanies = useSelector(adminUserSelectors.listData);

  // effects
  useEffect(() => {
    if (enableForRoles(authenticatedUser.role, [UserRoles.COMPANY_MANAGER])) {
      dispatch<any>(adminUserAdminAsyncActions.fetchList());
    } else {
      dispatch<any>(companiesAsyncActions.fetchList());
    }
    return () => {
      clearValues();

      dispatch(actions.clearCurrentItem());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.clearCurrentItem());

    if (insightId && isExistingInsight) {
      dispatch<any>(asyncActions.get({ id: +insightId }));
    }
  }, [dispatch, insightId]);

  useEffect(() => {
    if (statuses.create === RequestStatuses.SUCCESS && currentInsight?.id) {
      navigate(`/insights/${currentInsight.id}`);

      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  const clearValues = () => {
    setTitleImage(null);
    setContent(emptyTranslations());
    setName(emptyTranslations());
    setShortDescription(emptyTranslations());
    setImageBase64(null);
    setCreatedAt(null);
    setUpdatedAt(null);
    setTags([]);
    setCompanyIds([]);
    setCompanyOnly(false);
    setEditorContent('');
  };

  // map existing values
  useEffect(() => {
    if (currentInsight) {
      setTitleImage(currentInsight.titleImage);
      setImageBase64(currentInsight?.titleImage || null);
      const cAtDate = new Date(currentInsight?.createdAt || '');
      const uAtDate = new Date(currentInsight?.lastUpdatedAt || '');
      setCreatedAt(
        `${cAtDate.toLocaleDateString()} ${cAtDate.toLocaleTimeString()}`,
      );
      setUpdatedAt(
        `${uAtDate.toLocaleDateString()} ${uAtDate.toLocaleTimeString()}`,
      );
      setName(
        currentInsight.insightTranslations.reduce((acc, current) => {
          acc[current.language] = current.name;
          return acc;
        }, {} as { [key: string]: string }),
      );
      setTags(currentInsight.tags.map((aTag) => aTag.id));
      setEditorContent(
        currentInsight.insightTranslations.find(
          (aInsightTranslation) =>
            aInsightTranslation.language === selectedInsightLanguage,
        )?.content || '',
      );
      setContent(
        currentInsight.insightTranslations.reduce((acc, current) => {
          acc[current.language] = current.content;
          return acc;
        }, {} as { [key: string]: string }),
      );
      setShortDescription(
        currentInsight.insightTranslations.reduce((acc, current) => {
          acc[current.language] = current.description;
          return acc;
        }, {} as { [key: string]: string }),
      );
      setCompanyIds(currentInsight.companies?.map((c) => c.id) || []);
      setCompanyOnly(currentInsight.isCompanyOnly);
    } else {
      clearValues();
    }
  }, [currentInsight, insightId]);

  const formDisabled = !isActionAvailable(
    authenticatedUser?.role,
    adminCompanies?.list?.map((company: TCompany) => company.id),
    currentInsight?.companies?.map((company) => company.id),
  );

  const onBack = (locationState: string = '') =>
    navigate('/insights', {
      state: {
        search: location.state as string,
        state: locationState,
      },
    });

  const onCancelPress = () => {
    if (somethingChanged) {
      setShowConfirmModal(true);
    } else {
      onBack();
    }
  };

  const validateFields = () => {
    let allFilled = true;
    [titleImage].forEach((f) => {
      allFilled = allFilled && !!f;
    });
    if (tags.length === 0) allFilled = false;
    languages.forEach((aLang) => {
      if (
        name[aLang] === '' ||
        (selectedInsightLanguage === aLang
          ? editorContent === ''
          : content[aLang] === '')
      )
        allFilled = false;
    });

    return allFilled;
  };

  const onSave = (status: EInsightStatuses) => {
    if (!validateFields()) return;

    const formData = new FormData();

    const insightFields = {
      isCompanyOnly: companyOnly,
      companiesIds: companyIds,
      tagIds: tags,
      ...(typeof titleImage === 'string' ? {} : { titleImage }),
      insightTranslations: languages.map((aLang) => {
        return {
          language: aLang.toLowerCase(),
          name: name[aLang],
          description: shortDescription[aLang],
          content:
            aLang === selectedInsightLanguage ? editorContent : content[aLang],
        };
      }),
    };
    Object.keys(insightFields).forEach((k: string) => {
      const field = k as keyof typeof insightFields;
      const value = insightFields[field];
      if (field === 'tagIds') {
        insightFields.tagIds.forEach((aTagId, index) =>
          formData.append(`${k}[${index}]`, aTagId.toString()),
        );
      } else if (field === 'insightTranslations') {
        insightFields.insightTranslations.forEach(
          (aInsightTranslation, index) =>
            formData.append(
              `${k}[${index}]`,
              JSON.stringify(aInsightTranslation),
            ),
        );
      } else if (field === 'companiesIds') {
        if (!insightFields.isCompanyOnly) {
          insightFields.companiesIds.forEach((c) => {
            formData.append('companiesIds[]', c.toString());
          });
        }
      } else if (value) {
        formData.append(k, value as unknown as string);
      }
    });

    if (currentInsight?.id) {
      const { id } = currentInsight;
      switch (status) {
        case EInsightStatuses.ACTIVE:
          dispatch<any>(asyncActions.updateAndPublish({ id, data: formData }));
          break;
        case EInsightStatuses.INACTIVE:
          dispatch<any>(asyncActions.unpublish({ item: currentInsight }));
          break;
        case EInsightStatuses.DRAFT:
          dispatch<any>(
            asyncActions.updateAndUnpublish({ id, data: formData }),
          );
          break;
        default:
          break;
      }
    } else {
      switch (status) {
        case EInsightStatuses.ACTIVE:
          dispatch<any>(asyncActions.createAndPublish({ data: formData }));
          break;
        default:
          dispatch<any>(asyncActions.create({ data: formData }));
          break;
      }
    }
    onBack('published');
    clearValues();
  };

  const onUnPublish = () => {
    if (currentInsight) {
      onSave(EInsightStatuses.INACTIVE);
    }
  };

  const onLanguageTab = (appLanguage: string) => {
    setSelectedInsightLanguage(appLanguage);
    setContent({
      ...content,
      [selectedInsightLanguage]: editorContent,
    });
    setEditorContent(content[appLanguage]);
  };

  const disablePublishButton = !!(
    currentInsight &&
    [EInsightStatuses.DRAFT, EInsightStatuses.INACTIVE].indexOf(
      currentInsight.status,
    ) === -1
  );

  // components
  const headerMenu = (
    <Menu className={styles.dropdown}>
      <Menu.Item
        key={Math.random()}
        onClick={onUnPublish}
        disabled={!currentInsight || !disablePublishButton}
      >
        {t('generic.buttons.archive')}
      </Menu.Item>
      <DeleteInsightModal
        insightName={name[selectedInsightLanguage]}
        onClick={() => {
          if (currentInsight) {
            dispatch<any>(asyncActions.delete({ item: currentInsight }));
            onBack();
          }
        }}
      >
        <Menu.Item
          className={styles.deleteButton}
          key={Math.random()}
          disabled={!currentInsight}
        >
          {t('generic.buttons.delete')}
        </Menu.Item>
      </DeleteInsightModal>
    </Menu>
  );

  return (
    <>
      <ProPageHeader
        prefixedClassName={styles.pageHeader}
        title={
          insightId
            ? t('insights.edit.header.edit')
            : t('insights.edit.header.create')
        }
        extra={[
          <Button
            className={styles.defaultButton}
            onClick={() => onCancelPress()}
          >
            {t('generic.modals.buttons.cancel')}
          </Button>,
          <Tooltip
            placement="bottom"
            title={
              currentInsight && currentInsight.status !== EInsightStatuses.DRAFT
                ? t('generic.forms.saveAsDraftTooltip')
                : ''
            }
          >
            <Button
              className={styles.defaultButton}
              disabled={!validateFields()}
              onClick={() => onSave(EInsightStatuses.DRAFT)}
            >
              {t('generic.forms.saveAsDraft')}
            </Button>
          </Tooltip>,
          <ActivateInsightModal
            insightName={name[selectedInsightLanguage]}
            onClick={() => {
              onSave(EInsightStatuses.ACTIVE);
            }}
          >
            <Button type="primary" disabled={!validateFields()}>
              {t('generic.publish')}
            </Button>
          </ActivateInsightModal>,
          <Dropdown overlay={headerMenu} placement="bottomRight">
            <Button style={{ width: '32px', padding: 0 }}>...</Button>
          </Dropdown>,
        ]}
        onBack={() => {}}
        backIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ margin: 0, padding: 0 }}
            onClick={() => onCancelPress()}
          />
        }
      />
      <div className={styles.formContainer}>
        <FormCard className={styles.formCard}>
          <Form layout="vertical">
            <TranslationTabs
              selectedLanguage={selectedInsightLanguage}
              onLanguageTab={onLanguageTab}
            />
            <FormTitle>{t('insights.edit.title') || ''}</FormTitle>
            <Form.Item
              className={styles.formItem}
              label={t('insights.edit.insightName')}
            >
              <Input
                onChange={(e) => {
                  setName({
                    ...name,
                    [selectedInsightLanguage]: e.target.value,
                  });
                  setChangedFlag(true);
                }}
                placeholder={t('insights.edit.insightName') || ''}
                value={name[selectedInsightLanguage]}
              />
            </Form.Item>
            <Form.Item
              className={styles.formItem}
              label={t('insights.edit.tags')}
            >
              <TagsInput
                selectedInsightLanguage={selectedInsightLanguage}
                tags={tags}
                tagList={tagList}
                emptyTranslations={emptyTranslations}
                setTags={setTags}
                setTagList={setTagList}
              />
            </Form.Item>
            <Form.Item
              className={styles.formItem}
              label={t('insights.companySelector.title')}
            >
              <CompanySelector
                onSelectCompanies={(companyList, companiesOnly) => {
                  setCompanyIds(companyList);
                  setCompanyOnly(companiesOnly);
                }}
                selectedCompanyIds={nullOrAlt(
                  companyIds,
                  currentInsight?.companies?.map((c) => c.id) || [],
                )}
                onlySelected={enableForRoles(authenticatedUser.role, [
                  UserRoles.COMPANY_MANAGER,
                ])}
                disabled={formDisabled}
              />
            </Form.Item>
            <Form.Item
              className={styles.formItem}
              label={t('insights.shortDescription.title')}
            >
              <TextArea
                onChange={(e) => {
                  setShortDescription({
                    ...shortDescription,
                    [selectedInsightLanguage]: e.target.value,
                  });
                  setChangedFlag(true);
                }}
                autoSize={{ minRows: 4, maxRows: 10 }}
                showCount
                maxLength={150}
                value={shortDescription[selectedInsightLanguage]}
                placeholder={t('insights.shortDescription.placeholder') || ''}
              />
            </Form.Item>
            <div className={styles.formItem}>
              <ImageUploadForm
                onFileChanged={(image, base64) => {
                  setImageBase64(base64);
                  setTitleImage(image);
                }}
                tooltip={t('insights.image.tooltip')}
                currentImage={imageBase64}
              />
            </div>
            <Form.Item
              className={styles.formItem}
              label={t('insights.body.title')}
            >
              <CkEditor
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorContent(data);
                  setChangedFlag(true);
                }}
                currentData={editorContent}
              />
            </Form.Item>
          </Form>
        </FormCard>
        {currentInsight && (
          <Col className={styles.insightDataColumn}>
            <div>{t('insights.edit.forms.insightInfo')}</div>
            <div className={styles.dataBlock}>
              <div>{t('insights.currentStatus.title')}</div>
              {getInsightStatusRendered(currentInsight, t)}
            </div>
            <div className={styles.dataBlock}>
              <div>{t('insights.id.title')}</div>
              <div className={styles.dataBlockValue}>{currentInsight?.id}</div>
            </div>
            <div className={styles.dataBlock}>
              <div>{t('insights.created.title')}</div>
              <div className={styles.dataBlockValue}>{createdAt}</div>
            </div>
            <div className={styles.dataBlock}>
              <div>{t('insights.updated.title')}</div>
              <div className={styles.dataBlockValue}>{updatedAt}</div>
            </div>
          </Col>
        )}
      </div>
      <RouteLeavingGuard
        lastLocation="/insights"
        show={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </>
  );
}

export default EditInsight;
