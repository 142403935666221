import buildSlice from '../../general/helpers/genericSliceBuilder';
import {
  TCompany,
  TCompaniesListResponse,
  TCompaniesResponse,
} from './CompanyType';
import {
  createCompany,
  deleteCompany,
  fetchCompanies,
  getCompany,
  updateCompany,
  activeCompany,
  desactiveCompany,
  validate,
} from './CompaniesApi';
import { TListParams } from '../../../helpers/apiRequest';

const companiesSlice = buildSlice<
  TCompany,
  TCompaniesResponse,
  TCompaniesListResponse
>('companies', {
  createJson: createCompany as (data: { [key: string]: any }) => Promise<any>,
  delete: deleteCompany,
  fetchList: fetchCompanies,
  getOne: getCompany,
  updateJson: updateCompany as (
    id: number,
    data: { [key: string]: any },
  ) => Promise<any>,
  publish: activeCompany,
  unpublish: desactiveCompany,
  validate: validate as (data: { [key: string]: any }) => Promise<any>,
});

export const { selectors, asyncActions, actions } = companiesSlice;

export default companiesSlice.reducer;
