import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal, { ModalTypes } from '../../BasicModal/BasicModal';

type TDeleteChallengeModalProps = {
  challengeTitle: string;
  children: JSX.Element;
  onClick: () => void;
  type: string;
};

function DeleteChallengeModal({
  challengeTitle,
  children,
  onClick,
  type,
}: TDeleteChallengeModalProps) {
  const { t } = useTranslation();

  const config = {
    title: type === 'quiz' ? t('quizzes.modal.delete.title') : t('challenges.modal.delete.title'),
    okText: t('generic.modals.buttons.delete'),
    cancelText: t('generic.modals.buttons.cancel'),
    content: type === 'quiz' ? t('quizzes.modal.delete.content', {quizTitle: challengeTitle} ) : t('challenges.modal.delete.content', { challengeTitle }),
  };

  return (
    <BasicModal
      modalButton={children}
      onClick={onClick}
      modalConfig={config}
      type={ModalTypes.CONFIRM_DANGER}
    />
  );
}

export default DeleteChallengeModal;
