import { fetchActiveChallenges } from './UsersApi';
import {
  TActiveChallenge,
  TActiveChallengeListResponse,
  TActiveChallengeResponse,
} from './UsersTypes';
import buildSlice from '../general/helpers/genericSliceBuilder';

const activeChallengesSlice = buildSlice<
  TActiveChallenge,
  TActiveChallengeResponse,
  TActiveChallengeListResponse
>('activeChallenges', {
  fetchList: fetchActiveChallenges,
});

export const { selectors, asyncActions, actions } = activeChallengesSlice;

export default activeChallengesSlice.reducer;
