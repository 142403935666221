import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './BulkActions.module.scss';

type TBulkActionsProps = {
  dropdownOptions: {
    key: string;
    text: string;
    action: () => void;
    modal?: (
      children: React.ReactElement,
      action: () => void,
    ) => React.ReactElement;
  }[];
  selectedKeys: string[];
  initialKey?: string;
};

function BulkActions({
  dropdownOptions,
  selectedKeys,
  initialKey,
}: TBulkActionsProps) {
  const { t } = useTranslation();

  const [bulkOption, setBulkOption] = useState(
    initialKey || dropdownOptions[0].key,
  );

  const onBulkOperation = () => {
    dropdownOptions.find((dO) => dO.key === bulkOption)?.action();
  };

  const dropdownItems = dropdownOptions.map((item) => (
    <Menu.Item key={item.key}> {item.text}</Menu.Item>
  ));

  const currentModal = dropdownOptions.find(
    (dO) => dO.key === bulkOption,
  )?.modal;

  return (
    <div className={styles.bulkMenu}>
      <div className={styles.bulkMenuCounter}>
        {t('generic.bulk.selected', { length: selectedKeys.length })}
      </div>
      <Dropdown
        className={styles.bulkMenuDropdown}
        overlay={
          <Menu onClick={(e) => setBulkOption(e.key)}>{dropdownItems}</Menu>
        }
      >
        <Button>
          {dropdownOptions.find((o) => o.key === bulkOption)?.text}{' '}
          <DownOutlined />
        </Button>
      </Dropdown>
      {currentModal ? (
        currentModal(
          <Button type="primary">{t('generic.confirm')}</Button>,
          onBulkOperation,
        )
      ) : (
        <Button type="primary" onClick={onBulkOperation}>
          {t('generic.confirm')}
        </Button>
      )}
    </div>
  );
}

export default BulkActions;
