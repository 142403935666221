import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import styles from './NavMenuBase.module.scss';
import { IMenuItem } from './NavMenuBaseTypes';

const { SubMenu, Item } = Menu;

interface NavMenuProps {
  items: IMenuItem[];
  selectedCategory: string | null;
  selectedItem: string | null;
  onSwitchMode: () => void;
  collapsed: boolean;
}

function NavMenuBase({
  items,
  selectedCategory,
  selectedItem,
  onSwitchMode,
  collapsed,
}: NavMenuProps) {
  const [openCategory, setOpenCategory] = useState(null as string | null);

  useEffect(() => {
    setOpenCategory(openCategory);
  }, [selectedCategory]);

  
  return (
    <Menu
      className={styles.menu}
      mode="inline"
      selectedKeys={[selectedCategory || '', selectedItem || '']}
      openKeys={
        (openCategory && !collapsed && [openCategory]) ||
        (selectedCategory && selectedItem ? [selectedCategory] : undefined)
      }
    >
      {items.map((item) =>
        item.categoryItems ? (
          <SubMenu
            key={item.key}
            icon={item.icon}
            className={styles.menuSub}
            title={item.category}
            onTitleClick={() => setOpenCategory(item.key)}
          >
            {item.categoryItems.map((catItem) => (
              <Item key={catItem.key} className={styles.menuItem}>
                <Link to={catItem.link}>{catItem.name}</Link>
              </Item>
            ))}
          </SubMenu>
        ) : (
          <Item
            key={item.key}
            icon={item.icon}
            onClick={() => setOpenCategory(item.key)}
          >
            <Link to={item.link || ''}>{item.category}</Link>
          </Item>
        ),
      )}
      <Item
        className={styles.hideButton}
        icon={<MenuFoldOutlined />}
        onClick={onSwitchMode}
      />
    </Menu>
  );
}

export default NavMenuBase;
