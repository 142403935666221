import React, { useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import { Button, Dropdown, Layout, Menu, Select } from 'antd';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { Config } from '../../config/config';
import {
  removeToken,
  selectAuthenticatedUser,
} from '../../features/auth/AuthSlice';

const { Header } = Layout;
const { Option } = Select;

type TMainHeaderProps = {
  selectedLocale?: string;
  onSelectLanguage?: (lang: string) => void;
};

function MainHeader({ selectedLocale, onSelectLanguage }: TMainHeaderProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const logout = useCallback(() => {
    dispatch(removeToken());
  }, [dispatch]);

  return (
    <Header className={styles.header}>
      <Link to="/" className={styles.logoContainer}>
        <ReactSVG className={styles.logo} src="/assets/general/logo.svg" />
        <div className={styles.logoText}>Vitaance app</div>
      </Link>
      <div className={styles.buttonContainer}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item icon={<LogoutOutlined />} onClick={logout}>
                {t('generic.logOut')}
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <Button
            className={styles.headerButton}
            icon={<SettingOutlined />}
            type="text"
          />
        </Dropdown>
        <Button className={styles.userButton} type="text">
          <img
            className={styles.userImg}
            src="/assets/user/avatar-placeholder.png"
            alt="avatar"
          />
          <div className={styles.userName}>
            {authenticatedUser.firstName} {authenticatedUser.lastName}
          </div>
        </Button>
        <Select
          className={styles.langSelector}
          defaultValue={Config.I18N.DEFAULT_LANGUAGE}
          value={
            selectedLocale ||
            Config.I18N.DEFAULT_LANGUAGE ||
            Config.I18N.LOCALES[0]
          }
          onSelect={onSelectLanguage}
        >
          {Config.I18N.LOCALES.map((loc) => (
            <Option value={loc}>{loc.toUpperCase()}</Option>
          ))}
        </Select>
      </div>
    </Header>
  );
}

MainHeader.defaultProps = {
  selectedLocale: undefined,
  onSelectLanguage: () => {},
};

export default MainHeader;
