import React, { useEffect, useState } from 'react';
import {
  Form, Input, Button, Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import BackgroundWave from '../../../components/BackgoundWave/BackgroundWave';
import SideBackgroundImage from '../../../components/SideBackgroudImage/SideBackgroundImage';
import CentralCard from '../../../components/CentralCard/CentralCard';
import styles from './SetNewPassword.module.scss';
import { resetPasswordAsync, selectResetPasswordStatus } from '../AuthSlice';
import requestStatuses from '../../../constants/requestStatuses';
import { ROUTES } from '../../../constants/routes';

const { Item } = Form;

function SetNewPassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useParams() as { token: string };
  const navigate = useNavigate();

  // const window size
  const isMobile = window.screen.width < 600;

  const status = useSelector(selectResetPasswordStatus);

  const [password, setPassword] = useState(null as string | null);
  const [confirmPassword, setConfirmPassword] = useState(null as string | null);
  const [sent, setSent] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  useEffect(() => {
    if (status === requestStatuses.SUCCESS && sent) {
      navigate(ROUTES.setNewPasswordConfirmation);
    }
  });

  const onSend = () => {
    if (token && password && password === confirmPassword) {
      dispatch<any>(resetPasswordAsync({ password, token }));
    }
  };

  const isSubmitted = () => {
    if (
      password === null
      || password === ''
      || password !== confirmPassword
      || !isAgree
    ) return true;
    return false;
  };

  return (
    <div className={styles.container}>
      <BackgroundWave>
        <SideBackgroundImage
          leftImage={!isMobile ? '/assets/auth/fox.svg' : ''}
          rightImage={!isMobile ? '/assets/auth/payment.svg' : ''}
        >
          <div className={isMobile ? styles.wrapMobile : styles.wrap}>
            {!isMobile ? (
              <Link to="/" className={styles.logoContainer}>
                <ReactSVG
                  className={styles.logo}
                  src="/assets/general/logo.svg"
                />
                <div className={styles.logoText}>Vitaance app</div>
              </Link>
            ) : (
              <Link to="/" className={styles.logoContainerMobile}>
                <ReactSVG
                  className={styles.logoMobile}
                  src="/assets/auth/logoMobile.svg"
                />
              </Link>
            )}
            <CentralCard
              className={
                isMobile ? styles.centralCardMobile : styles.centralCard
              }
            >
              <>
                <div className={styles.title}>{t('setNewPassword.title')}</div>
                <div className={styles.helpText}>
                  {t('setNewPassword.helpText')}
                </div>
                <Form className={styles.form} layout="vertical">
                  <Item label={t('auth.newPassword')}>
                    <Input.Password
                      className={styles.input}
                      placeholder={t('auth.inputPassword') || ''}
                      onChange={(e) => setPassword(e.target.value)}
                      onPaste={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      required
                    />
                  </Item>
                  <Item label={t('auth.confirmPassword')}>
                    <Input.Password
                      className={styles.input}
                      placeholder={t('auth.inputPassword') || ''}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onPaste={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      required
                    />
                  </Item>
                  {status === requestStatuses.FAILED && (
                    <div className={styles.errorMessage}>
                      {t('auth.expiredLink')}
                    </div>
                  )}
                  {confirmPassword && confirmPassword !== password && (
                    <div className={styles.errorMessage}>
                      {t('auth.passwordMatchError')}
                    </div>
                  )}
                  <Item>
                    <Checkbox onChange={(e) => setIsAgree(e.target.checked)}>
                      <span className={styles.termsAndConditionsLabel}>
                        {t('generic.legalData.agree')}
                        {' '}
                        <a
                          className={styles.termsAndConditionsLink}
                          href="https://www.vitaance.com/aviso-legal"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('generic.legalData.TermsOfService')}
                        </a>
                        {' '}
                        {t('generic.legalData.and')}
                        {' '}
                        <a
                          className={styles.termsAndConditionsLink}
                          href="https://www.vitaance.com/politica-de-privacidad-vitaance"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('generic.privacyPolicy')}
                        </a>
                      </span>
                    </Checkbox>
                  </Item>
                  <Item>
                    <Button
                      onClick={() => {
                        setSent(true);
                        onSend();
                      }}
                      htmlType="submit"
                      className={
                        isSubmitted()
                          ? styles.myDisabledButton
                          : styles.signInButton
                      }
                      type="primary"
                      disabled={isSubmitted()}
                    >
                      <span style={{ color: '#fff' }}>
                        {t('setNewPassword.button')}
                      </span>
                    </Button>
                  </Item>
                </Form>
              </>
            </CentralCard>
            <div
              className={isMobile ? styles.legalBlockMobile : styles.legalBlock}
            >
              <div className={styles.questions}>
                {t('generic.haveQuestions')}
                {' '}
                <a
                  href="https://www.vitaance.com/sobre-nosotros"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>{t('generic.visitWeb')}</u>
                </a>
              </div>
              <div>{t('generic.copyright')}</div>
              <div>
                <a href="https://www.vitaance.com/aviso-legal" target="_blank" rel="noreferrer">
                  {t('generic.legalDocs')}
                </a>
                {' '}
                |
                {' '}
                <a
                  href="https://www.vitaance.com/politica-de-privacidad-vitaance"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('generic.privacyPolicy')}
                </a>
              </div>
            </div>
          </div>
        </SideBackgroundImage>
      </BackgroundWave>
    </div>
  );
}

export default SetNewPassword;
