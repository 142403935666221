import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './RouteLeavingGuard.module.scss';

import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';

type TNavigationPromptProps = {
  lastLocation: string | number;
  show: boolean;
  setShowConfirmModal: (isShow: boolean) => void;
};

const RouteLeavingGuard = ({lastLocation, show, setShowConfirmModal}: TNavigationPromptProps) => {
  const { t } = useTranslation();

  const {confirmNavigation, cancelNavigation} = useCallbackPrompt(show, lastLocation)

  return (
    <Modal
      title = {t('generic.modals.continueEditing.title')}
      okText = {t('generic.modals.continueEditing.buttons.continue')}
      cancelText = {t('generic.modals.continueEditing.buttons.discard')}
      children = {
        <div className={styles.container}>
          <span className={styles.text}>{t('generic.modals.continueEditing.content')}</span>
        </div>
      }
      open={show}
      onCancel={() => {
        setTimeout(() => {
          setShowConfirmModal(false)
          confirmNavigation()
        }, 200)
      }}
      onOk={() => {
        setShowConfirmModal(false)
        cancelNavigation()
      }}
    />
  );
}

export default RouteLeavingGuard;
