import { useTranslation } from 'react-i18next';
import {
  Col, Row, Layout, Collapse, Button,
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './YourInsurance.module.scss';
import Progress from '../../../components/Progress/Progress';
import VisitWebsite from '../../../components/VisitWebsite/VisitWebsite';
import LeftRowText from '../../../components/LeftRowText/LeftRowText';
import Heading from '../../../components/Heading/Heading';
import VLogo from '../../../components/VLogo/VLogo';
import { ROUTES } from '../../../constants/routes';
import GrayBox from '../../../components/GrayBox/GrayBox';
import { Config } from '../../../config/config';

// Svg assets
import check from '../../../assets/svg/check.svg';
import two from '../../../assets/svg/twoWhite.svg';
import three from '../../../assets/svg/three.svg';

const { Header } = Layout;
const { Panel } = Collapse;

function YourInsurance(props: Object) {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();

  console.log(props, ' props');
  console.log(location, ' useLocation Hook');
  console.log('state', state);
  const data = location.state as any;

  console.log('guarantees', data?.guarantees);
  console.log('token', data?.token);

  const guarantees = data?.guarantees;
  const activatedAt = data?.activatedAt;

  return (
    <Row className={styles.container}>
      <div className={styles.row}>
        <div className={styles.rowLeft}>
          <Header className={styles.progressPosition}>
            <div className={styles.logoPos}>
              <VLogo />
            </div>
            <Progress
              checkOne={check}
              checkTwo={two}
              checkThree={three}
              one={styles.circularCompleted}
              two={styles.circularActive}
              three={styles.circularUnActive}
              oneText={styles.completedText}
              twoText={styles.activeText}
              threeText={styles.unActiveText}
              numberOne={styles.numberActive}
              numberTwo={styles.numberActive}
              numberThree={styles.numberUnActive}
              lineOne={styles.lineActive}
              lineTwo={styles.line}
            />
          </Header>

          <div className={styles.innerRowLeft}>
            <div>
              {
                   activatedAt != null ? (
                     <div>
                       <Heading
                         heading={`👌 ${t(
                           'auth.yourInsurance.yourInsurance.title',
                         )}`}
                       />

                       <GrayBox>
                         <Collapse defaultActiveKey={['1']}>
                           <Panel
                             header={t('guarantees.toolBarTitle')}
                             key="1"
                           >
                             <div className={styles.guarantees}>
                               {guarantees.map((item: any) => (
                                 <>
                                    <span className={styles.name}>
                                      {item.name}
                                    </span>
                                    <p className={styles.description}>
                                      {item.description}
                                    </p>
                                  </>
                               ))}
                             </div>
                           </Panel>
                         </Collapse>
                       </GrayBox>
                     </div>
                   ) : (
                     <div className={styles.HeadingRes}>
                       <Heading
                         heading={`🚀 ${t(
                           'auth.yourInsurance.yourInsurance.onItsWay',
                         )}`}
                       />

                       <div className={styles.textContainer}>
                         <span className={styles.text}>
                           {t('auth.yourInsurance.yourInsurance.subTitle')}
                         </span>
                       </div>

                       <div className={styles.innerContainer}>
                         <span className={styles.heading}>
                           {t(
                             'auth.yourInsurance.yourInsuranceNotActive.lifeInsurance.title',
                           )}
                         </span>
                         <p className={styles.paragraph}>
                           {t(
                             'auth.yourInsurance.yourInsuranceNotActive.lifeInsurance.text',
                           )}
                         </p>
                         <span className={styles.heading}>
                           {t(
                             'auth.yourInsurance.yourInsuranceNotActive.covered.title',
                           )}
                         </span>
                         <p className={styles.paragraph}>
                           {t(
                             'auth.yourInsurance.yourInsuranceNotActive.covered.text',
                           )}
                         </p>
                       </div>
                     </div>
                   )
}
            </div>

            <Button
              className={styles.button}
              type="link"
              onClick={() => {
                navigate(ROUTES.activateAccount, {
                  state: {
                    guarantees: data?.guarantees,
                    email: data?.email,
                    token: data?.token,
                  },
                });
              }}
            >
              {t('auth.yourInsurance.yourInsurance.button')}
            </Button>
          </div>

          <div className={styles.footer}>
            <VisitWebsite />
            <div className={styles.footerContainer}>
              <p className={styles.policy}>{t('generic.copyright')}</p>
              <p>
                <a href={Config.LEGAL} target="_blank" rel="noreferrer">
                  <span>{t('generic.legalDocs')}</span>
                </a>
                {' '}
                |
                {' '}
                <a href={Config.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                  <span>{t('generic.privacyPolicy')}</span>
                </a>
              </p>
            </div>
          </div>
        </div>

        <Col className={styles.rowRight}>
          <LeftRowText
            textPos={styles.textPos}
            textStyle={styles.textStyle}
            text={t('auth.yourInsurance.yourInsurance.rightText')}
          />
        </Col>
      </div>
    </Row>
  );
}

export default YourInsurance;
