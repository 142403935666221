export const ROUTES = {
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password/:token',
  userResetPassword: '/auth/user-reset-password/:token',
  setNewPassword: '/auth/set-new-password/:token',
  setNewPasswordConfirmation: '/auth/set-new-password-confirmation/:token',
  setAppNewPassword: '/auth/set-employee-password/:token',
  setAppNewPasswordConfirmation:
    '/auth/set-employee-password-confirmation/:token',
  dashboard: '/',
  onboarding: '/auth/onboarding/:token',
  confirmData: '/auth/confirm-data',
  dateOfBirth: '/auth/date-of-birth',
  yourInsurance: '/auth/your-insurance',
  activateAccount: '/auth/activate-account',
  finish: '/auth/finish',
  alreadyRegistered: '/auth/already-registered',
  invitationExpired: '/auth/invitation-expired',
  redirectAppStores: '/redirect-app-stores',
};
