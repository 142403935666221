import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Input, Menu, Select, Tree } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  GlobalOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Key } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { omit, uniqBy } from 'lodash';
import { actions, asyncActions, selectors } from '../ChallengesSlice';
import styles from './ChallengeList.module.scss';
import { challengeGoalMap, challengeTypeMap } from './ChallengeListHelpers';
import { EChallengeStatuses, TTableData, TTypesElements } from './ChallengeListTypes';
import { EChallengeTypes, IChallenge } from '../ChallengesTypes';
import DeleteChallengeModal from '../../../components/Modals/ChallengesModals/DeleteChallengeModal/DeleteChallengeModal';
import PageHeaderDefault from '../../../components/PageHeaderDefault/PageHeaderDefault';
import RequestStatuses from '../../../constants/requestStatuses';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import useQuery from '../../../helpers/useQuery';
import Table from '../../../components/Table/Table';
import RoleWrapper from '../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../constants/userRoles';
import ChallengeStatusBadge from '../../../components/Badge/ChallengeStatusBadge/ChallengeStatusBadge';
import { isActionAvailable } from '../ChallengesHelpers';
import { selectAuthenticatedUser } from '../../auth/AuthSlice';
import {
  asyncActions as adminUserAsyncActions,
  selectors as adminUserSelectors,
} from '../../adminUsers/AdminUsersAdminSlice';
import { TCompany } from '../../collective/companies/CompanyType';
import { TCategory } from '../../categories/CategoriesTypes';
import EChallengeAssignmentTypes from '../EditChallenge/constants/challengeAssignmentTypes';
import { selectCurrentLang } from '../../i18n/i18nSlice';

const { Search } = Input;

function ChallengeList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useQuery();

  // selectors
  const challengesData = useSelector(selectors.listData);
  const filterData = useSelector(selectors.filterData) as
    | { categories: TCategory[] }
    | undefined;
  const statuses = useSelector(selectors.statuses);
  const bulkChallenges = useSelector(selectors.bulkItems);
  const actionChallenge = useSelector(selectors.actionItems);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const adminCompanies = useSelector(adminUserSelectors.listData);

  // list values
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [bulkOption, setBulkOption] = useState('activate');

  let selectedChallenges =
    challengesData.list?.filter((ch) =>
      selectedIds.includes(`challenge-${ch.id}`),
    ) || [];

  const filteredList = challengesData.list;

  const assignChallengeTypeFilter = () => {
    dispatch(
      actions.setFilter({
        field: 'entity',
        value: 'challenge',
      }),
    );
  }

  // effects
  useLayoutEffect(() => {
    dispatch(actions.clearFilters());
    dispatch<any>(adminUserAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';

      dispatch(
        actions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    if (query.has('companies')) {
      dispatch(actions.clearFilters());

      const filterCompanies = (query.get('companies') || '').split(',');

      for (const company of filterCompanies) {
        dispatch(
          actions.setFilter({
            field: 'company',
            value: company,
          }),
        );
      }
    } else {
      dispatch(actions.clearFilters());
    }

    if (query.has('entity')) {
      const filterEntities = (query.get('entity') || '').split(',');

      for (const entity of filterEntities) {
        dispatch(
          actions.setFilter({
            field: 'entity',
            value: entity,
          }),
        );
      }
    }

    if (query.has('category')) {
      const filterCategories = (query.get('category') || '').split(',');

      for (const category of filterCategories) {
        dispatch(
          actions.setFilter({
            field: 'category',
            value: category,
          }),
        );
      }
    }

    if (query.has('goalType')) {
      const filterGoalTypes = (query.get('goalType') || '').split(',');

      for (const goalType of filterGoalTypes) {
        dispatch(
          actions.setFilter({
            field: 'goalType',
            value: goalType,
          }),
        );
      }
    }

    if (query.has('status')) {
      const filterStatuses = (query.get('status') || '').split(',');

      for (const status of filterStatuses) {
        dispatch(
          actions.setFilter({
            field: 'status',
            value: status,
          }),
        );
      }
    }

    if (query.has('type')) {
      const filterTypes = (query.get('type') || '').split(',');

      for (const type of filterTypes) {
        dispatch(
          actions.setFilter({
            field: 'type',
            value: type,
          }),
        );
      }
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearOrder());
    }

    assignChallengeTypeFilter()
    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (challengesData.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, challengesData]);

  // status popup
  const bulkPopup = (
    status: RequestStatuses | null | undefined,
    challenges: IChallenge[],
    operation: string,
    success?: boolean,
  ) => {
    const { length } = challenges;
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        length > 1
          ? t('challenge.alerts.bulkPopupPrural', { length, operation })
          : t('challenge.alerts.bulkPopupSingular', { length, operation }),
      );
      dispatch(actions.clearStatuses());
    }
    assignChallengeTypeFilter()
  };
  const singlePopup = (
    status: RequestStatuses | null | undefined,
    challenge: IChallenge | null,
    operation: string,
    success?: boolean,
  ) => {
    if (status === RequestStatuses.SUCCESS) {
      ActionAlert[success ? 'success' : 'info'](
        t('challenge.alerts.singualarPopup', {
          title: challenge?.title,
          operation,
        }),
      );
      dispatch(actions.clearStatuses());
    }
    assignChallengeTypeFilter()
  };
  useEffect(() => {
    bulkPopup(
      statuses.deleteBulk,
      uniqBy([...bulkChallenges.delete], 'id'),
      t('generic.operations.deleted'),
    );
  }, [statuses]);
  useEffect(() => {
    bulkPopup(
      statuses.publishBulk,
      uniqBy([...bulkChallenges.publish], 'id'),
      t('generic.operations.activated'),
      true,
    );
  }, [statuses]);
  useEffect(() => {
    bulkPopup(
      statuses.unpublishBulk,
      uniqBy([...bulkChallenges.unpublish], 'id'),
      t('generic.operations.deactivated'),
    );
  }, [statuses]);
  useEffect(
    () =>
      singlePopup(
        statuses.delete,
        actionChallenge.delete,
        t('generic.operations.deleted'),
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.publish,
        actionChallenge.publish,
        t('generic.operations.activated'),
        true,
      ),
    [statuses],
  );
  useEffect(
    () =>
      singlePopup(
        statuses.unpublish,
        actionChallenge.unpublish,
        t('generic.operations.deactivated'),
      ),
    [statuses],
  );

  useEffect(() => setSelectedIds([]), [statuses]);

  // events
  const onDelete = (id: number) => () => {
    const challenge = challengesData.list?.find((ch) => ch.id === id);
    if (challenge) {
      dispatch<any>(asyncActions.delete({ item: challenge }));
    }
  };
  const onCopy = (id: number) => () => console.log('Copy', id);
  const onEdit = (item: any) => () =>
    navigate(`/challenges/biometrics/${item.id}?${query.toString()}`,
    );

  const onBulkOperation = () => {
    switch (bulkOption) {
      case 'delete': {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedChallenges }));
        setSelectedIds([]);
        break;
      }
      case 'activate': {
        selectedChallenges = selectedChallenges.filter((challenge) => {
          if (
            (challenge.publishDate !== null &&
              challenge.unpublishDate !== null) ||
            challenge.publishDate === null
          ) {
            return challenge;
          }
          return null;
        });
        dispatch<any>(asyncActions.publishBulk({ items: selectedChallenges }));
        break;
      }
      case 'deactivate': {
        selectedChallenges = selectedChallenges.filter(
          (challenge) => challenge.status.title !== EChallengeStatuses.INACTIVE,
        );
        dispatch<any>(asyncActions.unpublishBulk({ items: selectedChallenges }));
        break;
      }
    }
  };

  const actionsBlock = (item: any) => {
    const canPerformActions = isActionAvailable(
      authenticatedUser?.role,
      adminCompanies?.list?.map((company: TCompany) => company.id),
      item.companiesIds,
    );

    let challengeActions = [
      { icon: <EditOutlined />, action: onEdit(item), primary: true },
      { icon: <CopyOutlined />, action: onCopy(item.id) },
    ];

    if (!canPerformActions) {
      challengeActions = [{ icon: <EyeOutlined />, action: onEdit(item) }];
    }

    return (
      <div className={styles.tableActions}>
        {challengeActions.map((b) => (
          <div
            key={Math.random()}
            className={`${styles.actionButton} ${
              b.primary ? styles.actionButtonPrimary : ''
            }`}
            onClick={b.action}
          >
            {b.icon}
          </div>
        ))}
        {canPerformActions &&
          <DeleteChallengeModal
            type='challenge'
            challengeTitle={item.title}
            onClick={onDelete(item.id)}
          >
            <div className={styles.actionButton}>
              <DeleteOutlined />
            </div>
          </DeleteChallengeModal>
        }
      </div>
    );
  };

  const language = useSelector(selectCurrentLang);


  const tableData = (filteredList || []).map((item) => {
    const entity = 'challenge';

    return {
      key: `${entity}-${item.id}`,
      id: item.id,
      title: item.challengeTranslations?.find(i => i.language === language)?.title || item.title,
      goalType: challengeGoalMap(item.goalType, t),
      type: challengeTypeMap(item.type),
      status: item.status.title,
      actions: actionsBlock({
        ...item,
        entity,
      }),
      entity,
    } as TTableData;
  });

  const columns = [
    {
      title: t('challenges.challengeName'),
      dataIndex: 'title',
      width: '30%',
      render: (title: string, record: TTableData) => {
        return <a onClick={onEdit(record)}>{title}</a>;
      },
      sorter: true,
      ...(query.has('sort') &&
      ['title', '-title'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-title' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('challenges.goal'),
      dataIndex: 'goalType',
      filters: [
        { value: 'POINTS', text: t('challenges.points') },
        { value: 'DISTANCE', text: t('generic.distance') },
        { value: 'CALORIES', text: t('generic.caloriesFull') },
        { value: 'DURATION', text: t('generic.duration') },
        { value: 'STEPS', text: t('generic.steps') },
      ],
      ...(query.has('goalType')
        ? {
            defaultFilteredValue: query.get('goalType')?.split(','),
          }
        : {}),
    },
    {
      title: t('challenges.assignment'),
      dataIndex: 'type',
      filters: [
        {
          value: EChallengeTypes.PERSONAL,
          text: t('challenges.form.individual'),
        },
        { value: EChallengeTypes.TEAM, text: t('challenges.form.team') },
      ],
      filterMultiple: false,
      ...(query.has('type')
        ? {
            defaultFilteredValue: query.get('type')?.split(','),
          }
        : {}),
      render: (type: keyof TTypesElements) => {
        const typeMap = {
          Individual: (
            <div>
              <UserOutlined className={styles.typeIcon} />
              Individual
            </div>
          ),
          Global: (
            <div>
              <GlobalOutlined className={styles.typeIcon} />
              Global
            </div>
          ),
          Team: (
            <div>
              <TeamOutlined className={styles.typeIcon} />
              Team
            </div>
          ),
        };

        return typeMap[type];
      },
    },
    {
      title: t('challenges.status'),
      dataIndex: 'status',
      filters: [
        { value: EChallengeStatuses.DRAFT, text: t('generic.draft') },
        { value: EChallengeStatuses.UPCOMING, text: t('generic.upcoming') },
        { value: EChallengeStatuses.ONGOING, text: t('generic.ongoing') },
        { value: EChallengeStatuses.FINISHED, text: t('generic.finished') },
        { value: EChallengeStatuses.INACTIVE, text: t('generic.inactive') },
      ],
      render: (status: EChallengeStatuses) => (
        <ChallengeStatusBadge status={status} />
      ),
      ...(query.has('status')
        ? {
            defaultFilteredValue: query.get('status')?.split(','),
          }
        : {}),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      width: 100,
    },
  ];
  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };

  const dropdownOptions = [
    { key: 'activate', text: t('generic.activate') },
    { key: 'deactivate', text: t('generic.deactivate') },
    { key: 'delete', text: t('generic.delete') },
  ];
  const dropdownItems = dropdownOptions.map((item) => (
    <Menu.Item key={item.key}> {item.text}</Menu.Item>
  ));

  return (
    <div
      key={`challenges-list-${
        omit(Array.from(query.keys()), ['companies']).length
      }`}
    >
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('nav.category.wellness')]: '/challenges',
          [t('nav.item.challenges')]: '',
        }}
        title={t('nav.item.challenges')}
      />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>
          {t('challenges.toolBarTitle')}
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.companiesFilterLabel}>
            {t('challenges.assignedTo')}
          </div>
          <Select
            className={styles.companiesField}
            value={t(
              `challenges.assignments.${
                query.has('companies') ? 'companies' : 'everyone'
              }`,
            )}
            dropdownRender={() => (
              <Tree
                checkable
                defaultExpandAll
                selectable={false}
                checkedKeys={
                  query.has('companies')
                    ? query
                        .get('companies')
                        ?.split(',')
                        .map((key: string) => +key)
                    : [EChallengeAssignmentTypes.ALL]
                }
                treeData={[
                  {
                    title: t('challenges.assignments.everyone'),
                    key: EChallengeAssignmentTypes.ALL,
                  },
                  {
                    title: t('challenges.assignments.companies'),
                    key: EChallengeAssignmentTypes.COMPANIES_ONLY,
                    children:
                      adminCompanies?.list?.map((company) => ({
                        title: company.companyName,
                        key: company.id,
                      })) || [],
                  },
                ]}
                onCheck={(checkedKeys: any, e: any) => {
                  const isEveryoneOptionSelected =
                    e.checked && e.node.key === EChallengeAssignmentTypes.ALL;

                  if (!checkedKeys.length || isEveryoneOptionSelected) {
                    query.delete('companies');
                  } else {
                    query.set(
                      'companies',
                      checkedKeys
                        .filter(
                          (key: string | number) => typeof key === 'number',
                        )
                        .join(','),
                    );
                  }

                  navigate({
                    search: `?${query.toString()}`,
                  });
                }}
              />
            )}
          />
          <Search
            allowClear
            defaultValue={query.get('search') || ''}
            className={styles.searchField}
            placeholder={t('challenges.placeholder.searchChallenge') || ''}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                challengesData.listParams?.pagination?.perPage
                  ? challengesData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
          />
          <RoleWrapper roles={[UserRoles.ADMIN]}>
            <Button
              className={styles.manageButton}
              onClick={() =>
                navigate(`/categories/challenges?${query.toString()}`)
              }
            >
              {t('generic.manageCategories')}
            </Button>
          </RoleWrapper>
          <Button type="primary" onClick={() => navigate('/challenges/biometrics/new')}>
            {t('challenges.createChallenge')}
          </Button>
        </div>
      </div>
      {!!selectedIds.length && (
        <div className={styles.bulkMenu}>
          <div className={styles.bulkMenuCounter}>
            {t('generic.bulk.selected', { length: selectedIds.length })}
          </div>
          <Dropdown
            className={styles.bulkMenuDropdown}
            overlay={
              <Menu onClick={(e) => setBulkOption(e.key)}>{dropdownItems}</Menu>
            }
          >
            <Button>
              {dropdownOptions.find((o) => o.key === bulkOption)?.text}{' '}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button type="primary" onClick={onBulkOperation}>
            {t('generic.confirm')}
          </Button>
        </div>
      )}
      <Table
        actions={actions}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: challengesData.listParams?.pagination?.total,
          current: challengesData.listParams?.pagination?.page,
        }}
      />
    </div>
  );
}

export default ChallengeList;
