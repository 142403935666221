import { TCompany } from '../collective/companies/CompanyType';
import { IPagination } from '../general/GeneralTypes';
import { TTag } from '../tags/TagsTypes';

export type TInsight = {
  id: number;
  status: EInsightStatuses;
  insightTranslations: TInsightTranslation[];
  tags: TTag[];
  companies?: TCompany[];
  isCompanyOnly: boolean;
  titleImage: string;
  createdAt: string;
  lastUpdatedAt: string;
};

export type TInsightTranslation = {
  id: number;
  createdAt: string;
  lastUpdatedAt: string;
  language: string;
  name: string;
  description: string;
  content: string;
};

export type TInsightFilled = TInsight;

export type TSingleInsightResponse = {
  statusCode: number;
  message?: string[];
  error?: string;
  success?: boolean;
  data: TInsight;
};

export type TInsightsResponse = {
  data: TInsightFilled[];
  pagination: IPagination;
};

export enum EInsightStatuses {
  DRAFT = 'draft',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
