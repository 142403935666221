import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styles from './MembersList.module.scss';
import { Badge, Col, Input, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import UserForm from '../../../users/UserForm/UserForm';
import { enableForRoles } from '../../../../helpers/role';
import { UserRoles } from '../../../../constants/userRoles';
import { PlanColors } from '../../../../constants/planColors';
import { selectAuthenticatedUser } from '../../../auth/AuthSlice';
import { TUser } from '../../../users/UsersTypes';
import {
  asyncActions as usersAsyncActions,
  selectors as usersSelectors,
} from '../../../users/UsersSlice';
import { selectors, actions, asyncActions } from '../MembersSlice';
import Table from '../../../../components/Table/Table';
import { TMember } from '../MembersType';
import PageHeaderDefault from '../../../../components/PageHeaderDefault/PageHeaderDefault';
import { useNavigate } from 'react-router-dom';
import { TCompany } from '../../companies/CompanyType';
import {
  asyncActions as companiesAsyncActions,
  selectors as companiesSelectors,
} from '../../companies/AllCompaniesSlice';
import {
  asyncActions as adminUserAdminAsyncActions,
  selectors as adminUserAdminSelectors,
} from '../../../adminUsers/AdminUsersAdminSlice';

type TTableData = {
  key: number;
  productId: number;
  employeeId: number;
  employeeName: string;
  companyName: string;
  productPlan: string;
  productName: string;
  employeeAddedAt: string;
  policyStatus: string;
  actions: null;
};

const badgeColors: any = {
  Basic: PlanColors.BASIC,
  Flexible: PlanColors.FLEXIBLE,
  Individual: PlanColors.INDIVIDUAL,
};

function MembersList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [userEditWindow, setUserEditWindow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null as number | null);

  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const membersData = useSelector(selectors.listData);
  const selectedUser = useSelector(usersSelectors.currentItem);
  const allCompanies = useSelector(companiesSelectors.listData);
  const adminCountries = useSelector(adminUserAdminSelectors.listData);

  const companies = enableForRoles(authenticatedUser.role as UserRoles, [
    UserRoles.COMPANY_MANAGER,
  ])
    ? adminCountries?.list
    : allCompanies?.list;

  useEffect(() => {
    dispatch(actions.setSearch({ query: '' }));
    dispatch(actions.clearFilters());
    dispatch(actions.setOrder({ field: 'employeeAddedAt', direction: 'desc' }));
    dispatch(actions.setPagination({ page: 1, perPage: 10 }));
    dispatch<any>(asyncActions.fetchList());

    if (
      enableForRoles(authenticatedUser.role as UserRoles, [
        UserRoles.COMPANY_MANAGER,
      ])
    ) {
      dispatch<any>(adminUserAdminAsyncActions.fetchList());
    } else {
      dispatch<any>(companiesAsyncActions.fetchList());
    }
  }, [dispatch]);

  const onUserDelete = (id: TUser['id']) => () => {
    if (selectedUser) {
      dispatch<any>(usersAsyncActions.delete({ item: selectedUser }));
      dispatch<any>(asyncActions.fetchList());
    }
  };

  const onEdit = (userId: number) => {
    setUserEditWindow(true);
    setSelectedUserId(userId);
  };

  const columns = [
    {
      title: t('collective.members.id'),
      dataIndex: 'employeeId',
    },
    {
      title: t('collective.members.memberName'),
      dataIndex: 'employeeName',
      render: (_: null, record: TTableData) => {
        return (
          <a onClick={() => onEdit(record.employeeId)}>{record.employeeName}</a>
        );
      },
    },
    {
      title: t('collective.members.companyName'),
      dataIndex: 'companyId',
      filters: companies?.map((company: TCompany) => {
        return {
          value: company.id,
          text: company.companyName,
        };
      }),
      render: (_: string, record: TTableData) => {
        return record.companyName || 'N/A';
      },
    },
    {
      title: t('collective.members.plan'),
      dataIndex: 'plan',
      filters: [
        { value: 'Basic', text: t('collective.members.basic') },
        { value: 'Flexible', text: t('collective.members.flexible') },
        { value: 'Individual', text: t('collective.members.individual') },
      ],
      render: (_: null, record: TTableData) => {
        return (
          <Tag color={badgeColors[record.productPlan]} key={record.productPlan}>
            {record.productPlan}
          </Tag>
        );
      },
    },
    {
      title: t('collective.members.products'),
      dataIndex: 'productName',
    },
    {
      title: t('collective.members.additionDate'),
      dataIndex: 'employeeAddedAt',
      width: 150,
      sorter: true,
    },
    {
      title: t('collective.members.policyStatus'),
      dataIndex: 'policyStatus',
      filters: [
        { value: 'pending', text: t('generic.pending') },
        { value: 'active', text: t('generic.active') },
        { value: 'inactive', text: t('generic.inactive') },
      ],
      render: (_: string, record: TTableData) => {
        let badgeColor = '#BDC0C1';

        switch (record.policyStatus) {
          case 'pending':
            badgeColor = 'yellow';
            break;
          case 'active':
            badgeColor = 'green';
            break;
        }

        return (
          <Badge
            color={badgeColor}
            text={t(`generic.${record.policyStatus}`)}
          />
        );
      },
    },
  ];

  if (enableForRoles(authenticatedUser.role as UserRoles, [UserRoles.ADMIN])) {
    columns.push({
      title: t('collective.members.actions'),
      dataIndex: 'actions',
      width: 100,
      filters: [],
      className: styles.actionColumn,
      render: (_: string, record: TTableData) => {
        return (
          <div className={styles.tableActions}>
            <div
              className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
              onClick={() => onEdit(record.employeeId)}
            >
              <EditOutlined />
            </div>
          </div>
        );
      },
    } as any);
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <PageHeaderDefault
            routes={{
              [t('nav.category.home')]: '/',
              [t('nav.category.insurance')]: '/members',
              [t('nav.item.allMembers')]: '',
            }}
            title={t('nav.item.allMembers')}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row gutter={32} align="middle" className={styles.searchBarContainer}>
            <Col span={18}>
              <div className={styles.title}>{t('collective.members.all')}</div>
            </Col>
            <Col span={6}>
              <Input.Search
                allowClear
                className={styles.searchField}
                placeholder={t('collective.members.search') || ''}
                onSearch={(value: string) => {
                  dispatch(
                    actions.setPagination({
                      page: 1,
                      perPage:
                        membersData.listParams?.pagination?.perPage || 10,
                    }),
                  );
                  dispatch(actions.setSearch({ query: value }));
                  dispatch<any>(asyncActions.fetchList());
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                actions={actions}
                asyncActions={asyncActions}
                defaultOrder={{ field: 'employeeAddedAt', direction: 'desc' }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: [],
                }}
                dataSource={
                  membersData?.list?.map((member: TMember) => {
                    return {
                      ...member,
                      key: `${member.employeeId}-${member.productId}`,
                      employeeAddedAt: dayjs(member.employeeAddedAt).format(
                        'DD/MM/YYYY',
                      ),
                    };
                  }) || []
                }
                columns={columns}
                pagination={{
                  total: membersData.listParams?.pagination?.total,
                  current: membersData.listParams?.pagination?.page,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <UserForm
        currentUser={selectedUserId}
        show={userEditWindow}
        onClose={() => {
          setUserEditWindow(false);
          setSelectedUserId(null);

          dispatch<any>(asyncActions.fetchList());
        }}
        onDelete={onUserDelete}
      />
    </div>
  );
}

export default MembersList;
