import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TProduct, TUpdateEmployeeCertificate, TUpdatePolicies } from './ProductsType';
import {
  activatePoliciesRequest,
  deactivatePoliciesRequest,
  deleteEmployeeCertificateRequest,
  getProductsByEmployees,
} from './ProductsApi';

interface CompanyProductsByEmployeesState {
  products: {
    data: TProduct[];
    pagination: {
      page: number;
      perPage: number;
      total: number;
    };
    outdated: boolean;
  };
  policiesStatus: {
    activate: string;
    deactivate: string;
  };
  processedPolicies: {
    updated: TProduct[];
    ignored: TProduct[];
  };
}

const initialState: CompanyProductsByEmployeesState = {
  products: {
    data: [],
    pagination: {
      page: 1,
      perPage: 10,
      total: 0,
    },
    outdated: false,
  },
  policiesStatus: {
    activate: 'pending',
    deactivate: 'pending',
  },
  processedPolicies: {
    updated: [],
    ignored: [],
  },
};

export const getCompanyProductsByEmployees = createAsyncThunk(
  'companyProductsByEmployees',
  async (data: any) => getProductsByEmployees(data.companyId, data),
);

export const activatePolicies = createAsyncThunk(
  'activatePolicies',
  async (data: TUpdatePolicies) => activatePoliciesRequest(data),
);

export const deactivatePolicies = createAsyncThunk(
  'deactivatePolicies',
  async (data: TUpdatePolicies) => deactivatePoliciesRequest(data),
);

export const deleteEmployeeCertificate = createAsyncThunk(
  'deleteEmployeeCertificate',
  async (data: TUpdateEmployeeCertificate) => deleteEmployeeCertificateRequest(data),
);

const companyProductsByEmployeesSlice = createSlice({
  name: 'companyProductsByEmployees',
  initialState,
  reducers: {
    clearStatuses: (state) => {
      state.policiesStatus = initialState.policiesStatus;
    },
    clearProcessedPolicies: (state) => {
      state.processedPolicies = initialState.processedPolicies;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCompanyProductsByEmployees.fulfilled,
      (state, action) => {
        const {
          offset = 0,
          limit = 10,
          total = 10,
        } = action.payload.pagination || {};

        state.products = {
          ...initialState.products,
          ...action.payload,
          pagination: { page: offset / limit + 1, perPage: limit, total },
        };
      },
    );
    builder.addCase(activatePolicies.fulfilled, (state, action) => {
      state.policiesStatus = {
        deactivate: 'pending',
        activate: 'success',
      };

      state.processedPolicies = action.payload.data;

      state.products.outdated = true;
    });
    builder.addCase(activatePolicies.rejected, (state) => {
      state.policiesStatus = {
        deactivate: 'pending',
        activate: 'failed',
      };
      state.processedPolicies = initialState.processedPolicies;
    });
    builder.addCase(deactivatePolicies.fulfilled, (state, action) => {
      state.policiesStatus = {
        activate: 'pending',
        deactivate: 'success',
      };
      state.processedPolicies = action.payload.data;
      state.products.outdated = true;
    });
    builder.addCase(deactivatePolicies.rejected, (state) => {
      state.policiesStatus = {
        activate: 'pending',
        deactivate: 'failed',
      };
      state.processedPolicies = initialState.processedPolicies;
    });
    builder.addCase(
      deleteEmployeeCertificate.fulfilled,
      (state, action) => {
        state.products.data = state.products.data.map((item: TProduct) =>
          (item.employeeId === action.payload.userId ? { ...item, pathToFile: null } : item));
      },
    );
  },
});

export const { clearStatuses, clearProcessedPolicies } = companyProductsByEmployeesSlice.actions;

export const selectProducts = (state: any) =>
  state.companyProductsByEmployees.products;
export const selectStatuses = (state: any) =>
  state.companyProductsByEmployees.policiesStatus;
export const selectProcessedPolicies = (state: any) =>
  state.companyProductsByEmployees.processedPolicies;

export default companyProductsByEmployeesSlice.reducer;
