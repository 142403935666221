import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Dropdown, Input, Menu, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'antd/es/table/interface';
import { Link, useNavigate } from 'react-router-dom';
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { actions, asyncActions, selectors } from '../RewardsSlice';
import { EAssigmentType, ERewardStatuses, TReward } from '../RewardsTypes';
import DeleteRewardModal from '../../../components/Modals/RewardsModals/DeleteRewardModal/DeleteRewardModal';
import styles from './RewardsList.module.scss';
import PageHeaderDefault from '../../../components/PageHeaderDefault/PageHeaderDefault';
import ActionAlert from '../../../components/ActionAlert/ActionAlert';
import RequestStatuses from '../../../constants/requestStatuses';
import {
  getRewardStatus,
  getRewardStatusRendered,
  isActionAvailable,
} from '../RewardsHelpers';
import Table from '../../../components/Table/Table';
import RoleWrapper from '../../../components/RoleWrapper/RoleWrapper';
import { UserRoles } from '../../../constants/userRoles';
import { selectAuthenticatedUser } from '../../auth/AuthSlice';
import { TCompany } from '../../collective/companies/CompanyType';
import {
  asyncActions as adminUserAsyncActions,
  selectors as adminUserSelectors,
} from '../../adminUsers/AdminUsersAdminSlice';
import useQuery from '../../../helpers/useQuery';

const { Search } = Input;

type TTableData = {
  key: Key;
  id: TReward['id'];
  image: TReward['image'];
  name: TReward['name'];
  price: TReward['price'];
  totalPointsNeeded: TReward['totalPointsNeeded'];
  location: TReward['addressFormatted'];
  actions: null;
  status: string;
  assignmentTypeTitle: EAssigmentType;
  companiesIds: number[] | undefined;
  createdAt: string;
  updatedAt: string;
};

function RewardsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useQuery();

  // selectors
  const rewardsData = useSelector(selectors.listData);
  const statuses = useSelector(selectors.statuses);
  const bulkRewards = useSelector(selectors.bulkItems);
  const actionRewards = useSelector(selectors.actionItems);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const adminCompanies = useSelector(adminUserSelectors.listData);
  // effects
  useLayoutEffect(() => {
    dispatch(actions.clearFilters());
    dispatch<any>(adminUserAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    const page = query.get('page') || 1;
    const perPage = query.get('perPage') || 10;

    dispatch(
      actions.setPagination({
        page: +page,
        perPage: +perPage,
      }),
    );

    dispatch(actions.setSearch({ query: query.get('search') || '' }));

    if (query.has('sort')) {
      const sortOrder = query.get('sort') || '';

      dispatch(
        actions.setOrder({
          field: sortOrder.startsWith('-') ? sortOrder.substring(1) : sortOrder,
          direction: sortOrder.startsWith('-') ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(actions.clearOrder());
    }

    if (query.has('status')) {
      const filterStatus = (query.get('status') || '').split(',');

      for (const status of filterStatus) {
        dispatch(
          actions.setFilter({
            field: 'status',
            value: status,
          }),
        );
      }
    }

    if (!Array.from(query.keys()).length) {
      dispatch(actions.clearFilters());
      dispatch(actions.clearOrder());
    }

    dispatch<any>(asyncActions.fetchList());
  }, [query]);

  useEffect(() => {
    if (rewardsData?.outdated) {
      dispatch<any>(asyncActions.fetchList());
    }
  }, [dispatch, rewardsData]);

  // status popup
  useEffect(() => {
    const { length } = bulkRewards.delete;
    if (statuses.deleteBulk === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        length > 1
          ? t('rewards.alerts.bulkPopupPrural', {
              length,
              operation: t('generic.operations.deleted'),
            })
          : t('rewards.alerts.bulkPopupSingular', {
              length,
              operation: t('generic.operations.deleted'),
            }),
      );
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);
  useEffect(() => {
    const { length } = bulkRewards.publish;
    if (statuses.publishBulk === RequestStatuses.SUCCESS) {
      ActionAlert.success(
        length > 1
          ? t('rewards.alerts.bulkPopupPrural', {
              length,
              operation: t('generic.operations.publish'),
            })
          : t('rewards.alerts.bulkPopupSingular', {
              length,
              operation: t('generic.operations.publish'),
            }),
      );
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);
  useEffect(() => {
    const { length } = bulkRewards.unpublish;
    if (statuses.unpublishBulk === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        length > 1
          ? t('rewards.alerts.bulkPopupPrural', {
              length,
              operation: t('generic.operations.archived'),
            })
          : t('rewards.alerts.bulkPopupSingular', {
              length,
              operation: t('generic.operations.archived'),
            }),
      );
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);
  useEffect(() => {
    const reward = actionRewards.delete;
    if (statuses.delete === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        t('rewards.alerts.singualarPopup', {
          title: reward.name,
          operation: t('generic.operations.deleted'),
        }),
      );
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);
  useEffect(() => {
    const reward = actionRewards.publish;
    if (statuses.publish === RequestStatuses.SUCCESS) {
      ActionAlert.success(
        t('rewards.alerts.singualarPopup', {
          title: reward.name,
          operation: t('generic.operations.publish'),
        }),
      );
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);
  useEffect(() => {
    const reward = actionRewards.unpublish;
    if (statuses.unpublish === RequestStatuses.SUCCESS) {
      ActionAlert.info(
        t('rewards.alerts.singualarPopup', {
          title: reward.name,
          operation: t('generic.operations.archived'),
        }),
      );
      dispatch(actions.clearStatuses());
    }
  }, [statuses]);

  useEffect(() => {
    setSelectedIds([]);
  }, [statuses]);

  // state
  const [selectedIds, setSelectedIds] = useState([] as Key[]);
  const [bulkOption, setBulkOption] = useState('publish');

  const onManageClaimed = () => {
    dispatch(actions.clearCurrentItem());

    navigate('/rewards/claimed');
  };

  const onCopy = (id: TReward['id']) => () => {
    // TODO: implement copy
  };
  const onDelete = (id: TReward['id']) => () => {
    const reward = rewardsData.list?.find((a) => a.id === id);
    if (reward) {
      dispatch<any>(asyncActions.delete({ item: reward }));
    }
  };

  // table data
  const rowSelection = {
    selectedRowKeys: selectedIds as Key[],
    onChange: (selectedRows: Key[]) => setSelectedIds(selectedRows),
  };
  const actionsBlock = (item: TTableData) => {
    const canPerformActions = isActionAvailable(
      authenticatedUser?.role,
      adminCompanies?.list?.map((company: TCompany) => company.id),
      item.companiesIds,
      item?.assignmentTypeTitle,
    );

    if (canPerformActions) {
      return (
        <div className={styles.tableActions}>
          <Link
            to={`/rewards/${item.id}?${query.toString()}`}
            className={`${styles.actionButton} ${styles.actionButtonPrimary}`}
          >
            <EditOutlined />
          </Link>
          {[{ icon: <CopyOutlined />, action: onCopy(item.id) }].map((b) => (
            <div className={styles.actionButton} onClick={b.action}>
              {b.icon}
            </div>
          ))}
          <DeleteRewardModal rewardName={item.name} onClick={onDelete(item.id)}>
            <div className={styles.actionButton}>
              <DeleteOutlined />
            </div>
          </DeleteRewardModal>
        </div>
      );
    }

    return null;
  };

  const tableData: TTableData[] =
    rewardsData.list?.map((item) => ({
      key: item.id,
      id: item.id,
      image: item.image,
      name: item.name,
      price: item.price,
      totalPointsNeeded: item.totalPointsNeeded,
      location: item.addressFormatted,
      actions: null,
      status: getRewardStatus(item),
      companiesIds: item?.companies?.map((company: TCompany) => company.id),
      assignmentTypeTitle: item.assignmentType.title,
      createdAt: new Date(item.createdAt).toLocaleDateString(),
      updatedAt: new Date(item.updatedAt).toLocaleDateString(),
    })) || [];
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      ...(query.has('sort') &&
      ['id', '-id'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-id' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('generic.image'),
      dataIndex: 'image',
      render: (_: string, record: TTableData) => (
        <img
          className={styles.titleImage}
          src={record.image || '/assets/rewards/title-photo-placeholder.png'}
          alt={record.name}
        />
      ),
    },
    {
      title: t('rewards.rewardName'),
      dataIndex: 'name',
      render: (_: string, record: TTableData) => (
        <Link
          to={`/rewards/${record.id}?${query.toString()}`}
          className={styles.rewardTitle}
        >
          {record.name.length > 30 ? (
            <Tooltip placement="top" title={record.name}>{`${record.name.slice(
              0,
              30,
            )}...`}</Tooltip>
          ) : (
            record.name
          )}
        </Link>
      ),
      sorter: true,
      ...(query.has('sort') &&
      ['name', '-name'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-name' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('generic.points'),
      dataIndex: 'price',
      sorter: true,
      ...(query.has('sort') &&
      ['price', '-price'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-price' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('rewards.unlockPoints'),
      dataIndex: 'totalPointsNeeded',
      sorter: true,
      ...(query.has('sort') &&
      ['totalPointsNeeded', '-totalPointsNeeded'].includes(
        query.get('sort') as string,
      )
        ? {
            defaultSortOrder:
              query.get('sort') === '-totalPointsNeeded' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('generic.creationDate'),
      dataIndex: 'createdAt',
      sorter: true,
      ...(query.has('sort') &&
      ['createdAt', '-createdAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-createdAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('generic.lastUpdate'),
      dataIndex: 'updatedAt',
      sorter: true,
      ...(query.has('sort') &&
      ['updatedAt', '-updatedAt'].includes(query.get('sort') as string)
        ? {
            defaultSortOrder:
              query.get('sort') === '-updatedAt' ? 'descend' : 'ascend',
          }
        : {}),
    },
    {
      title: t('generic.status'),
      dataIndex: 'status',
      render: (_: string, record: TTableData) => {
        const reward = rewardsData.list?.find((it) => it.id === record.key);
        return reward ? getRewardStatusRendered(reward, t) : <div />;
      },
      filters: [
        { value: ERewardStatuses.ACTIVE, text: t('generic.active') },
        { value: ERewardStatuses.DRAFT, text: t('generic.draft') },
        { value: ERewardStatuses.INACTIVE, text: t('generic.inactive') },
      ],
      ...(query.has('status')
        ? {
            defaultFilteredValue: query.get('status')?.split(','),
          }
        : {}),
    },
    {
      title: t('generic.actions'),
      dataIndex: 'actions',
      render: (_: null, record: TTableData) => actionsBlock(record),
    },
  ];

  // bulk menu dropdown
  const dropdownOptions = [
    { key: 'publish', text: t('generic.publish') },
    { key: 'archive', text: t('generic.deactivate') },
    { key: 'delete', text: t('generic.delete') },
  ];
  const dropdownItems = dropdownOptions.map((item) => (
    <Menu.Item key={item.key}> {item.text}</Menu.Item>
  ));
  const onBulkOperation = () => {
    let selectedRewards =
      rewardsData.list?.filter(({ id }) => selectedIds.indexOf(id) !== -1) ||
      [];
    switch (bulkOption) {
      case 'delete': {
        dispatch<any>(asyncActions.deleteBulk({ items: selectedRewards }));
        setSelectedIds([]);
        break;
      }
      case 'publish': {
        selectedRewards = selectedRewards.filter((reward) => {
          if (
            (reward.publishDate !== null && reward.unpublishDate !== null) ||
            reward.publishDate === null
          ) {
            return reward;
          }
          return null;
        });
        dispatch<any>(asyncActions.publishBulk({ items: selectedRewards }));
        break;
      }
      case 'archive': {
        selectedRewards = selectedRewards.filter(
          (reward) => reward.createdAt && reward.unpublishDate === null,
        );
        dispatch<any>(asyncActions.unpublishBulk({ items: selectedRewards }));
        break;
      }
      default: {
        // do nothing
      }
    }
    setSelectedIds([]);
  };

  return (
    <div key={`rewards-list-${Array.from(query.keys()).length}`}>
      <ActionAlert />
      <PageHeaderDefault
        routes={{
          [t('nav.category.home')]: '/',
          [t('nav.category.wellness')]: '/rewards',
          [t('nav.item.rewards')]: '',
        }}
        title={t('nav.item.rewards')}
      />
      <div className={styles.toolBar}>
        <div className={styles.toolBarTitle}>{t('rewards.rewardsCreated')}</div>
        <div className={styles.buttonContainer}>
          <Search
            allowClear
            defaultValue={query.get('search') || ''}
            className={styles.searchField}
            placeholder={String(t('rewards.search'))}
            onSearch={(val) => {
              if (val) {
                query.set('search', val);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              query.set(
                'perPage',
                rewardsData.listParams?.pagination?.perPage
                  ? rewardsData.listParams?.pagination?.perPage.toString()
                  : '10',
              );

              navigate({
                search: `?${query.toString()}`,
              });
            }}
          />
          <RoleWrapper roles={[UserRoles.ADMIN, UserRoles.COMPANY_MANAGER]}>
            <Button className={styles.manageButton} onClick={onManageClaimed}>
              {t('rewards.manageClaimedRewarsButtom')}
            </Button>
          </RoleWrapper>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/rewards/new')}
          >
            {t('rewards.createRewardButton')}
          </Button>
        </div>
      </div>
      {!!selectedIds.length && (
        <div className={styles.bulkMenu}>
          <div className={styles.bulkMenuCounter}>
            {t('generic.bulk.selected', { length: selectedIds.length })}
          </div>
          <Dropdown
            className={styles.bulkMenuDropdown}
            overlay={
              <Menu onClick={(e) => setBulkOption(e.key)}>{dropdownItems}</Menu>
            }
          >
            <Button>
              {dropdownOptions.find((o) => o.key === bulkOption)?.text}{' '}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button type="primary" onClick={onBulkOperation}>
            {t('generic.confirm')}
          </Button>
        </div>
      )}
      <Table
        actions={actions}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: rewardsData.listParams?.pagination?.total,
          current: rewardsData.listParams?.pagination?.page,
        }}
      />
    </div>
  );
}

export default withTranslation()(RewardsList);
