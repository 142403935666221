import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import styles from './ConfirmData.module.scss';
import StaticInput from '../../../components/StaticInput/StaticInput';
import LargeButton from '../../../components/LargeButton/LargeButton';
import Progress from '../../../components/Progress/Progress';
import Heading from '../../../components/Heading/Heading';
import VisitWebsite from '../../../components/VisitWebsite/VisitWebsite';
import { Config } from '../../../config/config';
import ContactLink from '../../../components/ContactLink/ContactLink';
import LeftRowText from '../../../components/LeftRowText/LeftRowText';
import VLogo from '../../../components/VLogo/VLogo';

// Svg assets
import one from '../../../assets/svg/one.svg';
import two from '../../../assets/svg/two.svg';
import three from '../../../assets/svg/three.svg';
import characters from '../../../assets/svg/characters.svg';

function ConfirmData(props: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state as any;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.rowLeft}>
          <div className={styles.logoPos}>
            <VLogo />
          </div>
          <div className={styles.progressPosition}>
            <Progress
              checkOne={one}
              checkTwo={two}
              checkThree={three}
              one={styles.circularActive}
              two={styles.circularUnActive}
              three={styles.circularUnActive}
              oneText={styles.activeText}
              twoText={styles.unActiveText}
              threeText={styles.unActiveText}
              numberOne={styles.numberActive}
              numberTwo={styles.numberUnActive}
              numberThree={styles.numberUnActive}
              lineOne={styles.line}
              lineTwo={styles.line}
            />
          </div>
          <div className={styles.innerRowLeft}>
            {data?.dob !== null ? (
              <>
                <div className={styles.HeadingRes}>
                  <Heading
                    heading={`${t('auth.confirmData.confirmData.title')}`}
                  />
                </div>
                <div className={styles.margin}>
                  <span className={styles.label}>{t('auth.confirmData.confirmData.name')}</span>
                  <StaticInput text={`${data.firstName} ${data.lastName}`} />
                </div>

                <div className={styles.margin}>
                  <span className={styles.label}>{t('auth.confirmData.confirmData.dob')}</span>
                  <StaticInput text={data.dob} />
                </div>

                <div className={styles.align}>
                  <LargeButton
                    text={t('auth.confirmData.confirmData.button')}
                    onCLick={() => {
                      data.guarantees?.length > 0
                        ? navigate(ROUTES.yourInsurance, {
                          state: {
                            guarantees: data?.guarantees,
                            email: data?.email,
                            token: data?.token,
                            activatedAt: data?.activatedAt,
                          },
                        })
                        : navigate(ROUTES.activateAccount, {
                          state: {
                            guarantees: data?.guarantees,
                            email: data?.email,
                            token: data?.token,
                          },
                        });
                    }}
                  />
                </div>

                <div className={styles.margin}>
                  <ContactLink message={t('auth.confirmData.confirmData.contact')} />
                </div>
              </>
            ) : (
              <>
                <div className={styles.HeadingRes_dob}>
                  <Heading
                    heading={`${t(
                      'auth.confirmData.confirmData.titleTwo',
                    )}`}
                  />
                </div>
                <div className={styles.margin}>
                  <span className={styles.label}>
                    {t('auth.confirmData.confirmData.nameAndSurname')}
                  </span>
                  <StaticInput text={`${data.firstName} ${data.lastName}`}/>
                </div>

                <div className={styles.align}>
                  <LargeButton
                    text={t('auth.confirmData.confirmData.button')}
                    onCLick={() =>
                      navigate(ROUTES.dateOfBirth, {
                        state: {
                          guarantees: data?.guarantees,
                          email: data?.email,
                          token: data?.token,
                        },
                      })}
                  />
                </div>

                <div className={styles.margin}>
                  <ContactLink message={t('auth.confirmData.confirmData.contact')} />
                </div>
              </>
            )}

            <div className={styles.footer}>
              <VisitWebsite />
              <div className={styles.footerContainer}>
                <p className={styles.policy}>{t('generic.copyright')}</p>
                <p className={styles.copyrightUnderline}>
                  <a href={Config.LEGAL} target="_blank" rel="noreferrer">
                    <span>{t('generic.legalDocs')}</span>
                  </a>
                  {' | '}
                  <a href={Config.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                    <span>{t('generic.privacyPolicy')}</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rowRight}>
          <LeftRowText
            textPos={styles.textPos}
            textStyle={styles.textStyle}
            text={t('auth.onboarding.rightColumnText')}
          />
          <img src={characters} style={{ width: '90%' }} />
        </div>
      </div>
    </div>
  );
}

export default ConfirmData;
