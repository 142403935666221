import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ContinueEditingModal from '../../../../../components/Modals/ContinueEditingModal/ContinueEditingModal';
import { EChallengeStatuses } from '../../../ChallengeList/ChallengeListTypes';
import PublishChallengeModal from '../../../../../components/Modals/ChallengesModals/PublishChallengeModal/PublishChallengeModal';
import DeleteChallengeModal from '../../../../../components/Modals/ChallengesModals/DeleteChallengeModal/DeleteChallengeModal';
import { EChallengeTypes, IChallenge } from '../../../ChallengesTypes';
import DeactivateOngoingChallengeModal from '../../../../../components/Modals/ChallengesModals/DeactivateOngoingChallengeModal/DeactivateOngoingChallengeModal'; // eslint-disable-next-line max-len
import UpdateOngoingChallengeModal from '../../../../../components/Modals/ChallengesModals/UpdateOngoingChallengeModal/UpdateOngoingChallengeModal';
import styles from './HeaderButtons.module.scss';
import Update48hChallengeModal from '../../../../../components/Modals/ChallengesModals/update48hChallengeModal/Update48hChallengeModal';
import { IQuiz } from '../../../../quizzes/QuizzesTypes';

type THeaderButtonsProps = {
  challengeType: 'quiz' | 'biometrics';
  isDirty: boolean;
  isValid: boolean;
  onBack: () => void;
  onSave: (publish?: boolean) => void;
  onUnpublish: () => void;
  onPublish: () => void;
  onDraft: () => void;
  currentChallenge: IChallenge | IQuiz | null;
  onDelete: () => void;
  startDate?: string | null;
};

function HeaderButtons({
  challengeType,
  onSave,
  onBack,
  isDirty,
  isValid,
  currentChallenge,
  onUnpublish,
  onPublish,
  onDelete,
  onDraft,
  startDate,
}: THeaderButtonsProps) {
  const { t } = useTranslation();

  const startDayLess48h =
    currentChallenge && ('startDate' in (currentChallenge || {}) || startDate)
      ? dayjs(startDate || (currentChallenge as IChallenge).startDate).diff(
          dayjs(),
          'hours',
        ) <= 48
      : false;

  const cancelButton = isDirty ? (
    <ContinueEditingModal onClick={onBack}>
      <Button
        type="link"
        className={`${styles.button} ${styles.defaultButton}`}
      >
        {t('generic.modals.buttons.cancel')}
      </Button>
    </ContinueEditingModal>
  ) : (
    <Button
      type="link"
      className={`${styles.button} ${styles.defaultButton}`}
      onClick={onBack}
    >
      {t('generic.modals.buttons.cancel')}
    </Button>
  );

  const saveAsDraftButton = (
    <Button
      className={`${styles.button} ${isValid ? styles.defaultButton : ''}`}
      disabled={!isValid}
      onClick={() => onSave()}
    >
      {t('generic.forms.saveAsDraft')}
    </Button>
  );

  const publishButton = (
    <PublishChallengeModal
      type={challengeType}
      challengeName={currentChallenge?.title || ''}
      onClick={() => onSave(true)}
    >
      <Button className={styles.button} type="primary" disabled={!isValid}>
        {challengeType === 'quiz'
          ? t('quizzes.buttons.publish')
          : t('challenges.buttons.publish')}
      </Button>
    </PublishChallengeModal>
  );

  const saveChangesButton = (
    <Button
      className={`${styles.button} ${styles.defaultButton}`}
      disabled={!isValid}
      onClick={() => onSave(false)}
    >
      {t('challenges.buttons.saveChanges')}
    </Button>
  );

  const updateButton = (
    <UpdateOngoingChallengeModal
      type={challengeType}
      onClick={() => onSave(true)}
      status={currentChallenge?.status?.title || EChallengeStatuses.ONGOING}
    >
      <Button className={styles.button} type="primary" disabled={!isValid}>
        {challengeType === 'quiz'
          ? t('quizzes.form.updateQuiz')
          : t('challenges.buttons.updateChallenge')}
      </Button>
    </UpdateOngoingChallengeModal>
  );

  const updateButton48h = (
    <Update48hChallengeModal onClick={() => onSave(true)}>
      <Button className={styles.button} type="primary" disabled={!isValid}>
        {challengeType === 'quiz'
          ? t('quizzes.form.updateQuiz')
          : t('challenges.buttons.updateChallenge')}
      </Button>
    </Update48hChallengeModal>
  );

  const publishButton48h = (
    <Update48hChallengeModal onClick={() => onSave(true)}>
      <Button className={styles.button} type="primary" disabled={!isValid}>
        {challengeType === 'quiz'
          ? t('quizzes.buttons.publish')
          : t('challenges.buttons.publish')}
      </Button>
    </Update48hChallengeModal>
  );

  const deactivateButton = (
    <DeactivateOngoingChallengeModal
      type={challengeType}
      onClick={onUnpublish}
      status={currentChallenge?.status?.title || EChallengeStatuses.ONGOING}
    >
      <Button className={`${styles.button} ${styles.defaultButton}`}>
        {challengeType === 'quiz'
          ? t('quizzes.buttons.deactivateQuiz')
          : t('challenges.buttons.deactivateChallenge')}
      </Button>
    </DeactivateOngoingChallengeModal>
  );

  const reactivateButton = (
    <PublishChallengeModal
      type={challengeType}
      challengeName={currentChallenge?.title || ''}
      onClick={() => onSave(true)}
    >
      <Button className={`${styles.button} ${styles.defaultButton}`}>
        {challengeType === 'quiz'
          ? t('quizzes.buttons.reactivateQuiz')
          : t('challenges.buttons.reactivateChallenge')}
      </Button>
    </PublishChallengeModal>
  );

  const reactivate48hButton = (
    <Update48hChallengeModal onClick={() => onSave(true)}>
      <Button className={`${styles.button} ${styles.defaultButton}`}>
        {challengeType === 'quiz'
          ? t('quizzes.buttons.reactivateQuiz')
          : t('challenges.buttons.reactivateChallenge')}
      </Button>
    </Update48hChallengeModal>
  );

  const deleteButton = (
    <DeleteChallengeModal
      type={challengeType}
      challengeTitle={currentChallenge?.title || ''}
      onClick={onDelete}
    >
      <Button className={`${styles.button} ${styles.defaultButton}`}>
        {challengeType === 'quiz'
          ? t('quizzes.buttons.deleteQuiz')
          : t('challenges.buttons.deleteChallenge')}
      </Button>
    </DeleteChallengeModal>
  );

  const deleteHeaderMenuItem = (
    <DeleteChallengeModal
      type={challengeType}
      challengeTitle={currentChallenge?.title || ''}
      onClick={onDelete}
    >
      <Menu.Item
        className={styles.deleteButton}
        key={Math.random()}
        disabled={!currentChallenge}
      >
        {t('generic.buttons.delete')}
      </Menu.Item>
    </DeleteChallengeModal>
  );

  const draftHeaderMenuItem = (
    <Menu.Item key={Math.random()} onClick={onDraft}>
      {t('challenges.buttons.moveToDrafts')}
    </Menu.Item>
  );

  let showHiddenOptions = false;
  let mainButtons;
  let hiddenButtons = <div />;

  switch (currentChallenge?.status?.title) {
    case EChallengeStatuses.UPCOMING:
    case EChallengeStatuses.ONGOING:
      mainButtons = (
        <>
          {cancelButton}
          {deactivateButton}
          {startDayLess48h && currentChallenge?.type === EChallengeTypes.TEAM
            ? updateButton48h
            : updateButton}
        </>
      );
      showHiddenOptions = true;
      hiddenButtons = (
        <>
          {deleteHeaderMenuItem}
          {draftHeaderMenuItem}
        </>
      );
      break;

    case EChallengeStatuses.DRAFT:
      mainButtons = (
        <>
          {cancelButton}
          {saveChangesButton}
          {startDayLess48h && currentChallenge?.type === EChallengeTypes.TEAM
            ? publishButton48h
            : publishButton}
        </>
      );
      showHiddenOptions = true;
      hiddenButtons = deleteHeaderMenuItem;
      break;

    case EChallengeStatuses.FINISHED:
      mainButtons = deleteButton;
      break;

    case EChallengeStatuses.INACTIVE:
      mainButtons =
        startDayLess48h && currentChallenge?.type === EChallengeTypes.TEAM
          ? reactivate48hButton
          : reactivateButton;
      break;

    default:
      mainButtons = (
        <>
          {cancelButton}
          {saveAsDraftButton}
          {startDayLess48h && currentChallenge?.type === EChallengeTypes.TEAM
            ? publishButton48h
            : publishButton}
        </>
      );
  }

  const headerMenu = <Menu className={styles.dropdown}>{hiddenButtons}</Menu>;

  return (
    <div>
      {mainButtons}
      {showHiddenOptions && (
        <Dropdown overlay={headerMenu} placement="bottomRight">
          <Button
            style={{
              width: '32px',
              padding: 0,
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            ...
          </Button>
        </Dropdown>
      )}
    </div>
  );
}

HeaderButtons.defaultProps = {
  startDate: null,
};

export default HeaderButtons;
