import React from 'react';
import { useDispatch } from 'react-redux';
import { Table as AntTable, TablePaginationConfig } from 'antd';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../helpers/useQuery';

type TableProps = {
  columns: Array<any>;
  dataSource: Array<any>;
  pagination: TablePaginationConfig;
  actions: any;
  asyncActions?: any;
  defaultOrder?: { field: string; direction: string } | null;
  customFiltersHandler?: (filters: any, query: any) => void;
  customOrderHandler?: (sorter: any) => void;
  fetchListActionParams?: any;
  withFilters?: () => void;
  enableUrlChange?: boolean;
  [id: string]: any;
};

function Table({
  columns,
  dataSource,
  pagination,
  actions,
  asyncActions,
  defaultOrder,
  customFiltersHandler,
  fetchListActionParams,
  withFilters,
  customOrderHandler,
  enableUrlChange = true,
  ...props
}: TableProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const perPage = query.get('perPage') || 10;

  const handleTableChange = (
    tablePagination: any,
    filters: any,
    sorter: any,
  ) => {
    if (actions.clearFilters) {
      dispatch(actions.clearFilters());
    }

    if (actions.setOrder && actions.clearOrder) {
      if (sorter?.order) {
        if (customOrderHandler) {
          customOrderHandler(sorter);
        } else {
          dispatch(
            actions.setOrder({
              field: sorter.field,
              direction: sorter.order === 'descend' ? 'desc' : 'asc',
            }),
          );
        }

        if (enableUrlChange) {
          query.set(
            'sort',
            sorter.order === 'descend' ? `-${sorter.field}` : sorter.field,
          );
        }
      } else if (defaultOrder) {
        dispatch(actions.setOrder(defaultOrder));

        if (enableUrlChange) {
          query.set(
            'sort',
            defaultOrder.direction === 'desc'
              ? `-${defaultOrder.field}`
              : defaultOrder.field,
          );
        }
      } else {
        dispatch(actions.clearOrder());

        if (enableUrlChange) {
          query.delete('sort');
        }
      }
    }

    if (customFiltersHandler) {
      customFiltersHandler(filters, query);
    } else {
      Object.keys(filters).forEach((field: any) => {
        if (filters[field]?.length) {
          filters[field].forEach((filter: any) => {
            dispatch(actions.setFilter({ field, value: filter }));
          });

          if (enableUrlChange) {
            query.set(field, filters[field].join(','));
          }
        } else if (enableUrlChange) {
          query.delete(field);
        }
      });
    }

    if (withFilters) {
      withFilters();
    }

    dispatch(
      actions.setPagination({
        page: tablePagination.current,
        perPage: tablePagination.pageSize,
      }),
    );

    if (enableUrlChange) {
      query.set('page', tablePagination.current);
      query.set('perPage', tablePagination.pageSize);
    }

    if (asyncActions) {
      dispatch(asyncActions.fetchList(fetchListActionParams));
    }

    if (enableUrlChange) {
      navigate({
        search: `?${query.toString()}`,
      });
    }
  };

  return (
    <AntTable
      columns={columns}
      dataSource={dataSource}
      pagination={{
        showQuickJumper: true,
        defaultPageSize: +perPage,
        defaultCurrent: 1,
        pageSizeOptions: ['10', '20', '50', '100'],
        showSizeChanger: true,
        ...pagination,
      }}
      onChange={handleTableChange}
      {...props}
    />
  );
}

Table.defaultProps = {
  defaultOrder: null,
  customFiltersHandler: undefined,
  customOrderHandler: undefined,
  fetchListActionParams: {},
  withFilters: undefined,
  asyncActions: null,
  enableUrlChange: true,
};

export default Table;
