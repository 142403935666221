import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import styles from './Finish.module.scss';
import VisitWebsite from '../../../components/VisitWebsite/VisitWebsite';
import { Config } from '../../../config/config';
import logo from './images/logo.svg';
import AppStoresBadges from '../../../components/Badge/AppStoresBadges/AppStoresBadges';
import characters from '../../../assets/svg/characters.svg';
import LeftRowText from '../../../components/LeftRowText/LeftRowText';

function Finish() {
  const { state } = useLocation();
  const { t } = useTranslation();

  const isResetPasswordUser = state?.screen && state.screen === 'ResetPasswordUser';
  const heading = isResetPasswordUser
    ? t('auth.successResetMessage')
    : t('auth.finish.finish.title');
  const text = isResetPasswordUser
    ? t('auth.finish.passwordReset.text')
    : t('auth.finish.finish.text');

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div
          className={`${styles.rowLeft} ${
            isResetPasswordUser && styles.rowLeftMiddle
          }`}
        >
          <div className={styles.innerRowLeft}>
            <div className={styles.vitaanceTextContainer}>
              <img src={logo} className={styles.logo} />
              <span className={styles.vitaanceText}>Vitaance</span>
            </div>

            <div className={styles.containerHeading}>
              <span className={styles.heading}>{heading}</span>
            </div>

            <AppStoresBadges showScanQrCodeText />
            <img src={characters} className={styles.characters}/>

            <div className={styles.footer}>
              <VisitWebsite />
              <div className={styles.footerContainer}>
                <p className={styles.policy}>{t('generic.copyright')}</p>
                <p className={styles.copyrightUnderline}>
                  <a href={Config.LEGAL} target="_blank" rel="noreferrer">
                    <span>{t('generic.legalDocs')}</span>
                  </a>
                  {' '}
                  |
                  {' '}
                  <a href={Config.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                    <span>{t('generic.privacyPolicy')}</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rowRight}>
          <LeftRowText
            textPos={styles.textPos}
            textStyle={styles.textStyle}
            text={t('auth.onboarding.rightColumnText')}
          />
          <img src={characters} style={{ width: '90%' }} />
        </div>
      </div>
    </div>
  );
}

export default Finish;
