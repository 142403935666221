import {
  TCompany,
  TCompaniesResponse,
  TCompanyCreate,
  TCompaniesListResponse,
  TCategories,
  TCompanyUpdate,
  TMainContactCreationResponse,
} from './CompanyType';
import {
  apiRequest,
  apiRequestGet,
  TErrorPayload,
  TListParams,
} from '../../../helpers/apiRequest';

export const createCompany = async (
  CompanyData: TCompanyCreate,
): Promise<TCompaniesResponse> =>
  apiRequest<TCompaniesResponse>('/companies', 'post', CompanyData, { version: 'v2' });

export const fetchCompanies = async (
  listParams?: TListParams,
): Promise<TCompaniesListResponse> =>
  apiRequestGet<TCompaniesListResponse>('/companies', {...listParams, version: 'v2' });

export const getCompanyCategories = async (): Promise<TCategories> =>
  apiRequestGet<TCategories>('/companies/categories/activities', { version: 'v2' });

export const deleteCompany = async (
  id: TCompany['id'],
): Promise<TCompaniesResponse> =>
  apiRequest<TCompaniesResponse>(`/companies/${id}`, 'delete', null, { version: 'v2' });

export const getCompany = async (
  id: TCompany['id'],
): Promise<TCompaniesResponse> =>
  apiRequestGet<TCompaniesResponse>(`/companies/${id}`, { version: 'v2' });

export const updateCompany = async (
  id: TCompany['id'],
  companyData: TCompanyUpdate,
): Promise<TCompaniesResponse> =>
  apiRequest<TCompaniesResponse>(
    `/companies/${id}`,
    'patch',
    companyData,
    { version: 'v2' },
  );

export const activeCompany = async (id: number): Promise<TCompaniesResponse> =>
  apiRequest<TCompaniesResponse>(
    `/companies/${id}/activate`,
    'post',
    null,
    { version: 'v2' },
  );

export const desactiveCompany = async (
  id: number,
): Promise<TCompaniesResponse> =>
  apiRequest<TCompaniesResponse>(
    `/companies/${id}/deactivate`,
    'post',
    null,
    { version: 'v2' },
  );

export const fetchAllCompanies = async (
  listParams?: TListParams,
): Promise<TCompaniesListResponse> =>
  apiRequestGet<TCompaniesListResponse>('/companies/all', {...listParams, version: 'v2' });

export const createAdminInCompany = async (AdminData: {
  companyId: number;
  adminId: number;
}): Promise<TMainContactCreationResponse> =>
  apiRequest<TMainContactCreationResponse>(
    `/companies/${AdminData.companyId}/admins/${AdminData.adminId}`,
    'post',
    null,
    { version: 'v2' }
  );

export const deletAdminIncompany = async (AdminData: {
  companyId: number;
  adminId: number;
}): Promise<TMainContactCreationResponse> =>
  apiRequest<TMainContactCreationResponse>(
    `/companies/${AdminData.companyId}/admins/${AdminData.adminId}`,
    'delete',
    null,
    { version: 'v2' },
  );
export const validate = async (
  companyData: TCompanyCreate,
): Promise<TErrorPayload | void> =>
  apiRequest<TErrorPayload | void>(
    '/companies/verify',
    'post',
    companyData,
    { version: 'v2' },
  );
