import styles from './VisitWebsite.module.scss';
import { Config } from '../../config/config';
import { useTranslation } from 'react-i18next';

function VisitWebsite() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.container}>
        <span className={styles.text}>
          {t('VisitWebsite.title')}{' '}
          <a href={Config.WEB_SITE} target="_blank" className={styles.link}>
            {t('VisitWebsite.titleLink')}
          </a>
        </span>
      </div>
    </>
  );
}

export default VisitWebsite;
