import {
  apiRequest,
  apiRequestGet,
  TListParams,
} from '../../helpers/apiRequest';
import {
  TActiveChallengeListResponse,
  TAssignedProductListResponse,
  TClaimedRewardListResponse,
  TUser,
  TUserCreate,
  TUserListResponse,
  TUserResponse,
  TUserUpdate,
} from './UsersTypes';

export const createUser = async (
  userData: TUserCreate,
): Promise<TUserResponse> =>
  apiRequest<TUserResponse>('/users', 'post', userData, { version: 'v2' });

export const publishUser = async (id: TUser['id']): Promise<TUserResponse> =>
  apiRequest<TUserResponse>(`/users/${id}/publish`, 'post', null, null);

export const unpublishUser = async (id: TUser['id']): Promise<TUserResponse> =>
  apiRequest<TUserResponse>(`/users/${id}/unpublish`, 'post', null, null);

export const fetchUsers = async (
  listParams?: TListParams,
): Promise<TUserListResponse> =>
  apiRequestGet<TUserListResponse>('/users', {...listParams, version: 'v2'});

export const deleteUser = async (id: TUser['id']): Promise<TUserResponse> =>
  apiRequest<TUserResponse>(`/users/${id}`, 'delete', null, { version: 'v2' });

export const getUser = async (id: TUser['id']): Promise<TUserResponse> =>
  apiRequestGet<TUserResponse>(`/users/${id}`, {version: 'v2'});

export const updateUser = async (
  id: TUser['id'],
  userData: TUserUpdate,
): Promise<TUserResponse> =>
  apiRequest<TUserResponse>(`/users/${id}`, 'patch', userData, { version: 'v2' });

export const fetchActiveChallenges = async (
  listParams?: TListParams,
  id?: TUser['id'],
): Promise<TActiveChallengeListResponse> =>
  apiRequestGet<TActiveChallengeListResponse>(`/users/${id}/challenges/active`);

export const fetchClaimedrewards = async (
  listParams?: TListParams,
  id?: TUser['id'],
): Promise<TClaimedRewardListResponse> =>
  apiRequestGet<TClaimedRewardListResponse>(`/users/${id}/claimed-rewards`, { version: 'v2' });

export const resendInvitation = async (
  id?: TUser['id'],
): Promise<TUserResponse> =>
  apiRequest<TUserResponse>(`/auth/users/${id}/invite`, 'post', null, { version: 'v2' });

export const fetchAssignedProducts = async (
  listParams?: TListParams,
  id?: TUser['id'],
): Promise<TAssignedProductListResponse> =>
  apiRequestGet<TAssignedProductListResponse>(`/users/${id}/products`);
