import React from 'react';
import styles from './FormCard.module.scss';

type TFormCardProps = {
  children: JSX.Element;
  className?: string;
};

function FormCard({ children, className }: TFormCardProps) {
  return <div className={`${styles.card} ${className ?? ''}`}>{children}</div>;
}

export default FormCard;
