import {
  apiRequest,
  apiRequestFormData,
  apiRequestGet,
  TListParams,
} from '../../helpers/apiRequest';
import {
  TReward,
  TRewardsResponse,
  TSingleRewardResponse,
} from './RewardsTypes';

export const createReward = async (
  rewardData: FormData,
): Promise<TSingleRewardResponse> =>
  apiRequestFormData<TSingleRewardResponse>(
    '/rewards',
    'post',
    rewardData,
    { version: 'v2' },
  );

export const publishReward = async (
  id: TReward['id'],
): Promise<TSingleRewardResponse> =>
  apiRequest<TSingleRewardResponse>(
    `/rewards/${id}/publish`,
    'post',
    null,
    { version: 'v2' },
  );

export const unpublishReward = async (
  id: TReward['id'],
): Promise<TSingleRewardResponse> =>
  apiRequest<TSingleRewardResponse>(
    `/rewards/${id}/unpublish`,
    'post',
    null,
    { version: 'v2' },
  );

export const saveAsDraftReward = async (
  id: TReward['id'],
): Promise<TSingleRewardResponse> =>
  apiRequest<TSingleRewardResponse>(
    `/rewards/${id}/save-as-draft`,
    'post',
    null,
    { version: 'v2' },
  );

export const fetchRewards = async (
  listParams?: TListParams,
): Promise<TRewardsResponse> =>
  apiRequestGet<TRewardsResponse>('/rewards', {...listParams, version: 'v2' });

export const deleteReward = async (
  id: TReward['id'],
): Promise<TSingleRewardResponse> =>
  apiRequest<TSingleRewardResponse>(`/rewards/${id}`, 'delete', null, { version: 'v2' });

export const getReward = async (
  id: TReward['id'],
): Promise<TSingleRewardResponse> =>
  apiRequestGet<TSingleRewardResponse>(`/rewards/${id}`, { version: 'v2' });

export const updateReward = async (
  id: TReward['id'],
  rewardData: FormData,
): Promise<TSingleRewardResponse> =>
  apiRequestFormData<TSingleRewardResponse>(
    `/rewards/${id}`,
    'patch',
    rewardData,
    { version: 'v2' },
  );
